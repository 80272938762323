
import { DrawerProps } from "@fluentui/react-components";
import * as React from "react";
import {
  Hamburger,
  NavCategory,
  NavCategoryItem,
  NavDivider,
  NavDrawer,
  NavDrawerBody,
  NavDrawerHeader,
  NavDrawerProps,
  NavItem,
  NavSubItem,
  NavSubItemGroup,
} from "@fluentui/react-nav-preview";

import { Tooltip, makeStyles, tokens, useId } from "@fluentui/react-components";
import {
  MailTemplate24Regular
} from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";
import { convertStringToArray } from "../../utils";

const useStyles = makeStyles({
  root: {
    overflow: "hidden",
    display: "flex",
    height: "calc(100vh - 100px)",
    width: "200px",
    backgroundColor: "white !importent",
    border: "0.5px sold black",
  },
  content: {
    flex: "1",
    padding: "16px",
    display: "grid",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  field: {
    display: "flex",
    marginTop: "4px",
    marginLeft: "8px",
    flexDirection: "column",
    gridRowGap: tokens.spacingVerticalS,
  },
});
type DrawerType = Required<DrawerProps>["type"];
const Sidebar = (props: Partial<NavDrawerProps>) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(true);
  const [type, setType] = React.useState<DrawerType>("inline");
  return (
      <div className={styles.root}>
      <NavDrawer
        open={isOpen}
        type={type}
        size="medium"
        defaultSelectedValue="utilities"
      >
         <NavDrawerBody>
         {!convertStringToArray(process.env.REACT_APP_HIDE_SIDE_TAB).includes(
          "utilities_email"
        ) ? (
         <NavItem
              onClick={() => {
                navigate("/utilities");
              }}
              value="utilities"
              icon={<MailTemplate24Regular />}
            >
             Email
            </NavItem>
                    ) : (
                      ""
                    )}
         </NavDrawerBody>
        </NavDrawer>
      </div>
  )
}

export default Sidebar