import { Dispatch, SetStateAction, useEffect } from "react";
import "./style.css";
import { Outlet } from "react-router-dom";
import ModalDialog from "../../components/ModalDialog";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface PropsType {
  setTabs: Dispatch<SetStateAction<string>>;
}

const SystemManagerLayout = ({ setTabs }: PropsType) => {
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);

  useEffect(() => {
    setTabs("systemManager");
  }, []);

  return (
    <>
      <ModalDialog options={dialogModalOptions} />
      <Outlet />
    </>
  );
};

export default SystemManagerLayout;
