import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  Dropdown,
  Input,
  Spinner,
} from "@fluentui/react-components";
import {
  Tree,
  TreeItem,
  TreeItemLayout,
} from "@fluentui/react-components/unstable";
import React, { useEffect, useState } from "react";
import { makeStyles, shorthands } from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import apiServices from "../../service";
import ClientSelection from "./ClientSelection";
import "./style.css";
import moment from "moment";
import axios from "axios";
import store from "../../redux/store";
import OverlayLoader from "../../components/OverlayLoader";
import { formatedDate2 } from "../../utils";
const useStyles = makeStyles({
  root: {},
  division: {
    ...shorthands.gap("10px"),
    display: "flex",
  },
  main: {
    display: "flex",
    ...shorthands.gap("15px"),
    zIndex: "999",
  },
  container: {
    display: "flex",
    minHeight: "519px",
    ...shorthands.gap("6px"),
  },
  tree: {
    zIndex: "999",
  },
});

type TreeNode = {
  ReportCategoryId?: string;
  Name?: string;
  Title?: string;
  Description?: string;
  Type?: string;
  Parent?: string | null;
  children?: TreeNode[];
  IsActive?: boolean;
  CategoryCode?: any;
  input?: any;
  name?: any;
  title?: any;
  description?: any;
  ReportCode?: any;
  scope?: any;
  ContentType?: any;
};
type selectedValue = {
  customer_id: string;
  first_names: string;
  last_name: string;
};

const ReportDemo = () => {
  const [reportList, setReportList] = useState<TreeNode[]>([]);
  const [selectedOption, setSelectedOption] = useState<string | undefined>("");
  const [inputType, setInputType] = useState<{
    limit?: boolean;
    customerId?: boolean;
    endDate?: boolean;
    startDate?: boolean;
  }>({});
  const [isContentType, setContentType] = useState("");
  const [isDropdownExpand, setDropdownExpand] = useState(false);
  const [treeKey, setTreeKey] = useState(0);
  const [isFile, setFile] = useState<any>();
  const [isReportCode, setReportCode] = useState<any>();
  const [clients, setClients] = useState<selectedValue[]>([]);
  const [showClientSelection, setShowClientSelection] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedClient, setSelectedClient] = useState<selectedValue[]>([]);
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");
  const [isLimit, setLimit] = useState<any>("");
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isScope, setScope] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [reportGenerated, setReportGenerated] = useState<boolean>();

  let timestamp: any = new Date();
  timestamp = timestamp.toISOString();
  const generateNonce = () => {
    const CryptoJS = require("crypto-js");
    const requestData = {};
    const hash = CryptoJS.SHA256(`${requestData}${timestamp}`);
    return hash.toString(CryptoJS.enc.Hex);
  };
  const nonce = generateNonce();

  const handleDropdownToggle = (isOpen: any) => {
    setIsDropdownOpen(isOpen);
    setLimit("");
  };

  const getClientList = () => {
    apiServices.notification
      .clientList()
      .then((response: any) => {
        if (!response.data.isError) {
          if (response.data.data) {
            setClients(response.data.data.res);
          }
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  // useEffect(() => {
  //   getClientList();
  // }, []);
  const handleClientSelection = (selectedIds: string[]) => {
    const selectedClient = clients.find((client: any) =>
      selectedIds.includes(client?.customer_id)
    );

    if (selectedClient) {
      setSelectedOptions([selectedClient?.customer_id]);
      setSelectedClient([selectedClient]);
    }
  };

  const renderTreeReport = (
    data: TreeNode[],
    handleOptionClick: (node: TreeNode) => void
  ): React.ReactNode => {
    return data.map((node) => {
      if (node.children && node.children.length > 0) {
        return (
          <TreeItem itemType="branch" key={node.Name}>
            <TreeItemLayout>{node.Name}</TreeItemLayout>
            <Tree>{renderTreeReport(node.children, handleOptionClick)}</Tree>
          </TreeItem>
        );
      } else {
        return (
          <TreeItem
            itemType="leaf"
            key={node.Name}
            onClick={() => handleOptionClick(node)}
          >
            <TreeItemLayout>{node.Name}</TreeItemLayout>
          </TreeItem>
        );
      }
    });
  };

  const toggleDropdown = () => {
    setDropdownExpand((prevState) => !prevState);
    setTreeKey((prevKey) => prevKey + 1);
  };

  const handleOptionClick = (node: TreeNode) => {
    setSelectedOption(node.Name);
    setReportCode(node.ReportCode);
    setScope(node.scope);
    setContentType(node.ContentType);
    setSuccessMessage("");
    setErrorMessage("");
    setSelectedClient([]);
    setLimit("");
    setStartDate("");
    setEndDate("");
    setReportGenerated(false);
    let inputTypes: any = [];

    if (node.input) {
      const { customerId, endDate, startDate, limit } = node.input;

      if (customerId?.type) {
        inputTypes.push(...customerId.type.split(","));
      }
      if (endDate?.type) {
        inputTypes.push(...endDate.type.split(","));
      }
      if (startDate?.type) {
        inputTypes.push(...startDate.type.split(","));
      }
      if (limit?.type) {
        inputTypes.push(...limit.type.split(","));
      }
    }

    setInputType({
      limit: inputTypes.includes("number"),
      customerId: inputTypes.includes("text"),
      endDate: inputTypes.includes("date"),
      startDate: inputTypes.includes("date"),
    });

    setDropdownExpand(false);
  };

  const styles = useStyles();

  const getReportList = () => {
    apiServices.getReport
      .getList()
      .then((res: any) => {
        if (!res.data.isError) {
          if (res.data) {
            setReportList(res.data);
          }
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  useEffect(() => {
    getReportList();
  }, []);

  const generateReport = async () => {
    const data = {
      startDate: formatedDate2(startDate) ? formatedDate2(startDate) : "",
      endDate: formatedDate2(endDate) ? formatedDate2(endDate) : "",
      customerId: selectedClient[0]?.customer_id,
      limit: isLimit ? isLimit : "",
    };

    const report = isReportCode;
    const scope = isScope;
    const token = store.getState().authUser.userToken;
    const userId = store.getState().authUser.userId;
    const refreshToken = store.getState().authUser.refreshToken;
    const formatType = isContentType;
    const trimmedString = isReportCode?.split("-")[1]?.trim() || "";
    try {
      setLoading(true);

      let url = "";
      let fileExtension = "";
      let response;

      switch (formatType) {
        case "pdf":
          url = `${process.env.REACT_APP_BASE_URL}report/factfind/${trimmedString}/${data.customerId}?formatType=${formatType}&context=${report}`;
          fileExtension = isContentType;
          response = await axios.get(url, {
            headers: {
              "Content-Type": "application/pdf",
              Authorization: `Bearer ${token}`,
              "X-Nonce": nonce,
              "X-Timestamp": timestamp,
              UserId: userId,
              RefreshToken: refreshToken,
            },
            responseType: "blob",
          });
          break;
        case "xlsx":
          url = `${process.env.REACT_APP_BASE_URL}report/new/${scope}/${report}`;
          fileExtension = isContentType;
          response = await axios.post(url, data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "X-Nonce": nonce,
              "X-Timestamp": timestamp,
              UserId: userId,
              RefreshToken: refreshToken,
            },
            responseType: "blob",
          });
          break;
        default:
          throw new Error("Unsupported ContentType");
      }

      setLoading(false);

      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = fileUrl;
      const timestamp1 = new Date().toISOString().replace(/[-:.]/g, "");

      link.setAttribute("download", `${report}_${timestamp1}.${fileExtension}`);
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(fileUrl);
      setSuccessMessage("Report generated successfully! ");
      setErrorMessage(null);
      setReportGenerated(true);
    } catch (error) {
      setLoading(false);
      console.error("Error downloading file:", error);
      setSuccessMessage(null);
      setErrorMessage("Error generating report. Please try again.");
      setReportGenerated(true);
    }

    setSelectedOption("");
    setSelectedClient([]);
    setLimit("");
    setStartDate("");
    setEndDate("");
  };

  const limitOfReport = (e: any, x: any) => {
    setLimit(x.value);
  };
  const isGenerateButtonDisabled = () => {
    if (!selectedOption) {
      return true;
    }
    if (
      !inputType ||
      (Object.keys(inputType).length === 0 && inputType.constructor === Object)
    ) {
      return false;
    }
    if (
      inputType.customerId &&
      (!selectedClient ||
        selectedClient.length === 0 ||
        !selectedClient[0]?.customer_id)
    ) {
      return true;
    }
    if (inputType.limit && !isLimit) {
      return true;
    }
    if (inputType.startDate && !startDate) {
      return true;
    }
    if (inputType.endDate && !endDate) {
      return true;
    }
    if (loading) {
      return true;
    }
    return false;
  };

  return (
    <>
      <OverlayLoader isLoading={loading} />
      <div className={styles.container}>
        <div className={styles.root}>
          <Dropdown
            open={isDropdownExpand}
            onClick={toggleDropdown}
            aria-owns="listbox-id"
            size="small"
            onOpenChange={handleDropdownToggle}
            placeholder={selectedOption || "Select Report"}
          >
            <Tree aria-label="Dynamic Tree">
              {renderTreeReport(reportList, handleOptionClick)}
            </Tree>
          </Dropdown>
        </div>
        {!reportGenerated && (
          <div className={styles.division}>
            {inputType.customerId && (
              <div>
                <Input
                  size="small"
                  onClick={() => setShowClientSelection(true)}
                  readOnly
                  placeholder={
                    selectedClient.length > 0
                      ? selectedClient
                          .map((client) =>
                            client.first_names.length > 0
                              ? client.first_names
                              : client.last_name
                          )
                          .join(", ")
                      : "Select a Client"
                  }
                />
              </div>
            )}

            {inputType.limit && (
              <div>
                <Input
                  placeholder="Limit"
                  type="number"
                  size="small"
                  value={isLimit}
                  onChange={limitOfReport}
                />
              </div>
            )}
            {inputType.startDate && (
              <>
                <div>
                  <DatePicker
                    placeholder="Start Date"
                    allowTextInput
                    value={startDate}
                    onSelectDate={(date) => {
                      if (date) {
                        setStartDate(date);
                      }
                    }}
                    required
                    size="small"
                  />
                </div>
              </>
            )}
            {inputType.endDate && (
              <>
                <div>
                  <DatePicker
                    size="small"
                    placeholder="End Date"
                    value={endDate}
                    required
                    onSelectDate={(date) => {
                      if (date) {
                        setEndDate(date);
                      }
                    }}
                  />
                </div>
              </>
            )}
          </div>
        )}
        <div>
          <Button
            appearance="primary"
            size="small"
            disabled={isGenerateButtonDisabled()}
            onClick={generateReport}
          >
            {loading ? "Generating Report..." : "Generate Report"}
          </Button>
        </div>
      </div>
      <>
        {successMessage && (
          <div className="error-message">{successMessage}</div>
        )}
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </>
      <Dialog
        modalType="alert"
        open={showClientSelection}
        onOpenChange={(event, data) => setShowClientSelection(data.open)}
      >
        <DialogSurface>
          <DialogBody>
            <DialogContent>
              <ClientSelection
                onSelect={handleClientSelection}
                handleClientSelection={handleClientSelection}
                setClientList={setClients}
              />
            </DialogContent>
            <DialogActions>
              <Button
                appearance="secondary"
                onClick={() => {
                  setShowClientSelection(false);
                  setSelectedClient([]);
                }}
                size="small"
              >
                Close
              </Button>
              <Button
                className="asc-button-primary"
                appearance="primary"
                onClick={() => setShowClientSelection(false)}
                size="small"
              >
                Select
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </>
  );
};

export default ReportDemo;
