import { useFormik } from "formik";

interface InputParams {
  initialValues: {
    [key: string]: any;
  };
  onSubmitFn: (values: { [key: string]: any }) => any;
  validate: (values: any) => any;
}

const FormikInputRequest = ({ initialValues, onSubmitFn, validate }: InputParams) =>
  useFormik({
    initialValues,
    onSubmit: onSubmitFn,
    validate: validate
  });

export default FormikInputRequest;