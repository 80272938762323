// policy
export const lifeAssurance = [
    {
        LifeAssurance_id: 0,
        label: "Case Type",
        value: "case_type",
    },
    {
        LifeAssurance_id: 1,
        label: "Policyholder",
        value: "policy_holder",
    },
    {
        LifeAssurance_id: 2,
        label: "Assured",
        value: "assured_detail",
    },
    {
        LifeAssurance_id: 3,
        label: "Assured Name 1",
        value: "assured_name1",
    },
    {
        LifeAssurance_id: 4,
        label: "Assured Name 2",
        value: "assured_name2",
    },
    {
        LifeAssurance_id: 5,
        label: "Subtype",
        value: "subtype",
    },
    {
        LifeAssurance_id: 6,
        label: "Provider",
        value: "provider_name",
    },
    {
        LifeAssurance_id: 7,
        label: "Provider Contact",
        value: "provider_contact_name",
    },
    {
        LifeAssurance_id: 8,
        label: "Provider Phone",
        value: "provider_phone",
    },
    {
        LifeAssurance_id: 9,
        label: "Provider Note",
        value: "provider_note",
    },
    {
        LifeAssurance_id: 10,
        label: "Purpose",
        value: "purpose",
    },
    {
        LifeAssurance_id: 11,
        label: "Linked to Mortgage",
        value: "mortgage_link",
    },
    {
        LifeAssurance_id: 12,
        label: "Start Date",
        value: "start_date",
    },
    {
        LifeAssurance_id: 13,
        label: "End Date",
        value: "end_date",
    },
    {
        LifeAssurance_id: 14,
        label: "Term",
        value: "term",
    },
    {
        LifeAssurance_id: 15,
        label: "Scheme Name",
        value: "scheme_name",
    },
    {
        LifeAssurance_id: 16,
        label: "Policy Number",
        value: "policy_number",
    },
    {
        LifeAssurance_id: 17,
        label: "Other Reference",
        value: "other_reference",
    },
    {
        LifeAssurance_id: 18,
        label: "Status",
        value: "status",
    },
    {
        LifeAssurance_id: 19,
        label: "Status Date",
        value: "status_date",
    },
    {
        LifeAssurance_id: 20,
        label: "Service Status",
        value: "service_status",
    },
    {
        LifeAssurance_id: 21,
        label: "Premium Indexed",
        value: "premium_indexed",
    },
    {
        LifeAssurance_id: 22,
        label: "Waiver",
        value: "waiver",
    },
    {
        LifeAssurance_id: 23,
        label: "Surrender Value",
        value: "surrender_value",
    },
    {
        LifeAssurance_id: 24,
        label: "Future Value",
        value: "future_value",
    },
    {
        LifeAssurance_id: 25,
        label: "Total Fund Value",
        value: "total_fund_value",
    },
    {
        LifeAssurance_id: 26,
        label: "Valuation Date",
        value: "valuation_date",
    },
    {
        LifeAssurance_id: 27,
        label: "Sum Assured",
        value: "lump_sum_benefit",
    },
    {
        LifeAssurance_id: 28,
        label: "Periodic Benefit",
        value: "periodic_benefit",
    },
    {
        LifeAssurance_id: 29,
        label: "Benefit Frequency",
        value: "benefit_frequency",
    },
    {
        LifeAssurance_id: 30,
        label: "Benefits Indexed",
        value: "benefits_indexed",
    },
    {
        LifeAssurance_id: 31,
        label: "Benefit Note",
        value: "benefit_text",
    },
    {
        LifeAssurance_id: 32,
        label: "Rated",
        value: "rated",
    },
    {
        LifeAssurance_id: 33,
        label: "In Trust",
        value: "in_trust",
    },
    {
        LifeAssurance_id: 34,
        label: "In Trust Note",
        value: "in_trust_note",
    },
    {
        LifeAssurance_id: 35,
        label: "Age Admitted",
        value: "age_admitted",
    },
    {
        LifeAssurance_id: 36,
        label: "Assigned To",
        value: "assigned_to",
    },
    {
        LifeAssurance_id: 37,
        label: "Admin Note",
        value: "admin_note",
    },
    {
        LifeAssurance_id: 38,
        label: "Report Note",
        value: "report_note",
    },
]
export const pensions = [
    {
        Pensions_id: 0,
        label: "Case Type",
        value: "case_type",
    },
    {
        Pensions_id: 1,
        label: "Policyholder",
        value: "policy_holder",
    },
    {
        Pensions_id: 2,
        label: "Assured",
        value: "assured_detail",
    },
    {
        Pensions_id: 3,
        label: "Assured Name 1",
        value: "assured_name1",
    },
    {
        Pensions_id: 4,
        label: "Assured Name 2",
        value: "assured_name2",
    },
    {
        Pensions_id: 5,
        label: "Subtype",
        value: "subtype",
    },
    {
        Pensions_id: 6,
        label: "Provider",
        value: "provider_name",
    },
    {
        Pensions_id: 7,
        label: "Provider Contact",
        value: "provider_contact_name",
    },
    {
        Pensions_id: 8,
        label: "Provider Phone",
        value: "provider_phone",
    },
    {
        Pensions_id: 9,
        label: "Provider Note",
        value: "provider_note",
    },
    {
        Pensions_id: 10,
        label: "Purpose",
        value: "purpose",
    },
    {
        Pensions_id: 11,
        label: "Start Date",
        value: "start_date",
    },
    {
        Pensions_id: 12,
        label: "End Date",
        value: "end_date",
    },
    {
        Pensions_id: 13,
        label: "Term",
        value: "term",
    },
    {
        Pensions_id: 14,
        label: "Scheme Name",
        value: "scheme_name",
    },
    {
        Pensions_id: 15,
        label: "Policy Number",
        value: "policy_number",
    },
    {
        Pensions_id: 16,
        label: "Other Reference",
        value: "other_reference",
    },
    {
        Pensions_id: 17,
        label: "Status",
        value: "status",
    },
    {
        Pensions_id: 18,
        label: "Status Date",
        value: "status_date",
    },
    {
        Pensions_id: 19,
        label: "Service Status",
        value: "service_status",
    },
    {
        Pensions_id: 20,
        label: "Contracted Out",
        value: "contracted_out",
    },
    {
        Pensions_id: 21,
        label: "Premium Indexed",
        value: "premium_indexed",
    },
    {
        Pensions_id: 22,
        label: "Waiver",
        value: "waiver",
    },
    {
        Pensions_id: 23,
        label: "Surrender Value",
        value: "transfer_value",
    },
    {
        Pensions_id: 24,
        label: "Future Value",
        value: "future_value",
    },
    {
        Pensions_id: 25,
        label: "Total Fund Value",
        value: "total_fund_value",
    },
    {
        Pensions_id: 26,
        label: "Valuation Date",
        value: "valuation_date",
    },
    {
        Pensions_id: 27,
        label: "Expression of Wish",
        value: "expression_of_wish",
    },
    {
        Pensions_id: 28,
        label: "Lump Sum Benefit",
        value: "lump_sum_benefit",
    },
    {
        Pensions_id: 29,
        label: "Periodic Benefit",
        value: "periodic_benefit",
    },
    {
        Pensions_id: 30,
        label: "Benefit Frequency",
        value: "benefit_frequency",
    },
    {
        Pensions_id: 31,
        label: "Benefits Indexed",
        value: "benefits_indexed",
    },
    {
        Pensions_id: 32,
        label: "Benefit Note",
        value: "benefit_text",
    },
    {
        Pensions_id: 33,
        label: "In Trust",
        value: "in_trust",
    },
    {
        Pensions_id: 34,
        label: "In Trust Note",
        value: "in_trust_note",
    },
    {
        Pensions_id: 35,
        label: "Admin Note",
        value: "admin_note",
    },
    {
        Pensions_id: 36,
        label: "Report Note",
        value: "report_note",
    },
]
export const savingsPlans = [
    {
        SavingsPlans_id: 0,
        label: "Case Type",
        value: "case_type",
    },
    {
        SavingsPlans_id: 1,
        label: "Policyholder",
        value: "policy_holder",
    },
    {
        SavingsPlans_id: 2,
        label: "Assured",
        value: "assured_detail",
    },
    {
        SavingsPlans_id: 3,
        label: "Assured Name 1",
        value: "assured_name1",
    },
    {
        SavingsPlans_id: 4,
        label: "Assured Name 2",
        value: "assured_name2",
    },
    {
        SavingsPlans_id: 5,
        label: "Subtype",
        value: "subtype",
    },
    {
        SavingsPlans_id: 6,
        label: "Provider",
        value: "provider_name",
    },
    {
        SavingsPlans_id: 7,
        label: "Provider Contact",
        value: "provider_contact_name",
    },
    {
        SavingsPlans_id: 8,
        label: "Provider Phone",
        value: "provider_phone",
    },
    {
        SavingsPlans_id: 9,
        label: "Provider Note",
        value: "provider_note",
    },
    {
        SavingsPlans_id: 10,
        label: "Purpose",
        value: "purpose",
    },
    {
        SavingsPlans_id: 11,
        label: "Start Date",
        value: "start_date",
    },
    {
        SavingsPlans_id: 12,
        label: "End Date",
        value: "end_date",
    },
    {
        SavingsPlans_id: 13,
        label: "Term",
        value: "term",
    },
    {
        SavingsPlans_id: 14,
        label: "Scheme Name",
        value: "scheme_name",
    },
    {
        SavingsPlans_id: 15,
        label: "Policy Number",
        value: "policy_number",
    },
    {
        SavingsPlans_id: 16,
        label: "Other Reference",
        value: "other_reference",
    },
    {
        SavingsPlans_id: 17,
        label: "Status",
        value: "status",
    },
    {
        SavingsPlans_id: 18,
        label: "Status Date",
        value: "status_date",
    },
    {
        SavingsPlans_id: 19,
        label: "Service Status",
        value: "service_status",
    },
    {
        SavingsPlans_id: 20,
        label: "Premium Indexed",
        value: "premium_indexed",
    },
    {
        SavingsPlans_id: 21,
        label: "Waiver",
        value: "waiver",
    },
    {
        SavingsPlans_id: 22,
        label: "Surrender Value",
        value: "surrender_value",
    },
    {
        SavingsPlans_id: 23,
        label: "Future Value",
        value: "future_value",
    },
    {
        SavingsPlans_id: 24,
        label: "Total Fund Value",
        value: "total_fund_value",
    },
    {
        SavingsPlans_id: 25,
        label: "Valuation Date",
        value: "valuation_date",
    },
    {
        SavingsPlans_id: 26,
        label: "Lump Sum Benefit",
        value: "lump_sum_benefit",
    },
    {
        SavingsPlans_id: 27,
        label: "Periodic Benefit",
        value: "periodic_benefit",
    },
    {
        SavingsPlans_id: 28,
        label: "Benefit Frequency",
        value: "benefit_frequency",
    },
    {
        SavingsPlans_id: 29,
        label: "Benefits Indexed",
        value: "benefits_indexed",
    },
    {
        SavingsPlans_id: 30,
        label: "Benefit Note",
        value: "benefit_text",
    },
    {
        SavingsPlans_id: 31,
        label: "Rated",
        value: "rated",
    },
    {
        SavingsPlans_id: 32,
        label: "In Trust",
        value: "in_trust",
    },
    {
        SavingsPlans_id: 33,
        label: "In Trust Note",
        value: "in_trust_note",
    },
    {
        SavingsPlans_id: 34,
        label: "Age Admitted",
        value: "age_admitted",
    },
    {
        SavingsPlans_id: 35,
        label: "Assigned To",
        value: "assigned_to",
    },
    {
        SavingsPlans_id: 36,
        label: "Admin Note",
        value: "admin_note",
    },
    {
        SavingsPlans_id: 37,
        label: "Report Note",
        value: "report_note",
    },
]
export const incomeProtection = [
    {
        IncomeProtection_id: 0,
        label: "Case Type",
        value: "case_type",
    },
    {
        IncomeProtection_id: 1,
        label: "Policyholder",
        value: "policy_holder",
    },
    {
        IncomeProtection_id: 2,
        label: "Assured",
        value: "assured_detail",
    },
    {
        IncomeProtection_id: 3,
        label: "Assured Name 1",
        value: "assured_name1",
    },
    {
        IncomeProtection_id: 4,
        label: "Assured Name 2",
        value: "assured_name2",
    },
    {
        IncomeProtection_id: 5,
        label: "Subtype",
        value: "subtype",
    },
    {
        IncomeProtection_id: 6,
        label: "Provider",
        value: "provider_name",
    },
    {
        IncomeProtection_id: 7,
        label: "Provider Contact",
        value: "provider_contact_name",
    },
    {
        IncomeProtection_id: 8,
        label: "Provider Phone",
        value: "provider_phone",
    },
    {
        IncomeProtection_id: 9,
        label: "Provider Note",
        value: "provider_note",
    },
    {
        IncomeProtection_id: 10,
        label: "Purpose",
        value: "purpose",
    },
    {
        IncomeProtection_id: 11,
        label: "Start Date",
        value: "start_date",
    },
    {
        IncomeProtection_id: 12,
        label: "End Date",
        value: "end_date",
    },
    {
        IncomeProtection_id: 13,
        label: "Term",
        value: "term",
    },
    {
        IncomeProtection_id: 14,
        label: "Scheme Name",
        value: "scheme_name",
    },
    {
        IncomeProtection_id: 15,
        label: "Policy Number",
        value: "policy_number",
    },
    {
        IncomeProtection_id: 16,
        label: "Other Reference",
        value: "other_reference",
    },
    {
        IncomeProtection_id: 17,
        label: "Status",
        value: "status",
    },
    {
        IncomeProtection_id: 18,
        label: "Status Date",
        value: "status_date",
    },
    {
        IncomeProtection_id: 19,
        label: "Service Status",
        value: "service_status",
    },
    {
        IncomeProtection_id: 20,
        label: "Premium Indexed",
        value: "premium_indexed",
    },
    {
        IncomeProtection_id: 21,
        label: "Waiver",
        value: "waiver",
    },
    {
        IncomeProtection_id: 22,
        label: "Total Fund Value",
        value: "total_fund_value",
    },
    {
        IncomeProtection_id: 23,
        label: "Valuation Date",
        value: "valuation_date",
    },
    {
        IncomeProtection_id: 24,
        label: "Lump Sum Benefit",
        value: "lump_sum_benefit",
    },
    {
        IncomeProtection_id: 25,
        label: "Periodic Benefit",
        value: "periodic_benefit",
    },
    {
        IncomeProtection_id: 26,
        label: "Benefit Frequency",
        value: "benefit_frequency",
    },
    {
        IncomeProtection_id: 27,
        label: "Benefits Indexed",
        value: "benefits_indexed",
    },
    {
        IncomeProtection_id: 28,
        label: "Benefit Note",
        value: "benefit_text",
    },
    {
        IncomeProtection_id: 29,
        label: "Deferred Period",
        value: "deffered_period",
    },
    {
        IncomeProtection_id: 30,
        label: "Rated",
        value: "rated",
    },
    {
        IncomeProtection_id: 31,
        label: "In Trust",
        value: "in_trust",
    },
    {
        IncomeProtection_id: 32,
        label: "In Trust Note",
        value: "in_trust_note",
    },
    {
        IncomeProtection_id: 33,
        label: "Age Admitted",
        value: "age_admitted",
    },
    {
        IncomeProtection_id: 34,
        label: "Admin Note",
        value: "admin_note",
    },
    {
        IncomeProtection_id: 35,
        label: "Report Note",
        value: "report_note",
    },
]
export const healthAssurance = [
    {
        HealthAssurance_id: 0,
        label: "Case Type",
        value: "case_type",
    },
    {
        HealthAssurance_id: 1,
        label: "Policyholder",
        value: "policy_holder",
    },
    {
        HealthAssurance_id: 2,
        label: "Assured",
        value: "assured_detail",
    },
    {
        HealthAssurance_id: 3,
        label: "Assured Name 1",
        value: "assured_name1",
    },
    {
        HealthAssurance_id: 4,
        label: "Assured Name 2",
        value: "assured_name2",
    },
    {
        HealthAssurance_id: 5,
        label: "Subtype",
        value: "subtype",
    },
    {
        HealthAssurance_id: 6,
        label: "Provider",
        value: "provider_name",
    },
    {
        HealthAssurance_id: 7,
        label: "Provider Contact",
        value: "provider_contact_name",
    },
    {
        HealthAssurance_id: 8,
        label: "Provider Phone",
        value: "provider_phone",
    },
    {
        HealthAssurance_id: 9,
        label: "Provider Note",
        value: "provider_note",
    },
    {
        HealthAssurance_id: 10,
        label: "Purpose",
        value: "purpose",
    },
    {
        HealthAssurance_id: 11,
        label: "Start Date",
        value: "start_date",
    },
    {
        HealthAssurance_id: 12,
        label: "End Date",
        value: "end_date",
    },
    {
        HealthAssurance_id: 13,
        label: "Term",
        value: "term",
    },
    {
        HealthAssurance_id: 14,
        label: "Scheme Name",
        value: "scheme_name",
    },
    {
        HealthAssurance_id: 15,
        label: "Policy Number",
        value: "policy_number",
    },
    {
        HealthAssurance_id: 16,
        label: "Other Reference",
        value: "other_reference",
    },
    {
        HealthAssurance_id: 17,
        label: "Status",
        value: "status",
    },
    {
        HealthAssurance_id: 18,
        label: "Status Date",
        value: "status_date",
    },
    {
        HealthAssurance_id: 19,
        label: "Service Status",
        value: "service_status",
    },
    {
        HealthAssurance_id: 20,
        label: "Premium Indexed",
        value: "premium_indexed",
    },
    {
        HealthAssurance_id: 21,
        label: "Waiver",
        value: "waiver",
    },
    {
        HealthAssurance_id: 22,
        label: "Lump Sum Benefit",
        value: "lump_sum_benefit",
    },
    {
        HealthAssurance_id: 23,
        label: "Periodic Benefit",
        value: "periodic_benefit",
    },
    {
        HealthAssurance_id: 24,
        label: "Benefit Frequency",
        value: "benefit_frequency",
    },
    {
        HealthAssurance_id: 25,
        label: "Benefits Indexed",
        value: "benefits_indexed",
    },
    {
        HealthAssurance_id: 26,
        label: "Benefit Note",
        value: "benefit_text",
    },
    {
        HealthAssurance_id: 27,
        label: "Rated",
        value: "rated",
    },
    {
        HealthAssurance_id: 28,
        label: "Admin Note",
        value: "admin_note",
    },
    {
        HealthAssurance_id: 29,
        label: "Report Note",
        value: "report_note",
    },
]
export const General = [
    {
        General_id: 0,
        label: "Case Type",
        value: "case_type",
    },
    {
        General_id: 1,
        label: "Policyholder",
        value: "policy_holder",
    },
    {
        General_id: 2,
        label: "Assured",
        value: "assured_detail",
    },
    {
        General_id: 3,
        label: "Assured Name 1",
        value: "assured_name1",
    },
    {
        General_id: 4,
        label: "Assured Name 2",
        value: "assured_name2",
    },
    {
        General_id: 5,
        label: "Subtype",
        value: "subtype",
    },
    {
        General_id: 6,
        label: "Provider",
        value: "provider_name",
    },
    {
        General_id: 7,
        label: "Provider Contact",
        value: "provider_contact_name",
    },
    {
        General_id: 8,
        label: "Provider Phone",
        value: "provider_phone",
    },
    {
        General_id: 9,
        label: "Provider Note",
        value: "provider_note",
    },
    {
        General_id: 10,
        label: "Purpose",
        value: "purpose",
    },
    {
        General_id: 11,
        label: "Start Date",
        value: "start_date",
    },
    {
        General_id: 12,
        label: "End Date",
        value: "end_date",
    },
    {
        General_id: 13,
        label: "Term",
        value: "term",
    },
    {
        General_id: 14,
        label: "Scheme Name",
        value: "scheme_name",
    },
    {
        General_id: 15,
        label: "Policy Number",
        value: "policy_number",
    },
    {
        General_id: 16,
        label: "Other Reference",
        value: "other_reference",
    },
    {
        General_id: 17,
        label: "Status",
        value: "status",
    },
    {
        General_id: 18,
        label: "Status Date",
        value: "status_date",
    },
    {
        General_id: 19,
        label: "Service Status",
        value: "service_status",
    },
    {
        General_id: 20,
        label: "Premium Indexed",
        value: "premium_indexed",
    },
    {
        General_id: 21,
        label: "Lump Sum Benefit",
        value: "lump_sum_benefit",
    },
    {
        General_id: 22,
        label: "Periodic Benefit",
        value: "periodic_benefit",
    },
    {
        General_id: 23,
        label: "Benefit Frequency",
        value: "benefit_frequency",
    },
    {
        General_id: 24,
        label: "Benefits Indexed",
        value: "benefits_indexed",
    },
    {
        General_id: 25,
        label: "Benefit Note",
        value: "benefit_text",
    },
    {
        General_id: 26,
        label: "In Trust",
        value: "in_trust",
    },
    {
        General_id: 27,
        label: "In Trust Note",
        value: "in_trust_note",
    },
    {
        General_id: 28,
        label: "Admin Note",
        value: "admin_note",
    },
    {
        General_id: 29,
        label: "Report Note",
        value: "report_note",
    },
]
export const Investments = [
    {
        Investments_id: 0,
        label: "Case Type",
        value: "case_type",
    },
    {
        Investments_id: 1,
        label: "Policyholder",
        value: "policy_holder",
    },
    {
        Investments_id: 2,
        label: "Assured",
        value: "assured_detail",
    },
    {
        Investments_id: 3,
        label: "Assured Name 1",
        value: "assured_name1",
    },
    {
        Investments_id: 4,
        label: "Assured Name 2",
        value: "assured_name2",
    },
    {
        Investments_id: 5,
        label: "Subtype",
        value: "subtype",
    },
    {
        Investments_id: 6,
        label: "Provider",
        value: "provider_name",
    },
    {
        Investments_id: 7,
        label: "Provider Contact",
        value: "provider_contact_name",
    },
    {
        Investments_id: 8,
        label: "Provider Phone",
        value: "provider_phone",
    },
    {
        Investments_id: 9,
        label: "Provider Note",
        value: "provider_note",
    },
    {
        Investments_id: 10,
        label: "Purpose",
        value: "purpose",
    },
    {
        Investments_id: 11,
        label: "Start Date",
        value: "start_date",
    },
    {
        Investments_id: 12,
        label: "End Date",
        value: "end_date",
    },
    {
        Investments_id: 13,
        label: "Term",
        value: "term",
    },
    {
        Investments_id: 14,
        label: "Scheme Name",
        value: "scheme_name",
    },
    {
        Investments_id: 15,
        label: "Policy Number",
        value: "policy_number",
    },
    {
        Investments_id: 16,
        label: "Other Reference",
        value: "other_reference",
    },
    {
        Investments_id: 17,
        label: "Status",
        value: "status",
    },
    {
        Investments_id: 18,
        label: "Status Date",
        value: "status_date",
    },
    {
        Investments_id: 19,
        label: "Service Status",
        value: "service_status",
    },
    {
        Investments_id: 20,
        label: "Premium Indexed",
        value: "premium_indexed",
    },
    {
        Investments_id: 21,
        label: "Waiver",
        value: "waiver",
    },
    {
        Investments_id: 22,
        label: "Surrender Value",
        value: "surrender_value",
    },
    {
        Investments_id: 23,
        label: "Future Value",
        value: "future_value",
    },
    {
        Investments_id: 24,
        label: "Total Fund Value",
        value: "total_fund_value",
    },
    {
        Investments_id: 25,
        label: "Valuation Date",
        value: "valuation_date",
    },
    {
        Investments_id: 26,
        label: "Lump Sum Benefit",
        value: "lump_sum_benefit",
    },
    {
        Investments_id: 27,
        label: "Periodic Benefit",
        value: "periodic_benefit",
    },
    {
        Investments_id: 28,
        label: "Benefit Frequency",
        value: "benefit_frequency",
    },
    {
        Investments_id: 29,
        label: "Benefits Indexed",
        value: "benefits_indexed",
    },
    {
        Investments_id: 30,
        label: "Benefit Note",
        value: "benefit_text",
    },
    {
        Investments_id: 31,
        label: "In Trust",
        value: "in_trust",
    },
    {
        Investments_id: 32,
        label: "In Trust Note",
        value: "in_trust_note",
    },
    {
        Investments_id: 33,
        label: "Age Admitted",
        value: "age_admitted",
    },
    {
        Investments_id: 34,
        label: "Assigned To",
        value: "assigned_to",
    },
    {
        Investments_id: 35,
        label: "Admin Note",
        value: "admin_note",
    },
    {
        Investments_id: 36,
        label: "Report Note",
        value: "report_note",
    },
]
// assets
export const unitLinked = [
    {
        Unit_linked_id: 0,
        label: "Case Type",
        value: "case_type",
    },
    {
        Unit_linked_id: 1,
        label: "Investment Holder",
        value: "policy_holder",
    },
    {
        Unit_linked_id: 2,
        label: "Owner",
        value: "owner",
    },
    {
        Unit_linked_id: 3,
        label: "Owner Name 1",
        value: "owner_name1",
    },
    {
        Unit_linked_id: 4,
        label: "Owner Name 2",
        value: "owner_name2",
    },
    {
        Unit_linked_id: 5,
        label: "Type",
        value: "investment_type",
    },
    {
        Unit_linked_id: 6,
        label: "Subtype",
        value: "investment_subtype",
    },
    {
        Unit_linked_id: 7,
        label: "Provider",
        value: "provider_name",
    },
    {
        Unit_linked_id: 8,
        label: "Provider Contact",
        value: "provider_contact_name",
    },
    {
        Unit_linked_id: 9,
        label: "Provider Phone",
        value: "provider_phone",
    },
    {
        Unit_linked_id: 10,
        label: "Provider Note",
        value: "provider_note",
    },
    {
        Unit_linked_id: 11,
        label: "Account Number",
        value: "investment_account_number",
    },
    {
        Unit_linked_id: 12,
        label: "Current Value",
        value: "current_value",
    },
    {
        Unit_linked_id: 13,
        label: "Value Date",
        value: "value_date",
    },
    {
        Unit_linked_id: 14,
        label: "Purpose",
        value: "purpose",
    },
    {
        Unit_linked_id: 15,
        label: "Start Date",
        value: "investment_start_date",
    },
    {
        Unit_linked_id: 16,
        label: "End Date",
        value: "investment_end_date",
    },
    {
        Unit_linked_id: 17,
        label: "Status",
        value: "investment_status",
    },
    {
        Unit_linked_id: 18,
        label: "Status Date",
        value: "status_date",
    },
    {
        Unit_linked_id: 19,
        label: "Service Status",
        value: "service_status",
    },
    {
        Unit_linked_id: 20,
        label: "Admin Note",
        value: "note_text",
    },
    {
        Unit_linked_id: 21,
        label: "Report Note",
        value: "report_note",
    },
]
export const Shares = [
    {
        Shares_id: 0,
        label: "Case Type",
        value: "case_type",
    },
    {
        Shares_id: 1,
        label: "Owner",
        value: "owner",
    },
    {
        Shares_id: 2,
        label: "Owner Name 1",
        value: "owner_name1",
    },
    {
        Shares_id: 3,
        label: "Owner Name 2",
        value: "owner_name2",
    },
    {
        Shares_id: 4,
        label: "Type",
        value: "share_type",
    },
    {
        Shares_id: 5,
        label: "Subtype",
        value: "share_subtype",
    },
    {
        Shares_id: 6,
        label: "Company",
        value: "share_company",
    },
    {
        Shares_id: 7,
        label: "Company Contact",
        value: "provider_contact_name",
    },
    {
        Shares_id: 8,
        label: "Company Phone",
        value: "provider_phone",
    },
    {
        Shares_id: 9,
        label: "Company Note",
        value: "provider_note",
    },
    {
        Shares_id: 10,
        label: "Quantity",
        value: "share_current_number",
    },
    {
        Shares_id: 11,
        label: "Current Price",
        value: "share_current_price",
    },
    {
        Shares_id: 12,
        label: "Current Value",
        value: "current_value",
    },
    {
        Shares_id: 13,
        label: "Value Date",
        value: "value_date",
    },
    {
        Shares_id: 14,
        label: "Purpose",
        value: "purpose",
    },
    {
        Shares_id: 15,
        label: "Date Acquired",
        value: "share_date_acquired",
    },
    {
        Shares_id: 16,
        label: "Original Value",
        value: "share_original_value",
    },
    {
        Shares_id: 17,
        label: "Status",
        value: "share_status",
    },
    {
        Shares_id: 18,
        label: "Status Date",
        value: "status_date",
    },
    {
        Shares_id: 19,
        label: "Service Status",
        value: "service_status",
    },
    {
        Shares_id: 20,
        label: "Withdrawal Note",
        value: "withdrawal_note",
    },
    {
        Shares_id: 21,
        label: "Admin Note",
        value: "note_text",
    },
    {
        Shares_id: 22,
        label: "Report Note",
        value: "report_note",
    },
]
export const banksBSocieties = [
    {
        Banks_Societies_id: 0,
        label: "Case Type",
        value: "case_type",
    },
    {
        Banks_Societies_id: 1,
        label: "Owner",
        value: "owner",
    },
    {
        Banks_Societies_id: 2,
        label: "Owner Name 1",
        value: "owner_name1",
    },
    {
        Banks_Societies_id: 3,
        label: "Owner Name 2",
        value: "owner_name2",
    },
    {
        Banks_Societies_id: 4,
        label: "Account Type",
        value: "bank_account_type",
    },
    {
        Banks_Societies_id: 5,
        label: "Bank Name",
        value: "bank_name",
    },
    {
        Banks_Societies_id: 6,
        label: "Contact",
        value: "provider_contact_name",
    },
    {
        Banks_Societies_id: 7,
        label: "Phone",
        value: "provider_phone",
    },
    {
        Banks_Societies_id: 8,
        label: "Note",
        value: "provider_note",
    },
    {
        Banks_Societies_id: 9,
        label: "Account Name",
        value: "bank_account_name",
    },
    {
        Banks_Societies_id: 10,
        label: "Account Number",
        value: "bank_account_number",
    },
    {
        Banks_Societies_id: 11,
        label: "Purpose",
        value: "purpose",
    },
    {
        Banks_Societies_id: 12,
        label: "Balance",
        value: "current_value",
    },
    {
        Banks_Societies_id: 13,
        label: "Balance Date",
        value: "value_date",
    },
    {
        Banks_Societies_id: 14,
        label: "Interest Rate",
        value: "bank_interest_rate",
    },
    {
        Banks_Societies_id: 15,
        label: "Date Opened",
        value: "bank_date_opened",
    },
    {
        Banks_Societies_id: 16,
        label: "Status",
        value: "bank_status",
    },
    {
        Banks_Societies_id: 17,
        label: "Status Date",
        value: "status_date",
    },
    {
        Banks_Societies_id: 18,
        label: "Service Status",
        value: "service_status",
    },
    {
        Banks_Societies_id: 19,
        label: "Withdrawal Note",
        value: "withdrawal_note",
    },
    {
        Banks_Societies_id: 20,
        label: "Admin Note",
        value: "note_text",
    },
    {
        Banks_Societies_id: 21,
        label: "Report Note",
        value: "report_note",
    },
]
export const homePersonal = [
    {
        Home_Personal_id: 0,
        label: "Case Type",
        value: "case_type",
    },
    {
        Home_Personal_id: 1,
        label: "Owner",
        value: "owner",
    },
    {
        Home_Personal_id: 2,
        label: "Owner Name 1",
        value: "owner_name1",
    },
    {
        Home_Personal_id: 3,
        label: "Owner Name 2",
        value: "owner_name2",
    },
    {
        Home_Personal_id: 4,
        label: "Description",
        value: "home_description",
    },
    {
        Home_Personal_id: 5,
        label: "Date Acquired",
        value: "home_date_acquired",
    },
    {
        Home_Personal_id: 6,
        label: "Original Value",
        value: "home_original_value",
    },
    {
        Home_Personal_id: 7,
        label: "Current Value",
        value: "current_value",
    },
    {
        Home_Personal_id: 8,
        label: "Value Date",
        value: "value_date",
    },
    {
        Home_Personal_id: 9,
        label: "Purpose",
        value: "purpose",
    },
    {
        Home_Personal_id: 10,
        label: "Admin Note",
        value: "note_text",
    },
    {
        Home_Personal_id: 11,
        label: "Report Note",
        value: "report_note",
    },
]
// liability
export const Mortages = [
    {
        Mortages_id: 0,
        label: "Case Type",
        value: "case_type",
    },
    {
        Mortages_id: 1,
        label: "Owner",
        value: "joint_indicator",
    },
    {
        Mortages_id: 2,
        label: "Borrower",
        value: "borrower",
    },
    {
        Mortages_id: 3,
        label: "Borrower Name 1",
        value: "borrower_name1",
    },
    {
        Mortages_id: 4,
        label: "Borrower Name 2",
        value: "borrower_name2",
    },
    {
        Mortages_id: 5,
        label: "Type",
        value: "liability_type",
    },
    {
        Mortages_id: 6,
        label: "Subtype",
        value: "subtype",
    },
    {
        Mortages_id: 7,
        label: "Repayment Method",
        value: "repayment_method",
    },
    {
        Mortages_id: 9,
        label: "Purpose",
        value: "purpose",
    },
    {
        Mortages_id: 10,
        label: "Provider",
        value: "provider_name",
    },
    {
        Mortages_id: 11,
        label: "Provider Contact",
        value: "provider_contact_name",
    },
    {
        Mortages_id: 12,
        label: "Provider Phone",
        value: "provider_phone",
    },
    {
        Mortages_id: 13,
        label: "Address",
        value: "provider_address1",
    },
    {
        Mortages_id: 14,
        label: "Town",
        value: "provider_town",
    },
    {
        Mortages_id: 15,
        label: "County",
        value: "provider_county",
    },
    {
        Mortages_id: 16,
        label: "Country",
        value: "provider_country",
    },
    {
        Mortages_id: 17,
        label: "Postcode",
        value: "provider_postcode",
    },
    {
        Mortages_id: 18,
        label: "Provider Note",
        value: "provider_note",
    },
    {
        Mortages_id: 19,
        label: "Account Number",
        value: "account_number",
    },
    {
        Mortages_id: 20,
        label: "Start Date",
        value: "start_date",
    },
    {
        Mortages_id: 21,
        label: "End Date",
        value: "end_date",
    },
    {
        Mortages_id: 22,
        label: "Term",
        value: "term",
    },
    {
        Mortages_id: 23,
        label: "Amount Borrowed",
        value: "amount_borrowed",
    },
    {
        Mortages_id: 24,
        label: "Amount Outstanding",
        value: "amount_outstanding",
    },
    {
        Mortages_id: 25,
        label: "Outstanding Date",
        value: "amount_outstanding_date",
    },
    {
        Mortages_id: 26,
        label: "Interest Rate",
        value: "interest_rate",
    },
    {
        Mortages_id: 27,
        label: "Interest Basis",
        value: "interest_basis",
    },
    {
        Mortages_id: 28,
        label: "End of Fixed Rate",
        value: "mortgage_fixed_rate_end",
    },
    {
        Mortages_id: 29,
        label: "Status",
        value: "status",
    },
    {
        Mortages_id: 30,
        label: "Status Date",
        value: "status_date",
    },
    {
        Mortages_id: 31,
        label: "Covered by Insurance?",
        value: "covered",
    },
    {
        Mortages_id: 32,
        label: "Property Value",
        value: "property_value",
    },
    {
        Mortages_id: 33,
        label: "Loan to Valuation %",
        value: "loan_valuation_percentage",
    },
    {
        Mortages_id: 34,
        label: "Property Address",
        value: "property_address1",
    },
    {
        Mortages_id: 35,
        label: "Town",
        value: "property_town",
    },
    {
        Mortages_id: 36,
        label: "County",
        value: "property_county",
    },
    {
        Mortages_id: 37,
        label: "Country",
        value: "property_country",
    },
    {
        Mortages_id: 38,
        label: "Postcode",
        value: "property_postcode",
    },
    {
        Mortages_id: 39,
        label: "Admin Note",
        value: "note_text",
    },
    {
        Mortages_id: 40,
        label: "Report Note",
        value: "report_note",
    },
]
export const loansLeaseHP = [
    {
        Loans_Lease_HP_id: 0,
        label: "Case Type",
        value: "case_type",
    },
    {
        Loans_Lease_HP_id: 1,
        label: "Owner",
        value: "joint_indicator",
    },
    {
        Loans_Lease_HP_id: 2,
        label: "Borrower",
        value: "borrower",
    },
    {
        Loans_Lease_HP_id: 3,
        label: "Borrower Name 1",
        value: "borrower_name1",
    },
    {
        Loans_Lease_HP_id: 4,
        label: "Borrower Name 2",
        value: "borrower_name2",
    },
    {
        Loans_Lease_HP_id: 5,
        label: "Type",
        value: "liability_type",
    },
    {
        Loans_Lease_HP_id: 6,
        label: "Subtype",
        value: "subtype",
    },
    {
        Loans_Lease_HP_id: 7,
        label: "Repayment Method",
        value: "repayment_method",
    },
    {
        Loans_Lease_HP_id: 9,
        label: "Purpose",
        value: "purpose",
    },
    {
        Loans_Lease_HP_id: 10,
        label: "Provider",
        value: "provider_name",
    },
    {
        Loans_Lease_HP_id: 11,
        label: "Provider Contact",
        value: "provider_contact_name",
    },
    {
        Loans_Lease_HP_id: 12,
        label: "Provider Phone",
        value: "provider_phone",
    },
    {
        Loans_Lease_HP_id: 13,
        label: "Address",
        value: "provider_address1",
    },
    {
        Loans_Lease_HP_id: 14,
        label: "Town",
        value: "provider_town",
    },
    {
        Loans_Lease_HP_id: 15,
        label: "County",
        value: "provider_county",
    },
    {
        Loans_Lease_HP_id: 16,
        label: "Country",
        value: "provider_country",
    },
    {
        Loans_Lease_HP_id: 17,
        label: "Postcode",
        value: "provider_postcode",
    },
    {
        Loans_Lease_HP_id: 18,
        label: "Provider Note",
        value: "provider_note",
    },
    {
        Loans_Lease_HP_id: 19,
        label: "Account Number",
        value: "account_number",
    },
    {
        Loans_Lease_HP_id: 20,
        label: "Start Date",
        value: "start_date",
    },
    {
        Loans_Lease_HP_id: 21,
        label: "End Date",
        value: "end_date",
    },
    {
        Loans_Lease_HP_id: 22,
        label: "Term",
        value: "term",
    },
    {
        Loans_Lease_HP_id: 23,
        label: "Amount Borrowed",
        value: "amount_borrowed",
    },
    {
        Loans_Lease_HP_id: 24,
        label: "Amount Outstanding",
        value: "amount_outstanding",
    },
    {
        Loans_Lease_HP_id: 25,
        label: "Outstanding Date",
        value: "amount_outstanding_date",
    },
    {
        Loans_Lease_HP_id: 26,
        label: "Interest Rate",
        value: "interest_rate",
    },
    {
        Loans_Lease_HP_id: 27,
        label: "Interest Basis",
        value: "interest_basis",
    },
    {
        Loans_Lease_HP_id: 28,
        label: "End of Fixed Rate",
        value: "mortgage_fixed_rate_end",
    },
    {
        Loans_Lease_HP_id: 29,
        label: "Status",
        value: "status",
    },
    {
        Loans_Lease_HP_id: 30,
        label: "Status Date",
        value: "status_date",
    },
    {
        Loans_Lease_HP_id: 31,
        label: "Covered by Insurance?",
        value: "covered",
    },
    {
        Loans_Lease_HP_id: 32,
        label: "Property Value",
        value: "property_value",
    },
    {
        Loans_Lease_HP_id: 33,
        label: "Loan to Valuation %",
        value: "loan_valuation_percentage",
    },
    {
        Loans_Lease_HP_id: 34,
        label: "Property Address",
        value: "property_address1",
    },
    {
        Loans_Lease_HP_id: 35,
        label: "Town",
        value: "property_town",
    },
    {
        Loans_Lease_HP_id: 36,
        label: "County",
        value: "property_county",
    },
    {
        Loans_Lease_HP_id: 37,
        label: "Country",
        value: "property_country",
    },
    {
        Loans_Lease_HP_id: 38,
        label: "Postcode",
        value: "property_postcode",
    },
    {
        Loans_Lease_HP_id: 39,
        label: "Admin Note",
        value: "note_text",
    },
    {
        Loans_Lease_HP_id: 40,
        label: "Report Note",
        value: "report_note",
    },
]
export const creditCards = [
    {
        Credit_Cards_id: 0,
        label: "Case Type",
        value: "case_type",
    },
    {
        Credit_Cards_id: 1,
        label: "Owner",
        value: "joint_indicator",
    },
    {
        Credit_Cards_id: 2,
        label: "Borrower",
        value: "borrower",
    },
    {
        Credit_Cards_id: 3,
        label: "Borrower Name 1",
        value: "borrower_name1",
    },
    {
        Credit_Cards_id: 4,
        label: "Borrower Name 2",
        value: "borrower_name2",
    },
    {
        Credit_Cards_id: 5,
        label: "Type",
        value: "liability_type",
    },
    {
        Credit_Cards_id: 6,
        label: "Subtype",
        value: "subtype",
    },
    {
        Credit_Cards_id: 7,
        label: "Repayment Method",
        value: "repayment_method",
    },
    {
        Credit_Cards_id: 9,
        label: "Purpose",
        value: "purpose",
    },
    {
        Credit_Cards_id: 10,
        label: "Provider",
        value: "provider_name",
    },
    {
        Credit_Cards_id: 11,
        label: "Provider Contact",
        value: "provider_contact_name",
    },
    {
        Credit_Cards_id: 12,
        label: "Provider Phone",
        value: "provider_phone",
    },
    {
        Credit_Cards_id: 13,
        label: "Address",
        value: "provider_address1",
    },
    {
        Credit_Cards_id: 14,
        label: "Town",
        value: "provider_town",
    },
    {
        Credit_Cards_id: 15,
        label: "County",
        value: "provider_county",
    },
    {
        Credit_Cards_id: 16,
        label: "Country",
        value: "provider_country",
    },
    {
        Credit_Cards_id: 17,
        label: "Postcode",
        value: "provider_postcode",
    },
    {
        Credit_Cards_id: 18,
        label: "Provider Note",
        value: "provider_note",
    },
    {
        Credit_Cards_id: 19,
        label: "Account Number",
        value: "account_number",
    },
    {
        Credit_Cards_id: 20,
        label: "Start Date",
        value: "start_date",
    },
    {
        Credit_Cards_id: 21,
        label: "End Date",
        value: "end_date",
    },
    {
        Credit_Cards_id: 22,
        label: "Term",
        value: "term",
    },
    {
        Credit_Cards_id: 23,
        label: "Amount Borrowed",
        value: "amount_borrowed",
    },
    {
        Credit_Cards_id: 24,
        label: "Amount Outstanding",
        value: "amount_outstanding",
    },
    {
        Credit_Cards_id: 25,
        label: "Outstanding Date",
        value: "amount_outstanding_date",
    },
    {
        Credit_Cards_id: 26,
        label: "Interest Rate",
        value: "interest_rate",
    },
    {
        Credit_Cards_id: 27,
        label: "Interest Basis",
        value: "interest_basis",
    },
    {
        Credit_Cards_id: 28,
        label: "End of Fixed Rate",
        value: "mortgage_fixed_rate_end",
    },
    {
        Credit_Cards_id: 29,
        label: "Status",
        value: "status",
    },
    {
        Credit_Cards_id: 30,
        label: "Status Date",
        value: "status_date",
    },
    {
        Credit_Cards_id: 31,
        label: "Covered by Insurance?",
        value: "covered",
    },
    {
        Credit_Cards_id: 32,
        label: "Property Value",
        value: "property_value",
    },
    {
        Credit_Cards_id: 33,
        label: "Loan to Valuation %",
        value: "loan_valuation_percentage",
    },
    {
        Credit_Cards_id: 34,
        label: "Property Address",
        value: "property_address1",
    },
    {
        Credit_Cards_id: 35,
        label: "Town",
        value: "property_town",
    },
    {
        Credit_Cards_id: 36,
        label: "County",
        value: "property_county",
    },
    {
        Credit_Cards_id: 37,
        label: "Country",
        value: "property_country",
    },
    {
        Credit_Cards_id: 38,
        label: "Postcode",
        value: "property_postcode",
    },
    {
        Credit_Cards_id: 39,
        label: "Admin Note",
        value: "note_text",
    },
    {
        Credit_Cards_id: 40,
        label: "Report Note",
        value: "report_note",
    },
]
// client
export const clientDetails = [
    {
        Client_id: 0,
        label: "Title",
        value: "title",
    },
    {
        Client_id: 1,
        label: "First Name(s)",
        value: "first_names",
    },
    {
        Client_id: 2,
        label: "Initials",
        value: "initials",
    },
    {
        Client_id: 3,
        label: "Last Name",
        value: "last_name",
    },
    {
        Client_id: 4,
        label: "Former Last Name",
        value: "former_last_name",
    },
    {
        Client_id: 5,
        label: "Honours",
        value: "honours",
    },
    {
        Client_id: 6,
        label: "Known as",
        value: "known_as",
    },
    {
        Client_id: 7,
        label: "Address Name",
        value: "address_name",
    },
    {
        Client_id: 8,
        label: "Address",
        value: "address_1",
    },
    {
        Client_id: 9,
        label: "Town",
        value: "town",
    },
    {
        Client_id: 10,
        label: "County",
        value: "county",
    },
    {
        Client_id: 11,
        label: "Country",
        value: "country",
    },
    {
        Client_id: 12,
        label: "Postcode",
        value: "postcode",
    },
    {
        Client_id: 13,
        label: "Home Phone",
        value: "home_phone",
    },
    {
        Client_id: 14,
        label: "Work Phone",
        value: "work_phone",
    },
    {
        Client_id: 15,
        label: "Fax",
        value: "fax",
    },
    {
        Client_id: 16,
        label: "Mobile",
        value: "mobile",
    },
    {
        Client_id: 17,
        label: "Email",
        value: "email",
    },
    {
        Client_id: 18,
        label: "Email (Work)",
        value: "work_email",
    },
    {
        Client_id: 19,
        label: "Date of Birth",
        value: "date_of_birth",
    },
    {
        Client_id: 20,
        label: "Age Next",
        value: "preferred_retirement_age",
    },
    {
        Client_id: 21,
        label: "NI Number",
        value: "ni_number",
    },
    {
        Client_id: 22,
        label: "Sex",
        value: "sex",
    },
    {
        Client_id: 23,
        label: "Client Type",
        value: "client_type",
    },
    {
        Client_id: 24,
        label: "Consultant",
        value: "consultant",
    },
    {
        Client_id: 25,
        label: "Introduced by",
        value: "introduced_by",
    },
    {
        Client_id: 26,
        label: "IDD",
        value: "idd",
    },
    {
        Client_id: 27,
        label: "Menu",
        value: "menu",
    },
    {
        Client_id: 28,
        label: "Data Protection",
        value: "data_protection_form_sent",
    },
    {
        Client_id: 29,
        label: "Review Interval(mths)",
        value: "review_interval",
    },
    {
        Client_id: 30,
        label: "Next Review Note",
        value: "review_note",
    },
    {
        Client_id: 31,
        label: "Last Updated",
        value: "_",
    },
    {
        Client_id: 32,
        label: "Updated by",
        value: "_",
    },
    {
        Client_id: 33,
        label: "Note",
        value: "_",
    },
]