import { DrawerProps } from "@fluentui/react-components";
import * as React from "react";
import {
  Hamburger,
  NavCategory,
  NavCategoryItem,
  NavDivider,
  NavDrawer,
  NavDrawerBody,
  NavDrawerHeader,
  NavDrawerProps,
  NavItem,
  NavSubItem,
  NavSubItemGroup,
} from "@fluentui/react-nav-preview";

import { Tooltip, makeStyles, tokens, useId } from "@fluentui/react-components";
import {
  CreditCardPerson24Regular,
  PersonCircle24Regular,
  MoneySettings24Regular,
  TableSettings24Regular,
  BuildingDesktop24Filled,
  DocumentSignature24Regular,
  AppsAddIn24Regular,
  MailTemplate24Regular,
  PeopleCommunityAdd24Regular,
} from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";
import { convertStringToArray } from "../../utils";

const useStyles = makeStyles({
  root: {
    overflow: "hidden",
    display: "flex",
    height: "calc(100vh - 100px)",
    width: "200px",
    backgroundColor: "white !importent",
    border: "0.5px sold black",
  },
  content: {
    flex: "1",
    padding: "16px",
    display: "grid",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  field: {
    display: "flex",
    marginTop: "4px",
    marginLeft: "8px",
    flexDirection: "column",
    gridRowGap: tokens.spacingVerticalS,
  },
});

type DrawerType = Required<DrawerProps>["type"];

const SystemManagerSidebar = (props: Partial<NavDrawerProps>) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const typeLableId = useId("type-label");
  const linkLabelId = useId("link-label");

  const [isOpen, setIsOpen] = React.useState(true);
  const [enabledLinks, setEnabledLinks] = React.useState(true);
  const [type, setType] = React.useState<DrawerType>("inline");
  const handleNavigationLink = (link: any) => {
    navigate(link);
  };

  const renderHamburgerWithToolTip = () => {
    return (
      <Tooltip content="Navigation" relationship="label">
        <Hamburger onClick={() => setIsOpen(!isOpen)} />
      </Tooltip>
    );
  };

  return (
    <div className={styles.root}>
      <NavDrawer
        open={isOpen}
        type={type}
        size="medium"
      >
        {/* <NavDrawerHeader>{renderHamburgerWithToolTip()}</NavDrawerHeader> */}

        <NavDrawerBody>
          {!convertStringToArray(process.env.REACT_APP_HIDE_SIDE_TAB).includes(
            "sm-company-details"
          ) ? (
            <NavItem
              onClick={() => {
                navigate("/system-manager/company-details");
              }}
              value="company-details"
              icon={<BuildingDesktop24Filled />}
            >
              Company Details
            </NavItem>
          ) : (
            ""
          )}

          {!convertStringToArray(process.env.REACT_APP_HIDE_SIDE_TAB).includes(
            "sm-users"
          ) ? (
            <NavItem
              onClick={() => {
                navigate("/system-manager/manage-user");
              }}
              value="users"
              icon={<PersonCircle24Regular />}
            >
              Users
            </NavItem>
          ) : (
            ""
          )}
          <NavCategory value="Customisations">
            {!convertStringToArray(
              process.env.REACT_APP_HIDE_SIDE_TAB
            ).includes("sm-customisations") ? (
              <NavCategoryItem icon={<TableSettings24Regular />}>
                Customisations
              </NavCategoryItem>
            ) : (
              ""
            )}
            <NavSubItemGroup>
              {!convertStringToArray(
                process.env.REACT_APP_HIDE_SIDE_TAB
              ).includes("sm-income-categories") ? (
                <NavSubItem
                  onClick={() => {
                    navigate("/system-manager/customisations/income-category");
                  }}
                  value="income-category"
                  className="sidebar-navitem"
                >
                  Income Categories
                </NavSubItem>
              ) : (
                ""
              )}

              {!convertStringToArray(
                process.env.REACT_APP_HIDE_SIDE_TAB
              ).includes("sm-expense-categories") ? (
                <NavSubItem
                  onClick={() => {
                    navigate("/system-manager/customisations/expense-category");
                  }}
                  value="expense-category"
                >
                  Expense Categories
                </NavSubItem>
              ) : (
                ""
              )}

              {!convertStringToArray(
                process.env.REACT_APP_HIDE_SIDE_TAB
              ).includes("sm-atr-categories") ? (
                <NavSubItem
                  onClick={() => {
                    navigate(
                      "/system-manager/customisations/attitude-risk-categories"
                    );
                  }}
                  value="attitude-risk-categories"
                >
                  ATR - Categories
                </NavSubItem>
              ) : (
                ""
              )}

              {!convertStringToArray(
                process.env.REACT_APP_HIDE_SIDE_TAB
              ).includes("sm-atr-ratings") ? (
                <NavSubItem
                  onClick={() => {
                    navigate(
                      "/system-manager/customisations/attitude-risk-ratings"
                    );
                  }}
                  value="attitude-risk-ratings"
                >
                  ATR - Ratings
                </NavSubItem>
              ) : (
                ""
              )}

              {!convertStringToArray(
                process.env.REACT_APP_HIDE_SIDE_TAB
              ).includes("sm-objectives") ? (
                <NavSubItem
                  onClick={() => {
                    navigate("/system-manager/customisations/objectives");
                  }}
                  value="objectives"
                >
                  Objectives
                </NavSubItem>
              ) : (
                ""
              )}

              {!convertStringToArray(
                process.env.REACT_APP_HIDE_SIDE_TAB
              ).includes("sm-case-action") ? (
                <NavSubItem
                  onClick={() => {
                    navigate("/system-manager/customisations/case-action");
                  }}
                  value="case-action"
                >
                  Case Actions
                </NavSubItem>
              ) : (
                ""
              )}

              {!convertStringToArray(
                process.env.REACT_APP_HIDE_SIDE_TAB
              ).includes("sm-client-action") ? (
                <NavSubItem
                  onClick={() => {
                    navigate("/system-manager/customisations/client-action");
                  }}
                  value="client-action"
                >
                  Client Actions
                </NavSubItem>
              ) : (
                ""
              )}

              {!convertStringToArray(
                process.env.REACT_APP_HIDE_SIDE_TAB
              ).includes("sm-user-defined") ? (
                <NavSubItem
                  onClick={() => {
                    navigate("/system-manager/customisations/user-defined");
                  }}
                  value="user-defined"
                >
                  User Defined Fields
                </NavSubItem>
              ) : (
                ""
              )}
            </NavSubItemGroup>
          </NavCategory>
          {!convertStringToArray(process.env.REACT_APP_HIDE_SIDE_TAB).includes(
            "sm-providers"
          ) ? (
            <NavItem
              onClick={() => {
                navigate("/system-manager/providers");
              }}
              value="providers"
              icon={<PeopleCommunityAdd24Regular />}
            >
              Providers
            </NavItem>
          ) : (
            ""
          )}
          <NavCategory value="Templates">
            {!convertStringToArray(
              process.env.REACT_APP_HIDE_SIDE_TAB
            ).includes("sm-templates") ? (
              <NavCategoryItem icon={<MailTemplate24Regular />}>
                Templates
              </NavCategoryItem>
            ) : (
              ""
            )}
            <NavSubItemGroup>
              {!convertStringToArray(
                process.env.REACT_APP_HIDE_SIDE_TAB
              ).includes("sm-email-templates") ? (
                <NavSubItem
                  onClick={() => {
                    navigate("/system-manager/templates/email-template");
                  }}
                  value="email-templates"
                >
                  Email Templates
                </NavSubItem>
              ) : (
                ""
              )}
              {!convertStringToArray(
                process.env.REACT_APP_HIDE_SIDE_TAB
              ).includes("sm-letter-templates") ? (
                <NavSubItem
                  onClick={() => {
                    navigate("/system-manager/templates/letter-template");
                  }}
                  value="letter-templates"
                >
                  Letter Templates
                </NavSubItem>
              ) : (
                ""
              )}
            </NavSubItemGroup>
          </NavCategory>
          {!convertStringToArray(process.env.REACT_APP_HIDE_SIDE_TAB).includes(
            "sm-contract-enquiries"
          ) ? (
            <NavItem
              onClick={() => {
                navigate("/system-manager/contract-enquiry");
              }}
              value="contract-enquiries"
              icon={<DocumentSignature24Regular />}
            >
              Contract Enquiries
            </NavItem>
          ) : (
            ""
          )}
          {!convertStringToArray(process.env.REACT_APP_HIDE_SIDE_TAB).includes(
            "sm-integrations"
          ) ? (
            <NavItem
              onClick={() => {
                navigate("/system-manager/integrations");
              }}
              value="Integrations"
              icon={<AppsAddIn24Regular />}
            >
              Integrations
            </NavItem>
          ) : (
            ""
          )}
          <NavCategory value="Commissions">
            {!convertStringToArray(
              process.env.REACT_APP_HIDE_SIDE_TAB
            ).includes("sm-commissions") ? (
              <NavCategoryItem icon={<MoneySettings24Regular />}>
                Commissions
              </NavCategoryItem>
            ) : (
              ""
            )}
            <NavSubItemGroup>
              {!convertStringToArray(
                process.env.REACT_APP_HIDE_SIDE_TAB
              ).includes("sm-rules") ? (
                <NavSubItem
                  onClick={() => {
                    navigate("system-manager/commission/commission-rule");
                  }}
                  value="Rules"
                >
                  Rules
                </NavSubItem>
              ) : (
                ""
              )}
              {!convertStringToArray(
                process.env.REACT_APP_HIDE_SIDE_TAB
              ).includes("sm-types") ? (
                <NavSubItem
                  onClick={() => {
                    navigate("system-manager/commission/commission-type");
                  }}
                  value="Types"
                >
                  Types
                </NavSubItem>
              ) : (
                ""
              )}
            </NavSubItemGroup>
          </NavCategory>
          {!convertStringToArray(process.env.REACT_APP_HIDE_SIDE_TAB).includes(
            "sm-subscription"
          ) ? (
            <NavItem
              onClick={() => {
                navigate("system-manager/subscription");
              }}
              value="subscription"
              icon={<CreditCardPerson24Regular />}
            >
              Subscriptions
            </NavItem>
          ) : (
            ""
          )}
        </NavDrawerBody>
      </NavDrawer>
    </div>
  );
};
export default SystemManagerSidebar;
