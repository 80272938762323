import React, { useEffect, useMemo } from "react";
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_RowSelectionState,
} from "material-react-table";
import {
  Eye16Filled,
  MoreHorizontal24Filled,
  Edit16Filled,
  Dismiss24Regular,
} from "@fluentui/react-icons";
import {
  Button,
  makeStyles,
  Tooltip,
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuPopover,
  Avatar,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Label,
  Dropdown,
  Option,
  useId,
  shorthands,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
} from "@fluentui/react-components";
import {
  checkUserPermission,
  formatedDate,
  getClientType,
  getYes_NoConversion,
} from "../../../utils";
import ReactQuillComponent from "../../../components/ReactQuillComponent";
import { UserPermissionsList } from "../../../config";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
    cursor: "pointer",
  },
  contentHeader: {
    marginTop: "0",
  },
  scopeArea: {
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    maxWidth: "400px",
  },
});

type ComponentProps = {
  style?: React.CSSProperties;
  [key: string]: any;
};

const PolicyListItems = (props: ComponentProps) => {
  const styles = useStyles();
  const loggedUser = useSelector((state: RootState) => state.authUser);

  const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>(
    {}
  );
  const columns = useMemo<MRT_ColumnDef<{ [key: string]: any }>[]>(
    () => [
      {
        accessorKey: "case_type",
        header: "Policy Category",
      },
      {
        accessorKey: "policy_holder",
        header: "Policyholder",
        Cell: ({ renderedCellValue, row }) => (
          <>{getClientType(row.original.policy_holder)}</>
        ),
      },
      {
        accessorKey: "assured_detail",
        header: "Assured Detail",
      },
      {
        accessorKey: "assured_name1",
        header: "Assured Name1",
      },
      {
        accessorKey: "assured_name2",
        header: "Assured Name2",
      },
      {
        accessorKey: "subtype",
        header: "Subtype",
      },
      {
        accessorKey: "provider",
        header: "Provider",
      },
      {
        accessorKey: "provider_contact_name",
        header: "Provider Contact",
      },
      {
        accessorKey: "provider_phone",
        header: "Provider Phone",
      },
      {
        accessorKey: "provider_note",
        header: "Provider Note",
        Cell: ({ row }: any) => (
          <>
            <ReactQuillComponent value={row.original.provider_note} />
          </>
        ),
      },
      {
        accessorKey: "purpose",
        header: "Purpose",
      },
      {
        accessorKey: "start_date",
        header: "Start Date",
        Cell: ({ row }: any) => <>{formatedDate(row.original.start_date)}</>,
      },
      {
        accessorKey: "end_date",
        header: "End Date",
        Cell: ({ row }: any) => <>{formatedDate(row.original.end_date)}</>,
      },
      {
        accessorKey: "term",
        header: "Term",
      },
      {
        accessorKey: "scheme_name",
        header: "Scheme Name",
      },
      {
        accessorKey: "policy_number",
        header: "Policy Number",
      },
      {
        accessorKey: "other_reference",
        header: "Other Reference",
      },
      {
        accessorKey: "status",
        header: "Status",
      },
      {
        accessorKey: "status_date",
        header: "Status Date",
        Cell: ({ row }: any) => <>{formatedDate(row.original.status_date)}</>,
      },
      {
        accessorKey: "service_status",
        header: "Service Status",
      },
      {
        accessorKey: "premium_indexed",
        header: "Premium Indexed",
        Cell: ({ row }: any) => <>{row.original.service_status}</>,
      }, 
      {
        accessorKey: "periodic_benefit",
        header: "Periodic Benefit",
      },
      {
        accessorKey: "benefit_frequency",
        header: "Benefit Frequency",
      },
      {
        accessorKey: "benefits_indexed",
        header: "Benefits Indexed",
      },
      {
        accessorKey: "benefit_text",
        header: "Benefit Note",
        Cell: ({ row }: any) => (
          <>
            <ReactQuillComponent value={row.original.benefit_text} />
          </>
        ),
      },
      {
        accessorKey: "admin_note",
        header: "Admin Note",
        Cell: ({ row }: any) => (
          <>
            <ReactQuillComponent value={row.original.admin_note} />
          </>
        ),
      },
      {
        accessorKey: "report_note",
        header: "Report Note",
        Cell: ({ row }: any) => (
          <>
            <ReactQuillComponent value={row.original.report_note} />
          </>
        ),
      },
      {
        accessorKey: "link_to_mortgage",
        header: "Linked to Mortgage",
        Cell: ({ renderedCellValue, row }) => (
          <>{getYes_NoConversion(row.original.link_to_mortgage)}</>
        ),
      },
      {
        accessorKey: "rated",
        header: "Rate",
      },
      {
        accessorKey: "waiver",
        header: "Waiver",
      },
      {
        accessorKey: "surrender_value",
        header: "Surrender Value",
      },
      {
        accessorKey: "future_value",
        header: "Future Value",
      },
      {
        accessorKey: "age_admitted",
        header: "Age Admitted",
      },

      {
        accessorKey: "in_trust",
        header: "In trust",
      },
      {
        accessorKey: "in_trust_note",
        header: "In Trust Note",
        Cell: ({ row }: any) => (
          <>
            <ReactQuillComponent value={row.original.in_trust_note} />
          </>
        ),
      },
      {
        accessorKey: "valuation_date",
        header: "Valuation Date",
        Cell: ({ row }: any) => (
          <>{formatedDate(row.original.valuation_date)}</>
        ),
      },
      {
        accessorKey: "assigned_to",
        header: "Assigned To",
      },
      {
        accessorKey: "contracted_out",
        header: "Contracted Out",
      },
      {
        accessorKey: "transfer_value",
        header: "Transfer Value",
      },
      {
        accessorKey: "total_fund_value",
        header: "Total Fund Value",
      },
      {
        accessorKey: "expression_of_wish",
        header: "Expression of Wish",
      },

      {
        accessorKey: "lump_sum_benefit",
        header: "Lump Sum Benefit",
        Cell: ({ row }: any) => (
          <>
            {row.original.case_type !== "Life Assurance"
              ? row.original.lump_sum_benefit
              : null}
          </>
        ),
      },
      {
        accessorKey: "sum_assured",
        header: "Sum Assured",
        Cell: ({ row }: any) => (
          <>
            {row.original.case_type === "Life Assurance"
              ? row.original.lump_sum_benefit
              : null}
          </>
        ),
      },

      {
        accessorKey: "expression_of_wishnote",
        header: "Expression of Wish Note",
        Cell: ({ row }: any) => (
          <>
            <ReactQuillComponent value={row.original.expression_of_wishnote} />
          </>
        ),
      },
    ],
    []
  );
  useEffect(() => {
    if (
      Object.keys(rowSelection)[0] !== undefined &&
      props.handleRowSelection !== undefined &&
      props.data.length > 0
    ) {
      let selectedRow = props.data[Object.keys(rowSelection)[0]];

      props.handleRowSelection(selectedRow.policy_id, props.mode);
    }
  }, [rowSelection]);
  useEffect(() => {
    if (
      props.activeItem &&
      Object.keys(props.activeItem).length !== 0 &&
      props.data.length > 0
    ) {
      let index = 0;
      index = props.data.findIndex(
        (x: any) => x.policy_id === props.activeItem.policy_id
      );
      setRowSelection({ [index]: true });
      props?.setCurrentRecord(index + 1);
    }
  }, [props.activeItem]);
  return (
    <div>
      <div>
        <MaterialReactTable
          columns={columns}
          data={props.data}
          enableRowSelection
          enableColumnActions={false}
          onRowSelectionChange={setRowSelection}
          enablePagination={false}
          enableRowNumbers
          enableBottomToolbar={false}
          rowNumberMode="static"
          enableMultiRowSelection={false}
          muiTableBodyRowProps={({ row }) => ({
            onClick: row.getToggleSelectedHandler(),
            sx: { cursor: "pointer" },
          })}
          getRowId={(row) => row.id}
          enableRowActions={false}
          displayColumnDefOptions={{
            "mrt-row-actions": {
              header: "Actions", //change header text
              // size: 300, //make actions column wider
              muiTableHeadCellProps: {
                align: "center",
              },
              muiTableBodyCellProps: {
                align: "center",
              },
            },
          }}
          renderRowActions={({ row, table }) => (
            <div className={styles.wrapper}>
              {checkUserPermission(
                loggedUser,
                UserPermissionsList.ALLOW_VIEW_CLIENT
              ) && (
                <Tooltip
                  content="View"
                  relationship="label"
                  positioning="below"
                >
                  <Button
                    appearance="subtle"
                    icon={
                      <Eye16Filled
                        primaryFill="var(--button-color)"
                        onClick={() => {
                          props.handleRowAction("view", row);
                        }}
                      />
                    }
                  ></Button>
                </Tooltip>
              )}
              <Tooltip content="Edit" relationship="label" positioning="below">
                <Button
                  appearance="subtle"
                  icon={
                    <Edit16Filled
                      primaryFill="var(--button-color)"
                      onClick={() => {
                        props.handleRowAction("edit", row);
                      }}
                    />
                  }
                ></Button>
              </Tooltip>
            </div>
          )}
          initialState={{
            columnPinning: { right: ["mrt-row-actions"] },
          }}
          positionActionsColumn="last"
          positionToolbarAlertBanner="bottom"
          state={{ rowSelection }}
        />
      </div>
    </div>
  );
};

export default PolicyListItems;
