import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { ApiConstants } from "../config";
import { data } from "./SystemAdminFormCollection";
import { formSettings } from "./formSettings";
import { Dispatch } from "redux";
import apiServices from "../service";
type formSettingsTypes = {
  data: { [key: string]: any };
  systemAdmin: { [key: string]: any };
};
const initialState: formSettingsTypes = {
  data: {},
  systemAdmin: {},
};
// export const fetchSettings = createAsyncThunk(
//   "form/fetchSettings",
//   async () => {
//     const res = await axios(
//       `${process.env.REACT_APP_BASE_URL + ApiConstants.formSettings}`
//     );
//     const data = await res.data;
//     if (data && !data.isError) {
//       return data.data;
//     } else {
//       let res = {};
//       return res;
//     }
//     // return data.data;
//   }
// );

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    getFormConfig: (state, action) => {
      state.data = action.payload;
      state.systemAdmin = action.payload?.systemManager
        ? action.payload?.systemManager
        : {};
    },
    getSystemAdminFormConfig: (state, action) => {
      state.data = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase(fetchSettings.pending, (state: any) => {
  //     state.isLoading = true;
  //   });
  //   builder.addCase(fetchSettings.fulfilled, (state: any, action) => {
  //     state.isLoading = false;
  //     state.data = action.payload;
  //   });
  //   builder.addCase(fetchSettings.rejected, (state: any, action) => {
  //     state.isLoading = false;
  //     state.error = action.error.message;
  //   });
  // },
});
export const { getFormConfig, getSystemAdminFormConfig } = formSlice.actions;
export default formSlice.reducer;
// Asynchronous thunk actions
export const fetchSettings = () => {
  return async (dispatch: Dispatch) => {
    // dispatch(getFormConfig(formSettings.data));

    apiServices.getFormSettingsFiles
      .getSettings()

      .then((response: any) => {
        if (response.data?.data) {
          dispatch(getFormConfig(response.data.data));
        }
        // else {
        //   dispatch(getFormConfig({}));
        // }
      })
      .catch((err) => {
        // alert(err.message);
        console.log("err", err);
        // dispatch(getFormConfig({}));
      });
  };
};

// export const fetchSystemAdminFormSettings = () => {
//   return async (dispatch: Dispatch) => {
//     dispatch(getFormConfig(systemAdminData));
//   };
// };
