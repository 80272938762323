import { Field, Input } from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import moment from "moment";
import CreatableSelect from "react-select/creatable";
import React, { ChangeEvent } from "react";

function onFormatDate(date: Date | undefined) {
  return !date ? "" : moment(date).format("DD/MM/YYYY");
}

export const ElementData = ({
  element,
  handleAdd,
  formik,
  selectable,
  setSelectable,
  options,
  newOptionAdded,
  optionSelected,
  addNewOption,
  getOptionsForField,
  addNewFieldOption,
  getFieldValue,
  selectFieldOptions,
  clientDefinedData,
  newItemAdded,
  selectionListNewItems,
}: any) => {
  const getOptions = (field: string) => {
    const fieldData = selectFieldOptions.find(
      (item: any) => item.field === field
    );
    if (fieldData) {
      const uniqueOptions = fieldData.options.filter(
        (option: any, index: number, self: any) =>
          index === self.findIndex((t: any) => t.value === option.value)
      );

      return uniqueOptions;
      // return fieldData.options;
    }
    return [];
  };
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
  };

  console.log(element, "formik");
  switch (element.type) {
    case 0: {
      return (
        <div key={`div-${element.id}.${Math.random()}`}>
          <Field
            key={`Field-${element.id}.${Math.random()}`}
            label={element.name}
            className="field-pading"
          >
            <CreatableSelect
              styles={{
                control: (baseStyles: any, state) => ({
                  ...baseStyles,
                  border: "none",
                  borderColor: state.isFocused ? "grey" : "grey",
                  width: 350,
                }),
              }}
              isClearable
              id={element.id}
              name={formik.values[element.name]}
              value={selectionListNewItems[element.name]}
              onChange={(selectedOption: any) => {
                if (!selectedOption) {
                  formik.setFieldValue(element.name, "");
                  newItemAdded(
                    { label: "", value: "" },
                    element.name,
                    "remove"
                  );
                } else {
                  formik.setFieldValue(
                    element.name,
                    selectedOption.value || ""
                  );
                  newItemAdded(selectedOption, element.name, "add");
                }
              }}
              getOptionLabel={(option: any) => option.value}
              options={getOptions(element.name)}
            />
            {formik.values[element.name] &&
            formik.values[element.name].length > element.length
              ? `Length of ${
                  formik.values[element.name]
                } should not be greater than ${element.length}`
              : ""}
          </Field>
          {/* <Link onClick={(e) => handleAdd(element)}>Update</Link> */}
        </div>
      );
    }
    case 3: {
      return (
        <div key={`div-${element.id}.${Math.random()}`}>
          <Field
            key={`Field-${element.id}.${Math.random()}`}
            label={element.name}
            className="field-pading"
          >
            <DatePicker
              key={`Picker-${element.id}.${Math.random()}`}
              as="input"
              appearance="filled-lighter"
              formatDate={onFormatDate}
              name={element.name}
              value={
                formik?.values[element.name]
                  ? new Date(formik.values[element.name])
                  : null
              }
              onSelectDate={(date) => {
                formik.setFieldValue(element.name, date);
              }}
            />
          </Field>
          {/* <Link onClick={(e) => handleAdd(element)}>Update</Link> */}
        </div>
      );
    }
    default:
      return (
        <div key={`div-${element.id}.${Math.random()}`}>
          <Field
            key={`Field-${element.id}.${Math.random()}`}
            label={element.name}
            className="field-pading"
          >
            <Input
              appearance="filled-lighter"
              name={element.name}
              onBlur={formik.handleBlur}
              value={formik.values[element.name]}
              onChange={formik.handleChange}
              type="number"
            />
            {formik.values[element.name] &&
            formik.values[element.name].length > element.length
              ? `Length of ${
                  formik.values[element.name]
                } should not be greater than ${element.length}`
              : ""}
          </Field>
        </div>
      );
  }
};
