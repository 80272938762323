import {
  Button,
  Input,
  Label,
  Link,
  Select,
  Toast,
  ToastBody,
  ToastTitle,
  ToastTrigger,
  Toaster,
  useId,
  useToastController,
} from "@fluentui/react-components";
import { ChangeEvent, useEffect, useState } from "react";
import { Edit24Filled, DismissFilled } from "@fluentui/react-icons";
import _ from "lodash";
import "./style.css";
import apiServices from "../../service";
import {
  formatedDate,
  formatedTime,
  getAssetTypeByCaseTypeId,
} from "../../utils";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setDialogModalOptions } from "../../redux/modelDialogReducer";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import moment from "moment";
import TimePicker from "react-datepicker";
import TextEditor from "../../components/TextEditor";
import CreatableSelect from "react-select/creatable";
import { formBuilder } from "../../utils/formGenerator";
import CreatableSelectionList from "../../components/Creatablelist";
import OverlayLoader from "../../components/OverlayLoader";
import { setFormData } from "../../redux/formUpdateReducer";

const ActionListForm = (props: any) => {
  const [mode, setMode] = useState("edit");
  const dispatch: AppDispatch = useDispatch();
  const selectId = useId();
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const [formField, setFormField] = useState(props.data);
  const [startDate, setStartDate] = useState(new Date());
  const validateSchema = Yup.object().shape({});
  const [showToast, setShowToast] = useState(true);
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [newlyAddedSelectOptions, setNewlyAddedSelectOptions] = useState<any>(
    []
  );
  const [selectFieldOptionLabels, setSelectFieldOptionLabels] = useState<any>(
    []
  );
  const objFormSettings = useSelector((state: RootState) => state.formSettings);
  const [selectFieldOptions, setSelectFieldOptions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [newDate, setNewDate] = useState(
    formField?.appointment_date ? new Date(formField.appointment_date) : null
  );
  // Selection list
  const getSelectionListOptions = async () => {
    try {
      let fieldLbl = "";
      if (props.data.category === "Client Action") {
        fieldLbl =
          "appointment_type:ModifiableList,appointment_assigned:ModifiableList,appointment_status:ModifiableList";
      } else if (
        formField.case_type === "Home/Personal" ||
        formField.case_type === "Unit-linked" ||
        formField.case_type === "Shares" ||
        formField.case_type === "Banks/B Societies" ||
        formField.case_type === "Mortgages" ||
        formField.case_type === "Loans/Lease/HP" ||
        props.data.case_category === "policy_review"
      ) {
        fieldLbl = "review_assigned:ModifiableList";
      } else if (props.data.category === "Client Review") {
        fieldLbl = "review_assigned:ModifiableList";
      } else {
        fieldLbl =
          "business_assigned:ModifiableList,business_status:ModifiableList";
      }

      if (fieldLbl !== "") {
        const res = await apiServices.selectionList.getOptions(fieldLbl);
        if (res.data && res.data.data) {
          setSelectFieldOptions(res.data.data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const newSelectOptionAdded = (
    field: string,
    selectionLabel: string,
    selectionType: string,
    options: any,
    operation: string
  ) => {
    if (operation === "remove") {
      const filteredRes = newlyAddedSelectOptions.filter(
        (item: any) => item.field !== field
      );

      setNewlyAddedSelectOptions(filteredRes);
    } else {
      let newVall: any = {
        type: selectionType,
        options: [],
      };
      newVall["options"].push({
        field: selectionLabel,
        label: options.label,
        value: options.value,
      });

      setNewlyAddedSelectOptions((prev: any) => [...prev, newVall]);
    }
  };
  const AddSelectOptions = () => {
    if (newlyAddedSelectOptions.length > 0) {
      apiServices.selectionList
        .addOptions(newlyAddedSelectOptions)
        .then((response: any) => {})
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    getSelectionListOptions();
  }, []);
  useEffect(() => {
    if (props) {
      const areEqual = _.isEqual(props.data, formField);
      setIsFieldUpdated(!areEqual);
    }
  }, [formField]);
  const formik = useFormik({
    initialValues: props.data,
    validationSchema: validateSchema,
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values);
    },
  });
  const handleSubmit = (values: any) => {};
  function onChange(e: ChangeEvent<HTMLInputElement>) {
    setFormField({ ...formField, [e.target.name]: e.target.value });
  }
  const notify = (message: any, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {message}
        </ToastTitle>
      </Toast>,
      { intent: type ? type : "success" }
    );
  const onFormatDate = (date?: Date): string => {
    return !date ? "" : moment(date).format("DD/MM/YYYY");
  };
  const updateClientData = () => {
    let newData;
    if (formField.category === "Client Action") {
      setLoading(true);
      newData = {
        contacts_client_action: {
          customer_id: formField.customer_id,
          appointment_date: formField.appointment_date,
          appointment_time: newDate,
          appointment_type: formField.appointment_type,
          appointment_status: formField.appointment_status,
          appointment_assigned: formField.appointment_assigned,
          appointment_note: formField.appointment_note,
        },
      };
      apiServices.contactClientAction
        .updateFactFindNote(
          formField.customer_id,
          formField.appointment_id,
          newData
        )
        .then((response: any) => {
          setLoading(true);
          if (!response.data.isError) {
            notify("Action Updated Successfully", "success");
            AddSelectOptions();
            setTimeout(() => {
              setLoading(false);
              props.cancelPopup();
            }, 3000);
            props.getActionListData();
          } else {
            setLoading(false);
            notify("Something went wrong", "error");
          }
        })
        .catch((error: Error) => {
          notify("Something went wrong", "error");
          setLoading(false);
        });
    } else if (formField.category === "Client Review") {
      setLoading(true);
      newData = {
        administration: {
          customer_id: formField.customer_id,
          review_interval: formField.review_interval,
          review_reason: formField.review_reason,
          review_completed: formField.review_completed,
          review_date: formField.review_date,
          review_assigned: formField.review_assigned,
        },
      };
      apiServices.client
        .updateClient(formField.customer_id, newData)
        .then((response: any) => {
          setLoading(true);
          if (!response.data.isError) {
            AddSelectOptions();
            notify("Action Updated Successfully", "success");
            setTimeout(() => {
              setLoading(false);
              props.cancelPopup();
            }, 3000);
            props.getActionListData();
          }
        })
        .catch((e: Error) => {
          setLoading(false);
          console.log(e);
        });
    } else if (formField.case_category === "case_tracking_action") {
      if (formField.business_category === 2) {
        newData = {
          asset_investment_actions: {
            customer_id: formField.customer_id,
            business_date: formField.business_date,
            business_action: formField.business_action,
            business_category: 2,
            business_status: formField.business_status,
            business_assigned: formField.business_assigned,
            case_id: formField.case_id,
          },
        };
        apiServices.Actions.updateActions(formField.business_id, newData)

          .then((response) => {
            setLoading(true);
            if (!response.data.isError) {
              notify("Action Updated Successfully", "success");
              setTimeout(() => {
                setLoading(false);
                props.cancelPopup();
              }, 3000);
              setMode("view");
              AddSelectOptions();
              props.getActionListData();
            } else {
              notify("Something went wrong", "error");
            }
          })
          .catch((err) => {
            console.log(err);
            notify(err.message, "error");
          });
      } else if (formField.business_category === 6) {
        newData = {
          liabilities_mortgages_actions: {
            customer_id: formField.customer_id,
            business_date: formField.business_date,
            business_action: formField.business_action,
            business_category: 2,
            business_status: formField.business_status,
            business_assigned: formField.business_assigned,
            case_id: formField.case_id,
          },
        };
        apiServices.Actions.updateActions(formField.business_id, newData)
          .then((response) => {
            setLoading(true);
            if (!response.data.isError) {
              notify("Action Updated Successfully", "success");
              AddSelectOptions();
              setTimeout(() => {
                setLoading(false);
                props.cancelPopup();
              }, 3000);
              props.getActionListData();
            } else {
              notify("Something went wrong", "error");
            }
          })
          .catch((err) => {
            console.log(err);
            notify(err.message, "error");
          });
      } else if (formField.business_category === 7) {
        newData = {
          liabilities_loan_hire_purchase_actions: {
            customer_id: formField.customer_id,
            business_date: formField.business_date,
            business_action: formField.business_action,
            business_category: 2,
            business_status: formField.business_status,
            business_assigned: formField.business_assigned,
            case_id: formField.case_id,
          },
        };
        apiServices.Actions.updateActions(formField.business_id, newData)
          .then((response) => {
            setLoading(true);
            if (!response.data.isError) {
              notify("Action Updated Successfully", "success");
              AddSelectOptions();
              setTimeout(() => {
                setLoading(false);
                props.cancelPopup();
              }, 3000);
              props.getActionListData();
            } else {
              notify("Something went wrong", "error");
            }
          })
          .catch((err) => {
            console.log(err);
            notify(err.message, "error");
          });
      } else if (formField.business_category === 1) {
        if (formField.case_type === "Life Assurance") {
          newData = {
            policies_life_assurance_actions: {
              customer_id: formField.customer_id,
              business_date: formField.business_date,
              business_action: formField.business_action,
              business_category: 2,
              business_status: formField.business_status,
              business_assigned: formField.business_assigned,
              case_id: formField.case_id,
            },
          };
          apiServices.Actions.updateActions(formField.business_id, newData)
            .then((response) => {
              setLoading(true);
              if (!response.data.isError) {
                notify("Action Updated Successfully", "success");
                AddSelectOptions();
                setTimeout(() => {
                  setLoading(false);
                  props.cancelPopup();
                }, 3000);
                props.getActionListData();
              } else {
                notify("Something went wrong", "error");
              }
            })
            .catch((err) => {
              console.log(err);
              notify(err.message, "error");
            });
        } else if (formField.case_type === "Invesment") {
          newData = {
            policies_investments_actions: {
              customer_id: formField.customer_id,
              business_date: formField.business_date,
              business_action: formField.business_action,
              business_category: 2,
              business_status: formField.business_status,
              business_assigned: formField.business_assigned,
              case_id: formField.case_id,
            },
          };
          apiServices.Actions.updateActions(formField.business_id, newData)
            .then((response) => {
              setLoading(true);
              if (!response.data.isError) {
                notify("Action Updated Successfully", "success");
                AddSelectOptions();
                props.getActionListData();
                setTimeout(() => {
                  setLoading(false);
                  props.cancelPopup();
                }, 3000);
              } else {
                notify("Something went wrong", "error");
              }
            })
            .catch((err) => {
              console.log(err);
              notify(err.message, "error");
            });
        } else if (formField.case_type === "pension") {
          newData = {
            policies_pensions_actions: {
              customer_id: formField.customer_id,
              business_date: formField.business_date,
              business_action: formField.business_action,
              business_category: 2,
              business_status: formField.business_status,
              business_assigned: formField.business_assigned,
              case_id: formField.case_id,
            },
          };
          apiServices.Actions.updateActions(formField.business_id, newData)
            .then((response) => {
              setLoading(true);
              if (!response.data.isError) {
                notify("Action Updated Successfully", "success");
                AddSelectOptions();
                props.getActionListData();
                setTimeout(() => {
                  setLoading(false);
                  props.cancelPopup();
                }, 3000);
              } else {
                notify("Something went wrong", "error");
              }
            })
            .catch((err) => {
              console.log(err);
              notify(err.message, "error");
            });
        } else if (formField.case_type === "General") {
          newData = {
            policies_general_actions: {
              customer_id: formField.customer_id,
              business_date: formField.business_date,
              business_action: formField.business_action,
              business_category: 2,
              business_status: formField.business_status,
              business_assigned: formField.business_assigned,
              case_id: formField.case_id,
            },
          };
          apiServices.Actions.updateActions(formField.business_id, newData)
            .then((response) => {
              setLoading(true);
              if (!response.data.isError) {
                notify("Action Updated Successfully", "success");
                AddSelectOptions();
                props.getActionListData();
                setTimeout(() => {
                  setLoading(false);
                  props.cancelPopup();
                }, 3000);
              } else {
                notify("Something went wrong", "error");
              }
            })
            .catch((err) => {
              console.log(err);
              notify(err.message, "error");
            });
        } else if (formField.case_type === "Heatlh Assuerence") {
          newData = {
            policies_health_assurance_actions: {
              customer_id: formField.customer_id,
              business_date: formField.business_date,
              business_action: formField.business_action,
              business_category: 2,
              business_status: formField.business_status,
              business_assigned: formField.business_assigned,
              case_id: formField.case_id,
            },
          };
          apiServices.Actions.updateActions(formField.business_id, newData)
            .then((response) => {
              setLoading(true);
              if (!response.data.isError) {
                notify("Action Updated Successfully", "success");
                AddSelectOptions();
                props.getActionListData();
                setTimeout(() => {
                  setLoading(false);
                  props.cancelPopup();
                }, 3000);
              } else {
                notify("Something went wrong", "error");
              }
            })
            .catch((err) => {
              console.log(err);
              notify(err.message, "error");
            });
        } else if (formField.case_type === "Income protection") {
          newData = {
            policies_income_protection_actions: {
              customer_id: formField.customer_id,
              business_date: formField.business_date,
              business_action: formField.business_action,
              business_category: 2,
              business_status: formField.business_status,
              business_assigned: formField.business_assigned,
              case_id: formField.case_id,
            },
          };
          apiServices.Actions.updateActions(formField.business_id, newData)
            .then((response) => {
              setLoading(true);
              if (!response.data.isError) {
                notify("Action Updated Successfully", "success");
                AddSelectOptions();
                props.getActionListData();
                setTimeout(() => {
                  setLoading(false);
                  props.cancelPopup();
                }, 3000);
              } else {
                notify("Something went wrong", "error");
              }
            })
            .catch((err) => {
              console.log(err);
              notify(err.message, "error");
            });
        } else if (formField.case_type === "Savings plan") {
          newData = {
            policies_savings_plans_actions: {
              customer_id: formField.customer_id,
              business_date: formField.business_date,
              business_action: formField.business_action,
              business_category: 2,
              business_status: formField.business_status,
              business_assigned: formField.business_assigned,
              case_id: formField.case_id,
            },
          };
          apiServices.Actions.updateActions(formField.business_id, newData)
            .then((response) => {
              setLoading(true);
              if (!response.data.isError) {
                notify("Action Updated Successfully", "success");
                AddSelectOptions();
                props.getActionListData();
                setTimeout(() => {
                  setLoading(false);
                  props.cancelPopup();
                }, 3000);
              } else {
                notify("Something went wrong", "error");
              }
            })
            .catch((err) => {
              console.log(err);
              notify(err.message, "error");
            });
        }
      }
    } else {
      if (
        formField.case_type === "Banks/B Societies" ||
        formField.case_type === "Home/Personal" ||
        formField.case_type === "Unit-linked" ||
        formField.case_type === "Shares"
      ) {
        const commonData = {
          customer_id: formField.customer_id,
          case_type: getAssetTypeByCaseTypeId(formField.case_type),
          review_date: formField.review_date,
          review_interval: formField.review_interval,
          review_reason: formField.review_reason,
          review_completed: formField.review_completed,
          review_assigned: formField.review_assigned,
        };
        switch (formField.case_type) {
          case "Banks/B Societies":
            newData = { asset_banks_building_societies_review: commonData };
            break;
          case "Home/Personal":
            newData = { asset_home_personal_review: commonData };
            break;
          case "Unit-linked":
            newData = { asset_investment_review: commonData };
            break;
          case "Shares":
            newData = { asset_share_holdings_review: commonData };
            break;
          default:
            newData = null;
            break;
        }
        if (newData) {
          apiServices.assets
            .updateAsset(formField.asset_id, newData)
            .then((response: any) => {
              setLoading(true);
              if (!response.data.isError) {
                AddSelectOptions();
                notify("Action Updated Successfully", "success");
                // setMessages(response.data.message);
                setTimeout(() => {
                  props.cancelPopup();
                }, 2000);
              }
              props.getActionListData();
            })
            .catch((e: Error) => {
              console.log(e);
            });
        }
      } else if (
        formField.case_type === "Credit Cards" ||
        formField.case_type === "Loans/Lease/HP" ||
        formField.case_type === "Mortgages"
      ) {
        const commonData = {
          customer_id: formField.customer_id,
          case_type: getAssetTypeByCaseTypeId(formField.case_type),
          review_date: formField.review_date,
          review_interval: formField.review_interval,
          review_reason: formField.review_reason,
          review_completed: formField.review_completed,
          review_assigned: formField.review_assigned,
        };

        switch (formField.case_type) {
          case "Credit Cards":
            newData = { liabilities_credit_cards_review: commonData };
            break;
          case "Loans/Lease/HP":
            newData = { liabilities_loan_hire_purchase_review: commonData };
            break;
          case "Mortgages":
            newData = { liabilities_mortgages_review: commonData };
            break;
          default:
            newData = null;
            break;
        }

        if (newData) {
          apiServices.liability
            .update(formField.liability_id, newData)
            .then((response: any) => {
              setLoading(true);
              if (!response.data.isError) {
                AddSelectOptions();
                notify("Action Updated Successfully", "success");
                setTimeout(() => {
                  setLoading(false);
                  props.cancelPopup();
                }, 3000);
              }
              props.getActionListData();
            })
            .catch((e: Error) => {
              console.log(e);
            });
        }
      } else if (
        formField.case_type === "Life Assurance" ||
        formField.case_type === "Health Assurance" ||
        formField.case_type === "Pensions" ||
        formField.case_type === "Savings Plans" ||
        formField.case_type === "Investments" ||
        formField.case_type === "General" ||
        formField.case_type === "Income Protection"
      ) {
        const commonData = {
          customer_id: formField.customer_id,
          case_type: getAssetTypeByCaseTypeId(formField.case_type),
          review_date: formField.review_date,
          review_interval: formField.review_interval,
          review_reason: formField.review_reason,
          review_completed: formField.review_completed,
          review_assigned: formField.review_assigned,
        };

        switch (formField.case_type) {
          case "Life Assurance":
            newData = { policies_life_assurance_review: commonData };
            break;
          case "Health Assurance":
            newData = { policies_health_assurance_review: commonData };
            break;
          case "Savings Plans":
            newData = { policies_savings_plans_review: commonData };
            break;
          case "Investments":
            newData = { policies_investments_review: commonData };
            break;
          case "Income Protection":
            newData = { policies_income_protection_review: commonData };
            break;
          case "General":
            newData = { policies_general_review: commonData };
            break;
          case "Pensions":
            newData = { policies_pensions_review: commonData };
            break;
          default:
            newData = null;
            break;
        }

        if (newData) {
          apiServices.policy
            .update(formField.policy_id, newData)
            .then((response: any) => {
              setLoading(true);
              if (!response.data.isError) {
                AddSelectOptions();
                notify("Action Updated Successfully", "success");
                props.cancelPopup();
                props.getActionListData();
                setTimeout(() => {
                  setLoading(false);
                  props.cancelPopup();
                }, 3000);
              }
            })
            .catch((e: Error) => {
              console.log(e);
            });
        }
      }
    }
  };
  function getLabel(caseType: any) {
    switch (caseType) {
      case "Banks/B Societies":
        return "Asset Review";
      case "Home/Personal":
        return "Asset Review";
      case "Unit-linked":
        return "Asset Review";
      case "Shares":
        return "Asset Review";
      case "Credit Cards":
        return "Liability Review";
      case "Loans/Lease/HP":
        return "Liability Review";
      case "Mortgages":
        return "Liability Review";
      default:
        return null;
    }
  }
  function getCategoryLabel(category: any) {
    switch (category) {
      case "Client Action":
        return "Client Action";
      case "Client Review":
        return "Client Review";
      case "Case Tracking Action":
        return "Case Action";
      case "Asset (Investment) Review":
        return "Asset Review";
      case "Asset (Shares)Review":
        return "Asset Review";
      case "Asset (Bank) Review":
        return "Asset Review";
      case "Asset (Home)Review":
        return "Asset Review";
      case "Mortgages Review Date":
        return "Liability Review";
      case "Loan Review Date":
        return "Liability Review";
      case "Credit Cards":
        return "Liability Review";
      default:
        return "policy Review";
    }
  }
  if (formField) {
    return (
      <div>
        <OverlayLoader isLoading={loading} />
        <Toaster
          toasterId={toasterId}
          position="top-end"
          pauseOnHover
          pauseOnWindowBlur
          timeout={1000}
        />

        <div className="toast_message_main">
          <div className="actionlist_titile">
            <Label style={{ fontSize: "20px", fontWeight: 600 }}>
              Update Reminder
            </Label>
            <DismissFilled
              style={{ fontSize: "20px" }}
              onClick={() => props.cancelPopup()}
            />
          </div>
          <div className="actionlist-form-title">
            <Label size="large">
              {formField.category
                ? getLabel(formField.case_type)
                : getCategoryLabel(formField.category)}
            </Label>
          </div>
        </div>
        <div>
          {props.data.category === "Client Action" && (
            <>
              <div className="case_action_main">
                <div className="settings-table-content-label">Date</div>
                <DatePicker
                  as="input"
                  className=""
                  placeholder=""
                  allowTextInput
                  formatDate={onFormatDate}
                  onChange={onChange}
                  name={"appointment_date"}
                  value={
                    formField.appointment_date
                      ? new Date(formField.appointment_date)
                      : null
                  }
                  onSelectDate={(date) => {
                    dispatch(setFormData());
                    setFormField((prevState: any) => ({
                      ...prevState,
                      appointment_date: date,
                    }));
                  }}
                />
                <div className="settings-table-content-label">Time</div>
                <div
                  style={{ border: "1px solid #cdcdcd", borderRadius: "5px" }}
                >
                  <TimePicker
                    selected={startDate}
                    onChange={(date: any) => {
                      if (date) {
                        setStartDate(date);
                        let formatDate = new Date(date);
                        formatDate.setMinutes(
                          formatDate.getMinutes() -
                            formatDate.getTimezoneOffset()
                        );
                        setFormField({
                          ...formField,
                          time: formatDate.toISOString(),
                        });
                      }
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                  />
                </div>
                <div className="settings-table-content-label">Type</div>
                <CreatableSelectionList
                  name="appointment_type"
                  selectionLabel="appointment_type"
                  selectFieldOptions={selectFieldOptions}
                  newSelectOptionAdded={newSelectOptionAdded}
                  selectionType="ModifiableList"
                  values={formField.appointment_type}
                  onChange={(text: string) => {
                    dispatch(setFormData());
                    // setFormField({ ...formField, contact_type: text });
                    setFormField({ ...formField, appointment_type: text });
                  }}
                />
                <div className="settings-table-content-label">Reason</div>
                <TextEditor
                  inputData={formField.appointment_note}
                  onChange={(text: string) => {
                    setFormField({ ...formField, appointment_note: text });
                  }}
                  name={"appointment_note"}
                />
                <div className="settings-table-content-label">Assigned</div>
                <CreatableSelectionList
                  name="appointment_assigned"
                  selectionLabel="appointment_assigned"
                  selectFieldOptions={selectFieldOptions}
                  newSelectOptionAdded={newSelectOptionAdded}
                  selectionType="ModifiableList"
                  values={formField.appointment_assigned}
                  onChange={(text: string) => {
                    dispatch(setFormData());
                    // setFormField({ ...formField, contact_type: text });
                    setFormField({ ...formField, appointment_assigned: text });
                  }}
                />
                <div className="settings-table-content-label">Status</div>
                <CreatableSelectionList
                  name="appointment_status"
                  selectionLabel="appointment_status"
                  selectFieldOptions={selectFieldOptions}
                  newSelectOptionAdded={newSelectOptionAdded}
                  selectionType="ModifiableList"
                  values={formField.appointment_status}
                  onChange={(text: string) => {
                    dispatch(setFormData());
                    // setFormField({ ...formField, contact_type: text });
                    setFormField({ ...formField, appointment_status: text });
                  }}
                />
              </div>
            </>
          )}

          {([
            "Home/Personal",
            "Unit-linked",
            "Shares",
            "Banks/B Societies",
            "Credit Cards",
            "Loans/Lease/HP",
            "Mortgages",
          ].includes(formField.case_type) ||
            formField.case_category === "policy_review") && (
            <>
              <div className="case_action_main">
                <div className="settings-table-content-label">Date</div>
                <DatePicker
                  as="input"
                  className=""
                  placeholder=""
                  allowTextInput
                  formatDate={onFormatDate}
                  onChange={onChange}
                  name={"review_date"}
                  value={
                    formField.review_date !== undefined &&
                    formField.review_date !== ""
                      ? new Date(formField.review_date)
                      : new Date()
                  }
                  onSelectDate={(date) => {
                    dispatch(setFormData());
                    setFormField((prevState: any) => ({
                      ...prevState,
                      review_date: date,
                    }));
                  }}
                />
                <div className="settings-table-content-label">
                  Intervel(mths)
                </div>
                <Input
                  onChange={onChange}
                  name={"review_interval"}
                  value={formField.review_interval}
                />
                <div className="settings-table-content-label">Note</div>
                <TextEditor
                  inputData={formField.review_reason}
                  onChange={(text: string) => {
                    dispatch(setFormData());
                    setFormField({ ...formField, review_reason: text });
                  }}
                  name={"review_reason"}
                />
                <div className="settings-table-content-label">
                  Review Assigned
                </div>
                <CreatableSelectionList
                  name="review_assigned"
                  selectionLabel="review_assigned"
                  selectFieldOptions={selectFieldOptions}
                  newSelectOptionAdded={newSelectOptionAdded}
                  selectionType="ModifiableList"
                  values={formField.review_assigned}
                  onChange={(text: string) => {
                    dispatch(setFormData());
                    // setFormField({ ...formField, contact_type: text });
                    setFormField({ ...formField, review_assigned: text });
                  }}
                />
                <div className="settings-table-content-label">Completed</div>
                <DatePicker
                  as="input"
                  className=""
                  placeholder=""
                  allowTextInput
                  formatDate={onFormatDate}
                  onChange={onChange}
                  name={"review_completed"}
                  value={
                    formField.review_completed !== undefined &&
                    formField.review_completed !== ""
                      ? new Date(formField.review_completed)
                      : null
                  }
                  onSelectDate={(date) => {
                    dispatch(setFormData());
                    setFormField((prevState: any) => ({
                      ...prevState,
                      review_completed: date, // Store date as string in the state
                    }));
                  }}
                />
              </div>
            </>
          )}

          {props.data.case_category === "case_tracking_action" && (
            <>
              <div className="case_action_main">
                <div className="settings-table-content-label">Date</div>
                <DatePicker
                  as="input"
                  className=""
                  placeholder=""
                  allowTextInput
                  formatDate={onFormatDate}
                  onChange={onChange}
                  name={"action_date"}
                  value={
                    formField.action_date !== undefined &&
                    formField.action_date !== ""
                      ? new Date(formField.action_date)
                      : new Date()
                  }
                  onSelectDate={(date) => {
                    dispatch(setFormData());
                    setFormField((prevState: any) => ({
                      ...prevState,
                      action_date: date, // Store date as string in the state
                    }));
                  }}
                />
                <div className="settings-table-content-label">Review</div>
                <TextEditor
                  inputData={formField.action_note}
                  onChange={(text: string) => {
                    setFormField({ ...formField, action_note: text });
                  }}
                  name={"action_note"}
                />
                <div className="settings-table-content-label">Assigned</div>
                <CreatableSelectionList
                  name="business_assigned"
                  selectionLabel="business_assigned"
                  selectFieldOptions={selectFieldOptions}
                  newSelectOptionAdded={newSelectOptionAdded}
                  selectionType="ModifiableList"
                  values={formField.business_assigned}
                  onChange={(text: string) => {
                    dispatch(setFormData());
                    // setFormField({ ...formField, contact_type: text });
                    setFormField({ ...formField, business_assigned: text });
                  }}
                />
                <div className="settings-table-content-label">Status</div>
                <CreatableSelectionList
                  name="business_status"
                  selectionLabel="business_status"
                  selectFieldOptions={selectFieldOptions}
                  newSelectOptionAdded={newSelectOptionAdded}
                  selectionType="ModifiableList"
                  values={formField.business_status}
                  onChange={(text: string) => {
                    dispatch(setFormData());
                    // setFormField({ ...formField, contact_type: text });
                    setFormField({ ...formField, business_status: text });
                  }}
                />
              </div>
            </>
          )}

          {props.data.category === "Client Review" && (
            <>
              <div className="case_action_main">
                <div className="settings-table-content-label">
                  Interval(mnth)
                </div>
                <Input
                  onChange={onChange}
                  name={"review_interval"}
                  value={formField.review_interval}
                />
                <div className="settings-table-content-label">Note</div>
                <TextEditor
                  inputData={formField.review_reason}
                  onChange={(text: string) => {
                    dispatch(setFormData());
                    setFormField({ ...formField, review_reason: text });
                  }}
                  name={"review_reason"}
                />
                <div className="settings-table-content-label">
                  Review Assigned
                </div>
                <CreatableSelectionList
                  name="review_assigned"
                  selectionLabel="review_assigned"
                  selectFieldOptions={selectFieldOptions}
                  newSelectOptionAdded={newSelectOptionAdded}
                  selectionType="ModifiableList"
                  values={formField.review_assigned}
                  onChange={(text: string) => {
                    dispatch(setFormData());
                    // setFormField({ ...formField, contact_type: text });
                    setFormField({ ...formField, review_assigned: text });
                  }}
                />
                <div className="settings-table-content-label">Completed</div>
                <DatePicker
                  as="input"
                  className=""
                  placeholder=""
                  allowTextInput
                  formatDate={onFormatDate}
                  onChange={onChange}
                  name={"review_completed"}
                  value={
                    formField.review_completed !== undefined &&
                    formField.review_completed !== ""
                      ? new Date(formField.review_completed)
                      : null
                  }
                  onSelectDate={(date) => {
                    dispatch(setFormData());
                    setFormField((prevState: any) => ({
                      ...prevState,
                      review_completed: date, // Store date as string in the state
                    }));
                  }}
                />
                <div className="settings-table-content-label">
                  Next Review Date
                </div>
                <DatePicker
                  as="input"
                  className=""
                  placeholder=""
                  allowTextInput
                  formatDate={onFormatDate}
                  onChange={onChange}
                  name={"review_date"}
                  value={
                    formField.review_date !== undefined &&
                    formField.review_date !== ""
                      ? new Date(formField.review_date)
                      : null
                  }
                  onSelectDate={(date) => {
                    dispatch(setFormData());
                    setFormField((prevState: any) => ({
                      ...prevState,
                      review_date: date,
                    }));
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div className="button-end-popup">
          <Button
            className="asc-button-primary"
            appearance="primary"
            type="submit"
            size="small"
            disabled={isFieldUpdated ? false : true}
            onClick={() => updateClientData()}
            style={{ marginRight: 6 }}
          >
            Save
          </Button>
          <Button
            appearance="secondary"
            shape="square"
            type="submit"
            size="small"
            onClick={() => props.cancelPopup()}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ActionListForm;
