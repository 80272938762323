import React, { useEffect, useState } from "react";
import "../style.css";
import {
  useId,
  makeStyles,
  Button,
  shorthands,
} from "@fluentui/react-components";
import {
  Edit24Filled,
  ArrowUndo16Regular,
  ArrowRedo16Regular,
} from "@fluentui/react-icons";
import { fetchSettings } from "../../../redux/formSlice";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";

import FormContainerWithList from "../../../components/FormContainerWithList";
import { configFm } from "../../../types";
import apiServices from "../../../service";
import OverlayLoader from "../../../components/OverlayLoader";
// import FormContainer from "../../../components/Form/FormContainer";

const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "10px",
  },
  btnWrapper: {
    columnGap: "15px",
    display: "flex",
    marginTop: "20px",
    marginBottom: "10px",
  },
  selectBoxLabel: {
    fontWeight: "bold",
    marginBottom: "10px",
  },
  tabList: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    ...shorthands.padding("10px", "0px"),
    rowGap: "20px",
  },
});

type ComponentProps = {
  [x: string]: any;
  changeMode?: (mode: string) => void;
  setActiveItem?: (item: any) => void;
  closePage?: () => void;
};
const FactFindNoteForm = (props: ComponentProps) => {
  const styles = useStyles();
  const [formContext, setFormContext] = useState("FACT_FIND_NOTES");

  const [initialData, setInitialData] = useState({});
  const dispatch: AppDispatch = useDispatch();
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const [configForm, setConfigForm] = useState<configFm>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      formContext &&
      formSettingsObj.data &&
      formSettingsObj.data.formConfig !== undefined
    ) {
      let configObj = formSettingsObj.data.formConfig.find(
        (item: any) => item.context === formContext
      );
      if (configObj) {
        setConfigForm(configObj);
      }
    }
  }, [formSettingsObj]);
  useEffect(() => {
    getDataList();
  }, [props.activeCustomerId]);

  const loadListData = () => {
    getDataList();
  };
  const getDataList = () => {
    setLoading(true);
    apiServices.factFindNote
      .getList(props.activeCustomerId)

      .then((res) => {
        setLoading(false);
        if (res.data !== undefined) {
          if (res.data.data) {
            let singleData = {};
            singleData = {
              ...singleData,
              factFindNote: res.data.data,
            };
            setInitialData(singleData);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  return (
    <>
      <OverlayLoader isLoading={loading} />
      <div className="form-container">
        <FormContainerWithList
          context={formContext}
          config={configForm}
          activeCustomerId={props.activeCustomerId}
          notify={props.notify}
          initialData={initialData}
          reloadData={loadListData}
        />
      </div>
    </>
  );
};

export default FactFindNoteForm;
