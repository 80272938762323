import React from "react";
import "./style.css";

interface BreadCrumbProps {
  path: string[];
  navigateTo: (path: string[], children: [], isbreadcrumb: boolean) => void;
}

const BreadCrumb: React.FC<BreadCrumbProps> = ({ path, navigateTo }) => {
  const splitPathSegments = (segments: string[]) => {
    return segments.flatMap((segment) =>
      segment.split("/").filter((part) => part)
    );
  };

  const fullPath = splitPathSegments(["Documents", ...path]);

  return (
    <div className="document_breadcrumb">
      {fullPath.map((segment, index) => (
        <span
          key={index}
          onClick={() => navigateTo(fullPath.slice(1, index + 1), [], true)}
        >
          {segment} {index < fullPath.length - 1 && " / "}
        </span>
      ))}
    </div>
  );
};

export default BreadCrumb;
