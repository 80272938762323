import React, { useEffect } from "react";
import {
  Input,
  Checkbox,
  Button,
  Toaster,
  useToastController,
  ToastTitle,
  ToastTrigger,
  Toast,
  useId,
  Link,
  ToastBody,
  Field,
} from "@fluentui/react-components";
import Logo from "../../assets/logo/assystCloudLogo.png";
import "./style.css";
interface PropsType {
  [x: string]: any;
}
const SplashScreen = (props: PropsType) => {
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const notify = (title?: string, message?: string, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <Link
              onClick={() => {
                props.setLoading(false);
                props.setIsAuthenticated(false);
              }}
            >
              Dismiss
            </Link>
            // <ToastTrigger >
            //   <Link>Dismiss</Link>
            // </ToastTrigger>
          }
        >
          {title ?? title}
        </ToastTitle>
        <ToastBody>{message ?? message}</ToastBody>
      </Toast>,
      {
        intent: type ? type : "success",
        position: "top-end",
        timeout: type && type === "error" ? 20000 : 4000,
      }
    );
  useEffect(() => {
    if (props.loginError) {
      notify(props.loginErrorMessage, "", "error");
      const timeoutId = setTimeout(() => {
        props.setLoading(false);
        props.setIsAuthenticated(false);
      }, 4000);
      return () => clearTimeout(timeoutId);
      // props.setLoading(false);
      // props.setIsAuthenticated(false);
    }
  }, [props]);
  return (
    <>
      <Toaster toasterId={toasterId} />
      <div className="splash-body">
        <img src={Logo} alt="Splash"  height={200} />{" "}
      </div>
    </>
  );
};

export default SplashScreen;
