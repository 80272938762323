import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
type ComponentProps = {
	[x: string]: any;
    
    
};

const TextEditor = (props:ComponentProps) => {
  const [text, setText] = useState<string>("");

  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "bullet" }, { list: "ordered" }]
    ]
  };

  const handleChange = (value: string) => {
    setText(value);
  };

  return (
    <div>
      <ReactQuill value={props.inputData} onChange={props.onChange} modules={modules} />
    </div>
  );
};

export default TextEditor;
