import React, { useState, useMemo, useEffect } from "react";
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_RowSelectionState,
} from "material-react-table";
import {
  Eye16Filled,
  MoreHorizontal24Filled,
  Edit16Filled,
  Dismiss24Regular,
} from "@fluentui/react-icons";
import {
  Button,
  makeStyles,
  Tooltip,
  shorthands,
  TabList,
  Tab,
} from "@fluentui/react-components";
import { formatedDate, getClientType, isObjectEmpty } from "../../../utils";
import ReactQuillComponent from "../../../components/ReactQuillComponent";
import apiServices from "../../../service";
import CalculationTable from "../../../components/CalculationTable/fundCalculation";
import FundCalculation from "../../../components/CalculationTable/fundCalculation";
import WithdrawalCalculation from "../../../components/CalculationTable/withdrawalCalculation";
import PaymentCalculation from "../../../components/CalculationTable/paymentCalculation";

const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
    cursor: "pointer",
  },
  contentHeader: {
    marginTop: "0",
  },
  scopeArea: {
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    maxWidth: "400px",
  },
});

type ComponentProps = {
  style?: React.CSSProperties;
  [key: string]: any;
};

const tableColumnList = {
  fund: [
    {
      accessorKey: "fund_name",
      header: "Name",
    },
    {
      accessorKey: "fund_code",
      header: "Code",
    },
    {
      accessorKey: "fund_value",
      header: "Value",
    },
    {
      accessorKey: "fund_units",
      header: "Units",
    },
    {
      accessorKey: "unit_price",
      header: "Price",
    },
    {
      accessorKey: "price_date",
      header: "Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.price_date)}</>,
    },
    {
      accessorKey: "fund_code_source",
      header: "Code Source",
    },
    {
      accessorKey: "currency_code",
      header: "Currency",
    },
    {
      accessorKey: "sector",
      header: "Sector",
    },
    {
      accessorKey: "total_expense_ratio",
      header: "Total Expense Ratio",
    },
    {
      accessorKey: "risk_rating",
      header: "Risk Rating",
    },
  ],
  payments: [
    {
      accessorKey: "payment.start_date",
      header: "Start Date",
      Cell: ({ row }: any) => (
        <>
          {formatedDate(
            row.original.payment ? row.original.payment.start_date : null
          )}
        </>
      ),
    },
    {
      accessorKey: "payment.end_date",
      header: "End Date",
      Cell: ({ row }: any) => (
        <>
          {formatedDate(
            row.original.payment ? row.original.payment.end_date : null
          )}
        </>
      ),
    },
    {
      accessorKey: "payment.amount",
      header: "Amount",
    },
    {
      accessorKey: "payment.frequency",
      header: "Frequency",
    },
    {
      accessorKey: "payment.payer",
      header: "Payer",
    },
    {
      accessorKey: "payment.new_business_register",
      header: "New Business?",
    },
    {
      accessorKey: "payment_complience.high_risk",
      header: "High Risk?",
    },
    {
      accessorKey: "payment_complience.high_risk_note",
      header: "High Risk Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent
            value={row.original?.payment_complience?.high_risk_note}
          />
        </>
      ),
    },
    {
      accessorKey: "payment.reinvestment",
      header: "Reinvestment?",
    },
    {
      accessorKey: "payment.note_text",
      header: "Admin Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original?.payment?.note_text} />
        </>
      ),
    },
  ],
  withdrawals: [
    {
      accessorKey: "start_date",
      header: "Start Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.start_date)}</>,
    },
    {
      accessorKey: "end_date",
      header: "End Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.end_date)}</>,
    },
    {
      accessorKey: "amount",
      header: "Amount",
    },
    {
      accessorKey: "frequency",
      header: "Frequency",
    },
    {
      accessorKey: "high_risk",
      header: "High Risk?",
    },
    {
      accessorKey: "high_risk_note",
      header: "High Risk Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.high_risk_note} />
        </>
      ),
    },
    {
      accessorKey: "reinvestment",
      header: "For Reinvestment",
    },
    {
      accessorKey: "note_text",
      header: "Admin Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.note_text} />
        </>
      ),
    },
  ],
  actions: [
    {
      accessorKey: "business_date",
      header: "Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.business_date)}</>,
    },

    {
      accessorKey: "business_action",
      header: "Action",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.business_action} />
        </>
      ),
    },
    {
      accessorKey: "business_assigned",
      header: "Assigned",
    },
    {
      accessorKey: "business_status",
      header: "Status",
    },
  ],
  valuations: [
    {
      accessorKey: "effective_date",
      header: "Effective Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.effective_date)}</>,
    },
    {
      accessorKey: "valuation_amount",
      header: "Valuation Amount",
    },

    {
      accessorKey: "note_text",
      header: "Admin Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.note_text} />
        </>
      ),
    },
  ],
};
const returnColumnList = {
  return: [
    {
      accessorKey: "valuation_amount",
      header: "Period",
    },
    {
      accessorKey: "valuation_amount",
      header: "Increase/(Decrease) %",
    },
  ],
};

function CaseSummaryDetail(props: ComponentProps) {
  const styles = useStyles();
  const [selectedItem, setSelectedItem] = useState<any | {}>({});
  const PaymentContext = (props: ComponentProps) => {
    const [activePaymentTab, setActivePaymentTab] = useState("compliance");
    const selectTab = (tab: string) => {
      setActivePaymentTab(tab);
    };

    return (
      <>
        <TabList>
          <Tab value={"compliance"} onClick={() => selectTab("compliance")}>
            Compliance
          </Tab>
          <Tab value={"notes"} onClick={() => selectTab("notes")}>
            Notes
          </Tab>
        </TabList>

        {activePaymentTab == "compliance" && (
          <div className="payment_compliance">
            <div className="payment_compliance_grid">
              <b>Consultant:</b>
              <p>{props.rowData?.payment_complience?.consultant || "-"}</p>
              <b>Replacement:</b>
              <p>{props.rowData?.payment_complience?.replacement || "-"}</p>
              <b>High Risk:</b>
              <p>{props.rowData?.payment_complience?.high_risk || "-"}</p>
              <b>Application Submitted:</b>
              <p>
                {formatedDate(props.rowData?.payment_complience?.submitted) ||
                  "-"}
              </p>
              <b>Type of Sale:</b>
              <p>{props.rowData?.payment_complience?.type_of_sale || "-"}</p>
              <b>Business Card:</b>
              <p>
                {formatedDate(
                  props.rowData?.payment_complience?.business_card
                ) || "-"}
              </p>
              <b>Client Agreement:</b>
              <p>
                {formatedDate(
                  props.rowData?.payment_complience?.client_agreement
                ) || "-"}
              </p>
              <b>Terms of Engagement:</b>
              <p>
                {formatedDate(
                  props.rowData?.payment_complience?.terms_of_business_sent
                ) || "-"}
              </p>
            </div>
            <div className="payment_compliance_grid">
              <b>IDD:</b>
              <p>
                {formatedDate(props.rowData?.payment_complience?.idd) || "-"}
              </p>
              <b>Menu:</b>
              <p>
                {formatedDate(props.rowData?.payment_complience?.menu) || "-"}
              </p>
              <b>Fact-find:</b>
              <p>
                {formatedDate(
                  props.rowData?.payment_complience?.fact_find_completed
                ) || "-"}
              </p>
              <b>Data Protection:</b>
              <p>
                {formatedDate(
                  props.rowData?.payment_complience?.data_protection_form_sent
                ) || "-"}
              </p>
              <b>Risk Profiling:</b>
              <p>
                {formatedDate(
                  props.rowData?.payment_complience?.risk_profile
                ) || "-"}
              </p>
              <b>Identity Verified:</b>
              <p>
                {formatedDate(
                  props.rowData?.payment_complience?.verification_of_identity
                ) || "-"}
              </p>
              <b>Sanctions Check:</b>
              <p>
                {formatedDate(
                  props.rowData?.payment_complience?.sanctions_check
                ) || "-"}
              </p>
              <b>Research Completed:</b>
              <p>
                {formatedDate(
                  props.rowData?.payment_complience?.research_completed
                ) || "-"}
              </p>
            </div>
            <div className="payment_compliance_grid_1">
              <b>Illustration:</b>
              <p>
                {formatedDate(
                  props.rowData?.payment_complience?.illustration
                ) || "-"}
              </p>
              <b>Key Features Received:</b>
              <p>
                {formatedDate(
                  props.rowData?.payment_complience?.key_features_received
                ) || "-"}
              </p>
              <b>Key Features Sent:</b>
              <p>
                {formatedDate(
                  props.rowData?.payment_complience?.key_features_sent
                ) || "-"}
              </p>
              <b>Suitability Letter:</b>
              <p>
                {formatedDate(
                  props.rowData?.payment_complience?.suitability_letter_sent
                ) || "-"}
              </p>
              <b>Documents Received:</b>
              <p>
                {formatedDate(
                  props.rowData?.payment_complience?.docs_received
                ) || "-"}
              </p>
              <b>Documents Sent:</b>
              <p>
                {formatedDate(props.rowData?.payment_complience?.docs_sent) ||
                  "-"}
              </p>
              <b>Replacement Reason:</b>
              <p>
                <ReactQuillComponent
                  value={
                    props.rowData?.payment_complience?.replacement_reason || "-"
                  }
                />
              </p>
              <b>Research Note:</b>
              <p>
                <ReactQuillComponent
                  value={
                    props.rowData?.payment_complience?.research_note || "-"
                  }
                />
              </p>
            </div>
          </div>
        )}
        {activePaymentTab == "notes" && (
          <div className="payment_notes">
            <b>Admin Note:</b>
            <p>
              <ReactQuillComponent
                value={props.rowData.payment.note_text || "-"}
              />
            </p>
            <b>High Risk Note:</b>
            <p>
              <ReactQuillComponent
                value={props.rowData?.payment_complience?.high_risk_note || "-"}
              />
            </p>
            <b style={{ color: "red" }}>Payment Type:</b>
            <p>{props.rowData.payment.payment_type || "-"}</p>
          </div>
        )}
      </>
    );
  };

  const ReturnContext = (props: ComponentProps) => {
    return (
      <>
        <div className="payment_compliance_grid">
          <div className="payment_compliance_grid">
            <b>Initial Value *:</b>
            <p>{"-"}</p>
            <b>Current Value:</b>
            <p>{"-"}</p>
            <b>Earliest Date:</b>
            <p>{"-"}</p>
            <b>Earliest Value:</b>
            <p>{"-"}</p>
            <b>Most Recent Date:</b>
            <p>{"-"}</p>
          </div>
          <div className="payment_compliance_grid">
            <b>Most Recent Value:</b>
            <p>{"-"}</p>
            <b>Interval(days):</b>
            <p>{"-"}</p>
            <b>Daily Change:</b>
            <p>{"-"}</p>
            <b>Payments in the Perios:</b>
            <p>{"-"}</p>
            <b>Withdrawals in the Period:</b>
            <p>{"-"}</p>
          </div>
        </div>
        <span>*Initial Value may be Estimated</span>
      </>
    );
  };
  const customColumns = useMemo<MRT_ColumnDef<{ [key: string]: any }>[]>(() => {
    if (props.context !== "") {
      return (tableColumnList as { [key: string]: any })[props.context];
    }
    return [];
  }, [props.context]);

  return (
    <div>
      <>
        {props.context === "fund" ? (
          <FundCalculation calculatedData={props.fundValue} />
        ) : null}
        {props.context === "withdrawals" ? (
          <WithdrawalCalculation calculatedData={props.withdrawalValue} />
        ) : null}
        {props.context === "payments" ? (
          <PaymentCalculation calculatedData={props.paymentValue} />
        ) : null}
      </>
    
      {customColumns && customColumns.length > 0 && (
        <div
          // className={`${
          //   props.context === "valuations" ? "flex-between" : "flex-block"
          // }`}
        >
          <MaterialReactTable
            columns={customColumns}
            data={props.data}
            enableColumnActions={false}
            enablePagination={false}
            enableBottomToolbar={false}
            rowNumberMode="static"
            enableRowActions={false}
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "Actions",
                muiTableHeadCellProps: {
                  align: "center",
                },
                muiTableBodyCellProps: {
                  align: "center",
                },
              },
            }}
            renderDetailPanel={
              props.context === "payments"
                ? ({ row }) => PaymentContext({ rowData: row.original })
                : undefined
            }
            initialState={{
              columnPinning: { right: ["mrt-row-actions"] },
            }}
            positionActionsColumn="last"
            positionToolbarAlertBanner="bottom"
          />
          {/* {props.context === "valuations" && (
            <MaterialReactTable
              columns={returnColumnList.return}
              data={props.data}
              enableColumnActions={false}
              enablePagination={false}
              enableBottomToolbar={false}
              rowNumberMode="static"
              enableRowActions={false}
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  header: "Actions",
                  muiTableHeadCellProps: {
                    align: "center",
                  },
                  muiTableBodyCellProps: {
                    align: "center",
                  },
                },
              }}
              renderDetailPanel={({ row }) =>
                ReturnContext({ rowData: row.original })
              }
              initialState={{
                columnPinning: { right: ["mrt-row-actions"] },
              }}
              positionActionsColumn="last"
              positionToolbarAlertBanner="bottom"
            />
          )} */}
        </div>
      )}
    </div>
  );
}

export default CaseSummaryDetail;