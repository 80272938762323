import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Label,
  Toaster,
} from "@fluentui/react-components";
import { Dropdown, IDropdownStyles } from "@fluentui/react/lib/Dropdown";
import React, { useEffect, useState } from "react";
import apiServices from "../../service";
import { useSelector } from "react-redux";
import {
  Dismiss24Regular,
  AttachRegular,
  DismissRegular,
  Edit24Filled,
} from "@fluentui/react-icons";
import Dropzone from "react-dropzone";
import ReactQuill from "react-quill";
import OverlayLoader from "../../components/OverlayLoader";
import { generateUUID } from "../../utils";
type propsType = {
  openSendMailDialog?: any;
  isSendMailDialogOpen: any;
  closeSendMailDialog: any;
  reloadList: any;
  notify: any;
  selectClient: { customer_id: string; first_names: string; last_name: string };
};

type selectedValue = {
  customer_id: string;
  first_names: string;
  last_name: string;
  email: string;
};

interface Template {
  CommunicationTemplateId: string;
  Title: string;
  Body: string;
  Description: string;
}
const modules = {
  toolbar: false,
};

function SendMail(props: propsType) {
  const [clients, setClients] = useState<selectedValue[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<selectedValue[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<string>("");
  const [template, setTemplate] = useState<Template[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>("");
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [messageBody, setMessageBody] = useState<any>([]);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showCancel, setShowCancel] = useState<boolean>(false);
  const { userProfileInfo } = useSelector((state: any) => state.authUser);
  const [enableEdit, setEnableEdit] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState("");
  const [initialMessageBody, setInitialMessageBody] = useState<string>("");
  const [uploadedFiles, setUploadedFiles] = useState<{ [key: string]: File[] }>(
    {}
  );
  const [fileStatus, setFileStatus] = useState<{
    [key: string]: { file: File; status: "new" | "removed" | "saved" }[];
  }>({});

  const handleDropdownChangetemplate = (
    event: React.FormEvent<HTMLDivElement>,
    item?: any
  ): void => {
    if (item) {
      setSelectedTemplate(item.text);
      setSelectedTemplateId(item.key);
      // getCreateContent(item.key, selectedOptions[0]);
      generatePreviewList(item.key, props.selectClient.customer_id);
    }
  };

  const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: {
      width: 350,
      borderRadius: "6px",
    },
    callout: {
      maxHeight: "200px",
    },
  };

  const getTemplateList = () => {
    apiServices.notification
      .getList()
      .then((response: any) => {
        if (!response.data.isError) {
          if (response.data) {
            setTemplate(response.data);
          }
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  const generateMessage = (
    template: string,
    replacements: { [key: string]: string }
  ) => {
    let result = template;

    for (const [placeholder, value] of Object.entries(replacements)) {
      const regex = new RegExp(`{{${placeholder}}}`, "g");
      result = result.replace(regex, value);
    }
    return result;
  };
  const generatePreviewList = (templateId: string, client: any) => {
    if (templateId !== undefined && client !== undefined) {
      getMessageBody(templateId, props.selectClient.customer_id);
    }
  };

  const getMessageBody = (templateId: string, customer: any) => {
    setLoading(true);
    apiServices.notification
      .createContent(templateId, props.selectClient.customer_id)
      .then((response: any) => {
        setLoading(false);
        if (response.data) {
          const { Body, Subject, Placeholders } = response.data;
          const message = generateMessage(Body, Placeholders);

          // Set both the current message body and the initial message body
          const newPreviewItem = {
            body: message,
            subject: Subject,
            placeholders: Placeholders,
            customer,
            edited: false,
          };
          setMessageBody([newPreviewItem]);
          setInitialMessageBody(message); // Store the initial body
        }
      })
      .catch((e: Error) => {
        setLoading(false);
        console.log(e);
      });
  };

  const NOTIFICATION_ID = generateUUID();


  const resetPage = () => {
    setSelectedTemplate("");
    setSelectedTemplateId("");
    setShowPopup(false);
    setShowConfirmation(false);
    setSelectedOptions([]);
    setSelectedClient([]);
    setMessageBody("");
    props.reloadList(true);
  };
  const removeFile = (clientId: string, index: number) => {
    setUploadedFiles((prevFiles) => {
      const newFiles = { ...prevFiles };
      newFiles[clientId].splice(index, 1);
      return newFiles;
    });
  };
  console.log(props);
  const handleSendMail = async () => {
    const formData = new FormData();
    formData.append("customerId", props.selectClient.customer_id);
    formData.append("subject", selectedTemplate);
    formData.append("cc", userProfileInfo.user_email);
    formData.append("from", userProfileInfo.user_email);
    formData.append("body", messageBody[0]?.body);
    formData.append("notificationId", NOTIFICATION_ID);

    const files = uploadedFiles[props.selectClient.customer_id] || [];
    files.forEach((file: File) => {
      formData.append("files", file);
    });

    try {
      const res = await apiServices.notification.sendEmail(formData);
      console.log(res.data.message, "ressss");
      props.notify(res.data.message);
      resetPage();
      setShowPopup(false);
      setShowConfirmation(false);
      setShowCancel(false);
      props.closeSendMailDialog();
    } catch (err) {
      console.error("Error sending email:", err);
      props.notify("Failed to send email. Please try again.");
    }
  };
  const handleCancel = (): void => {
    setShowPopup(false);
    setShowConfirmation(false);
    setShowCancel(true);
  };
  const handleContinue = (): void => {
    setShowPopup(false);
    setShowConfirmation(true);
  };

  const handleConfirmation = (confirmed: boolean): void => {
    setShowConfirmation(false);
    if (confirmed) {
      resetPage();
    }
  };

  const cancelConfirmation = () => {
    setShowCancel(false);
    props.closeSendMailDialog();
  };

  useEffect(() => {
    getTemplateList();
  }, []);
  console.log(messageBody, "selectedTemplate");
  return (
    <>
      {/* <Toaster toasterId={toasterId} position="top-end" timeout={5000} /> */}
      <Dialog open={props.isSendMailDialogOpen}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle
              action={
                <DialogTrigger action="close">
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    icon={
                      <Dismiss24Regular onClick={props.closeSendMailDialog} />
                    }
                  />
                </DialogTrigger>
              }
            >
              Send Mail
            </DialogTitle>
            <DialogContent>
              <div className="send_mail_popup">
                <Label className="label">Selected Client</Label>
                <Label>
                  {props.selectClient.first_names}{" "}
                  {props.selectClient.last_name}
                </Label>
                <Label className="label">Select Template</Label>
                <Dropdown
                  placeholder={selectedTemplate || "Select Template"}
                  selectedKey={selectedTemplate}
                  options={template.map((templates: Template) => ({
                    key: templates.CommunicationTemplateId,
                    text: templates.Title,
                    body: templates.Body,
                  }))}
                  onChange={handleDropdownChangetemplate}
                  styles={dropdownStyles} // Directly pass the dropdownStyles object here
                />
              </div>
              {selectedTemplate.length > 0 && (
                <>
                  <div className="send_mail_popup">
                    <Label className="label">From</Label>
                    <Label>{userProfileInfo.user_email}</Label>
                    <Label className="label">Cc</Label>
                    <Label>{userProfileInfo.user_email}</Label>
                    <Label className="label">Subject</Label>
                    <Label>{selectedTemplate}</Label>
                  </div>
                  <div className="message_body">
                    {enableEdit ? (
                      <>
                        <Dropzone
                          onDrop={(acceptedFiles: File[]) => {
                            if (acceptedFiles.length > 0) {
                              setUploadedFiles((prevFiles) => {
                                const newFiles = { ...prevFiles };
                                if (!newFiles[props.selectClient.customer_id]) {
                                  newFiles[props.selectClient.customer_id] = [];
                                }
                                const newFileStatus = { ...fileStatus };
                                if (
                                  !newFileStatus[props.selectClient.customer_id]
                                ) {
                                  newFileStatus[
                                    props.selectClient.customer_id
                                  ] = [];
                                }
                                newFileStatus[
                                  props.selectClient.customer_id
                                ].push({
                                  file: acceptedFiles[0],
                                  status: "new",
                                });

                                newFiles[props.selectClient.customer_id].push(
                                  acceptedFiles[0]
                                );
                                setFileStatus(newFileStatus);
                                return newFiles;
                              });
                            }
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ className: "flex-end" })}>
                              <input {...getInputProps()} />
                              <AttachRegular
                                fontSize="20px"
                                onClick={() =>
                                  setSelectedItemId(
                                    props.selectClient.customer_id
                                  )
                                }
                              />
                            </div>
                          )}
                        </Dropzone>
                        <ReactQuill
                          value={messageBody[0]?.body}
                          onChange={(value) => {
                            setMessageBody([
                              { ...messageBody[0], body: value },
                            ]);
                          }}
                          modules={modules}
                          style={{ border: "none", padding: 0 }}
                        />
                        <div className="file_upload_list">
                          {uploadedFiles[props.selectClient.customer_id] &&
                            uploadedFiles[props.selectClient.customer_id].map(
                              (file: any, index: number) => (
                                <div
                                  className="file_upload_detail"
                                  key={`${props.selectClient.customer_id}-${index}`}
                                >
                                  <div>
                                    <p>{file.name}</p>
                                    <span style={{ fontSize: 12 }}>
                                      {(file.size / 1024).toFixed(2)} KB
                                    </span>
                                  </div>
                                  <DismissRegular
                                    onClick={() =>
                                      removeFile(
                                        props.selectClient.customer_id,
                                        index
                                      )
                                    }
                                  />
                                </div>
                              )
                            )}
                        </div>
                        <div className="action_buttons">
                          <Button
                            className="asc-button-primary"
                            appearance="primary"
                            onClick={() => {
                              setEnableEdit(false);
                              setSelectedItemId("");
                              setMessageBody([{ body: initialMessageBody }]);
                              setUploadedFiles((prevFiles) => {
                                const newFiles = { ...prevFiles };
                                delete newFiles[props.selectClient.customer_id];
                                return newFiles;
                              });
                              setFileStatus((prevStatus) => {
                                const newStatus = { ...prevStatus };
                                delete newStatus[
                                  props.selectClient.customer_id
                                ];
                                return newStatus;
                              });
                            }}
                            size="small"
                          >
                            Cancel
                          </Button>
                          <Button
                            appearance="secondary"
                            onClick={() => {
                              setEnableEdit(false);
                              setSelectedItemId("");
                              setMessageBody((prevList: any) =>
                                prevList.map((item: any, i: number) =>
                                  i === 0 ? { ...item, edited: true } : item
                                )
                              );

                              // Mark files as saved
                              setFileStatus((prevStatus) => {
                                const newStatus = { ...prevStatus };
                                const clientId = props.selectClient.customer_id;
                                if (newStatus[clientId]) {
                                  newStatus[clientId] = newStatus[clientId].map(
                                    (file) =>
                                      file.status === "new"
                                        ? { ...file, status: "saved" }
                                        : file
                                  );
                                }
                                return newStatus;
                              });
                            }}
                            size="small"
                          >
                            Save
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex-end">
                          <Button
                            appearance="subtle"
                            onClick={() => {
                              setEnableEdit(true);
                              setSelectedItemId(props.selectClient.customer_id);
                            }}
                            icon={<Edit24Filled />}
                          />
                        </div>
                        <OverlayLoader isLoading={loading} />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: messageBody[0]?.body,
                          }}
                        ></div>
                        <div className="file_upload_list">
                          {uploadedFiles[props.selectClient.customer_id] &&
                            uploadedFiles[props.selectClient.customer_id].map(
                              (file: any, index: number) => (
                                <div
                                  className="file_upload_detail"
                                  key={`${props.selectClient.customer_id}-${index}`}
                                >
                                  <div>
                                    <p>{file.name}</p>
                                    <span style={{ fontSize: 12 }}>
                                      {(file.size / 1024).toFixed(2)} KB
                                    </span>
                                  </div>
                                  {/* <DismissRegular
                                    onClick={() =>
                                      removeFile(
                                        props.selectClient.customer_id,
                                        index
                                      )
                                    }
                                  /> */}
                                </div>
                              )
                            )}
                        </div>
                      </>
                    )}
                  </div>
                  {!enableEdit && (
                    <div className="message_buttons">
                      <Button appearance="secondary" onClick={handleCancel}>
                        Cancel
                      </Button>
                      <Button
                        className="asc-button-primary"
                        appearance="primary"
                        onClick={handleContinue}
                      >
                        Send
                      </Button>
                    </div>
                  )}
                </>
              )}
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      {showCancel && (
        <Dialog
          open={showCancel}
          onOpenChange={(event, data) => setShowCancel(data.open)}
        >
          <DialogSurface>
            <DialogContent>
              <DialogTitle>Are you sure you want to cancel?</DialogTitle>

              <div className="message_buttons">
                <Button
                  appearance="primary"
                  className="asc-button-primary"
                  onClick={() => setShowCancel(false)}
                >
                  No
                </Button>
                <Button appearance="secondary" onClick={cancelConfirmation}>
                  Yes
                </Button>
              </div>
            </DialogContent>
          </DialogSurface>
        </Dialog>
      )}
      {showConfirmation && (
        <Dialog
          open={showConfirmation}
          onOpenChange={(event, data) => setShowConfirmation(data.open)}
        >
          <DialogSurface>
            <DialogContent>
              <DialogTitle>Confirmation for sending mail</DialogTitle>

              <div className="message_buttons">
                <Button
                  appearance="secondary"
                  onClick={() => handleConfirmation(false)}
                >
                  Cancel
                </Button>
                <Button
                  className="asc-button-primary"
                  appearance="primary"
                  onClick={() => handleSendMail()}
                >
                  Send Email
                </Button>
              </div>
            </DialogContent>
          </DialogSurface>
        </Dialog>
      )}
    </>
  );
}

export default SendMail;
