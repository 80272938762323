import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogSurface,
  DialogTitle,
} from "@fluentui/react-components";
import React from "react";

function DeleteDependencyPopup(props: any) {
  return (
    <Dialog open={props.dependencyDeletePopup}>
      <DialogSurface>
        <DialogBody>
          <h3>{props.dependencymessage}</h3>
          <DialogActions>
            <Button
              className="asc-button-primary"
              appearance="primary"
              onClick={() => props.setDependencyDeletePopup(false)}
            >
              Close
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}

export default DeleteDependencyPopup;
