import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { NavbarItems } from "../../types/NavbarTypes";
import { ExtractMain } from "./ExtractLocate/extractMain";
import Navbar from "../../components/Navbar/Index";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { SkeletonLoader } from "../../components/SkeletonLoader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import ModalDialog from "../../components/ModalDialog";
import ASCBreadcrumb from "../../components/Breadcrumb";
import { addBreadcrumbs } from "../../redux/appSettingsSlice";

import {
  Button,
  Combobox,
  ComboboxProps,
  Dropdown,
  Label,
  makeStyles,
  Option,
  shorthands,
  typographyStyles,
  useId,
} from "@fluentui/react-components";
import TemplateDropdown from "./EmailNotification/TemplateDropdown"
import SideBar from "../../components/SideBar";
// import EmailNotification from "./EmailNotification/v2";
interface PropsType {
  setTabs?: Dispatch<SetStateAction<string>>;
}

const Utilities = ({ setTabs }: PropsType) => {
  const [activeSideMenu, setActiveSideMenu] = useState("Email");

  const sideMenuListUtilities = ["Email", "Saved Searches"];
  const dropdownId = useId("dropdown-default");
  const [context, setContext] = useState<string>("");
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const [isSelectedClient, setIsSelectedClient] = useState<boolean>(false);
  const breadcrumbItems = useSelector(
    (state: RootState) => state.appSettings.breadcrumbItems
  );

  const dispatch: AppDispatch = useDispatch();
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);
  const dispatchBread: AppDispatch = useDispatch();

  useEffect(() => {
    dispatchBread(
      addBreadcrumbs([
        {
          key: 0,
          value: "Home",
          link: "/",
        },
        {
          key: 1,
          value: "Utilities",
        },
        {
          key: 2,
          value: "Email",
        },
        {
          key: 3,
          value: " Saved Searches",
        },
      ])
    );
  }, [dispatchBread]);

  useEffect(() => {
    if (setTabs) {
      setTabs("utilities");
    }
  }, []);

  function onDropDownList(e: any, data: any) {
    setContext(data.optionValue);

    // console.log(data, "Data tree");
    const whatClient = isSelectedClient ? "Selected Client" : "All Client";

    dispatchBread(
      addBreadcrumbs([
        {
          key: 0,
          value: "Home",
          link: "/",
        },
        {
          key: 1,
          value: "Utilities",
        },
        {
          key: 2,
          value: "Email",
        },
        {
          key: 3,
          value: "Saved Searches",
        },
      ])
    );
  }

  const navigate = useNavigate();

  return (
    <>
        <div>
          {/* {activeSideMenu === "Email" && <EmailNotification />} */}
          {activeSideMenu === "Email" && <TemplateDropdown />}
        </div>
      <ModalDialog options={dialogModalOptions} />
    </>
  );
};

export default Utilities;
