import * as React from "react";
import { Switch } from "@fluentui/react-components";

export const SwitchButton = (props: any) => {
  const [checked, setChecked] = React.useState(
    props.itemChecked !== undefined ? props.itemChecked : false
  );
  const onChange = React.useCallback(
    (ev: any) => {
      setChecked(ev.currentTarget.checked);
    },
    [setChecked]
  );
  React.useEffect(() => {
    if (props.itemChecked !== undefined) {
      setChecked(props.itemChecked);
    }
  }, [props.itemChecked]);

  return (
    <Switch
      value={props.label}
      checked={checked}
      onChange={(ev) => {
        props.onChange(ev.target.value);
        onChange(ev);
      }}
      label={props.label ? props.label : ""}
      labelPosition={props.labelPosition ? props.labelPosition : undefined}
      disabled={props.disabled ? props.disabled : undefined}
    />
  );
};
