import { useEffect, useState } from "react";
import {
  Dropdown,
  makeStyles,
  Option,
  shorthands,
  useId,
  Badge,
} from "@fluentui/react-components";

type ComponentProps = {
  options: { label: string; value: string }[];
  selectedDropDownValues: string[];
  setSelectedDropDownValues: (values: string[]) => void;
  placeholder?: string;
};

const useStyles = makeStyles({
  root: {
    width: "fit-content !important",
    maxWidth: "380px !important",
  },
  badge: {
    marginLeft: "8px",
  },
});

const Multiselectsearch = (props: ComponentProps) => {
  const comboId = useId("combo-multi");
  const styles = useStyles();
  const [optionState, setOptionState] = useState(props.options);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const uniqueOptions = props.options.filter(
      (option, index, self) =>
        index === self.findIndex((t) => t.value === option.value)
    );
    setOptionState(uniqueOptions);
  }, [props.options]);

  const handleOptionChange = (event: any, option: any) => {
    if (!option) return;
    const newSelectedValues = [...props.selectedDropDownValues];

    if (newSelectedValues.includes(option.optionValue)) {
      const index = newSelectedValues.indexOf(option.optionValue);
      newSelectedValues.splice(index, 1);
    } else {
      newSelectedValues.push(option.optionValue);
    }

    props.setSelectedDropDownValues(newSelectedValues);
  };

  const displayedOptions = props.selectedDropDownValues.slice(0, 2);
  const remainingCount =
    props.selectedDropDownValues.length - displayedOptions.length;

  const renderSelectedOptions = () => {
    const selectedLabels = displayedOptions.map((optionValue) => {
      const option = optionState.find((opt) => opt.value === optionValue);
      return option ? option.label : "";
    });

    return (
      <div>
        <span className="customTriggerText">{selectedLabels.join(", ")}</span>
        {remainingCount > 0 && (
          <>
            {","}
            <Badge className="badge">+{remainingCount}</Badge>
          </>
        )}
      </div>
    );
  };

  return (
    <div className={styles.root}>
      {!isDropdownOpen && (
        <div
          className="customTrigger"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          {props.selectedDropDownValues.length > 0
            ? renderSelectedOptions()
            : props.placeholder}
        </div>
      )}
      {isDropdownOpen && (
        <Dropdown
          className="dropdown_button"
          aria-labelledby={comboId}
          multiselect
          placeholder={props.placeholder}
          selectedOptions={props.selectedDropDownValues}
          onOptionSelect={handleOptionChange}
          onOpenChange={(e, data) => setIsDropdownOpen(data.open)}
        >
          {optionState.map((option: any, index: number) => (
            <Option
              value={option.value}
              key={`option-${index}`}
              text={option.label}
            >
              {option.label}
            </Option>
          ))}
        </Dropdown>
      )}
    </div>
  );
};

export default Multiselectsearch;
