import * as React from "react";
import './style.css';
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
} from "@fluentui/react-components";
type ComponentProps = {
  [x: string]: any;
};
const DialogModal = (props: ComponentProps) => {
  return (
    <Dialog open={props.open !== undefined ? props.open : false}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>
            {props.title !== undefined ? props.title : ""}
          </DialogTitle>
          <DialogContent>
            {props.description !== undefined ? props.description : ""}
          </DialogContent>
          <DialogActions>
            {/* <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Ok</Button>
            </DialogTrigger> */}
            <Button
              appearance="primary"
              className="asc-button-primary"
              onClick={props.onClick !== undefined ? props.onClick : null}
            >
              Ok
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
export default DialogModal;
