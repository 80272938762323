import React from "react";
import "./OverlayLoader.css"; // Import CSS for styling the loader
import { Spinner } from "@fluentui/react-components";

const OverlayLoader = ({ isLoading, message }: any) => {
  return (
    <div className={`overlay ${isLoading ? "show" : "hide"}`}>
      <Spinner
        size="huge"
        labelPosition="below"
        label={message ? message : "Loading..."}
      />
    </div>
  );
};

export default OverlayLoader;
