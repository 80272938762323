import { useState } from "react";
import "./style.css";
type ContainerProps = {
  [x: string]: any;
};
const IncomeCalcTable = (props: ContainerProps) => {

  return (
    <div className="outgoing-wrapper">
      <div className="heading-label-only">
        <div className="table-heading">Gross</div>
      </div>
      <div className="heading-label-only">
        <div className="table-heading">Net</div>
      </div>

      <div className="month-annual-grid">
        <div></div>
        <div className="table-value-income table-heading">
          <div>Client</div>
          <div>Partner</div>
          <div>Joint</div>
        </div>
        <div className="table-subhead">Monthly</div>
        <div className="table-value-income">
          <div>{props.IncomeCalculationValues.ClientMonthlyGrossAmount}</div>
          <div>{props.IncomeCalculationValues.PartnerMonthlyGrossAmount}</div>
          <div>{props.IncomeCalculationValues.JointMonthlyGrossAmount}</div>
        </div>
        <div className="table-subhead">Annual</div>
        <div className="table-value-income">
          <div>{props.IncomeCalculationValues.ClientAnnualGrossAmount}</div>
          <div>{props.IncomeCalculationValues.PartnerAnnualGrossAmount}</div>
          <div>{props.IncomeCalculationValues.JointAnnualGrossAmount}</div>
        </div>
      </div>

      <div className="month-annual-grid">
        <div></div>
        <div className="table-value-income table-heading">
          <div>Client</div>
          <div>Partner</div>
          <div>Joint</div>
        </div>
        <div className="table-subhead"></div>
        <div className="table-value-income">
          <div>{props.IncomeCalculationValues.ClientMonthlyNetAmount}</div>
          <div>{props.IncomeCalculationValues.PartnerMonthlyNetAmount}</div>
          <div>{props.IncomeCalculationValues.JointMonthlyNetAmount}</div>
        </div>
        <div className="table-subhead"></div>
        <div className="table-value-income">
          <div>{props.IncomeCalculationValues.ClientAnnualNetAmount}</div>
          <div>{props.IncomeCalculationValues.PartnerAnnualNetAmount}</div>
          <div>{props.IncomeCalculationValues.JointAnnualNetAmount}</div>
        </div>
      </div>
    </div>
  );
};

export default IncomeCalcTable;
