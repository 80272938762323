import { useEffect, useState } from "react";
import "../../style.css";
import {
  useId,
  makeStyles,
  Button,
  shorthands,
} from "@fluentui/react-components";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { configFm } from "../../../../types";
import apiServices from "../../../../service";
import SystemManagerFormContainer from "../../SystemManagerFormContainer";
import OverlayLoader from "../../../../components/OverlayLoader";

const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "10px",
  },
  btnWrapper: {
    columnGap: "15px",
    display: "flex",
    marginTop: "20px",
    marginBottom: "10px",
  },
  selectBoxLabel: {
    fontWeight: "bold",
    marginBottom: "10px",
  },
  tabList: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    ...shorthands.padding("10px", "0px"),
    rowGap: "20px",
  },
});

type ComponentProps = {
  [x: string]: any;
};
const ObjectivesForm = (props: ComponentProps) => {
  const styles = useStyles();
  const [formContext, setFormContext] = useState("MASTER_SETTINGS_OBJECTIVES");

  const [initialData, setInitialData] = useState({});
  const [objectivesCategory, setObjectiveCategories] = useState([]);
  const dispatch: AppDispatch = useDispatch();
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const [configForm, setConfigForm] = useState<configFm>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      formContext &&
      formSettingsObj?.systemAdmin &&
      formSettingsObj.systemAdmin.formConfig !== undefined
    ) {
      let configObj = formSettingsObj.systemAdmin.formConfig.find(
        (item: any) => item.context === formContext
      );
      if (configObj) {
        setConfigForm(configObj);
      }
    }
  }, [formSettingsObj]);
  useEffect(() => {
    getDataList();
  }, []);

  const loadListData = () => {
    getDataList();
  };
  const getDataList = async () => {
    setIsLoading(true);
    try {
      const res = await apiServices.systemManager.objectives.getList();
      if (res.data !== undefined) {
        if (res.data.data) {
          setObjectiveCategories(res.data.data);
        }
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  return (
    <div className="admin-form-container">
      <OverlayLoader isLoading={isLoading} />
      <SystemManagerFormContainer
        context={formContext}
        config={configForm}
        notify={props.notify}
        title="Objectives"
        isFormAdmin={true}
        dataList={objectivesCategory}
        reloadData={loadListData}
      />
    </div>
  );
};

export default ObjectivesForm;
