import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import store from "./redux/store";
import { Provider } from "react-redux";
import { FluentProvider, teamsLightTheme } from "@fluentui/react-components";
import { BrowserRouter } from 'react-router-dom';
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <FluentProvider theme={teamsLightTheme}>
      <BrowserRouter>
        <App />
        </BrowserRouter>
      </FluentProvider>
    </Provider>
  // </React.StrictMode>
);
