import Axios from "axios";
import { seesionExpired, accessDenied } from "../redux/appSettingsSlice";
import store from "../redux/store";
import { setCurrentUser } from "../redux/authSlice";

const apiCache: Record<string, any> = {};
const CACHE_TTL = 5 * 60 * 1000;

const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const createCacheKey = (config: any) => {
  const { url, params } = config;
  const queryString = params ? new URLSearchParams(params).toString() : "";
  const cacheKey = queryString ? `${url}?${queryString}` : url;
  // console.log(`Cache key generated: ${cacheKey}`);
  return cacheKey;
};

axiosInstance.interceptors.request.use(
  async (config: any) => {
    const cacheKey = createCacheKey(config);
    if (config.method === "get") {
      const cachedResponse = apiCache[cacheKey];
      if (cachedResponse) {
        const { data, expiration } = cachedResponse;
        if (Date.now() < expiration) {
          // Cache hit, return cached data
          return Promise.reject({
            config,
            data,
            status: 200,
            statusText: "OK",
            headers: {},
            request: {},
            fromCache: true,
          });
        } else {
          delete apiCache[cacheKey]; // Cache expired, delete it
        }
      } 
    }
    
    const cacheInvalidatingMethods = ["patch", "post", "delete"];
    if (cacheInvalidatingMethods.includes(config.method.toLowerCase())) {
      const { url } = config;
    
      const cacheInvalidationRules = [
        "/client",
        "/case/asset",
        "/case/liability",
        "/case/policy/",
        "/case/fund",
        "/case/payments",
        "/case/commission",
        "/case/withdrawal",
        "/case/business",
        "/case/valuation",
        "/assystcashflow/income",
        "/assystcashflow/expense",
        "/client/notes",
        "/client/document",
        "/client/appointmentbycustomer",
        "/client/commissionbycustomer",
        "/client/note/notebycustomerid",
        "/client/timeallocationbycustomer",
        "/client/depentant",
        "/client/time",
        "/case/case-summary",
        "/case/contact/customer",
        "masterdata/transactions/incomecategories",
        "masterdata/transactions/expensecategories",
        "masterdata/attituderisk/categories",
        "masterdata/attituderisk/ratings",
        "masterdata/objectives",
        "masterdata/delete/objective",
        "masterdata/standardtracking",
        "masterdata/standardtracking",
        "masterdata/userdefined/field",
        "masterdata/providers",
        "masterdata/commission/commissionrule",
        "masterdata/commission/commissiontypes",
        "user",
        "user/info",
        "user/auth",
        "user/auth/roles",
        "user/limit-user",
        "/report",
        "/option?"
      ];
    
      cacheInvalidationRules.forEach((path) => {
      
        if (url.toLowerCase().includes(path.toLowerCase())) {
        
          if(url.toLowerCase().includes("masterdata/delete/objective")){
            delete apiCache["masterdata/objectives"]
          }else{
            for (const key in apiCache) {
             
              if (key.includes(path)) {
                delete apiCache[key]; // Invalidate cache
              }
            }
          }
         
        }
      });
    }
    return config; // Don't forget to return the config object
  },
  (error) => {
    return Promise.reject(error);
  }
);


axiosInstance.interceptors.response.use(
  (response) => {
    if (response.config.method === "get") {
      const cacheKey = createCacheKey(response.config);
      const expiration = Date.now() + CACHE_TTL;
      apiCache[cacheKey] = {
        data: response.data,
        expiration,
      };
      // console.log(`Response cached for key: ${cacheKey}`);
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Check if the error came from the cache (if so, it's not a real error)
    if (error.fromCache) {
      // console.log("Serving cached data:", error);
      return Promise.resolve({
        data: error.data,
        status: error.status,
        statusText: error.statusText,
        config: error.config,
        headers: error.headers,
        request: error.request,
      });
    }
    // console.error("API call failed:", error);
    return Promise.reject(error);
  }
);

let timestamp: any = new Date();
timestamp = timestamp.toISOString();
const generateNonce = () => {
  const CryptoJS = require("crypto-js");
  const requestData = {};
  const hash = CryptoJS.SHA256(`${requestData}${timestamp}`);
  return hash.toString(CryptoJS.enc.Hex);
};

const nonce = generateNonce();

axiosInstance.interceptors.request.use(
  (config: any) => {
    const token = store.getState().authUser.userToken;
    const userId = store.getState().authUser.userId;
    const refreshToken = store.getState().authUser.refreshToken;
    if (!(config.data instanceof FormData)) {
      config.headers["Content-Type"] = "application/json";
    }
    config.headers["X-Nonce"] = nonce;
    config.headers["X-Timestamp"] = timestamp;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (userId) {
      config.headers.UserId = userId;
    }
    if (refreshToken) {
      config.headers.RefreshToken = refreshToken;
    }

    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (response: any) => {
    let authorizationHeader =
      response.headers["authorization"] ||
      response.headers["x-amzn-remapped-authorization"];
    if (
      authorizationHeader !== undefined &&
      authorizationHeader.startsWith("Bearer ")
    ) {
      authorizationHeader = authorizationHeader.replace("Bearer ", "");

      store.dispatch(setCurrentUser(authorizationHeader));
    }
    return response;
  },
  (error: any) => {
    const { config, response } = error;

    if (!error.response) {
      // console.log("Network Error:", error.message);
    } else {
      if (error.response?.status === 401) {
        store.dispatch(seesionExpired(true));
      } else if (error.response?.status === 403) {
        store.dispatch(accessDenied(true));
      } else {
      }
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;

export const axiosPublicInstance = Axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_URL,
});