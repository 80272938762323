import React, { useEffect, useState } from "react";
import SystemManagerFormContainer from "../SystemManagerFormContainer";
import { configFm } from "../../../types";
import apiServices from "../../../service";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import OverlayLoader from "../../../components/OverlayLoader";
type ComponentProps = {
  [x: string]: any;
};
function CommissionType({ notify }: any) {
  const [formContext, setFormContext] = useState("COMMISSION_TYPES");
  const [configForm, setConfigForm] = useState<configFm>();
  const [listDataItems, setListDataItems] = useState([]);
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (
      formContext &&
      formSettingsObj?.systemAdmin &&
      formSettingsObj.systemAdmin.formConfig !== undefined
    ) {
      let configObj = formSettingsObj.systemAdmin.formConfig.find(
        (item: any) => item.context === formContext
      );
      if (configObj) {
        setConfigForm(configObj);
      }
    }
  }, [formSettingsObj]);
  useEffect(() => {
    getDataList();
  }, []);

  const loadListData = () => {
    getDataList();
  };
  const getDataList = async () => {
    setIsLoading(true);
    try {
      const res =
        await apiServices.systemManager.commissionType
        .getList()
      if (res.data !== undefined) {
        if (res.data.data) {
          setListDataItems(res.data.data);
        }
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
   
    
  };
  return (
    <div className="form-container">
       <OverlayLoader isLoading={isLoading} />
      <SystemManagerFormContainer
        context={formContext}
        config={configForm}
        notify={notify}
        title="Commission Type"
        isFormAdmin={true}
        dataList={listDataItems}
        reloadData={loadListData}
      />
    </div>
  );
}

export default CommissionType;
