export const unitLinked = [
    {
        unitLinked_id: 0,
        label: "Owner",
        value: "joint_indicator",
    },
    {
        unitLinked_id: 1,
        label: "Owner Detail",
        value: "owner",
    },
    {
        unitLinked_id: 2,
        label: "Owner Name 1",
        value: "owner_name1",
    },
    {
        unitLinked_id: 3,
        label: "Owner Name 2",
        value: "owner_name2",
    },
    {
        unitLinked_id: 4,
        label: "Type",
        value: "investment_type",
    },
    {
        unitLinked_id: 5,
        label: "Subtype",
        value: "investment_subtype",
    },
    {
        unitLinked_id: 6,
        label: "Provider",
        value: "provider_contact_name",
    },
    {
        unitLinked_id: 7,
        label: "Provider Contact",
        value: "purpose",
    },
    {
        unitLinked_id: 8,
        label: "Provider Phone",
        value: "provider_phone",
    },
    {
        unitLinked_id: 9,
        label: "Provider Note",
        value: "provider_note",
    },
    {
        unitLinked_id: 10,
        label: "Account Number",
        value: "investment_account_number",
    },
    {
        unitLinked_id: 11,
        label: "Current Value",
        value: "current_value",
    },
    {
        unitLinked_id: 12,
        label: "Value Date",
        value: "value_date",
    },
    {
        unitLinked_id: 13,
        label: "Purpose",
        value: "purpose",
    },
    {
        unitLinked_id: 14,
        label: "Start Date",
        value: "investment_start_date",
    },
    {
        unitLinked_id: 15,
        label: "End Date",
        value: "investment_end_date",
    },
    {
        unitLinked_id: 16,
        label: "Status",
        value: "investment_status",
    },
    {
        unitLinked_id: 17,
        label: "Service Status",
        value: "service_status",
    },
    {
        unitLinked_id: 18,
        label: "Admin Note",
        value: "note_text",
    },
    {
        unitLinked_id: 19,
        label: "Report Note",
        value: "report_note",
    },
]
export const shares = [
    {
        Shares_id: 0,
        label: "Owner",
        value: "joint_indicator",
    },
    {
        Shares_id: 1,
        label: "Owner Detail",
        value: "owner",
    },
    {
        Shares_id: 2,
        label: "Owner Name 1",
        value: "owner_name1",
    },
    {
        Shares_id: 3,
        label: "Owner Name 2",
        value: "owner_name2",
    },
    {
        Shares_id: 4,
        label: "Type",
        value: "share_type",
    },
    {
        Shares_id: 5,
        label: "Subtype",
        value: "share_subtype",
    },
    {
        Shares_id: 6,
        label: "Company",
        value: "share_company",
    },
    {
        Shares_id: 7,
        label: "Company Contact",
        value: "provider_contact_name",
    },
    {
        Shares_id: 8,
        label: "Company Phone",
        value: "provider_phone",
    },
    {
        Shares_id: 9,
        label: "Company Note",
        value: "provider_note",
    },
    {
        Shares_id: 10,
        label: "Quantity",
        value: "share_current_number",
    },
    {
        Shares_id: 11,
        label: "Current Price",
        value: "share_current_price",
    },
    {
        Shares_id: 12,
        label: "Current Value",
        value: "current_value",
    },
    {
        Shares_id: 13,
        label: "Value Date",
        value: "value_date",
    },
    {
        Shares_id: 14,
        label: "Purpose",
        value: "purpose",
    },
    {
        Shares_id: 15,
        label: "Date Acquired",
        value: "share_date_acquired",
    },
    {
        Shares_id: 16,
        label: "Original Value",
        value: "share_original_value",
    },
    {
        Shares_id: 17,
        label: "Status",
        value: "share_status",
    },
    {
        Shares_id: 18,
        label: "Service Status",
        value: "service_status",
    },
    {
        Shares_id: 19,
        label: "Admin Note",
        value: "note_text",
    },
    {
        Shares_id: 20,
        label: "Report Note",
        value: "report_note",
    },
]
export const homePersonal = [
    {
        Home_Personal_id: 0,
        label: "Owner",
        value: "joint_indicator",
    },
    {
        Home_Personal_id: 1,
        label: "Owner Detail",
        value: "owner",
    },
    {
        Home_Personal_id: 2,
        label: "Owner Name 1",
        value: "owner_name1",
    },
    {
        Home_Personal_id: 3,
        label: "Owner Name 2",
        value: "owner_name2",
    },
    {
        Home_Personal_id: 4,
        label: "Description",
        value: "home_description",
    },
    {
        Home_Personal_id: 5,
        label: "Date Acquired",
        value: "home_date_acquired",
    },
    {
        Home_Personal_id: 6,
        label: "Orginal Value",
        value: "home_original_value",
    },
    {
        Home_Personal_id: 7,
        label: "Current Value",
        value: "current_value",
    },
    {
        Home_Personal_id: 8,
        label: "Value Date",
        value: "value_date",
    },
    {
        Home_Personal_id: 9,
        label: "Purpose",
        value: "purpose",
    },
    {
        Home_Personal_id: 10,
        label: "Admin Note",
        value: "note_text",
    },
    {
        Home_Personal_id: 11,
        label: "Report Note",
        value: "report_note",
    },
]
export const banksBSocieties = [
    {
        Banks_B_Societies_id: 0,
        label: "Owner",
        value: "joint_indicator",
    },
    {
        Banks_B_Societies_id: 1,
        label: "Owner Detail",
        value: "owner",
    },
    {
        Banks_B_Societies_id: 2,
        label: "Owner Name 1",
        value: "owner_name1",
    },
    {
        Banks_B_Societies_id: 3,
        label: "Owner Name 2",
        value: "owner_name2",
    },
    {
        Banks_B_Societies_id: 4,
        label: "Account Type",
        value: "bank_account_type",
    },
    {
        Banks_B_Societies_id: 5,
        label: "Bank Name",
        value: "bank_name",
    },
    {
        Banks_B_Societies_id: 6,
        label: "Contact",
        value: "provider_contact_name",
    },
    {
        Banks_B_Societies_id: 7,
        label: "Phone",
        value: "provider_phone",
    },
    {
        Banks_B_Societies_id: 8,
        label: "Note",
        value: "provider_note",
    },
    {
        Banks_B_Societies_id: 9,
        label: "Sort Code",
        value: "bank_sort_code",
    },
    {
        Banks_B_Societies_id: 10,
        label: "Account Name",
        value: "bank_account_name",
    },
    {
        Banks_B_Societies_id: 11,
        label: "Account Number",
        value: "bank_account_number",
    },
    {
        Banks_B_Societies_id: 12,
        label: "Purpose",
        value: "purpose",
    },
    {
        Banks_B_Societies_id: 13,
        label: "Balance",
        value: "current_value",
    },
    {
        Banks_B_Societies_id: 14,
        label: "Balance Date",
        value: "balance_date",
    },
    {
        Banks_B_Societies_id: 15,
        label: "Interest Rate",
        value: "bank_interest_rate",
    },
    {
        Banks_B_Societies_id: 16,
        label: "Date Opened",
        value: "bank_date_opened",
    },
    {
        Banks_B_Societies_id: 17,
        label: "Status",
        value: "bank_status",
    },
    {
        Banks_B_Societies_id: 18,
        label: "Service Status",
        value: "service_status",
    },
    {
        Banks_B_Societies_id: 19,
        label: "Admin Note",
        value: "note_text",
    },
    {
        Banks_B_Societies_id: 20,
        label: "Report Note",
        value: "report_note",
    },
]
