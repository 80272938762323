import React from "react";
import tekk from "../../assets/logo/tekk_ai.png";
import "./style.css";

const Footer = () => {
  return (
    <footer>
      <p>&copy;{new Date().getFullYear()}&nbsp;
         Assyst Software and Consultancy Ltd., a fully owned subsidiary of</p>&nbsp;
      <img src={tekk} alt="tekk.ai" height="30px" />&nbsp;
      <p>an ISO 9001 and ISO 27001 certified company</p>
    </footer>
  );
};

export default Footer;
