import { makeStyles, shorthands } from "@fluentui/react-components";
export interface OperatorType {
  id: number;
  name: string;
}
export interface ConditionType {
  id: number;
  name: string;
}
export interface filterTypeData {
  x?: any;
  value?: any;
  type?: any;
}
export interface filterConditionalTypeData {
  x?: any;
  value?: any;
  type?: any;
}
export interface getDatatype {
  dataType?: string;
}
export interface TreeNode {
  label?: string;
  items?: TreeNode[];
  table?: string;
  fieldName?: string;
  fk?: string;
  name?: string;
  datatype?: any;
}
export interface TreeHandleOptionClick{
  value: string,
  label: string,
  itemType: string,
  data: any,
  datatype: any,
  table: any,
  fieldName?:any
}
export const Operator: OperatorType[] = [
  {
    id: 1,
    name: "StartsWith",
  },
  {
    id: 2,
    name: "EndsWith",
  },
  {
    id: 3,
    name: "Contains",
  },
];

export const booleanOperators = [
  {
    label: "Equals",
    value: "equals",
  },
  {
    label: "Not Equals",
    value: "notEquals",
  },
  {
    label: "Greater Than",
    value: "greaterThan",
  },

  {
    label: "Greater Than Or Equal",
    value: "greaterThanOrEqual",
  },
  {
    label: "Less Than",
    value: "lessThan",
  },
  {
    label: "Less Than Or Equal",
    value: "lessThanOrEqual",
  },
];

export const stringOperators = [
  {
    label: "Contains",
    value: "in",
  },
  {
    label: "Starts With",
    value: "startsWith",
  },
  {
    label: "Ends With",
    value: "endsWith",
  },
  {
    label: "Equals",
    value: "equals",
  },
];

export const dateOperators = [
  {
    label: "Equals",
    value: "equals",
  },
  {
    label: "Not Equals",
    value: "notEquals",
  },
  {
    label: "Greater Than",
    value: "greaterThan",
  },
  {
    label: "Greater Than Or Equal",
    value: "greaterThanOrEqual",
  },
  {
    label: "Less Than",
    value: "lessThan",
  },
  {
    label: "Less Than Or Equal",
    value: "lessThanOrEqual",
  },
  {
    label: "Contains",
    value: "in",
  },
  {
    label: "Does Not Contains",
    value: "notIn",
  },
];
export const Condition: ConditionType[] = [
  {
    id: 1,
    name: "AND",
  },
  {
    id: 2,
    name: "OR",
  },
];

export const operatorsList = [
  {
    operator: "contains",
    label: "Contains",
    fieldTypes: ["char", "timestamp", "integer"],
  },
  {
    operator: "equals",
    label: "Equals",
    fieldTypes: ["char", "timestamp"],
  },
  {
    operator: "startsWith",
    label: "Starts With",
    fieldTypes: ["char", "timestamp"],
  },
  {
    operator: "endsWith",
    label: "Ends With",
    fieldTypes: ["char", "timestamp"],
  },
  {
    operator: "isEqualTo",
    label: "Is Equal to",
    fieldTypes: ["integer", "timestamp", "char"],
  },
  {
    operator: "isNotEqualTo",
    label: "Is Not Equal to",
    fieldTypes: ["integer", "timestamp", "char"],
  },
  {
    operator: "isGreaterThan",
    label: "Is Greater Than",
    fieldTypes: ["integer", "timestamp", "char"],
  },
  {
    operator: "isGreaterThanOrEqualTo",
    label: "Is Greater Than Or Equal to",
    fieldTypes: ["integer", "timestamp", "char"],
  },
  {
    operator: "isLessThan",
    label: "Is Less Than",
    fieldTypes: ["integer", "timestamp", "char"],
  },
  {
    operator: "isLessThanOrEqualTo",
    label: "Is Less Than or Equal To",
    fieldTypes: ["integer", "timestamp", "char"],
  },
  {
    operator: "doesNotContains",
    label: "Does Not Contain",
    fieldTypes: ["timestamp", "integer"],
  },
];
export const intList = operatorsList.filter(
  (op) =>
    op.operator.includes("isEqualTo") ||
    op.operator.includes("isNotEqualTo") ||
    op.operator.includes("isGreaterThan") ||
    op.operator.includes("isGreaterThanOrEqualTo") ||
    op.operator.includes("isLessThan")
);
export const timestampList = operatorsList.filter(
  (x) =>
    x.operator.includes("isEqualTo") ||
    x.operator.includes("isNotEqualTo") ||
    x.operator.includes("isGreaterThan") ||
    x.operator.includes("isGreaterThanOrEqualTo") ||
    x.operator.includes("isLessThan") ||
    x.operator.includes("isLessThanOrEqualTo") ||
    x.operator.includes("doesNotContains") ||
    x.operator.includes("contains")
);
export const charList = operatorsList.filter(
  (x) =>
    x.operator.includes("contains") ||
    x.operator.includes("startsWith") ||
    x.operator.includes("endsWith") ||
    x.operator.includes("equals")
);
export interface Errors {
  titleData?: string;
  descriptionData?: string;
  selectedOption?: string;
  selectedOperator?: string;
  selectedValue?: string;
  selectConditionOption?: string;
  selectedConditionOperator?: string;
  selectedConditionValue?: string;
}
export interface ValidationErrors {
  titleData?: string;
  descriptionData?: string;
  selectedOption?: string;
  selectedOperator?: string;
  selectedValue?: string;
  selectConditionOption?: string;
  selectedConditionOperator?: string;
  selectedConditionValue?: string;
}
export const useStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    maxWidth: "400px",
    minHeight: "30vh",
  },
  card: {
    ...shorthands.margin("auto"),
    width: "1080px",
    maxWidth: "100%",
    // top:'-80px',
    left: "30px",
  },
  inlineContainer: {
    display: "grid",
    gridTemplateColumns: "2fr 2fr 2fr 1fr",
    alignItems: "end",
    ...shorthands.gap("5px"),
  },
  Addbutton: {
    ...shorthands.padding("10px"),
  },
  wrapper: {
    columnGap: "15px",
    display: "flex",
    cursor: "pointer",
  },
  contentHeader: {
    marginTop: "0",
  },
  scopeArea: {
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    maxWidth: "400px",
  },
});

