import * as React from "react";
import {
  Skeleton,
  SkeletonItem,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  invertedWrapper: {
    backgroundColor: tokens.colorNeutralBackground1,
  },
  firstRow: {
    alignItems: "center",
    paddingBottom: "10px",
    ...shorthands.gap("10px"),
  },
  secondThirdRow: {
    alignItems: "center",
    display: "block",
    paddingBottom: "10px",
    ...shorthands.gap("10px"),
  },
});

export const SkeletonLoader = ({ isLoadingSkeleton, message }: any) => {
  const styles = useStyles();
  return (
    <div className={styles.invertedWrapper}>
      <Skeleton>
        <div className={styles.firstRow}>
          <SkeletonItem shape="rectangle" size={16} />
        </div>
        <div className={styles.secondThirdRow}>
          <SkeletonItem size={16} />
        </div>
       <div className={styles.secondThirdRow}>
          <SkeletonItem size={16} />
        </div>
        <div className={styles.secondThirdRow}>
          <SkeletonItem size={16} />
        </div>
      </Skeleton>
    </div>
  );
};