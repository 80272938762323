import React, { useEffect, useState } from "react";
import FormContent from "../../../components/Form/FormContent";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import { setFormData, resetFormData } from "../../../redux/reducer";
import { formBuilder } from "../../../utils/formGenerator";
import { useFormik } from "formik";
import {
  makeStyles,
  shorthands,
  Button,
  tokens,
  useId,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
  Link,
  ToastBody,
  ToastFooter,
  ToastTrigger,
  Label,
  Card,
  CardHeader,
  CardPreview,
} from "@fluentui/react-components";
import {
  Edit24Filled,
  ArrowUndo16Regular,
  ArrowRedo16Regular,
  Delete16Regular,
  DismissCircleRegular,
  DismissCircle20Regular,
  Circle24Regular,
  Add16Filled,
} from "@fluentui/react-icons";
import TableDataList from "../../../components/TableDataList";

import apiServices from "../../../service";
// import { UserPermissionsList } from "../config";
import {
  formatedTime,
  checkUserPermission,
  getClientCat,
  getClientNoteCategories,
  getClientType,
  convertStringToArray,
  getYes_NoConversion,
} from "../../../utils";
import axios from "axios";
import { UserPermissionsList } from "../../../config";
import { setDialogModalOptions } from "../../../redux/modelDialogReducer";
import { FormErrorMessage } from "../../../components/FormErrorMessage/FormErrorMessage";
import OverlayLoader from "../../../components/OverlayLoader";
import DeletePopup from "../../../components/DeletePopup/Deletepopup";
const useStyles = makeStyles({
  btnwrapper: {
    columnGap: "8px",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "6px",
    marginTop: "6px",
  },
  buttonWrapper: {
    columnGap: "15px",
    display: "flex",
    ...shorthands.padding("10px"),
  },
  containerTopArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  message: {
    marginRight: "14px",
  },
});
type PartnerFormContainerWithListProps = {
  [x: string]: any;
};

const PartnerFormContainerWithList = (
  props: PartnerFormContainerWithListProps
) => {
  let { context } = props;
  const classes = useStyles();
  const [nextAction, setNextAction] = useState("");
  const [formMode, setFormMode] = useState(props.mode === "add" ? "New" : "");
  const [mode, setMode] = useState("");
  const [formError, setFormError] = useState<any[]>([]);
  const [formFieldData, setFormFieldData] = useState<{ [key: string]: any }>(
    {}
  );
  const [loading, setLoading] = useState(false);
  const [past, setPast] = useState<any[]>([]);
  const [present, setPresent] = useState(null);
  const [future, setFuture] = useState<any[]>([]);
  const [selectedRowItem, setSelectedRowItem] = useState<{
    [key: string]: any;
  }>({});
  const [dataList, setDataList] = useState<{ [key: string]: any }[]>([]);
  const [initialFormData, setInitialFormData] = useState({});
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [optionData, setOptionData] = useState<any>();
  const [optionList, setOptionList] = useState<any>([]);
  const [reloadRequired, setReloadRequired] = useState(0);
  const [isOptionLoaded, setIsOptionLoaded] = useState<boolean>(false);
  const isFormDataUpdated = useSelector(
    (state: RootState) => state.someReducer.isFormDataUpdated
  );
  const objFormSettings = useSelector((state: RootState) => state.formSettings);
  const dispatch: AppDispatch = useDispatch();
  const [currentRecord, setCurrentRecord] = useState(1);
  const updatePresent = (newState: any) => {
    setPast([...past, present]);
    setPresent(newState);
    setFuture([]);
  };
  const loggedUser = useSelector((state: RootState) => state.authUser);

  const [newlyAddedSelectOptions, setNewlyAddedSelectOptions] = useState<any>(
    []
  );
  const [selectFieldOptionLabels, setSelectFieldOptionLabels] = useState<any>(
    []
  );
  const [selectFieldOptions, setSelectFieldOptions] = useState<any>([]);
  const [deletePopup, setDeletePopup] = useState(false);

  const newSelectOptionAdded = (
    field: string,
    options: any,
    operation: string
  ) => {
    // console.log("field", field);
    // console.log("options", options);
    // console.log("selectFieldOptions", selectFieldOptions);
    // console.log("selectFieldOptionLabels", selectFieldOptionLabels);
    // let fieldInfo = selectFieldOptions.find((e: any) => e.field === field);
    let labelInfo = selectFieldOptionLabels.find(
      (e: any) => e.formField === field
    );
    // console.log("labelInfo", labelInfo);
    // console.log("fieldInfo", fieldInfo);
    if (operation === "remove") {
      const filteredRes = newlyAddedSelectOptions.filter(
        (item: any) => item.field !== field
      );
      setNewlyAddedSelectOptions(filteredRes);
    } else {
      if (labelInfo) {
        let newVall: any = {
          type: labelInfo.listType,
          options: [],
        };
        newVall["options"].push({
          field: labelInfo.label,
          label: options.label,
          value: options.value,
        });

        setNewlyAddedSelectOptions((prev: any) => [...prev, newVall]);
      }
    }
    // console.log("newlyAddedSelectOptions", newlyAddedSelectOptions);
  };

  const AddSelectOptions = () => {
    if (newlyAddedSelectOptions.length > 0) {
      apiServices.selectionList
        .addOptions(newlyAddedSelectOptions)
        .then((response: any) => {
          console.log("response--add options", response);
        })
        .catch((err) => console.log(err));
    }
  };
  const getSelectionListOptions = async (fieldLabels: any) => {
    try {
      let fieldLbl = "";
      if (fieldLabels.length > 0) {
        fieldLabels.map((itm: any, index: number) => {
          // console.log("index", index);
          if (fieldLabels.length - 1 != index) {
            fieldLbl = fieldLbl.concat(`${itm.label}:${itm.listType},`);
          } else {
            fieldLbl = fieldLbl.concat(`${itm.label}:${itm.listType}`);
          }
        });

        apiServices.selectionList
          .getOptions(fieldLbl)
          .then((res) => {
            if (res.data && res.data.data) {
              setSelectFieldOptions(res.data.data);
            }
            // console.log("response-selectionlist res", res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const generateDynamicForm = (formContext: string) => {
    let { initialValues, inputs, validationSchema } = formBuilder({
      context: context,
      formSettings:
        props.module != undefined && props.module == "commission"
          ? objFormSettings.data.commission
          : objFormSettings.data,
    });

    let filteredSelectionOptions = inputs
      .filter(
        (opt: any) =>
          opt.listType !== undefined &&
          [
            "ModifiableList",
            "FixedList",
            "MasterDatawithNewEntry",
            "MasterDatawithoutNewEntry",
          ].includes(opt.listType) &&
          opt.fieldOptionLabel !== undefined
      )
      .map((opt) => ({
        label: opt.fieldOptionLabel,
        listType: opt.listType,
        formField: opt.field,
      }));
    let filteredPrefixFields = inputs
      .filter(
        (opt: any) =>
          opt.prefix !== undefined &&
          opt.prefix.listType !== undefined &&
          [
            "ModifiableList",
            "FixedList",
            "MasterDatawithNewEntry",
            "MasterDatawithoutNewEntry",
          ].includes(opt.prefix.listType) &&
          opt.prefix.fieldOptionLabel !== undefined
      )
      .map((opt) => ({
        label: opt.prefix.fieldOptionLabel,
        listType: opt.prefix.listType,
        formField: opt.prefix.field,
      }));
    if (filteredPrefixFields && filteredPrefixFields.length > 0) {
      filteredSelectionOptions = [
        ...filteredSelectionOptions,
        ...filteredPrefixFields,
      ];
    }
    setSelectFieldOptionLabels(filteredSelectionOptions);
    if (filteredSelectionOptions.length > 0) {
      getSelectionListOptions(filteredSelectionOptions);
    }
    setFormFieldData({ initialValues, inputs, validationSchema });
    // setPrimaryContact("home_phone");
  };

  useEffect(() => {
    if (reloadRequired !== 0) {
      props.reloadData();
      setReloadRequired(0);
    }
  }, [reloadRequired]);

  useEffect(() => {
    fetchDataList();
  }, [props.initialData, props.attitudeToRiskData]);
  const undo = () => {
    if (past.length === 0) return;

    const newPast = [...past];
    const newPresent = newPast.pop();

    setPast(newPast);
    setFuture([present, ...future]);
    setPresent(newPresent);
    formik.setValues(newPresent);
  };
  const redo = () => {
    if (future.length === 0) return;

    const newFuture = [...future];
    const newPresent = newFuture.shift();

    setPast([...past, present]);
    setFuture(newFuture);
    setPresent(newPresent);
    formik.setValues(newPresent);
  };
  useEffect(() => {
    if (context !== "") {
      generateDynamicForm(context);
      fetchDataList();
    }
    const fetchOption = async () => {
      try {
        const response = await axios.get("/option.json");
        const optiondata = response.data;
        setOptionData(optiondata);
        optiondata[context] && setOptionList(optiondata[context]);
        optionList && setIsOptionLoaded(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchOption();
  }, [context]);
  const customHandleChange = (event: any) => {
    // alert("custom handle chnage");
    handleOnChange(event);
  };
  useEffect(() => {
    if (
      mode &&
      selectedRowItem &&
      ["view", "edit"].includes(mode) &&
      Object.keys(selectedRowItem || {}).length !== 0
    ) {
      let rowItem = { ...selectedRowItem };
      const contextData: any = {
        PARTNER_OBJECTIVES: {
          applicable:
            mode === "view"
              ? selectedRowItem.applicable === 0
                ? "No"
                : "Yes"
              : selectedRowItem.applicable,
        },
        PARTNER_INCOME: {
          joint_indicator:
            mode === "view"
              ? getClientType(selectedRowItem.joint_indicator)
              : selectedRowItem.joint_indicator,
        },
        PARTNER__NOTES: {
          note_time:
            mode === "view"
              ? formatedTime(selectedRowItem.note_time)
              : selectedRowItem.note_time,
          category:
            mode === "view"
              ? getClientNoteCategories(selectedRowItem.category)
              : selectedRowItem.category,
        },
        FACT_FIND_NOTES: {
          note_time:
            mode === "view"
              ? formatedTime(selectedRowItem.note_time)
              : selectedRowItem.note_time,
          category:
            mode === "view"
              ? getClientNoteCategories(selectedRowItem.category)
              : selectedRowItem.category,
        },
        CONTACTS_TIME_ALLOCATION: {
          duration:
            mode === "view"
              ? formatedTime(selectedRowItem.duration)
              : selectedRowItem.duration,
        },
        CONTACTS_PARTNER_ACTION: {
          appointment_time:
            mode === "view"
              ? formatedTime(selectedRowItem.appointment_time)
              : selectedRowItem.appointment_time,
        },
        PARTNER_EMPLOYMENT: {
          controlling_director:
            mode === "view"
              ? getYes_NoConversion(selectedRowItem.controlling_director)
              : selectedRowItem.controlling_director,
        },
      };
      if (context in contextData) {
        rowItem = { ...rowItem, ...contextData[context] };
      }

      setInitialFormData(rowItem);
    }
  }, [mode, selectedRowItem]);

  const formik = useFormik({
    initialValues: ["edit", "view"].includes(mode)
      ? initialFormData
      : formFieldData?.initialValues || {},
    validationSchema: formFieldData?.validationSchema || null,
    enableReinitialize: true,
    onSubmit: function (values, { setSubmitting }) {
      // formik.resetForm();
      if (Object.keys(formik.errors).length === 0) {
        formSubmitted(values);
      }
    },
  });

  useEffect(() => {
    setPresent(formik.initialValues);
  }, [formik.initialValues]);
  useEffect(() => {
    fetchDataList();
  }, [props.initialData]);
  const formSubmitted = (values: any) => {
    setIsFormLoading(true);
    const payload = formFieldData.inputs.map(({ field }: any) => field);
    setFormError([]);
    let reqBody: { [key: string]: any } = {};

    if (payload && payload.length > 0) {
      payload.map((el: any) => {
        if (values.hasOwnProperty(el)) {
          reqBody[el] = values[el] !== null ? values[el] : "";
        }
      });
    }

    if (mode === "add") {
      setFormError([]);
      let addReq: any = {};
      if (
        props.config &&
        props.config.renderForm &&
        props.config.renderForm.reference_id
      ) {
        if (props.config.renderForm.reference_id === "objectives") {
          addReq[props.config.renderForm.reference_id] = {
            customer_id: props.initialData.customer_id,
            objective_description: reqBody.objective_description,
            sort_order: reqBody.sort_order === "" ? 0 : reqBody.sort_order,
            applicable: reqBody.applicable === "" ? 0 : reqBody.applicable,
            comments: reqBody.comments,
            rating: reqBody.rating === "" ? 0 : reqBody.rating,
          };
        } else if (props.config.renderForm.reference_id === "identity") {
          addReq[props.config.renderForm.reference_id] = {
            customer_id: props.initialData.customer_id,
            category: reqBody.category,
            item: reqBody.item,
            reference: reqBody.reference,
            issued: reqBody.issued,
            expires: reqBody.expires,
            issued_at: reqBody.issued_at,
            seen: reqBody.seen,
          };
        } else if (props.config.renderForm.reference_id === "service_type") {
          addReq[props.config.renderForm.reference_id] = {
            customer_id: props.initialData.customer_id,
            description: reqBody.description,
            start_date: reqBody.start_date,
            end_date: reqBody.end_date,
          };
        } else if (props.config.renderForm.reference_id === "employment") {
          addReq[props.config.renderForm.reference_id] = {
            customer_id: props.initialData.customer_id,
            principal_employment: reqBody.principal_employment,
            occupation: reqBody.occupation,
            employment_type: reqBody.employment_type,
            employer_name: reqBody.employer_name,
            address_1: reqBody.address_1,
            address_2: reqBody.address_2,
            address_3: reqBody.address_3,
            town: reqBody.town,
            county: reqBody.county,
            country: reqBody.country,
            postcode: reqBody.postcode,
            phone: reqBody.phone,
            fax: reqBody.fax,
            start_date: reqBody.start_date,
            end_date: reqBody.end_date,
            salary_review_date: reqBody.salary_review_date,
            payroll_id: reqBody.payroll_id,
            position: reqBody.position,
            controlling_director: reqBody.controlling_director,
            shareholding: reqBody.shareholding,
            note_text: reqBody.note_text,
            employment_basis: reqBody.employment_basis,
            retirement_age:
              reqBody.retirement_age === "" ? 0 : reqBody.retirement_age,
            death_in_service_yn: parseInt(reqBody.death_in_service_yn),
            death_in_service: reqBody.death_in_service,
            sick_pay_yn: parseInt(reqBody.sick_pay_yn),
            sick_pay: reqBody.sick_pay,
            pmi_yn: parseInt(reqBody.pmi_yn),
            pmi: reqBody.pmi,
            phi_yn: parseInt(reqBody.phi_yn),
            phi: reqBody.phi,
            critical_illness_yn: parseInt(reqBody.critical_illness_yn),
            critical_illness: reqBody.critical_illness,
            company_car_yn: parseInt(reqBody.company_car_yn),
            company_car: reqBody.company_car,
            share_options_yn: parseInt(reqBody.share_options_yn),
            share_options: reqBody.share_options,
            other_yn: parseInt(reqBody.other_yn),
            other: reqBody.other,
            pension_yn: parseInt(reqBody.pension_yn),
            pension: reqBody.pension,
            auto_enrolment: reqBody.auto_enrolment,
            // cash_calc_id: reqBody.createdAt,
          };
        } else if (props.config.renderForm.reference_id === "dependants") {
          addReq[props.config.renderForm.reference_id] = {
            customer_id: props.initialData.customer_id,
            title: reqBody.title,
            first_names: reqBody.first_names,
            last_name: reqBody.last_name ? reqBody.last_name : "",
            known_as: reqBody.known_as ? reqBody.known_as : "",
            sex: reqBody.sex ? reqBody.sex : "",
            date_of_birth: reqBody.date_of_birth ? reqBody.date_of_birth : "",
            relation: reqBody.relation ? reqBody.relation : "",
            parent: reqBody.parent ? reqBody.parent : "",
            smoker: reqBody.smoker ? reqBody.smoker : "",
            living_at_home: reqBody.living_at_home
              ? reqBody.living_at_home
              : "",
            financially_dependant: reqBody.financially_dependant
              ? reqBody.financially_dependant
              : "",
            note_text: reqBody.note_text ? reqBody.note_text : "",
          };
        } else if (props.config.renderForm.reference_id === "income") {
          addReq[props.config.renderForm.reference_id] = {
            customer_id: props.initialData.customer_id,
            joint_indicator: reqBody.joint_indicator,
            income_type: reqBody.income_type,
            gross_amount: reqBody.gross_amount ? reqBody.gross_amount : 0,
            net_amount: reqBody.net_amount ? reqBody.net_amount : 0,
            frequency: reqBody.frequency ? reqBody.frequency : "",
            source: reqBody.source ? reqBody.source : "",
            start_date: reqBody.start_date ? reqBody.start_date : "",
            end_date: reqBody.end_date ? reqBody.end_date : "",
            note_text: reqBody.note_text ? reqBody.note_text : "",
            sort_order: reqBody.sort_order === "" ? 0 : reqBody.sort_order,
          };
        } else if (props.config.renderForm.reference_id === "outgoings") {
          addReq[props.config.renderForm.reference_id] = {
            customer_id: props.initialData.customer_id,
            description: reqBody.description,
            frequency: reqBody.frequency,
            PARTNER_amount: reqBody.PARTNER_amount ? reqBody.PARTNER_amount : 0,
            partner_amount: reqBody.partner_amount ? reqBody.partner_amount : 0,
            joint_amount: reqBody.joint_amount ? reqBody.joint_amount : 0,
            sort_order: reqBody.sort_order ? reqBody.sort_order : 0,
            discretionary: reqBody.discretionary ? reqBody.discretionary : "",
          };
        } else if (props.config.renderForm.reference_id === "address_book") {
          addReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.activeCustomerId,
          };

          // addReq[props.config.renderForm.reference_id]={
          //   "customer_id":props.activeCustomerId,
          //   "description":reqBody.description,
          //   "frequency":reqBody.frequency,
          //   "PARTNER_amount":reqBody.PARTNER_amount?reqBody.PARTNER_amount:0,
          //   "partner_amount":reqBody.partner_amount?reqBody.partner_amount:0,
          //   "joint_amount":reqBody.joint_amount?reqBody.joint_amount:0,
          //   "sort_order":reqBody.sort_order?reqBody.sort_order:0,
          // }
        } else if (props.config.renderForm.reference_id === "contacts_notes") {
          addReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.initialData.customer_id,
            category: parseInt(reqBody.category),
          };

          // addReq[props.config.renderForm.reference_id]={
          //   "customer_id":props.activeCustomerId,
          //   "description":reqBody.description,
          //   "frequency":reqBody.frequency,
          //   "PARTNER_amount":reqBody.PARTNER_amount?reqBody.PARTNER_amount:0,
          //   "partner_amount":reqBody.partner_amount?reqBody.partner_amount:0,
          //   "joint_amount":reqBody.joint_amount?reqBody.joint_amount:0,
          //   "sort_order":reqBody.sort_order?reqBody.sort_order:0,
          // }
        } else if (props.config.renderForm.reference_id === "fact_find_notes") {
          addReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.initialData.customer_id,
            category: parseInt(reqBody.category),
          };
        } else if (
          props.config.renderForm.reference_id === "contacts_time_allocation"
        ) {
          addReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.initialData.customer_id,
          };
        } else if (
          props.config.renderForm.reference_id === "contacts_PARTNER_action"
        ) {
          addReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.initialData.customer_id,
          };
        } else if (
          props.config.renderForm.reference_id === "attitude_to_risk"
        ) {
          addReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.initialData.customer_id,
            score: 0,
          };
        } else if (props.config.renderForm.reference_id === "fees_charges") {
          addReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.initialData.customer_id,
            payment_id: props.activeCustomerId,
            case_id: props.activeCustomerId,
            amount_of_company: parseFloat(reqBody.amount_of_company),
            // commission_type:""
          };
        } else {
          addReq[props.config.renderForm.reference_id] = reqBody;
        }
      }

      if (
        [
          "PARTNER_DEPENDANT",
          "PARTNER_INCOME",
          "PARTNER_OUTGOINGS",
          "ADDRESS_BOOK",
          "CONTACTS_NOTES",
          "FACT_FIND_NOTES",
          "CONTACTS_TIME_ALLOCATION",
          "CONTACTS_PARTNER_ACTION",
          "PARTNER_ATTITUDE_TO_RISK",
          "FEES_CHARGES",
        ].includes(props.context)
      ) {
        if (props.context === "PARTNER_DEPENDANT") {
          apiServices.dependant
            .createNew(addReq)
            .then((response: any) => {
              handleApiSuccessResponse(response);
            })
            .catch((e: Error) => {
              handleApiErrorResponse(e);
            });
        } else if (props.context === "PARTNER_INCOME") {
          apiServices.income
            .createNew(addReq)
            .then((response: any) => {
              handleApiSuccessResponse(response);
            })
            .catch((e: Error) => {
              handleApiErrorResponse(e);
            });
        } else if (props.context === "PARTNER_OUTGOINGS") {
          apiServices.outgoings
            .createNew(addReq)
            .then((response: any) => {
              handleApiSuccessResponse(response);
            })
            .catch((e: Error) => {
              handleApiErrorResponse(e);
            });
        } else if (props.context === "ADDRESS_BOOK") {
          apiServices.addressBook
            .createNew(addReq)
            .then((response: any) => {
              handleApiSuccessResponse(response);
            })
            .catch((e: Error) => {
              handleApiErrorResponse(e);
            });
        } else if (props.context === "CONTACTS_NOTES") {
          apiServices.notes
            .create(addReq)
            .then((response: any) => {
              handleApiSuccessResponse(response);
            })
            .catch((e: Error) => {
              handleApiErrorResponse(e);
            });
        } else if (props.context === "FACT_FIND_NOTES") {
          apiServices.factFindNote
            .createNew(addReq)
            .then((response: any) => {
              handleApiSuccessResponse(response);
            })
            .catch((e: Error) => {
              handleApiErrorResponse(e);
            });
        } else if (props.context === "CONTACTS_PARTNER_ACTION") {
          apiServices.contactClientAction
            .createNew(addReq)
            .then((response: any) => {
              handleApiSuccessResponse(response);
            })
            .catch((e: Error) => {
              handleApiErrorResponse(e);
            });
        } else if (props.context === "CONTACTS_TIME_ALLOCATION") {
          apiServices.timeAllocation
            .create(addReq)
            .then((response: any) => {
              handleApiSuccessResponse(response);
            })
            .catch((e: Error) => {
              handleApiErrorResponse(e);
            });
        } else if (props.context === "PARTNER_ATTITUDE_TO_RISK") {
          apiServices.attitudeToRisk
            .create(addReq)
            .then((response: any) => {
              handleApiSuccessResponse(response);
            })
            .catch((e: Error) => {
              handleApiErrorResponse(e);
            });
        } else if (props.context === "FEES_CHARGES") {
          apiServices.feesCharges
            .create(addReq)
            .then((response: any) => {
              handleApiSuccessResponse(response);
            })
            .catch((e: Error) => {
              handleApiErrorResponse(e);
            });
        } else {
        }
      } else {
        apiServices.client
          .createNew(addReq)
          .then((response: any) => {
            AddSelectOptions();
            handleApiSuccessResponse(response);
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
          });
      }
    } else if (mode === "edit") {
      setFormError([]);
      let updateReq: any = {};
      if (
        props.config &&
        props.config.renderForm &&
        props.config.renderForm.reference_id
      ) {
        if (props.config.renderForm.reference_id === "objectives") {
          updateReq[props.config.renderForm.reference_id] = {
            customer_id: props.initialData.customer_id,
            objective_description: reqBody.objective_description,
            sort_order:
              reqBody.sort_order === "" ? 0 : parseInt(reqBody.sort_order),
            comments: reqBody.comments,
            rating: reqBody.rating === "" ? 0 : parseInt(reqBody.rating),
            applicable:
              reqBody.applicable === "" ? 0 : parseInt(reqBody.applicable),
          };
        } else if (props.config.renderForm.reference_id === "service_type") {
          updateReq[props.config.renderForm.reference_id] = {
            // customer_Id: props.activeCustomerId,
            description: reqBody.description,
            start_date: reqBody.start_date,
            end_date: reqBody.end_date,
          };
        } else if (props.config.renderForm.reference_id === "identity") {
          updateReq[props.config.renderForm.reference_id] = {
            // Customer_id: props.activeCustomerId,
            category: reqBody.category,
            item: reqBody.item,
            reference: reqBody.reference,
            issued: reqBody.issued,
            expires: reqBody.expires,
            issued_at: reqBody.issued_at,
            seen: reqBody.seen,
          };
        } else if (props.config.renderForm.reference_id === "employment") {
          updateReq[props.config.renderForm.reference_id] = {
            customer_id: props.initialData.customer_id,
            principal_employment: reqBody.principal_employment,
            occupation: reqBody.occupation,
            employment_type: reqBody.employment_type,
            employer_name: reqBody.employer_name,
            address_1: reqBody.address_1,
            address_2: reqBody.address_2,
            address_3: reqBody.address_3,
            town: reqBody.town,
            county: reqBody.county,
            country: reqBody.country,
            postcode: reqBody.postcode,
            phone: reqBody.phone,
            fax: reqBody.fax,
            start_date: reqBody.start_date,
            end_date: reqBody.end_date,
            salary_review_date: reqBody.salary_review_date,
            payroll_id: reqBody.payroll_id,
            position: reqBody.position,
            controlling_director: reqBody.controlling_director,
            shareholding: reqBody.shareholding,
            note_text: reqBody.note_text,
            employment_basis: reqBody.employment_basis,
            retirement_age:
              reqBody.retirement_age === "" ? 0 : reqBody.retirement_age,
            death_in_service_yn: parseInt(reqBody.death_in_service_yn),
            death_in_service: reqBody.death_in_service,
            sick_pay_yn: parseInt(reqBody.sick_pay_yn),
            sick_pay: reqBody.sick_pay,
            pmi_yn: parseInt(reqBody.pmi_yn),
            pmi: reqBody.pmi,
            phi_yn: parseInt(reqBody.phi_yn),
            phi: reqBody.phi,
            critical_illness_yn: parseInt(reqBody.critical_illness_yn),
            critical_illness: reqBody.critical_illness,
            company_car_yn: parseInt(reqBody.company_car_yn),
            company_car: reqBody.company_car,
            share_options_yn: parseInt(reqBody.share_options_yn),
            share_options: reqBody.share_options,
            other_yn: parseInt(reqBody.other_yn),
            other: reqBody.other,
            pension_yn: parseInt(reqBody.pension_yn),
            pension: reqBody.pension,
            auto_enrolment: reqBody.auto_enrolment,
            // cash_calc_id: reqBody.createdAt,
          };
        } else if (props.config.renderForm.reference_id === "dependants") {
          updateReq[props.config.renderForm.reference_id] = {
            customer_id: props.initialData.customer_id,
            title: reqBody.title,
            first_names: reqBody.first_names,
            last_name: reqBody.last_name ? reqBody.last_name : "",
            known_as: reqBody.known_as ? reqBody.known_as : "",
            sex: reqBody.sex ? reqBody.sex : "",
            date_of_birth: reqBody.date_of_birth ? reqBody.date_of_birth : "",
            relation: reqBody.relation ? reqBody.relation : "",
            parent: reqBody.parent ? reqBody.parent : "",
            smoker: reqBody.smoker ? reqBody.smoker : "",
            living_at_home: reqBody.living_at_home
              ? reqBody.living_at_home
              : "",
            financially_dependant: reqBody.financially_dependant
              ? reqBody.financially_dependant
              : "",
            note_text: reqBody.note_text ? reqBody.note_text : "",
          };
        } else if (props.config.renderForm.reference_id === "income") {
          updateReq[props.config.renderForm.reference_id] = {
            customer_id: props.initialData.customer_id,
            joint_indicator: reqBody.joint_indicator,
            income_type: reqBody.income_type,
            gross_amount: reqBody.gross_amount,
            net_amount: reqBody.net_amount ? reqBody.net_amount : 0,
            frequency: reqBody.frequency ? reqBody.frequency : "",
            source: reqBody.source ? reqBody.source : "",
            start_date: reqBody.start_date ? reqBody.start_date : "",
            end_date: reqBody.end_date ? reqBody.end_date : "",
            note_text: reqBody.note_text ? reqBody.note_text : "",
            sort_order: reqBody.sort_order === "" ? 0 : reqBody.sort_order,
          };
        } else if (props.config.renderForm.reference_id === "outgoings") {
          updateReq[props.config.renderForm.reference_id] = {
            customer_id: props.initialData.customer_id,
            description: reqBody.description,
            frequency: reqBody.frequency,
            PARTNER_amount: reqBody.PARTNER_amount,
            partner_amount: reqBody.partner_amount ? reqBody.partner_amount : 0,
            joint_amount: reqBody.joint_amount ? reqBody.joint_amount : "",
            sort_order: reqBody.sort_order ? reqBody.sort_order : "",
            discretionary: reqBody.discretionary ? reqBody.discretionary : "",
          };
        } else if (props.config.renderForm.reference_id === "address_book") {
          updateReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.initialData.customer_id,
          };
        } else if (props.config.renderForm.reference_id === "contacts_notes") {
          updateReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.initialData.customer_id,
            category: parseInt(reqBody.category),
          };
        } else if (
          props.config.renderForm.reference_id === "contacts_PARTNER_action"
        ) {
          updateReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.initialData.customer_id,
          };
        } else if (
          props.config.renderForm.reference_id === "contacts_time_allocation"
        ) {
          updateReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.initialData.customer_id,
            category: parseInt(reqBody.category),
          };
        } else if (
          props.config.renderForm.reference_id === "attitude_to_risk"
        ) {
          updateReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.initialData.customer_id,
          };
        } else if (props.config.renderForm.reference_id === "fees_charges") {
          updateReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.initialData.customer_id,
          };
        } else {
          updateReq[props.config.renderForm.reference_id] = reqBody;
        }
      }
      if (props.config.renderForm.reference_id === "service_type") {
        apiServices.client
          .updateServiceType(
            props.initialData.customer_id,
            selectedRowItem.servicetype_id,
            updateReq
          )

          .then((response: any) => {
            AddSelectOptions();
            handleApiSuccessResponse(response);
            generateDynamicForm(context);

            // if (!response.data.isError) {
            //   props.notify(response.data.message, "", "success");
            //   // props.setFormUpdated(false);
            //   dispatch(resetFormData());

            //   props.reloadDetail();
            //   //  props.getList();
            //   //  if (props.resetClientFormSubmited) {
            //   //    props.resetClientFormSubmited();
            //   //  }
            //   //  if (props.changeMode) {
            //   //    // props.setActiveItem(response.data.data);
            //   //    props.changeMode("edit");
            //   //  }
            //   if (nextAction === "next") {
            //     props.changeTab?.(props.tabMenuIndex.next);
            //   }
            // } else if (response.data.isError) {
            //   if (
            //     response.data.error &&
            //     response.data.error &&
            //     Object.keys(response.data.error).length !== 0
            //   ) {
            //     if (Array.isArray(response.data.error.message)) {
            //       setFormError(response.data.error.message);
            //     } else {
            //       let errMsg = [];
            //       errMsg.push(response.data.error.description);
            //       setFormError(errMsg);
            //     }
            //   }
            // } else {
            //   props.notify("Something went wrong", "", "error");
            // }
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
            // props.notify(e.message, "", "error");
          });
      } else if (props.config.renderForm.reference_id === "objectives") {
        apiServices.client
          .updateObjectives(
            props.initialData.customer_id,
            selectedRowItem.objective_id,
            updateReq
          )

          .then((response: any) => {
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
            // if (!response.data.isError) {
            //   props.notify(response.data.message, "", "success");
            //   // props.setFormUpdated(false);
            //   dispatch(resetFormData());

            //   props.reloadDetail();
            //   // handleRowAction("view",selectedRowItem);
            //   //  props.getList();
            //   //  if (props.resetClientFormSubmited) {
            //   //    props.resetClientFormSubmited();
            //   //  }
            //   //  if (props.changeMode) {
            //   //    // props.setActiveItem(response.data.data);
            //   //    props.changeMode("edit");
            //   //  }
            //   if (nextAction === "next") {
            //     props.changeTab?.(props.tabMenuIndex.next);
            //   }
            // } else if (response.data.isError) {
            //   if (
            //     response.data.error &&
            //     response.data.error &&
            //     Object.keys(response.data.error).length !== 0
            //   ) {
            //     if (Array.isArray(response.data.error.message)) {
            //       setFormError(response.data.error.message);
            //     } else {
            //       let errMsg = [];
            //       errMsg.push(response.data.error.description);
            //       setFormError(errMsg);
            //     }
            //   }
            // } else {
            //   props.notify("Something went wrong", "", "error");
            // }
          })
          .catch((e: Error) => {
            // props.notify(e.message, "", "error");
            handleApiErrorResponse(e);
          });
      } else if (props.config.renderForm.reference_id === "identity") {
        apiServices.client
          .updateIdentity(
            props.initialData.customer_id,
            selectedRowItem.identity_id,
            updateReq
          )

          .then((response: any) => {
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
            // if (!response.data.isError) {
            //   props.notify(response.data.message, "", "success");
            //   // props.setFormUpdated(false);
            //   dispatch(resetFormData());

            //   props.reloadDetail();
            //   //  props.getList();
            //   //  if (props.resetClientFormSubmited) {
            //   //    props.resetClientFormSubmited();
            //   //  }
            //   //  if (props.changeMode) {
            //   //    // props.setActiveItem(response.data.data);
            //   //    props.changeMode("edit");
            //   //  }
            //   if (nextAction === "next") {
            //     props.changeTab?.(props.tabMenuIndex.next);
            //   }
            // } else if (response.data.isError) {
            //   if (
            //     response.data.error &&
            //     response.data.error &&
            //     Object.keys(response.data.error).length !== 0
            //   ) {
            //     if (Array.isArray(response.data.error.message)) {
            //       setFormError(response.data.error.message);
            //     } else {
            //       let errMsg = [];
            //       errMsg.push(response.data.error.description);
            //       setFormError(errMsg);
            //     }
            //   }
            // } else {
            //   props.notify("Something went wrong", "", "error");
            // }
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
            // props.notify(e.message, "", "error");
          });
      } else if (props.config.renderForm.reference_id === "employment") {
        apiServices.client
          .updateEmployment(
            props.initialData.customer_id,
            selectedRowItem.employment_id,
            updateReq
          )

          .then((response: any) => {
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
            // if (!response.data.isError) {
            //   props.notify(response.data.message, "", "success");
            //   // props.setFormUpdated(false);
            //   dispatch(resetFormData());

            //   props.reloadDetail();
            //   //  props.getList();
            //   //  if (props.resetClientFormSubmited) {
            //   //    props.resetClientFormSubmited();
            //   //  }
            //   //  if (props.changeMode) {
            //   //    // props.setActiveItem(response.data.data);
            //   //    props.changeMode("edit");
            //   //  }
            //   if (nextAction === "next") {
            //     props.changeTab?.(props.tabMenuIndex.next);
            //   }
            // } else if (response.data.isError) {
            //   if (
            //     response.data.error &&
            //     response.data.error &&
            //     Object.keys(response.data.error).length !== 0
            //   ) {
            //     if (Array.isArray(response.data.error.message)) {
            //       setFormError(response.data.error.message);
            //     } else {
            //       let errMsg = [];
            //       errMsg.push(response.data.error.description);
            //       setFormError(errMsg);
            //     }
            //   }
            // } else {
            //   props.notify("Something went wrong", "", "error");
            // }
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
            // props.notify(e.message, "", "error");
          });
      } else if (props.config.renderForm.reference_id === "dependants") {
        apiServices.dependant
          .updateDependant(
            props.activeCustomerId,
            selectedRowItem.dependant_id,
            updateReq
          )
          .then((response: any) => {
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
          });
      } else if (props.config.renderForm.reference_id === "income") {
        apiServices.income
          .updateIncome(selectedRowItem.income_id, updateReq)
          .then((response: any) => {
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
          });
      } else if (props.config.renderForm.reference_id === "outgoings") {
        apiServices.outgoings
          .updateOutgoings(selectedRowItem.expense_id, updateReq)
          .then((response: any) => {
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
          });
      } else if (props.config.renderForm.reference_id === "address_book") {
        apiServices.addressBook
          .updateAddressBook(
            props.activeCustomerId,
            selectedRowItem.address_id,
            updateReq
          )
          .then((response: any) => {
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
          });
      } else if (props.config.renderForm.reference_id === "contacts_notes") {
        apiServices.notes
          .updateNotes(selectedRowItem.note_id, updateReq)
          .then((response: any) => {
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
          });
      } else if (props.config.renderForm.reference_id === "fact_find_notes") {
        apiServices.factFindNote
          .updateFactFindNote(selectedRowItem.note_id, updateReq)
          .then((response: any) => {
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
          });
      } else if (
        props.config.renderForm.reference_id === "contacts_PARTNER_action"
      ) {
        apiServices.contactClientAction
          .updateFactFindNote(
            props.initialData.customer_id,
            selectedRowItem.appointment_id,
            updateReq
          )
          .then((response: any) => {
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
          });
      } else if (props.config.renderForm.reference_id === "attitude_to_risk") {
        apiServices.attitudeToRisk
          .update(
            props.initialData.customer_id,
            selectedRowItem.attituderisk_id,
            updateReq
          )
          .then((response: any) => {
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
          });
      } else if (props.config.renderForm.reference_id === "fees_charges") {
        apiServices.feesCharges
          .update(
            props.activeCustomerId,
            selectedRowItem.commission_id,
            updateReq
          )
          .then((response: any) => {
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
          });
      } else {
      }
    }
  };

  const handleApiSuccessResponse = (response: any) => {
    if (!response.data.isError) {
      setTimeout(() => {
        setIsFormLoading(false);
        props.notify(response.data.message, "", "success");
      }, 1000);
      // props.setFormUpdated(false);
      dispatch(resetFormData());

      props.reloadData();
      //  props.getList();
      //  if (props.resetClientFormSubmited) {
      //    props.resetClientFormSubmited();
      //  }
      //  if (props.changeMode) {
      //    props.setActiveItem(response.data.data);
      //    props.changeMode("edit");
      //  }
      if (nextAction === "next") {
        props.changeTab?.(props.tabMenuIndex.next);
      }
    } else if (response.data.isError) {
      if (
        response.data.error &&
        response.data.error &&
        Object.keys(response.data.error).length !== 0
      ) {
        if (Array.isArray(response.data.error.message)) {
          setFormError(response.data.error.message);
        } else {
          setFormError((prev) => [response.data.error.message]);
        }
      }
    } else {
      props.notify("Something went wrong", "", "error");
    }
  };
  const handleApiErrorResponse = (e: any) => {
    props.notify(e.message, "", "error");
  };
  const handleOnChange = (event: any) => {
    const data = { ...formik.values };
    data[event.target.name] = event.target.value;
    let updated = false;
    dispatch(setFormData());
    // if (["title", "first_name", "last_name"].includes(event.target.name)) {
    //   formik.setFieldValue("salutation", getSalutations(data));
    // }
    updatePresent(data);
    if (isFormDataUpdated) {
      if (JSON.stringify(formik.initialValues) === JSON.stringify(data)) {
        // // props.setFormUpdated(false);
        dispatch(resetFormData());
        updated = false;
      } else {
        // // props.setFormUpdated(true);
        dispatch(setFormData());
        updated = true;
      }
    }

    if (mode !== "add" && updated === true) {
      setFormMode("Changed");
    } else {
      setFormMode(mode === "add" ? "New" : "");
    }
  };
  const changeMode = (mode: string) => {
    setMode(mode);
  };
  const handleRowAction = (action: string, row: any) => {
    changeMode(action);
    setSelectedRowItem(row);
  };
  const deleteData = () => {
    let updatedDataList;
    const removeDeletedItem = (idKey: string) => {
      updatedDataList = dataList.filter(
        (item) => item[idKey] !== selectedRowItem[idKey]
      );
      setDataList(updatedDataList);
      setSelectedRowItem({});
      if (updatedDataList.length === 0) {
        setMode("");
      }
    };
    if (context === "PARTNER_DEPENDANT") {
      apiServices.dependant
        .delete(selectedRowItem.dependant_id)
        .then((response: any) => {
          handleApiSuccessResponse(response);
          removeDeletedItem("dependant_id");
        })
        .catch((e: Error) => {
          handleApiErrorResponse(e);
        });
    } else if (context === "PARTNER_EMPLOYMENT") {
      apiServices.client
        .employmentDelete(selectedRowItem.employment_id)
        .then((response: any) => {
          handleApiSuccessResponse(response);
          removeDeletedItem("employment_id");
        })
        .catch((e: Error) => {
          handleApiErrorResponse(e);
        });
    } else if (context === "PARTNER_SERVICE_TYPE") {
      apiServices.client
        .deleteServiceType(selectedRowItem.servicetype_id)
        .then((response: any) => {
          handleApiSuccessResponse(response);
          removeDeletedItem("servicetype_id");
        })
        .catch((e: Error) => {
          handleApiErrorResponse(e);
        });
    } else if (context === "PARTNER_INCOME") {
      apiServices.income
        .delete(selectedRowItem.income_id)
        .then((response: any) => {
          handleApiSuccessResponse(response);
          removeDeletedItem("income_id");
        })
        .catch((e: Error) => {
          handleApiErrorResponse(e);
        });
    } else if (context === "PARTNER_OUTGOINGS") {
      apiServices.outgoings
        .delete(selectedRowItem.expence_id)
        .then((response: any) => {
          handleApiSuccessResponse(response);
          removeDeletedItem("expence_id");
        })
        .catch((e: Error) => {
          handleApiErrorResponse(e);
        });
    } else if (context === "PARTNER_ATTITUDE_TO_RISK") {
      apiServices.attitudeToRisk
        .delete(selectedRowItem.attituderisk_id)
        .then((response: any) => {
          handleApiSuccessResponse(response);
          removeDeletedItem("attituderisk_id");
        })
        .catch((e: Error) => {
          handleApiErrorResponse(e);
        });
    } else if (context === "PARTNER_IDENTITY") {
      apiServices.client
        .deleteIdentity(selectedRowItem.identity_id)
        .then((response: any) => {
          handleApiSuccessResponse(response);
          removeDeletedItem("identity_id");
        })
        .catch((e: Error) => {
          handleApiErrorResponse(e);
        });
    } else if (context === "PARTNER_OBJECTIVES") {
      apiServices.client
        .deleteObjective(selectedRowItem.objective_id)
        .then((response: any) => {
          handleApiSuccessResponse(response);
          removeDeletedItem("objective_id");
        })
        .catch((e: Error) => {
          handleApiErrorResponse(e);
        });
    }

    setDeletePopup(false);
    // dispatch(setDialogModalOptions({ open: false }));
  };
  const fetchDataList = () => {
    if (context === "PARTNER_OBJECTIVES" && props.initialData.objectives) {
      setDataList(
        props.initialData.objectives ? props.initialData.objectives : []
      );
      if (props.initialData.objectives.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.initialData.objectives.findIndex(
            (x: any) => x.objective_id === selectedRowItem.objective_id
          );

          handleRowAction("view", props.initialData.objectives[index]);
        } else {
          handleRowAction("view", props.initialData.objectives[0]);
        }
      }
    } else if (context === "PARTNER_EMPLOYMENT") {
      setDataList(
        props.initialData.employment ? props.initialData.employment : []
      );
      if (props.initialData?.employment?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.initialData.employment.findIndex(
            (x: any) => x.employment_id === selectedRowItem.employment_id
          );
          handleRowAction("view", props.initialData.employment[index]);
        } else {
          handleRowAction("view", props.initialData.employment[0]);
        }
      }
    } else if (context === "PARTNER_IDENTITY") {
      setDataList(props.initialData.identity ? props.initialData.identity : []);
      if (props.initialData?.identity?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.initialData.identity.findIndex(
            (x: any) => x.identity_id === selectedRowItem.identity_id
          );
          handleRowAction("view", props.initialData.identity[index]);
        } else {
          handleRowAction("view", props.initialData.identity[0]);
        }
      }
    } else if (context === "PARTNER_SERVICE_TYPE") {
      setDataList(
        props.initialData.servicetype ? props.initialData.servicetype : []
      );
      if (props.initialData?.servicetype?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.initialData.servicetype.findIndex(
            (x: any) => x.servicetype_id === selectedRowItem.servicetype_id
          );
          handleRowAction("view", props.initialData.servicetype[index]);
        } else {
          handleRowAction("view", props.initialData.servicetype[0]);
        }
      }
    } else if (context === "COMMISSION_PARTNER_SERVICE_TYPE") {
      setDataList(
        props.initialData.servicetype ? props.initialData.servicetype : []
      );
      if (props.initialData?.servicetype?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.initialData.servicetype.findIndex(
            (x: any) => x.servicetype_id === selectedRowItem.servicetype_id
          );
          handleRowAction("view", props.initialData.servicetype[index]);
        } else {
          handleRowAction("view", props.initialData.servicetype[0]);
        }
      }
    } else if (context === "PARTNER_DEPENDANT") {
      setDataList(
        props.initialData.dependants ? props.initialData.dependants : []
      );
      if (props.initialData?.dependants?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.initialData.dependants.findIndex(
            (x: any) => x.dependant_id === selectedRowItem.dependant_id
          );
          handleRowAction("view", props.initialData.dependants[index]);
        } else {
          handleRowAction("view", props.initialData.dependants[0]);
        }
      }
    } else if (context === "PARTNER_INCOME") {
      setDataList(props.initialData.income ? props.initialData.income : []);
      if (props.initialData?.income?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.initialData.income.findIndex(
            (x: any) => x.income_id === selectedRowItem.income_id
          );
          handleRowAction("view", props.initialData.income[index]);
        } else {
          handleRowAction("view", props.initialData.income[0]);
        }
      }
    } else if (context === "PARTNER_OUTGOINGS") {
      setDataList(
        props.initialData.outgoings ? props.initialData.outgoings : []
      );
      if (props.initialData?.outgoings?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.initialData.outgoings.findIndex(
            (x: any) => x.expense_id === selectedRowItem.expense_id
          );
          handleRowAction("view", props.initialData.outgoings[index]);
        } else {
          handleRowAction("view", props.initialData.outgoings[0]);
        }
      }
    } else if (context === "ADDRESS_BOOK") {
      setDataList(
        props.initialData.addressBook ? props.initialData.addressBook : []
      );

      if (props.initialData?.addressBook?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.initialData.addressBook.findIndex(
            (x: any) => x.address_id === selectedRowItem.address_id
          );
          handleRowAction("view", props.initialData.addressBook[index]);
        } else {
          handleRowAction("view", props.initialData.addressBook[0]);
        }
      }
    } else if (context === "FACT_FIND_NOTES") {
      setDataList(
        props.initialData.factFindNote ? props.initialData.factFindNote : []
      );
      if (props.initialData?.factFindNote?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.initialData.factFindNote.findIndex(
            (x: any) => x.address_id === selectedRowItem.address_id
          );
          handleRowAction("view", props.initialData.factFindNote[index]);
        } else {
          handleRowAction("view", props.initialData.factFindNote[0]);
        }
      }
    } else if (context === "CONTACTS_TIME_ALLOCATION") {
      setDataList(
        props.initialData.timeAllocation ? props.initialData.timeAllocation : []
      );
      if (props.initialData?.timeAllocation?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index: any = props.initialData.timeAllocation.findIndex(
            (x: any) =>
              x.timeAllocation_id === selectedRowItem.timeAllocation_id
          );
          handleRowAction("view", props.initialData.timeAllocation[index]);
        } else {
          handleRowAction("view", props.initialData.timeAllocation[0]);
        }
      }
    } else if (context === "CONTACTS_PARTNER_ACTION") {
      setDataList(
        props.initialData.contactClientAction
          ? props.initialData.contactClientAction
          : []
      );
      if (props.initialData?.contactClientAction?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index: any = props.initialData.contactClientAction.findIndex(
            (x: any) =>
              x.contactClientAction_id ===
              selectedRowItem.contactClientAction_id
          );
          handleRowAction("view", props.initialData.contactClientAction[index]);
        } else {
          handleRowAction("view", props.initialData.contactClientAction[0]);
        }
      }
    } else if (context === "CONTACTS_NOTES") {
      setDataList(props.initialData.notes ? props.initialData.notes : []);
      if (props.initialData?.notes?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.initialData.notes.findIndex(
            (x: any) => x.note_id === selectedRowItem.note_id
          );
          handleRowAction("view", props.initialData.notes[index]);
        } else {
          handleRowAction("view", props.initialData.notes[0]);
        }
      }
    } else if (context === "PARTNER_ATTITUDE_TO_RISK") {
      setDataList(props.attitudeToRiskData ? props.attitudeToRiskData : []);
      if (props.attitudeToRiskData?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.attitudeToRiskData.findIndex(
            (x: any) => x.attituderisk_id === selectedRowItem.attituderisk_id
          );
          handleRowAction("view", props.attitudeToRiskData[index]);
        } else {
          handleRowAction("view", props.attitudeToRiskData[0]);
        }
      }
    } else if (context === "FEES_CHARGES") {
      setDataList(
        props.initialData.feesCharges ? props.initialData.feesCharges : []
      );
      if (props.initialData?.feesCharges?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.initialData.feesCharges.findIndex(
            (x: any) => x.commission_id === selectedRowItem.commission_id
          );
          handleRowAction("view", props.initialData.feesCharges[index]);
        } else {
          handleRowAction("view", props.initialData.feesCharges[0]);
        }
      }
    } else {
      setDataList([]);
    }
  };
  return (
    <>
      <OverlayLoader isLoading={loading} />
      {props.title !== undefined && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Label size="large">{props.title}</Label>
        </div>
      )}
      {dataList && dataList.length === 0 && mode === "" && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {context === "PARTNER_ATTITUDE_TO_RISK" && (
            <>
              {checkUserPermission(
                loggedUser,
                UserPermissionsList.ALLOW_UPDATE_CLIENT
              ) &&
                !convertStringToArray(
                  process.env.REACT_APP_HIDE_CTA_BUTTON
                ).includes("partner_attitude_to_risk_get_default") && (
                  <Button
                    appearance="secondary"
                    shape="square"
                    disabled={convertStringToArray(
                      process.env.REACT_APP_DISABLE_CTA_BUTTON
                    ).includes("partner_attitude_to_risk_disable_get_default")}
                    onClick={() => {
                      dispatch(
                        setDialogModalOptions({
                          open: true,
                          master_table: true,
                          customerId: props.initialData.customer_id,
                          table_type: "attitudeToRisk",
                          reload: () => {
                            setReloadRequired(reloadRequired + 1);
                          },
                          cancel: {
                            onclick: () => {
                              dispatch(setDialogModalOptions({ open: false }));
                            },
                            label: "Close",
                          },
                          apply: {
                            onclick: () => {
                              dispatch(setDialogModalOptions({ open: false }));
                            },
                            label: "Apply",
                            type: "primary",
                          },
                        })
                      );
                    }}
                  >
                    Get Default Categories
                  </Button>
                )}
            </>
          )}
          {context === "PARTNER_OBJECTIVES" && (
            <>
              {checkUserPermission(
                loggedUser,
                UserPermissionsList.ALLOW_UPDATE_CLIENT
              ) &&
                !convertStringToArray(
                  process.env.REACT_APP_HIDE_CTA_BUTTON
                ).includes("partner_objectives_get_default") && (
                  <Button
                    appearance="secondary"
                    shape="square"
                    disabled={
                      !convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("partner_objectives_disable_get_default")
                        ? false
                        : true
                    }
                    onClick={() => {
                      dispatch(
                        setDialogModalOptions({
                          open: true,
                          master_table: true,
                          table_type: "objectives",
                          customerId: props.initialData.customer_id,
                          reload: () => {
                            setReloadRequired(reloadRequired + 1);
                          },
                          cancel: {
                            onclick: () => {
                              dispatch(setDialogModalOptions({ open: false }));
                            },
                            label: "Close",
                          },
                          apply: {
                            onclick: () => {
                              dispatch(setDialogModalOptions({ open: false }));
                            },
                            label: "Apply",
                            type: "primary",
                          },
                        })
                      );
                    }}
                  >
                    Get Default Objectives
                  </Button>
                )}
            </>
          )}

          {checkUserPermission(
            loggedUser,
            UserPermissionsList.ALLOW_UPDATE_CLIENT
          ) && (
            <Button
              className="asc-button-primary"
              style={{ marginRight: 2, marginBottom: 6 }}
              appearance="primary"
              size="medium"
              icon={<Add16Filled />}
              onClick={() => {
                changeMode("add");
              }}
            >
              Add New
            </Button>
          )}
        </div>
      )}
      {mode && mode !== "" && (
        <>
          {["edit", "add"].includes(mode) && (
            <div className={classes.btnwrapper}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Label
                  size="medium"
                  weight="semibold"
                  className={classes.message}
                >
                  {formMode !== "" && (
                    <>
                      <span>**</span> {formMode} <span>**</span>
                    </>
                  )}
                </Label>
              </div>
              <div>
                <Button
                  icon={<ArrowUndo16Regular />}
                  onClick={undo}
                  disabled={!isFormDataUpdated}
                ></Button>
                <Button
                  icon={<ArrowRedo16Regular />}
                  onClick={redo}
                  disabled={future.length === 0 ? true : false}
                ></Button>
              </div>
              <Button
                disabled={!isFormDataUpdated}
                appearance="transparent"
                onClick={() => {
                  formik.resetForm();
                }}
              >
                Reset All
              </Button>
            </div>
          )}
          {props.mode && props.mode === "edit" && (
            <Button
              appearance="transparent"
              size="medium"
              icon={<Delete16Regular />}
              onClick={() => {
                dispatch(
                  setDialogModalOptions({
                    open: true,
                    content_line_1: "Do you want to delete?",
                    no: {
                      onclick: () => {
                        dispatch(setDialogModalOptions({ open: false }));
                      },
                      label: "No",
                      type: "primary",
                    },
                    cancel: {
                      onclick: () => {
                        dispatch(setDialogModalOptions({ open: false }));
                      },
                      label: "Yes",
                    },
                  })
                );
              }}
            ></Button>
          )}
          {mode && mode === "view" && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                Record {currentRecord} of {dataList.length}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                {context === "PARTNER_ATTITUDE_TO_RISK" && (
                  <>
                    {checkUserPermission(
                      loggedUser,
                      UserPermissionsList.ALLOW_UPDATE_CLIENT
                    ) &&
                      !convertStringToArray(
                        process.env.REACT_APP_HIDE_CTA_BUTTON
                      ).includes("partner_attitude_to_risk_get_default") && (
                        <Button
                          appearance="secondary"
                          shape="square"
                          disabled={convertStringToArray(
                            process.env.REACT_APP_DISABLE_CTA_BUTTON
                          ).includes(
                            "partner_attitude_to_risk_disable_get_default"
                          )}
                          onClick={() => {
                            dispatch(
                              setDialogModalOptions({
                                open: true,
                                master_table: true,
                                customerId: props.initialData.customer_id,
                                table_type: "attitudeToRisk",
                                reload: () => {
                                  setReloadRequired(reloadRequired + 1);
                                },
                                cancel: {
                                  onclick: () => {
                                    dispatch(
                                      setDialogModalOptions({ open: false })
                                    );
                                  },
                                  label: "Close",
                                },
                                apply: {
                                  onclick: () => {
                                    dispatch(
                                      setDialogModalOptions({ open: false })
                                    );
                                  },
                                  label: "Apply",
                                  type: "primary",
                                },
                              })
                            );
                          }}
                        >
                          Get Default Categories
                        </Button>
                      )}
                  </>
                )}
                {context === "PARTNER_OBJECTIVES" && (
                  <>
                    {checkUserPermission(
                      loggedUser,
                      UserPermissionsList.ALLOW_UPDATE_CLIENT
                    ) &&
                      !convertStringToArray(
                        process.env.REACT_APP_HIDE_CTA_BUTTON
                      ).includes("partner_objectives_get_default") && (
                        <Button
                          appearance="secondary"
                          shape="square"
                          disabled={convertStringToArray(
                            process.env.REACT_APP_DISABLE_CTA_BUTTON
                          ).includes("partner_objectives_disable_get_default")}
                          onClick={() => {
                            dispatch(
                              setDialogModalOptions({
                                open: true,
                                master_table: true,
                                table_type: "objectives",
                                customerId: props.initialData.customer_id,
                                reload: () => {
                                  setReloadRequired(reloadRequired + 1);
                                },
                                cancel: {
                                  onclick: () => {
                                    dispatch(
                                      setDialogModalOptions({ open: false })
                                    );
                                  },
                                  label: "Close",
                                },
                                apply: {
                                  onclick: () => {
                                    dispatch(
                                      setDialogModalOptions({ open: false })
                                    );
                                  },
                                  label: "Apply",
                                  type: "primary",
                                },
                              })
                            );
                          }}
                        >
                          Get Default Objectives
                        </Button>
                      )}
                  </>
                )}

                {checkUserPermission(
                  loggedUser,
                  UserPermissionsList.ALLOW_UPDATE_CLIENT
                ) && (
                  <Button
                    className="asc-button-primary"
                    style={{ marginRight: 2, marginBottom: 6 }}
                    appearance="primary"
                    size="medium"
                    icon={<Add16Filled />}
                    onClick={() => {
                      changeMode("add");
                    }}
                  >
                    Add New
                  </Button>
                )}
                {checkUserPermission(
                  loggedUser,
                  UserPermissionsList.ALLOW_UPDATE_CLIENT
                ) && (
                  <Button
                    style={{ marginRight: 2, marginBottom: 6 }}
                    icon={<Edit24Filled />}
                    size="medium"
                    onClick={() => {
                      changeMode("edit");
                    }}
                  >
                    Edit
                  </Button>
                )}
                {checkUserPermission(
                  loggedUser,
                  UserPermissionsList.ALLOW_UPDATE_CLIENT
                ) && (
                  <Button
                    icon={<Delete16Regular />}
                    style={{ marginRight: 2, marginBottom: 6, color: "red" }}
                    size="medium"
                    onClick={() => {
                      setDeletePopup(true);
                    }}
                  >
                    Delete
                  </Button>
                )}
              </div>
            </div>
          )}
          <DeletePopup
            deletePopup={deletePopup}
            setDeletePopup={setDeletePopup}
            deleteData={deleteData}
          />
          {formError && formError.length > 0 && (
            <FormErrorMessage
              errorMessages={formError}
              onDismiss={() => setFormError([])}
            />
          )}
        </>
      )}
      <div style={{ height: "calc(100vh - 390px)", overflow: "auto" }}>
        {mode && mode !== "" && isOptionLoaded && (
          <FormContent
            {...{
              formik,
              formFieldData,
              mode,
              optionList,
              handleOnChange,
              customHandleChange,
              selectFieldOptions,
              newSelectOptionAdded,
            }}
          />
        )}
        {mode !== "view" && mode !== "" && (
          <div className={classes.buttonWrapper}>
            <Button
              className="asc-button-primary"
              appearance="primary"
              disabled={(isFormDataUpdated ? false : true) || isFormLoading}
              onClick={
                () => (isFormDataUpdated ? formik.handleSubmit() : "")
                // formik.handleSubmit();
              }
            >
              Save
            </Button>
            {/* {props.tabMenuIndex && props.tabMenuIndex.previous !== -1 && (
                <Button
                  onClick={() => {
                    // props.formDataUpdated
                    isFormDataUpdated
                      ? props.setDialogModal({
                          ...props.dialogModalOptions,
                          open: true,
                          content_line_1:
                            "We have detected an attempt to move away from the current page",
                          content_line_2:
                            "This would cause all changes made to be lost",
                          no: {
                            onclick: () => {
                              props.setDialogModal({
                                ...props.dialogModalOptions,
                                open: false,
                              });
                              // props.setFormUpdated(false);
                              dispatch(resetFormData());
                              props.changeTab?.(props.tabMenuIndex.previous);
                            },
                            label: "Leave the Page",
                            type: "primary",
                          },
                          cancel: {
                            onclick: () => {
                              props.setDialogModal({
                                ...props.dialogModalOptions,
                                open: false,
                              });
                              // // props.changeTab?.(props.tabMenuIndex.previous);
                            },
                            label: "Stay on Page",
                          },
                        })
                      : props.changeTab?.(props.tabMenuIndex.previous);
                  }}
                >
                  Previous
                </Button>
              )} */}

            {props.tabMenuIndex && props.tabMenuIndex.next !== -1 && (
              <Button
                onClick={() => {
                  setNextAction("next");
                  formik.handleSubmit();
                }}
                disabled={isFormLoading}
              >
                Save & Next
              </Button>
            )}

            <Button
              onClick={() => {
                if (isFormDataUpdated) {
                  dispatch(
                    setDialogModalOptions({
                      open: true,
                      content_line_1:
                        "We have detected an attempt to move away from the current page.",
                      content_line_2:
                        "This would cause all changes made to be lost",
                      cancel: {
                        onclick: () => {
                          dispatch(
                            setDialogModalOptions({
                              open: false,
                            })
                          );
                          // props.setFormUpdated(false);
                          dispatch(resetFormData());
                          formik.resetForm();
                          changeMode("view");
                        },
                        label: "Leave the Page",
                      },

                      no: {
                        onclick: () => {
                          dispatch(setDialogModalOptions({ open: false }));
                        },
                        label: "Stay on Page",
                        type: "primary",
                      },
                    })
                  );
                } else {
                  formik.resetForm();
                  dataList.length === 0 ? changeMode("") : changeMode("view");
                }
                // props.formDataUpdated
              }}
            >
              Cancel
            </Button>
          </div>
        )}
        {dataList && dataList.length > 0 ? (
          <div>
            <TableDataList
              {...{
                changeMode,
                handleRowAction,
                mode,
                context: props.context,
                data: dataList,
                selectedRowItem,
                setCurrentRecord,
              }}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 200,
            }}
          >
            {mode === "" && (
              <>
                {" "}
                <Label size="large" style={{ color: "red" }}>
                  There are no active records - click Add New to create a new
                  record
                </Label>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default PartnerFormContainerWithList;
