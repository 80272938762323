// import React from "react";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { initializeIcons } from "@fluentui/font-icons-mdl2";
import "./App.css";
// import ClientLayout from "./layout/index";
// import ClientList from "./pages/client/List";
// import ActionList from "./pages/Actionlist/List";
// import Report from "./pages/report";
import { AppRouter } from "./routes/AppRouter";

function App() {
  return (
    <>
    <AppRouter/>
    {/* // <BrowserRouter>
    //   <Routes>
    //     <Route path="/" element={<ClientLayout />}>
    //       <Route index element={<ClientList />} />
    //       <Route path="/action-list" element={<ActionList />} />
    //       <Route path="/report" element={<Report />} />
    //     </Route>
    //   </Routes>
    // </BrowserRouter> */}
    </>
    
  );
}

export default App;