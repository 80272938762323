import * as React from "react";
import {
  Checkbox,
  Dropdown,
  Field,
  makeStyles,
  Option,
  shorthands,
  useId,
} from "@fluentui/react-components";
import type { CheckboxProps, DropdownProps } from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import ASCDropdown from "../DropDown";
import { setDialogModalOptions } from "../../redux/modelDialogReducer";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";

type ComponentProps = {
  [x: string]: any;
};

export const DropdownDatepicker = (props: ComponentProps) => {
  const dispatch: AppDispatch = useDispatch();

  return (
    <Dropdown
      className="date_dropdown"
      placeholder={
        props.optionDateValue !== "" ? props.optionDateValue : "Select the Date"
      }
      onClick={() => {
        dispatch(
          setDialogModalOptions({
            open: true,
            date_filter: true,
            dateSelection: props.dateSelection,
            dateLabel: props.dateLabel,
            optionDateValue: props.optionDateValue,
            optionDate: props.optionDate,
            close: {
              onclick: () => {
                dispatch(setDialogModalOptions({ open: false }));
              },
              label: "Cancel",
            },
          })
        );
      }}
    ></Dropdown>
  );
};
