import "./style.css";
type ContainerProps = {
  [x: string]: any;
};

const OutgoingCalcTable = (props: ContainerProps) => {

  return (
    <div className="month-annual-grid">
      <div></div>
      <div className="table-value table-heading">
        <div>Client</div>
        <div>Partner</div>
        <div>Joint</div>
      </div>
      <div className="table-subhead">Monthly</div>
      <div className="table-value">
        <div>{props.OutgoingCalculationValues.MonthlyClientAmount}</div>
        <div>{props.OutgoingCalculationValues.MonthlyPartnerAmount}</div>
        <div>{props.OutgoingCalculationValues.MonthlyJointAmount}</div>
      </div>
      <div className="table-subhead">Annual</div>
      <div className="table-value">
        <div>{props.OutgoingCalculationValues.AnnualClientAmount}</div>
        <div>{props.OutgoingCalculationValues.AnnualPartnerAmount}</div>
        <div>{props.OutgoingCalculationValues.AnnualJointAmount}</div>
      </div>
    </div>
  );
};

export default OutgoingCalcTable;
