import React from "react";

interface PropsType {
  [x: string]: any;
}
export default function FundCalculation(props: PropsType) {
  
  return (
    <div className="calculation_box">
      <div className="calculation_main">
        <div>
          <p>
          Total Fund Value:{" "}
            <span>{props.calculatedData}</span>
          </p>
        </div>
      </div>
    </div>
  )
}


