export const clientDetail = [
  {
    clientDetail_id: 0,
    label: "Title",
    value: "title",
  },
  {
    clientDetail_id: 1,
    label: "First Name(s)",
    value: "first_names",
  },
  {
    clientDetail_id: 2,
    label: "Last Name",
    value: "last_name",
  },
  {
    clientDetail_id: 3,
    label: "Known as",
    value: "known_as",
  },
  {
    clientDetail_id: 4,
    label: "Former Last Name",
    value: "first_names",
  },
  {
    clientDetail_id: 5,
    label: "Honours",
    value: "honours",
  },
  {
    clientDetail_id: 6,
    label: "Address Line 1",
    value: "address_1",
  },
  {
    clientDetail_id: 7,
    label: "Address Line 2",
    value: "address_2",
  },
  {
    clientDetail_id: 8,
    label: "Address Line 3",
    value: "address_3",
  },
  {
    clientDetail_id: 9,
    label: "Town",
    value: "town",
  },
  {
    clientDetail_id: 10,
    label: "County",
    value: "county",
  },
  {
    clientDetail_id: 11,
    label: "Country",
    value: "country",
  },
  {
    clientDetail_id: 12,
    label: "Postcode",
    value: "postcode",
  },
  {
    clientDetail_id: 13,
    label: "Date of Birth",
    value: "date_of_birth",
  },
  {
    clientDetail_id: 14,
    label: "Consultant",
    value: "consultant",
  },
  {
    clientDetail_id: 15,
    label: "Introduced by",
    value: "introduced_by",
  },
  {
    clientDetail_id: 16,
    label: "Fact-find",
    value: "fact_find_completed",
  },
  {
    clientDetail_id: 17,
    label: "Terms of Engagement",
    value: "terms_of_business_sent",
  },
  {
    clientDetail_id: 18,
    label: "IDD",
    value: "idd",
  },
  {
    clientDetail_id: 19,
    label: "Menu",
    value: "menu",
  },
  {
    clientDetail_id: 20,
    label: "Data Protection",
    value: "data_protection_form_sent",
  },
  {
    clientDetail_id: 21,
    label: "Service Type",
    value: "service_type",
  },
];
export const partnerDetail = [
  {
    partnerDetail_id: 0,
    label: "Title",
    value: "title",
  },
  {
    partnerDetail_id: 1,
    label: "First Name(s)",
    value: "first_names",
  },
  {
    partnerDetail_id: 2,
    label: "Last Name",
    value: "last_name",
  },
  {
    partnerDetail_id: 3,
    label: "Known as",
    value: "known_as",
  },
  {
    partnerDetail_id: 4,
    label: "Former Last Name",
    value: "first_names",
  },
  {
    partnerDetail_id: 5,
    label: "Honours",
    value: "first_names",
  },
  {
    partnerDetail_id: 6,
    label: "Known as",
    value: "address_1",
  },
  {
    partnerDetail_id: 7,
    label: "Town",
    value: "town",
  },
  {
    partnerDetail_id: 8,
    label: "County",
    value: "county",
  },
  {
    partnerDetail_id: 9,
    label: "Country",
    value: "country",
  },
  {
    partnerDetail_id: 10,
    label: "Postcode",
    value: "postcode",
  },
  {
    partnerDetail_id: 11,
    label: "Date of Birth",
    value: "date_of_birth",
  },
];
export const policyDetail = [
  {
    policy_id: 0,
    label: "Life Assurence",
    value: "review_completed",
  },
  {
    policy_id: 1,
    label: "Pensions",
    value: "review_date",
  },
  {
    policy_id: 2,
    label: "Savings",
    value: "review_interval",
  },
  {
    policy_id: 3,
    label: "Investment",
    value: "review_note",
  },
  {
    policy_id: 4,
    label: "Income Protection",
    value: "review_note",
  },
  {
    policy_id: 6,
    label: "General",
    value: "review_note",
  },
  {
    policy_id: 7,
    label: "Health Assurence",
    value: "review_note",
  },
];
export const liabilityDetail = [
  {
    liability_id: 0,
    label: "Mortgaes",
    value: "review_completed",
  },
  {
    liability_id: 1,
    label: "Loan",
    value: "review_date",
  },
];
export const assetDetail = [
  {
    assetDetail_id: 0,
    label: "Investments",
    value: "investments",
  },
];
