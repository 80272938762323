import { createSlice, PayloadAction, Draft } from "@reduxjs/toolkit";

interface DialogState {
  open: boolean;
  [key: string]: any; // Allow any additional key-value pairs
}

const initialState: DialogState = {
  open: false,
};

const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    setDialogModalOptions: (state: Draft<DialogState>, action: PayloadAction<Partial<DialogState>>) => {
      return action.payload as DialogState;
    },
  },
});

export const { setDialogModalOptions } = dialogSlice.actions;

export default dialogSlice.reducer;
