export const data = {
  formConfig: [
    {
      context: "SUBSCRIPTION_SUBSCRIPTION",
      actionUrl: {
        create: "",
        edit: "",
        redirect: "",
      },
      renderForm: {
        reference_id: "subscription_subscription",
        general_fields: [],
        custom_fields: [],
      },
    },
    {
      context: "SUBSCRIPTION_PAYMENTS_SUBSCRIPTION",
      actionUrl: {
        create: "",
        edit: "",
        redirect: "",
      },
      renderForm: {
        reference_id: "subscription_payments_subscription",
        general_fields: [],
        custom_fields: [],
      },
    },
    {
      context: "SUBSCRIPTION_PAYMENTS_ORDER",
      actionUrl: {
        create: "",
        edit: "",
        redirect: "",
      },
      renderForm: {
        reference_id: "subscription_payments_order",
        general_fields: [],
        custom_fields: [],
      },
    },
    {
      context: "SYSTEM_MANAGER_USERLIST",
      actionUrl: {
        create: "",
        edit: "",
        redirect: "",
      },
      renderForm: {
        reference_id: "user_list",
        general_fields: [],
        custom_fields: [],
      },
    },
    {
      context: "MASTER_SETTINGS_COMPANY_DETAILS",
      actionUrl: {
        create: "",
        edit: "",
        redirect: "",
      },
      renderForm: {
        reference_id: "master_settings_company_details",
        general_fields: [],
        custom_fields: [],
      },
    },
    {
      context: "MASTER_SETTINGS_LIFE_LABELS",
      actionUrl: {
        create: "",
        edit: "",
        redirect: "",
      },
      renderForm: {
        reference_id: "master_settings_life_labels",
        general_fields: [],
        custom_fields: [],
      },
    },
    {
      context: "MASTER_SETTINGS_INCOME_CATEGORY",
      actionUrl: {
        create: "",
        edit: "",
        redirect: "",
      },
      renderForm: {
        reference_id: "income_category",
        general_fields: [],
        custom_fields: [],
      },
    },
    {
      context: "MASTER_SETTINGS_EXPENSE_CATEGORY",
      actionUrl: {
        create: "",
        edit: "",
        redirect: "",
      },
      renderForm: {
        reference_id: "expense_category",
        general_fields: [],
        custom_fields: [],
      },
    },
    {
      context: "MASTER_SETTINGS_ATTITUDE_TO_RISK_CATEGORY",
      actionUrl: {
        create: "",
        edit: "",
        redirect: "",
      },
      renderForm: {
        reference_id: "attitude_to_risk_category",
        general_fields: [],
        custom_fields: [],
      },
    },
    {
      context: "MASTER_SETTINGS_ATTITUDE_TO_RISK_RATING",
      actionUrl: {
        create: "",
        edit: "",
        redirect: "",
      },
      renderForm: {
        reference_id: "attitude_to_risk_rating",
        general_fields: [],
        custom_fields: [],
      },
    },
    {
      context: "MASTER_SETTINGS_OBJECTIVES",
      actionUrl: {
        create: "",
        edit: "",
        redirect: "",
      },
      renderForm: {
        reference_id: "objectives_detail",
        general_fields: [],
        custom_fields: [],
      },
    },
    {
      context: "MASTER_SETTINGS_PROVIDERS",
      actionUrl: {
        create: "",
        edit: "",
        redirect: "",
      },
      renderForm: {
        reference_id: "provider",
        general_fields: [],
        custom_fields: [],
      },
    },
    {
      context: "MASTER_SETTINGS_CLIENT_ACTIONS",
      actionUrl: {
        create: "",
        edit: "",
        redirect: "",
      },
      renderForm: {
        reference_id: "tracking_client_actions",
        general_fields: [],
        custom_fields: [],
      },
    },
    {
      context: "MASTER_SETTINGS_CASE_ACTIONS",
      actionUrl: {
        create: "",
        edit: "",
        redirect: "",
      },
      renderForm: {
        reference_id: "tracking_case_actions",
        general_fields: [],
        custom_fields: [],
      },
    },
    {
      context: "MASTER_SETTINGS_TEMPLATE_EMAIL",
      actionUrl: {
        create: "",
        edit: "",
        redirect: "",
      },
      renderForm: {
        reference_id: "master_settings_template_email",
        general_fields: [],
        custom_fields: [],
      },
    },
    {
      context: "MASTER_SETTINGS_TEMPLATE_LETTER",
      actionUrl: {
        create: "",
        edit: "",
        redirect: "",
      },
      renderForm: {
        reference_id: "master_settings_template_letter",
        general_fields: [],
        custom_fields: [],
      },
    },
    {
      context: "MASTER_SETTINGS_FUND_MANAGEMENT_CLIENT_ASSYST_FUND_LIST",
      actionUrl: {
        create: "",
        edit: "",
        redirect: "",
      },
      renderForm: {
        reference_id: "master_settings_fund_management_client_assyst_fund_list",
        general_fields: [],
        custom_fields: [],
      },
    },
    {
      context: "MASTER_SETTINGS_CONTRACT_ENQUIRY",
      actionUrl: {
        create: "",
        edit: "",
        redirect: "",
      },
      renderForm: {
        reference_id: "master_settings_contract_enquiry",
        general_fields: [],
        custom_fields: [],
      },
    },
    {
      context: "MASTER_SETTINGS_INTEGRATIONS",
      actionUrl: {
        create: "",
        edit: "",
        redirect: "",
      },
      renderForm: {
        reference_id: "master_settings_integrations",
        general_fields: [],
        custom_fields: [],
      },
    },
    {
      context: "COMMISSION_COMMISSION_RULES",
      actionUrl: {
        create: "",
        edit: "",
        redirect: "",
      },
      renderForm: {
        reference_id: "commission_commission_rules",
        general_fields: [],
        custom_fields: [],
      },
    },
    {
      context: "COMMISSION_TYPES",
      actionUrl: {
        create: "",
        edit: "",
        redirect: "",
      },
      renderForm: {
        reference_id: "commission_types",
        general_fields: [],
        custom_fields: [],
      },
    },
  ],
  formCollections: {
    subscription_subscription: {
      title: "Subscription",
      reference_id: "subscription_subscription",
      fields: {
        general: [
          {
            field: "subscription_details",
            type: "text",
            label: "Subscription Details",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 1,
            enabled: true,
          },
          {
            field: "subscription_id",
            type: "text",
            label: "Subscription Id",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 2,
            enabled: true,
          },
          {
            field: "renewal_date",
            type: "datepicker",
            label: "Renewal Date",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 3,
            enabled: true,
          },
          {
            field: "no_of_users",
            type: "text",
            label: "No of Users",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 4,
            enabled: true,
          },
        ],
        custom: [],
      },
    },
    subscription_payments_subscription: {
      title: "Payments",
      reference_id: "subscription_payments_subscription",
      fields: {
        general: [
          {
            field: "description",
            type: "text-area",
            label: "Description",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 1,
            enabled: true,
          },
          {
            field: "billing_date",
            type: "datepicker",
            label: "Billing Date",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 2,
            enabled: true,
          },
          {
            field: "payment_method",
            type: "text",
            label: "Payment Method",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 3,
            enabled: true,
          },
          {
            field: "no_of_users",
            type: "text",
            label: "No of Users",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 4,
            enabled: true,
          },
        ],
        custom: [],
      },
    },
    subscription_payments_order: {
      title: "Payments",
      reference_id: "subscription_payments_order",
      fields: {
        general: [
          {
            field: "order_id",
            type: "text",
            label: "Order Id",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 1,
            enabled: true,
          },
          {
            field: "billing_date",
            type: "datepicker",
            label: "Billing Date",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 2,
            enabled: true,
          },
          {
            field: "amount",
            type: "text",
            label: "Amount",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 3,
            enabled: true,
          },
        ],
        custom: [],
      },
    },
    user_list: {
      title: "User List",
      reference_id: "user_list",
      fields: {
        general: [
          {
            field: "full_name",
            type: "text",
            label: "Full Name",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 1,
            enabled: true,
          },
          {
            field: "short_name",
            type: "text",
            label: "Short Name",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 2,
            enabled: true,
          },
          {
            field: "user_email",
            type: "text",
            label: "Email",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 3,
            enabled: true,
          },
          {
            field: "password",
            type: "password",
            label: "Password",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 4,
            enabled: true,
          },
          // {
          //   field: "role",
          //   type: "select",
          //   label: "Role",
          //   placeholder: "Choose",
          //   remote: false,
          //   apiurl: "",
          //   options: [
          //     {
          //       label: "IFA Manager",
          //       value: "IFA Manager",
          //     },
          //     {
          //       label: "IFA Admin",
          //       value: "IFA Admin",
          //     },
          //     {
          //       label: "Consultant",
          //       value: "Consultant",
          //     },
          //   ],
          //   value: "",
          //   order: 4,
          //   enabled: true,
          // },
        ],
        custom: [],
      },
    },
    master_settings_company_details: {
      title: "Company Details",
      reference_id: "master_settings_company_details",
      fields: {
        general: [
          {
            field: "company_name",
            type: "text",
            label: "Company Name",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 1,
            enabled: true,
          },
          {
            field: "company_address",
            type: "text",
            label: "Company Address",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 2,
            enabled: true,
          },
        ],
        custom: [],
      },
    },
    master_settings_life_labels: {
      title: "Life Labels",
      reference_id: "master_settings_life_labels",
      fields: {
        general: [
          {
            field: "life_label1",
            type: "text",
            label: "Life Label 1",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 1,
            enabled: true,
          },
          {
            field: "life_label2",
            type: "text",
            label: "Life Label 2",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 2,
            enabled: true,
          },
        ],
        custom: [],
      },
    },
    expense_category: {
      title: "Expense Categories",
      reference_id: "expense_category",
      fields: {
        general: [
          {
            field: "sort_order",
            type: "test",
            label: "Sort Order",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 1,
            enabled: true,
            required: true,
          },
          {
            field: "description",
            type: "text-area",
            label: "Description",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 2,
            enabled: true,
            required: true,
          },
          {
            field: "frequency",
            type: "select",
            label: "Frequency",
            placeholder: "Choose",
            remote: false,
            apiurl: "",
            options: [
              {
                label: "Weekly",
                value: "Weekly",
              },
              {
                label: "Fortnightly",
                value: "Fortnightly",
              },
              {
                label: "4-weekly",
                value: "4-weekly",
              },
              {
                label: "Monthly",
                value: "Monthly",
              },
              {
                label: "Quarterly",
                value: "Quarterly",
              },

              {
                label: "Half-yearly",
                value: "Half-yearly",
              },
              {
                label: "Annual",
                value: "Annual",
              },
            ],
            value: "Monthly",
            order: 3,
            enabled: true,
            required: true,
          },
          {
            field: "discretionary",
            type: "select",
            label: "Type",
            placeholder: "Choose",
            remote: false,
            apiurl: "",
            options: [
              {
                label: "",
                value: "",
              },
              {
                label: "Discretionary",
                value: "Discretionary",
              },
              {
                label: "Committed",
                value: "Committed",
              },
              {
                label: "Essential",
                value: "Essential",
              },
            ],
            value: "",
            order: 4,
            enabled: true,
            required: true,
          },

          {
            field: "auto_add",
            type: "toggle-button",
            label: "Auto Add",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 5,
            enabled: true,
            required: true,
          },
        ],
        custom: [],
      },
    },
    income_category: {
      title: "Income Categories",
      reference_id: "income_category",
      fields: {
        general: [
          {
            field: "sort_order",
            type: "text",
            label: "Sort Order",
            placeholder: "",
            apiurl: "",
            value: "0",
            order: 1,

            enabled: true,
          },
          {
            field: "description",
            type: "text-area",
            label: "Description",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 2,
            enabled: true,
            required: true,
          },
          {
            field: "frequency",
            type: "select",
            label: "Frequency",
            placeholder: "Choose",
            remote: false,
            apiurl: "",
            options: [
              {
                label: "Weekly",
                value: "Weekly",
              },
              {
                label: "Fortnightly",
                value: "Fortnightly",
              },
              {
                label: "4-weekly",
                value: "4-weekly",
              },
              {
                label: "Monthly",
                value: "Monthly",
              },
              {
                label: "Quarterly",
                value: "Quarterly",
              },

              {
                label: "Half-yearly",
                value: "Half-yearly",
              },
              {
                label: "Annual",
                value: "Annual",
              },
            ],
            value: "Monthly",
            order: 3,
            enabled: true,
          },
          {
            field: "auto_add",
            type: "toggle-button",
            label: "Auto Add",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 4,
            enabled: true,
          },
        ],
        custom: [],
      },
    },
    attitude_to_risk_category: {
      title: "Attitide Categories",
      reference_id: "attitude_to_risk_category",
      fields: {
        general: [
          {
            field: "sort_order",
            type: "text",
            label: "Sort Order",
            placeholder: "",
            apiurl: "",
            value: "0",
            order: 1,
            enabled: true,
            required: true,
          },
          {
            field: "category",
            type: "text",
            label: "Category",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 2,
            enabled: true,
            required: true,
          },
          {
            field: "description",
            type: "text-area",
            label: "Description",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 3,
            enabled: true,
            required: true,
          },
          {
            field: "auto_add",
            type: "toggle-button",
            label: "Auto Add",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 4,
            enabled: true,
            required: true,
          },
        ],
        custom: [],
      },
    },
    attitude_to_risk_rating: {
      title: "Attitide Ratings",
      reference_id: "attitude_to_risk_rating",
      fields: {
        general: [
          {
            field: "sort_order",
            type: "text",
            label: "Sort Order",
            placeholder: "",
            apiurl: "",
            value: "0",
            order: 1,
            enabled: true,
            required: true,
          },

          {
            field: "rating",
            type: "text",
            label: "Rating",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 2,
            enabled: true,
            required: true,
          },
          {
            field: "rating_score",
            type: "number",
            label: "Score",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 3,
            enabled: true,
            required: true,
          },
          {
            field: "description",
            type: "text-area",
            label: "Description",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 4,
            enabled: true,
            required: true,
          },
        ],
        custom: [],
      },
    },
    objectives_detail: {
      title: "Objectives",
      reference_id: "objectives_detail",
      fields: {
        general: [
          {
            field: "sort_order",
            type: "text",
            label: "Sort Order",
            placeholder: "",
            apiurl: "",
            value: "0",
            order: 1,
            enabled: true,
            required: true,
          },
          {
            field: "detail",
            type: "text",
            label: "Detail",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 2,
            enabled: true,
            required: true,
          },
          {
            field: "auto_add",
            type: "toggle-button",
            label: "Auto Add",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 3,
            enabled: true,
            required: true,
          },
        ],
        custom: [],
      },
    },
    provider: {
      title: "Providers",
      reference_id: "provider",
      fields: {
        general: [
          {
            field: "group_name",
            type: "select",
            label: "Group",
            placeholder: "",
            remote: false,
            apiurl: "",
            options: [
              {
                label: "",
                value: "",
              },
              {
                label: "Canaccord Genuity",
                value: "Canaccord Genuity",
              },
              {
                label: "Legal and General",
                value: "Legal and General",
              },
              {
                label: "Royal London",
                value: "Royal London",
              },
              {
                label: "Zurich",
                value: "Zurich",
              },
            ],
            value: "",
            order: 1,
            enabled: true,
          },
          {
            field: "provider_name",
            type: "text",
            label: "Provider Name",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 2,
            enabled: true,
            required: true,
          },
          {
            field: "contact_name",
            type: "text",
            label: "Contact ",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 3,
            enabled: true,
          },
          {
            field: "address1",
            type: "text",
            label: "Address 1",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 4,
            enabled: true,
          },
          {
            field: "address2",
            type: "text",
            label: "Address 2",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 5,
            enabled: true,
          },
          {
            field: "address3",
            type: "text",
            label: "Address 3",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 6,
            enabled: true,
          },
          {
            field: "town",
            type: "text",
            label: "Town",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 7,
            enabled: true,
          },
          {
            field: "county",
            type: "text",
            label: "County",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 8,
            enabled: true,
          },
          {
            field: "country",
            type: "text",
            label: "Country",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 9,
            enabled: true,
          },
          {
            field: "postcode",
            type: "text",
            label: "Postcode",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 10,
            enabled: true,
          },
          {
            field: "salutation",
            type: "text",
            label: "Salutation",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 11,
            enabled: true,
          },
          {
            field: "telephone",
            type: "text",
            label: "Telephone",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 12,
            enabled: true,
          },
          {
            field: "fax",
            type: "text",
            label: "Fax",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 13,
            enabled: true,
          },
          {
            field: "mobile",
            type: "text",
            label: "Mobile",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 14,
            enabled: true,
          },
          {
            field: "email",
            type: "text",
            label: "Email",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 15,
            enabled: true,
          },

          {
            field: "status",
            type: "text",
            label: "Status",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 16,
            enabled: true,
          },
          {
            field: "note_text",
            type: "text-editor",
            label: "Note",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 17,
            enabled: true,
          },
        ],
        custom: [],
      },
    },
    tracking_client_actions: {
      title: "Client Actions",
      reference_id: "tracking_client_actions",
      fields: {
        general: [
          {
            field: "case_type",
            label: "Action Group",
            type: "selectionList",
            placeholder: "Choose",
            remote: false,
            apiurl: "",
            value: "",
            order: 1,
            enabled: true,
            required: true,
          },
          {
            field: "due_interval",
            type: "number",
            label: "Due Interval(Days)",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 2,
            enabled: true,
            required: true,
          },
          {
            field: "description",
            type: "text-area",
            label: "Description",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 3,
            enabled: true,
            required: true,
          },
          {
            field: "assigned",
            type: "selectionList",
            label: "Assigned",
            placeholder: "Choose",
            remote: false,
            apiurl: "",
            value: "",
            order: 4,
            enabled: true,
          },
        ],
        custom: [],
      },
    },
    tracking_case_actions: {
      title: "Case Actions",
      reference_id: "tracking_case_actions",
      fields: {
        general: [
          {
            field: "case_type",
            label: "Action Group",
            type: "selectionList",
            placeholder: "Choose",
            remote: false,
            apiurl: "",
            value: "",
            order: 1,
            enabled: true,
            required: true,
          },
          {
            field: "due_interval",
            type: "number",
            label: "Due Interval(Days)",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 2,
            enabled: true,
            required: true,
          },
          {
            field: "description",
            type: "text-area",
            label: "Description",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 3,
            enabled: true,
            required: true,
          },
          {
            field: "assigned",
            type: "selectionList",
            label: "Assigned",
            placeholder: "Choose",
            remote: false,
            apiurl: "",
            value: "",
            order: 4,
            enabled: true,
          },
        ],
        custom: [],
      },
    },
    master_settings_template_email: {
      title: "Email",
      reference_id: "master_settings_template_email",
      fields: {
        general: [
          {
            field: "title",
            type: "text",
            label: "Title",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 1,
            enabled: true,
          },
          {
            field: "description",
            type: "text",
            label: "Description",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 2,
            enabled: true,
          },
          {
            field: "file_uploader",
            type: "Fileuploader",
            label: "File Uploader",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 3,
            enabled: true,
          },
        ],
        custom: [],
      },
    },
    master_settings_template_letter: {
      title: "Letter",
      reference_id: "master_settings_template_letter",
      fields: {
        general: [
          {
            field: "title",
            type: "text",
            label: "Title",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 1,
            enabled: true,
          },
          {
            field: "description",
            type: "text",
            label: "Description",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 2,
            enabled: true,
          },
          {
            field: "file_uploader",
            type: "Fileuploader",
            label: "File Uploader",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 3,
            enabled: true,
          },
        ],
        custom: [],
      },
    },
    master_settings_fund_management_client_assyst_fund_list: {
      title: "Fund Management",
      reference_id: "master_settings_fund_management_client_assyst_fund_list",
      fields: {
        general: [
          {
            field: "protected",
            type: "select",
            label: "Protected",
            placeholder: "Choose",
            remote: false,
            apiurl: "",
            options: [
              {
                label: "Yes",
                value: "Yes",
              },
              {
                label: "No",
                value: "No",
              },
            ],
            value: "",
            order: 1,
            enabled: true,
          },
          {
            field: "code",
            type: "text",
            label: "Code",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 2,
            enabled: true,
          },
          {
            field: "code_source",
            type: "select",
            label: "Code Source",
            placeholder: "Choose",
            remote: false,
            apiurl: "",
            options: [
              {
                label: "Undefined",
                value: "Undefined",
              },
              {
                label: "MEX",
                value: "MEX",
              },
              {
                label: "SEDOC",
                value: "SEDOC",
              },
              {
                label: "Product Provider Specific",
                value: "Product Provider Specific",
              },
              {
                label: "ISIN",
                value: "ISIN",
              },
            ],
            value: "",
            order: 3,
            enabled: true,
          },
        ],
        custom: [],
      },
    },
    master_settings_contract_enquiry: {
      title: "Contract Enquiry",
      reference_id: "master_settings_contract_enquiry",
      fields: {
        general: [
          {
            field: "description",
            type: "select",
            label: "Description",
            placeholder: "Choose",
            remote: false,
            apiurl: "",
            options: [
              {
                label: "Undefined",
                value: "Undefined",
              },
              {
                label: "MEX",
                value: "MEX",
              },
              {
                label: "SEDOC",
                value: "SEDOC",
              },
              {
                label: "Product Provider Specific",
                value: "Product Provider Specific",
              },
              {
                label: "ISIN",
                value: "ISIN",
              },
            ],
            value: "",
            order: 1,
            enabled: true,
          },
        ],
        custom: [],
      },
    },
    master_settings_integrations: {
      title: "Integrations",
      reference_id: "master_settings_integrations",
      fields: {
        general: [
          {
            field: "integration_partner",
            type: "select",
            label: "Integration Partner",
            placeholder: "Choose",
            remote: false,
            apiurl: "",
            options: [
              {
                label: "Cashcalc",
                value: "Cashcalc",
              },
            ],
            value: "",
            order: 1,
            enabled: true,
          },
          {
            field: "username",
            type: "text",
            label: "Username",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 2,
            enabled: true,
          },
          {
            field: "client_id",
            type: "text",
            label: "Client Id",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 3,
            enabled: true,
          },
          {
            field: "client_secret",
            type: "text",
            label: "Client Secret",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 4,
            enabled: true,
          },
        ],
        custom: [],
      },
    },
    commission_commission_rules: {
      title: "Commission Rules",
      reference_id: "commission_commission_rule",
      fields: {
        general: [
          {
            field: "party_type",
            type: "select",
            label: "Party Type",
            placeholder: "Choose",
            remote: false,
            apiurl: "",
            options: [
              {
                label: "Consultant",
                value: "Consultant",
              },
              {
                label: "Introducer",
                value: "Introducer",
              },
              {
                label: "Network",
                value: "Network",
              },
            ],
            value: "",
            order: 1,
            enabled: true,
          },
          {
            field: "case_type",
            type: "select",
            label: "Case Type",
            placeholder: "Choose",
            remote: false,
            apiurl: "",
            options: [
              {
                label: "Life Assurance",
                value: "Life Assurance",
              },
              {
                label: "Pensions",
                value: "Pensions",
              },
              {
                label: "Investments",
                value: "Investments",
              },
              {
                label: "Savings Plans",
                value: "Savings Plans",
              },
              {
                label: "Income Protection",
                value: "Income Protection",
              },
              {
                label: "General",
                value: "General",
              },
              {
                label: "Health Assurance",
                value: "Health Assurance",
              },
              {
                label: "Mortgages",
                value: "Mortgages",
              },
              {
                label: "Loans",
                value: "Loans",
              },
              {
                label: "Asset(Investment)",
                value: "Asset(Investment)",
              },
            ],
            value: "",
            order: 2,
            enabled: true,
          },
          {
            field: "party_name",
            type: "text",
            label: "Party Name",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 3,
            enabled: true,
          },
          {
            field: "amount",
            type: "text",
            label: "Amount",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 4,
            enabled: true,
          },
          {
            field: "amount_type",
            type: "select",
            label: "Amount Type",
            placeholder: "Choose",
            remote: false,
            apiurl: "",
            options: [
              {
                label: "Money",
                value: "Money",
              },
              {
                label: "Percent",
                value: "Percent",
              },
            ],
            value: "",
            order: 5,
            enabled: true,
          },
          {
            field: "note",
            type: "text-area",
            label: "Note",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 6,
            enabled: true,
          },
        ],
        custom: [],
      },
    },
    commission_types: {
      title: "Commission Types",
      reference_id: "commission_types",
      fields: {
        general: [
          {
            field: "category",
            type: "select",
            label: "Category",
            placeholder: "Choose",
            remote: false,
            apiurl: "",
            listType: "MasterDatawithNewEntry",
            fieldOptionLabel: "category",
         
            value: "",
            order: 1,
            enabled: true,
          },
          {
            field: "sort_order",
            type: "text",
            label: "Sort Order",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 2,
            enabled: true,
          },
          {
            field: "commission_type",
            type: "select",
            label: "Commission Type",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 3,
            enabled: true,
            options: [],
            listType: "FixedList",
            fieldOptionLabel: "commission_type",
          },
          {
            field: "description",
            type: "text",
            label: "Description",
            placeholder: "",
            apiurl: "",
            value: "",
            order: 4,
            enabled: true,
          },
        ],
        custom: [],
      },
    },
  },

  formValidations: {
    user_list: [
      {
        field: "full_name",
        validationType: "string",
        validations: [
          {
            type: "max",
            value: 20,
            message: "Full Name cannot be longer than 20 characters",
          },
        ],
      },
      {
        field: "short_name",
        validationType: "string",
        validations: [
          {
            type: "max",
            value: 20,
            message: "Short Name cannot be longer than 20 characters",
          },
        ],
      },
      {
        field: "user_email",
        validationType: "string",
        validations: [
          {
            type: "max",
            value: 50,
            message: "Email cannot be longer than 50 characters",
          },
          {
            type: "email",
            message: "Invalid Email",
          },
        ],
      },
      {
        field: "password",
        validationType: "string",
        validations: [
          {
            type: "min",
            value: 6,
            message: "Password is too short - should be 6 chars minimum.",
          },
        ],
      },
    ],
    expense_category: [
      {
        field: "sort_order",
        validationType: "string",
        validations: [
          {
            type: "pattern",
            value: "^[0-9]+$",
            message: "Sort Order Must be a number",
          },
          {
            type: "required",
            message: "Sort Order is required",
          },
        ],
      },
      {
        field: "description",
        validationType: "string",
        validations: [
          {
            type: "required",
            message: "Description is required",
          },
        ],
      },
      {
        field: "frequency",
        validationType: "string",
        validations: [
          {
            type: "required",
            message: "Frequency is required",
          },
        ],
      },
    ],
    income_category: [
      {
        field: "sort_order",
        validationType: "string",
        validations: [
          {
            type: "pattern",
            value: "^[0-9]+$",
            message: "Sort Order Must be a number",
          },
        ],
      },
      {
        field: "description",
        validationType: "string",
        validations: [
          {
            type: "required",
            message: "Description is required",
          },
        ],
      },
    ],
    attitude_to_risk_rating: [
      {
        field: "sort_order",
        validationType: "string",
        validations: [
          {
            type: "pattern",
            value: "^[0-9]+$",
            message: "Sort Order Must be a number",
          },
        ],
      },
      {
        field: "description",
        validationType: "string",
        validations: [
          {
            type: "required",
            message: "Description is required",
          },
        ],
      },

      {
        field: "rating_score",
        validationType: "number",
        validations: [
          {
            type: "min",
            value: 1,
            message: "Score must be a positive number",
          },
        ],
      },
    ],
    attitude_to_risk_category: [
      {
        field: "sort_order",
        validationType: "string",
        validations: [
          {
            type: "pattern",
            value: "^[0-9]+$",
            message: "Sort Order Must be a number",
          },
        ],
      },

      {
        field: "category",
        validationType: "string",
        validations: [
          {
            type: "required",
            message: "Category is required",
          },
        ],
      },
    ],
    objectives_detail: [
      {
        field: "sort_order",
        validationType: "string",
        validations: [
          {
            type: "pattern",
            value: "^[0-9]+$",
            message: "Sort Order Must be a number",
          },
        ],
      },
      {
        field: "detail",
        validationType: "string",
        validations: [
          {
            type: "required",
            message: "Detail is required",
          },
        ],
      },
    ],
    tracking_case_actions: [
      {
        field: "case_type",
        validationType: "string",
        validations: [
          {
            type: "required",
            message: "Action Group is required",
          },
          {
            type: "max",
            value: 50,
            message: "Action Grou cannot be longer than 50 characters",
          },
        ],
      },
      {
        field: "due_interval",
        validationType: "number",
        validations: [
          {
            type: "required",
            message: "Due Interval(Days) is required",
          },
          {
            type: "min",
            value: 0,
            message: "Due Interval(Days) must be a number",
          },
        ],
      },
      {
        field: "description",
        validationType: "string",
        validations: [
          {
            type: "required",
            message: "Description is required",
          },
          {
            type: "max",
            value: 250,
            message: "Description cannot be longer than 250 characters",
          },
        ],
      },
    ],
    tracking_client_actions: [
      {
        field: "case_type",
        validationType: "string",
        validations: [
          {
            type: "required",
            message: "Action Group is required",
          },
        ],
      },
      {
        field: "due_interval",
        validationType: "number",
        validations: [
          {
            type: "required",
            message: "Due Interval(Days) is required",
          },
          {
            type: "min",
            value: 0,
            message: "Due Interval(Days) must be a number",
          },
        ],
      },
      {
        field: "description",
        validationType: "string",
        validations: [
          {
            type: "required",
            message: "Description is required",
          },
          {
            type: "max",
            value: 250,
            message: "Description cannot be longer than 250 characters",
          },
        ],
      },
    ],
    provider: [
      {
        field: "provider_name",
        validationType: "string",
        validations: [
          {
            type: "required",
            message: "Provider Name is required",
          },
        ],
      },
    ],
  },
};
