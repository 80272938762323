import React, { useEffect, useState } from "react";
import FormContent from "../../../components/Form/FormContent";
import { formBuilder } from "../../../utils/formGenerator";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import apiServices from "../../../service";
import { useFormik } from "formik";
import {
  makeStyles,
  shorthands,
  Button,
  tokens,
  useId,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
  Link,
  ToastBody,
  ToastFooter,
  ToastTrigger,
  Label,
  Card,
  CardHeader,
  CardPreview,
} from "@fluentui/react-components";
import {
  Edit16Filled,
  ArrowUndo16Regular,
  ArrowRedo16Regular,
  Delete16Regular,
  DismissCircleRegular,
  DismissCircle20Regular,
} from "@fluentui/react-icons";
import axios from "axios";
import { checkUserPermission, formatedDate2 } from "../../../utils";
import { setFormData, resetFormData } from "../../../redux/reducer";
import { UserPermissionsList } from "../../../config";
import { setDialogModalOptions } from "../../../redux/modelDialogReducer";
import OverlayLoader from "../../../components/OverlayLoader";
import { FormErrorMessage } from "../../../components/FormErrorMessage/FormErrorMessage";
import PartnerDetailPopup from "../../../components/PartnerDetailPopup/PartnerDetailPopup";
import DeletePopup from "../../../components/DeletePopup/Deletepopup";
import DeleteDependencyPopup from "../../../components/DeletePopup/DeleteDepedencty";
const useStyles = makeStyles({
  btnwrapper: {
    columnGap: "8px",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "6px",
  },
  buttonWrapper: {
    columnGap: "15px",
    display: "flex",
    ...shorthands.padding("10px"),
  },
  containerTopArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  message: {
    marginRight: "14px",
  },
});
type configFm = {
  context: string;
  actionUrl: {
    create: string;
    edit: string;
    redirect: string;
  };
  renderForm: {
    reference_id: string;
    general_fields: string[];
    custom_fields: string[];
  };
};
type FormContainerProps = {
  style?: React.CSSProperties;
  title?: string;
  mode?: string;
  context: string;
  [x: string]: any;
};

const FormContainer = (props: FormContainerProps) => {
  let { context } = props;
  const classes = useStyles();
  const [configForm, setConfigForm] = useState<configFm>();
  const [formContext, setFormContext] = useState("");
  const [formMode, setFormMode] = useState(props.mode === "add" ? "New" : "");
  const [formFieldData, setFormFieldData] = useState<{ [key: string]: any }>(
    {}
  );
  const [partnerDialogPopup, setPartnerDialogPopup] = useState(false);
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const [optionData, setOptionData] = useState<any>();
  const [optionList, setOptionList] = useState<any>([]);
  const [isOptionLoaded, setIsOptionLoaded] = useState<boolean>(false);
  const [formError, setFormError] = useState<any[]>([]);
  const [past, setPast] = useState<any[]>([]);
  const [present, setPresent] = useState(null);
  const [future, setFuture] = useState<any[]>([]);
  const dispatch: AppDispatch = useDispatch();
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const isFormDataUpdated = useSelector(
    (state: RootState) => state.someReducer.isFormDataUpdated
  );
  const [newlyAddedSelectOptions, setNewlyAddedSelectOptions] = useState<any>(
    []
  );
  const [selectFieldOptionLabels, setSelectFieldOptionLabels] = useState<any>(
    []
  );
  const [selectFieldOptions, setSelectFieldOptions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [dependencyDeletePopup, setDependencyDeletePopup] = useState(false);
  const [dependencymessage, setDependencyMessage] = useState("");

  const newSelectOptionAdded = (
    field: string,
    options: any,
    operation: string
  ) => {
    let labelInfo = selectFieldOptionLabels.find(
      (e: any) => e.formField === field
    );
    if (operation === "remove") {
      const filteredRes = newlyAddedSelectOptions.filter(
        (item: any) => item.field !== field
      );
      setNewlyAddedSelectOptions(filteredRes);
    } else {
      if (labelInfo) {
        let newVall: any = {
          type: labelInfo.listType,
          options: [],
        };
        newVall["options"].push({
          field: labelInfo.label,
          label: options.label,
          value: options.value,
        });

        setNewlyAddedSelectOptions((prev: any) => [...prev, newVall]);
      }
    }
    // console.log("newlyAddedSelectOptions", newlyAddedSelectOptions);
  };

  const AddSelectOptions = () => {
    if (newlyAddedSelectOptions.length > 0) {
      setLoading(true);
      apiServices.selectionList
        .addOptions(newlyAddedSelectOptions)
        .then((response: any) => {
          setLoading(false);
          // console.log("response--add options", response);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const getSelectionListOptions = async (fieldLabels: any) => {
    try {
      let fieldLbl = "";
      if (fieldLabels.length > 0) {
        fieldLabels.map((itm: any, index: number) => {
          // console.log("index", index);
          if (fieldLabels.length - 1 != index) {
            fieldLbl = fieldLbl.concat(`${itm.label}:${itm.listType},`);
          } else {
            fieldLbl = fieldLbl.concat(`${itm.label}:${itm.listType}`);
          }
        });
        setLoading(true);
        apiServices.selectionList
          .getOptions(fieldLbl)
          .then((res) => {
            setLoading(false);
            if (res.data && res.data.data) {
              setSelectFieldOptions(res.data.data);
            }
            // console.log("response-selectionlist res", res.data);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (context !== "") {
      setFormContext(context);
      generateDynamicForm(context);
    }
    // const fetchOption = async () => {
    //   try {
    //     const response = await axios.get("/option.json");
    //     const optiondata = response.data;
    //     setOptionData(optiondata);
    //     optiondata[context] && setOptionList(optiondata[context]);
    //     optionList && setIsOptionLoaded(true);
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    // };

    // fetchOption();
  }, [context, formSettingsObj]);
  const customHandleChange = (event: any) => {
    handleOnChange(event);
  };
  const handleOnChange = (event: any) => {
    const data = { ...formik.values };
    data[event.target.name] = event.target.value;
    let updated = false;

    updatePresent(data);

    if (JSON.stringify(formik.initialValues) === JSON.stringify(data)) {
      dispatch(resetFormData());
      updated = false;
    } else {
      dispatch(setFormData());
      updated = true;
    }

    if (props.mode !== "add" && updated === true) {
      setFormMode("Changed");
    } else {
      setFormMode(props.mode === "add" ? "New" : "");
    }
  };
  const updatePresent = (newState: any) => {
    setPast([...past, present]);
    setPresent(newState);
    setFuture([]);
  };

  const undo = () => {
    if (past.length === 0) {
      setPast([]);
      return;
    }

    const newPast = [...past];
    const newPresent = newPast.pop();

    setPast(newPast);
    setFuture([present, ...future]);
    setPresent(newPresent);

    formik.setValues(newPresent);
    if (JSON.stringify(formik.initialValues) === JSON.stringify(newPresent)) {
      // // props.setFormUpdated(false);
      dispatch(resetFormData());
      setFormMode("");
    } else {
      // // props.setFormUpdated(true);
      dispatch(setFormData());
    }
  };
  const redo = () => {
    if (future.length === 0) return;

    const newFuture = [...future];
    const newPresent = newFuture.shift();

    setPast([...past, present]);
    setFuture(newFuture);
    setPresent(newPresent);

    formik.setValues(newPresent);
  };
  const generateDynamicForm = (formContext: string) => {
    if (formSettingsObj.data && formSettingsObj.data.formConfig !== undefined) {
      let { initialValues, inputs, validationSchema } = formBuilder({
        context: context,
        formSettings: formSettingsObj.data,
      });

      let filteredSelectionOptions = inputs
        .filter(
          (opt: any) =>
            opt.listType !== undefined &&
            [
              "ModifiableList",
              "FixedList",
              "MasterDatawithNewEntry",
              "MasterDatawithoutNewEntry",
            ].includes(opt.listType) &&
            opt.fieldOptionLabel !== undefined
        )
        .map((opt) => ({
          label: opt.fieldOptionLabel,
          listType: opt.listType,
          formField: opt.field,
        }));
      // console.log("filteredSelectionOptions", filteredSelectionOptions);
      setSelectFieldOptionLabels(filteredSelectionOptions);
      if (filteredSelectionOptions.length > 0) {
        getSelectionListOptions(filteredSelectionOptions);
      }
      setFormFieldData({ initialValues, inputs, validationSchema });
      let configObj = formSettingsObj.data.formConfig.find(
        (item: any) => item.context === context
      );

      if (configObj) {
        setConfigForm(configObj);
      }
    }
  };

  const formik = useFormik({
    initialValues:
      props.mode && ["edit", "view"].includes(props.mode)
        ? props.initialFormData
        : formFieldData.initialValues
        ? formFieldData.initialValues
        : {},

    validationSchema: formFieldData ? formFieldData.validationSchema : null,
    enableReinitialize: true,
    onSubmit: function (values, { setSubmitting }) {
      if (Object.keys(formik.errors).length === 0) {
        formSubmitted(values);
      }
    },
  });

  useEffect(() => {
    setPresent(formik.initialValues);
  }, [formik.initialValues]);

  const formSubmitted = (values: any) => {
    const payload = formFieldData.inputs.map(({ field }: any) => field);
    let reqBody: { [key: string]: any } = {};
    if (payload && payload.length > 0) {
      payload.map((el: any) => {
        if (values.hasOwnProperty(el)) {
          reqBody[el] = values[el] !== null ? values[el] : "";
        }
      });
    }
    let reqParam: any = {};
    if (
      configForm &&
      configForm.renderForm &&
      configForm.renderForm.reference_id
    ) {
      reqParam[configForm.renderForm.reference_id] = reqBody;
      if (
        [
          "liabilities_mortgages_review",
          "liabilities_loan_hire_purchase_review",
          "liabilities_credit_cards_review",
        ].includes(configForm.renderForm.reference_id)
      ) {
        reqParam[configForm.renderForm.reference_id]["case_type"] =
          props.liabilityType;
        reqParam[configForm.renderForm.reference_id]["customer_id"] =
          formik.values.joint_indicator === "1"
            ? props.activePartnerId
            : props.activeCustomerId;
        reqParam[configForm.renderForm.reference_id]["joint_indicator"] =
          props.activeItem.joint_indicator === ""
            ? 0
            : parseInt(props.activeItem.joint_indicator);
        reqParam[configForm.renderForm.reference_id]["review_completed"] =
          formatedDate2(reqBody.review_completed);
        reqParam[configForm.renderForm.reference_id]["review_date"] =
          formatedDate2(reqBody.review_date);
        if (
          reqBody.review_interval !== undefined &&
          reqBody.review_interval !== ""
        ) {
          reqParam[configForm.renderForm.reference_id]["review_interval"] =
            parseInt(reqBody.review_interval);
        } else {
          delete reqParam[configForm.renderForm.reference_id][
            "review_interval"
          ];
        }
      } else {
        reqParam[configForm.renderForm.reference_id]["case_type"] =
          props.liabilityType;
        reqParam[configForm.renderForm.reference_id]["customer_id"] =
          formik.values.joint_indicator === "1"
            ? props.activePartnerId
            : props.activeCustomerId;
        reqParam[configForm.renderForm.reference_id]["joint_indicator"] =
          reqBody.joint_indicator === ""
            ? 0
            : parseInt(reqBody.joint_indicator);
        reqParam[configForm.renderForm.reference_id][
          "loan_valuation_percentage"
        ] =
          reqBody.loan_valuation_percentage === ""
            ? 0
            : parseInt(reqBody.loan_valuation_percentage);
        reqParam[configForm.renderForm.reference_id]["interest_rate"] =
          reqBody.interest_rate === "" ? 0 : parseInt(reqBody.interest_rate);
        reqParam[configForm.renderForm.reference_id]["term"] =
          reqBody.term === "" ? 0 : parseInt(reqBody.term);
        reqParam[configForm.renderForm.reference_id]["amount_borrowed"] =
          reqBody.amount_borrowed === ""
            ? 0
            : parseFloat(reqBody.amount_borrowed);
        reqParam[configForm.renderForm.reference_id]["amount_outstanding"] =
          reqBody.amount_outstanding === ""
            ? 0
            : parseFloat(reqBody.amount_outstanding);
        if (
          reqBody.provider_correspondence !== undefined &&
          reqBody.provider_correspondence !== ""
        ) {
          reqParam[configForm.renderForm.reference_id][
            "provider_correspondence"
          ] = reqBody.provider_correspondence;
        } else {
          delete reqParam[configForm.renderForm.reference_id][
            "provider_correspondence"
          ];
        }
        reqParam[configForm.renderForm.reference_id]["property_value"] =
          reqBody.property_value === "" ? 0 : parseInt(reqBody.property_value);
        if (
          reqBody.review_interval !== undefined &&
          reqBody.review_interval !== ""
        ) {
          reqParam[configForm.renderForm.reference_id]["review_interval"] =
            parseInt(reqBody.review_interval);
        } else {
          delete reqParam[configForm.renderForm.reference_id][
            "review_interval"
          ];
        }
      }
    }

    if (props.mode === "add") {
      setFormError([]);
      setLoading(true);
      apiServices.liability
        .createNew(reqParam)
        .then((response) => {
          setLoading(false);
          if (!response.data.isError) {
            props.notify(response.data.message, "", "success");
            if (props.handleReloadList) {
              props.handleReloadList();
              AddSelectOptions();
            }
          } else if (response.data.isError) {
            if (
              response.data.error &&
              response.data.error &&
              Object.keys(response.data.error).length !== 0
            ) {
              if (Array.isArray(response.data.error.message)) {
                setFormError(response.data.error.message);
              } else {
                setFormError((prev) => [response.data.error.message]);
              }
            }
          } else {
            props.notify("Something went wrong", "", "error");
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          props.notify(err.message, "", "error");
        });

      //  add api
    } else if (props.mode === "edit") {
      setFormError([]);
      // update api
      setLoading(true);
      apiServices.liability
        .update(props.activeItem.liability_id, reqParam)
        .then((response: any) => {
          setLoading(false);
          props.notify(response.data.message, "", "success");
          if (props.handleReloadList) {
            props.handleReloadList();
            AddSelectOptions();
          }
          generateDynamicForm(context);
        })
        .catch((e: Error) => {
          setLoading(false);
          console.log(e);
          props.notify(e.message, "", "error");
        });
    } else {
    }
  };
  const indexOfActiveItem = (activeItem: any) => {
    let index = 1;
    if (props.liabilityList && props.liabilityList.length > 0) {
      index = props.liabilityList.findIndex(
        (x: any) => x.LiabilityId === activeItem.LiabilityId
      );
      index++;
    }
    return index;
  };
  const deleteData = () => {
    setLoading(true);
    if (
      context === "LIABILITIES_MORTGAGES_LIABILITY" ||
      context === "LIABILITIES_LOAN_HIRE_PURCHASE_LIABILITY" ||
      context === "LIABILITIES_CREDIT_CARDS_LIABILITY"
    ) {
      apiServices.liability
        .delete(props.activeItem.liability_id)
        .then((response: any) => {
          if (response.data.isdependancy) {
            setDependencyDeletePopup(true);
            setDependencyMessage(response.data.message);
          } else {
            props.notify(response.data.message, "", "success");
            props.handleReloadList();
          }
          setLoading(false);
        })
        .catch((e: Error) => {
          props.notify(e, "", "error");
          setLoading(false);
        });
    }
    setDeletePopup(false);
  };

  return (
    <>
      <OverlayLoader isLoading={loading} />
      {props.mode && props.mode === "view" && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            Record {props?.currentRecord} of {props.liabilityList.length}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div>
              {checkUserPermission(
                loggedUser,
                UserPermissionsList.ALLOW_UPDATE_CLIENT
              ) && (
                <Button
                  style={{ marginRight: 2, marginBottom: 6 }}
                  icon={<Edit16Filled />}
                  size="small"
                  onClick={() => {
                    props.setMode("edit");
                  }}
                >
                  Edit
                </Button>
              )}
              {checkUserPermission(
                loggedUser,
                UserPermissionsList.ALLOW_UPDATE_CLIENT
              ) && (
                <Button
                  icon={<Delete16Regular />}
                  style={{
                    marginRight: 2,
                    marginBottom: 6,
                    color: "red",
                  }}
                  size="small"
                  onClick={() => {
                    setDeletePopup(true);
                  }}
                >
                  Delete
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
      <DeletePopup
        deletePopup={deletePopup}
        setDeletePopup={setDeletePopup}
        deleteData={deleteData}
      />
      <DeleteDependencyPopup
        dependencyDeletePopup={dependencyDeletePopup}
        dependencymessage={dependencymessage}
        setDependencyDeletePopup={setDependencyDeletePopup}
        deleteData={deleteData}
      />
      {formError && formError.length > 0 && (
        <FormErrorMessage
          errorMessages={formError}
          onDismiss={() => setFormError([])}
        />
      )}
      {props.mode && ["edit", "add"].includes(props.mode) && (
        <div className={classes.btnwrapper}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Label size="medium" weight="semibold" className={classes.message}>
              {formMode !== "" && (
                <>
                  <span>**</span> {formMode} <span>**</span>
                </>
              )}
            </Label>
          </div>

          <div>
            <Button
              icon={<ArrowUndo16Regular />}
              onClick={undo}
              disabled={!isFormDataUpdated}
            ></Button>
            <Button
              icon={<ArrowRedo16Regular />}
              onClick={redo}
              disabled={future.length === 0 ? true : false}
            ></Button>
          </div>
          <Button
            disabled={!isFormDataUpdated}
            appearance="transparent"
            onClick={() => {
              dispatch(resetFormData());
              formik.resetForm();
            }}
          >
            Reset All
          </Button>
          {props.mode &&
            props.mode === "edit" &&
            props.showListItems === false && (
              <Button
                appearance="transparent"
                icon={<Delete16Regular />}
                onClick={() => {
                  props.setDialogModal({
                    ...props.dialogModalOptions,
                    open: true,
                    content_line_1: "Do you want to delete?",
                    no: {
                      onclick: () => {
                        props.setDialogModal({
                          ...props.dialogModalOptions,
                          open: false,
                        });
                      },
                      label: "No",
                    },
                    cancel: {
                      onclick: () => {
                        props.setDialogModal({
                          ...props.dialogModalOptions,
                          open: false,
                        });
                      },
                      label: "Yes",
                      type: "primary",
                    },
                  });
                }}
              ></Button>
            )}
        </div>
      )}
      {/* {props.mode && props.mode === "view" && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            style={{ marginRight: 2, marginBottom: 6 }}
            icon={<Edit16Filled />}
            size="small"
            onClick={() => {
              props.setMode("edit");
            }}
          >
            Edit
          </Button>
        </div>
      )} */}
      {context !== undefined && context !== "" && (
        <div style={{ marginTop: 10 }}>
          <FormContent
            {...{
              formik,
              formFieldData,
              title: "",
              mode: props.mode,
              optionList,
              handleOnChange,
              customHandleChange,
              context,
              selectFieldOptions,
              newSelectOptionAdded,
              caseDetails: props.caseDetails,
            }}
          />

          {props.mode !== "view" && (
            <div className={classes.buttonWrapper}>
              <Button
                className="asc-button-primary"
                appearance="primary"
                disabled={props.defaultAction === true ? true : false}
                onClick={() => {
                  if (isFormDataUpdated) {
                    if (
                      props.activePartnerId === null &&
                      formik.values.joint_indicator === "1"
                    ) {
                      setPartnerDialogPopup(true);
                    } else {
                      formik.handleSubmit();
                    }
                  }
                }}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  if (isFormDataUpdated) {
                    dispatch(
                      setDialogModalOptions({
                        open: true,
                        content_line_1:
                          "We have detected an attempt to move away from the current page.",
                        content_line_2:
                          "This would cause all changes made to be lost",
                        cancel: {
                          onclick: () => {
                            dispatch(setDialogModalOptions({ open: false }));
                            dispatch(resetFormData());
                            props.liabilityList.length === 0
                              ? props.setMode("")
                              : props.setMode("view");
                          },
                          label: "Leave the Page",
                        },

                        no: {
                          onclick: () => {
                            dispatch(setDialogModalOptions({ open: false }));
                          },
                          label: "Stay on Page",
                          type: "primary",
                        },
                      })
                    );
                  } else {
                    formik.resetForm();
                    props.liabilityList.length === 0
                      ? props.setMode("")
                      : props.setMode("view");
                  }
                }}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
      )}
      <PartnerDetailPopup
        notify={props.notify}
        mode={props.mode}
        partnerDialogPopup={partnerDialogPopup}
        setPartnerDialogPopup={setPartnerDialogPopup}
        isFormDataUpdated={isFormDataUpdated}
        formError={formError}
        setFormError={setFormError}
        newSelectOptionAdded={newSelectOptionAdded}
        activeCustomerId={props.activeCustomerId}
        activePartnerId={props.activePartnerId}
        setActivePartnerId={props.setActivePartnerId}
        customHandleChange={customHandleChange}
      />
    </>
  );
};

export default FormContainer;
