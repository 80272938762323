// someReducer.ts
import { createSlice } from "@reduxjs/toolkit";

interface SomeState {
  isFormDataUpdated: boolean; // Add isFormDataUpdated to the state
}

const initialState: SomeState = {
  isFormDataUpdated: false, // Initialize isFormDataUpdated as false
};

const someSlice = createSlice({
  name: "someReducer",
  initialState,
  reducers: {
    setFormData: (state) => {
      state.isFormDataUpdated = true; // Set isFormDataUpdated to true when someData changes
    },
    resetFormData: (state) => {
      state.isFormDataUpdated = false; // Set isFormDataUpdated to false when resetting data
    },
  },
});

export const { setFormData, resetFormData } = someSlice.actions;

export default someSlice.reducer;
