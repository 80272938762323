import React, { useEffect, useMemo } from "react";
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_RowSelectionState,
} from "material-react-table";
import {
  Eye16Filled,
  MoreHorizontal24Filled,
  Edit16Filled,
  Dismiss24Regular,
} from "@fluentui/react-icons";
import {
  Button,
  makeStyles,
  Tooltip,
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuPopover,
  Avatar,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Label,
  Dropdown,
  Option,
  useId,
  shorthands,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
} from "@fluentui/react-components";
import {
  checkUserPermission,
  formatedDate,
  getAssetCategoryByCaseType,
  getAssetTypeByCaseType,
  getClientType,
} from "../../../utils";
import ReactQuillComponent from "../../../components/ReactQuillComponent";
import { UserPermissionsList } from "../../../config";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
    cursor: "pointer",
  },
  contentHeader: {
    marginTop: "0",
  },
  scopeArea: {
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    maxWidth: "400px",
  },
});

type ComponentProps = {
  style?: React.CSSProperties;
  [key: string]: any;
};

const AssetListItems = (props: ComponentProps) => {
  const styles = useStyles();
  const loggedUser = useSelector((state: RootState) => state.authUser);

  const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>(
    {}
  );
  const columns = useMemo<MRT_ColumnDef<{ [key: string]: any }>[]>(
    () => [
      {
        accessorKey: "case_type",
        header: "Asset Category",
        Cell: ({ renderedCellValue, row }) => (
          <>{getAssetCategoryByCaseType(row.original.case_type)}</>
        ),
      },
      {
        accessorKey: "bank_sort_code",
        header: "Sort Code",
      },
      {
        accessorKey: "joint_indicator",
        header: "Owner",
        Cell: ({ renderedCellValue, row }) => (
          <>{getClientType(row.original.joint_indicator)}</>
        ),
      },
      {
        accessorKey: "owner",
        header: "Owner Detail",
      },
      {
        accessorKey: "owner_name1",
        header: "Owner Name1",
      },
      {
        accessorKey: "owner_name2",
        header: "Owner Name2",
      },
      {
        accessorKey: "current_value",
        header: "Current Value",
      },
      {
        accessorKey: "value_date",
        header: "Value Date",
        Cell: ({ row }: any) => (
          <>{formatedDate(row.original.value_date)}</>
        ),
      },
      {
        accessorKey: "purpose",
        header: "Purpose",
      },
      {
        accessorKey: "note_text",
        header: "Admin Note",
        Cell: ({ row }: any) => (
          <><ReactQuillComponent value={row.original.note_text} /></>
          ),
      },
      {
        accessorKey: "report_note",
        header: "Report Note",
        Cell: ({ row }: any) => (
          <><ReactQuillComponent value={row.original.report_note} /></>
          ),
      },
      {
        accessorKey: "investment_type"||"share_type",
        header: "Type",
        Cell: ({ row }: any) => (
          <>{row.original.investment_type ||row.original.share_type }</>
          ),
      },
      {
        accessorKey: "investment_subtype"||"share_subtype",
        header: "Subtype",
        Cell: ({ row }: any) => (
          <>{row.original.investment_subtype ||row.original.share_subtype }</>
          ),
      },
      {
        accessorKey: "investment_account_number" || "bank_account_number",
        header: "Account Number",
        Cell: ({ row }: any) => (
          <>{row.original.investment_account_number ||row.original.bank_account_number }</>
          ),
      },
      {
        accessorKey: "investment_start_date",
        header: "Start Date",
        Cell: ({ row }: any) => (
          <>{formatedDate(row.original.investment_start_date)}</>
        ),
      },
      {
        accessorKey: "investment_end_date",
        header: "End Date",
        Cell: ({ row }: any) => (
          <>{formatedDate(row.original.investment_end_date)}</>
        ),
      },
      {
        accessorKey: "investment_status" || "bank_status" || "share_status",
        header: "Status",
        Cell: ({ row }: any) => (
          <>{row.original.investment_status ||row.original.bank_status || row.original.share_status }</>
          ),
      },
      {
        accessorKey: "status_date",
        header: "Status Date",
        Cell: ({ row }: any) => (
          <>{formatedDate(row.original.status_date)}</>
        ),
      },
      {
        accessorKey: "service_status",
        header: "Service Status",
        Cell:({row}:any) => (
          <>{row.original.case_type === 'Home/Personal' ? "" :row.original.service_status}</>
        ),
      },
      {
        accessorKey: "investment_provider",
        header: "Provider",
      },
      {
        accessorKey: "provider_contact_name",
        header: "Provider Contact",
      },
      {
        accessorKey: "provider_phone",
        header: "Provider Phone",
      },
      {
        accessorKey: "provider_note",
        header: "Provider Note",
        Cell: ({ row }: any) => (
          <><ReactQuillComponent value={row.original.provider_note} /></>
          ),
      },
      {
        accessorKey: "share_company",
        header: "Company",
      },
      {
        accessorKey: "company_contact_name",
        header: "Company Contact",
        Cell: ({ row }: any) => (
          <>{row.original.provider_contact_name}</>
        ),
      },
      {
        accessorKey: "company_phone",
        header: "Company Phone",
        Cell: ({ row }: any) => (
          <>{row.original.provider_phone}</>
        ),
      },
      {
        accessorKey: "company_note",
        header: "Company Note",
        Cell: ({ row }: any) => (
          <><ReactQuillComponent value={row.original.provider_note} /></>
          ),
      },
      {
        accessorKey: "share_current_number",
        header: "Quantity",
      },
      {
        accessorKey: "share_current_price",
        header: "Current Price",
      },
      {
        accessorKey: "acquired_date",
        header: "Date Acquired",
        Cell: ({ row }: any) => (
          <>{formatedDate(row.original.value_date)}</>
        ),
      },
      {
        accessorKey: "share_original_value",
        header: "Original Value",
        Cell:({row}:any) => (
          <>{row.original.case_type === 'Home/Personal' ? row.original.home_original_value :row.original.share_original_value}</>
        ),
      },
      
      {
        accessorKey: "home_description",
        header: "Description",
      },
      {
        accessorKey: "bank_account_type",
        header: "Account Type",
      },
      {
        accessorKey: "bank_name",
        header: "Bank Name",
      },
      {
        accessorKey: "contact_name",
        header: "Contact",
        Cell: ({ row }: any) => (
          <>{row.original.provider_contact_name}</>
        ),
      },
      // {
      //   accessorKey: "provider_phone",
      //   header: "Phone",
      // },
      // {
      //   accessorKey: "provider_note",
      //   header: "Note",
      //   Cell: ({ row }: any) => (
      //     <><ReactQuillComponent value={row.original.provider_note} /></>
      //     ),
      // },
      {
        accessorKey: "bank_account_name",
        header: "Account Name",
      },
      {
        accessorKey: "balance_value",
        header: "Balance",
        Cell: ({ row }: any) => (
          <>{row.original.current_value}</>
        ),
      },
      {
        accessorKey: "balance_date",
        header: "Balance Date",
        Cell: ({ row }: any) => (
          <>{formatedDate(row.original.value_date)}</>
        ),
      },
      {
        accessorKey: "bank_interest_rate",
        header: "Interest Rate",
      },
      {
        accessorKey: "bank_date_opened",
        header: "Date Opened",
        Cell: ({ row }: any) => (
          <>{formatedDate(row.original.bank_date_opened)}</>
        ),
      },
    ],
    []
  );
  
  useEffect(() => {
    if (
      Object.keys(rowSelection)[0] !== undefined &&
      props.handleRowSelection !== undefined &&
      props.data.length > 0
    ) {
      let selectedRow = props.data[Object.keys(rowSelection)[0]];

      props.handleRowSelection(selectedRow.asset_id, props.mode);
    }
  }, [rowSelection]);
  useEffect(() => {
    if (
      props.activeItem &&
      Object.keys(props.activeItem).length !== 0 &&
      props.data.length > 0
    ) {
      let index = 0;
      index = props.data.findIndex(
        (x: any) => x.asset_id === props.activeItem.asset_id
      );
      setRowSelection({ [index]: true });
      props?.setCurrentRecord(index + 1);
    }
  }, [props.activeItem]);
  return (
    <div>
      <div>
        <MaterialReactTable
          columns={columns}
          data={props.data}
          enableRowSelection
          enableColumnActions={false}
          onRowSelectionChange={setRowSelection}
          enablePagination={false}
          enableRowNumbers
          enableBottomToolbar={false}
          rowNumberMode="static"
          enableMultiRowSelection={false}
          muiTableBodyRowProps={({ row }) => ({
            onClick: row.getToggleSelectedHandler(),
            sx: { cursor: "pointer" },
          })}
          getRowId={(row) => row.id}
          enableRowActions={false}
          displayColumnDefOptions={{
            "mrt-row-actions": {
              header: "Actions", //change header text
              // size: 300, //make actions column wider
              muiTableHeadCellProps: {
                align: "center",
              },
              muiTableBodyCellProps: {
                align: "center",
              },
            },
          }}
          renderRowActions={({ row, table }) => (
            <div className={styles.wrapper}>
              {checkUserPermission(
                loggedUser,
                UserPermissionsList.ALLOW_VIEW_CLIENT
              ) && (
              <Tooltip content="View" relationship="label" positioning="below">
                <Button
                  appearance="subtle"
                  icon={
                    <Eye16Filled
                      primaryFill="var(--button-color)"
                      onClick={() => {
                        props.handleRowAction("view", row);
                      }}
                    />
                  }
                ></Button>
              </Tooltip>
              )}
              <Tooltip content="Edit" relationship="label" positioning="below">
                <Button
                  appearance="subtle"
                  icon={
                    <Edit16Filled
                      primaryFill="var(--button-color)"
                      onClick={() => {
                        props.handleRowAction("edit", row);
                      }}
                    />
                  }
                ></Button>
              </Tooltip>
            </div>
          )}
          initialState={{
            columnPinning: { right: ["mrt-row-actions"] },
          }}
          positionActionsColumn="last"
          positionToolbarAlertBanner="bottom"
          state={{ rowSelection }}
        />
      </div>
    </div>
  );
};

export default AssetListItems;
