import {
  Card,
  CardPreview,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { DismissCircle20Regular } from "@fluentui/react-icons";

const useClasses = makeStyles({
  card: {
    width: "fit-content",
    marginBottom: "10px",
    boxShadow: "none",
    backgroundColor: tokens.colorPaletteRedBackground1,
  },
  errorMessages: {
    ...shorthands.padding("10px"),
    ...shorthands.margin("20px"),
    color: tokens.colorPaletteRedForeground1,
  },
  list: {
    listStyleType: "none",
  },
});

type FormErrorMessageProps = {
  errorMessages: string[];
  onDismiss: () => void;
};

export function FormErrorMessage({
  errorMessages,
  onDismiss,
}: FormErrorMessageProps) {
  const classes = useClasses();
  return (
    <Card className={classes.card}>
      <CardPreview className={classes.errorMessages}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 10,
          }}
        >
          <ul className={classes.list}>
            {errorMessages.map((err, i) => (
              <li key={i}>{err}</li>
            ))}
          </ul>
          <DismissCircle20Regular
            color={tokens.colorPaletteRedForeground2}
            style={{ cursor: "pointer" }}
            onClick={onDismiss}
          />
        </div>
      </CardPreview>
    </Card>
  );
}
