import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Select,
  useId,
  makeStyles,
  Button,
  Divider,
  tokens,
  CompoundButton,
  ButtonProps,
  Tab,
  TabList,
  shorthands,
  Label,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Radio,
  RadioOnChangeData,
  RadioGroup,
} from "@fluentui/react-components";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  OverlayDrawer,
} from "@fluentui/react-components";
import {
  Add20Filled,
  Add16Filled,
  ChevronLeft20Regular,
  Add20Regular,
  DocumentBulletList20Regular,
  DocumentFolder20Regular,
  Dismiss24Regular,
} from "@fluentui/react-icons";
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_RowSelectionState,
} from "material-react-table";
import FormContainer from "./FormContainer";
import { TableSmall } from "../../../components/TableSmall";
import PolicyDetails from "./PolicyDetails";
import {
  camalize,
  checkIfKeyExist,
  checkUserPermission,
  convertStringToArray,
  formatedDate,
  formatedDate2,
  getAssetFundCodeSource,
  // getPolicyTypeByCaseTypeASReq,
  // getPolicyTypeByCaseTypeId,
  getClientType,
  getYes_NoConversion,
} from "../../../utils";
import PolicyListItems from "./PolicyListItems";
import FormContainerDetailPage from "./FormContainerDetailPage";
import apiServices from "../../../service";
import "../style.css";
import { setDialogModalOptions } from "../../../redux/modelDialogReducer";
import ReactQuillComponent from "../../../components/ReactQuillComponent";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import LoadStandardAction from "../../../components/LoadStandardAction";
import { UserPermissionsList } from "../../../config";
import { PageLoader } from "../../../components/Loader/PageLoader";
import { addBreadcrumbs } from "../../../redux/appSettingsSlice";
import OverlayLoader from "../../../components/OverlayLoader";
import {
  General,
  HealthAssurance,
  IncomeProtection,
  Investments,
  lifeAssurance,
  pension,
  SavingsPlan,
} from "../../../shared/policyDetails";
const renderDate = (date: string) => (date ? formatedDate(date) : "-");
const renderRichText = (text: string) =>
  text ? <ReactQuillComponent value={text} /> : "-";
const renderYesorNo = (value: number) =>
  value ? getYes_NoConversion(value) : "-";
const CaseDetails = (activeItem: any) => {
  if (Object.keys(activeItem).length !== 0) {
    if (activeItem.case_type === "Life Assurance") {
      return (
        <div>
          {Object.keys(activeItem).length !== 0 &&
            lifeAssurance.length > 0 &&
            lifeAssurance.map((policyLA: any) => (
              <div key={policyLA.lifeAssurance_id} className="details">
                <p className="details_label">{policyLA.label} </p>
                <p>
                  {(() => {
                    switch (policyLA.value) {
                      case "policy_holder":
                        return getClientType(activeItem.policy_holder) || "-";
                      case "start_date":
                        return renderDate(activeItem.start_date);
                      case "end_date":
                        return renderDate(activeItem.end_date);
                      case "valuation_date":
                        return renderDate(activeItem.valuation_date);
                      case "provider_note":
                        return renderRichText(activeItem.provider_note);
                      case "benefit_text":
                        return renderRichText(activeItem.benefit_text);
                      case "in_trust_note":
                        return renderRichText(activeItem.in_trust_note);
                      case "admin_note":
                        return renderRichText(activeItem.admin_note);
                      case "report_note":
                        return renderRichText(activeItem.report_note);
                      default:
                        return activeItem[policyLA.value] || "-";
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else if (activeItem.case_type === "Pensions") {
      return (
        <div>
          {Object.keys(activeItem).length !== 0 &&
            pension.length > 0 &&
            pension.map((policyP: any) => (
              <div key={policyP.pension_id} className="details">
                <p className="details_label">{policyP.label} </p>
                <p>
                  {(() => {
                    switch (policyP.value) {
                      case "policy_holder":
                        return getClientType(activeItem.policy_holder) || "-";
                      case "start_date":
                        return renderDate(activeItem.start_date);
                      case "end_date":
                        return renderDate(activeItem.end_date);
                      case "valuation_date":
                        return renderDate(activeItem.valuation_date);
                      case "provider_note":
                        return renderRichText(activeItem.provider_note);
                      case "benefit_text":
                        return renderRichText(activeItem.benefit_text);
                      case "in_trust_note":
                        return renderRichText(activeItem.in_trust_note);
                      case "admin_note":
                        return renderRichText(activeItem.admin_note);
                      case "report_note":
                        return renderRichText(activeItem.report_note);
                      default:
                        return activeItem[policyP.value];
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else if (activeItem.case_type === "Investments") {
      return (
        <div>
          {Object.keys(activeItem).length !== 0 &&
            Investments.length > 0 &&
            Investments.map((policyInvestments: any) => (
              <div key={policyInvestments.Investments_id} className="details">
                <p className="details_label">{policyInvestments.label} </p>
                <p>
                  {(() => {
                    switch (policyInvestments.value) {
                      case "policy_holder":
                        return getClientType(activeItem.policy_holder) || "-";
                      case "start_date":
                        return renderDate(activeItem.start_date);
                      case "end_date":
                        return renderDate(activeItem.end_date);
                      case "valuation_date":
                        return renderDate(activeItem.valuation_date);
                      case "provider_note":
                        return renderRichText(activeItem.provider_note);
                      case "benefit_text":
                        return renderRichText(activeItem.benefit_text);
                      case "in_trust_note":
                        return renderRichText(activeItem.in_trust_note);
                      case "admin_note":
                        return renderRichText(activeItem.admin_note);
                      case "report_note":
                        return renderRichText(activeItem.report_note);
                      default:
                        return activeItem[policyInvestments.value];
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else if (activeItem.case_type === "Savings Plans") {
      return (
        <div>
          {Object.keys(activeItem).length !== 0 &&
            SavingsPlan.length > 0 &&
            SavingsPlan.map((policySP: any) => (
              <div key={policySP.Savings_Plans_id} className="details">
                <p className="details_label">{policySP.label} </p>
                <p>
                  {(() => {
                    switch (policySP.value) {
                      case "policy_holder":
                        return getClientType(activeItem.policy_holder) || "-";
                      case "start_date":
                        return renderDate(activeItem.start_date);
                      case "end_date":
                        return renderDate(activeItem.end_date);
                      case "valuation_date":
                        return renderDate(activeItem.valuation_date);
                      case "provider_note":
                        return renderRichText(activeItem.provider_note);
                      case "benefit_text":
                        return renderRichText(activeItem.benefit_text);
                      case "in_trust_note":
                        return renderRichText(activeItem.in_trust_note);
                      case "admin_note":
                        return renderRichText(activeItem.admin_note);
                      case "report_note":
                        return renderRichText(activeItem.report_note);
                      default:
                        return activeItem[policySP.value];
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else if (activeItem.case_type === "Income Protection") {
      return (
        <div>
          {Object.keys(activeItem).length !== 0 &&
            IncomeProtection.length > 0 &&
            IncomeProtection.map((policyIP: any) => (
              <div key={policyIP.Income_Protection_id} className="details">
                <p className="details_label">{policyIP.label} </p>
                <p>
                  {(() => {
                    switch (policyIP.value) {
                      case "policy_holder":
                        return getClientType(activeItem.policy_holder) || "-";
                      case "start_date":
                        return renderDate(activeItem.start_date);
                      case "end_date":
                        return renderDate(activeItem.end_date);
                      case "valuation_date":
                        return renderDate(activeItem.valuation_date);
                      case "provider_note":
                        return renderRichText(activeItem.provider_note);
                      case "benefit_text":
                        return renderRichText(activeItem.benefit_text);
                      case "in_trust_note":
                        return renderRichText(activeItem.in_trust_note);
                      case "admin_note":
                        return renderRichText(activeItem.admin_note);
                      case "report_note":
                        return renderRichText(activeItem.report_note);
                      default:
                        return activeItem[policyIP.value];
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else if (activeItem.case_type === "Health Assurance") {
      return (
        <div>
          {Object.keys(activeItem).length !== 0 &&
            HealthAssurance.length > 0 &&
            HealthAssurance.map((policyHA: any) => (
              <div key={policyHA.Income_Protection_id} className="details">
                <p className="details_label">{policyHA.label} </p>
                <p>
                  {(() => {
                    switch (policyHA.value) {
                      case "policy_holder":
                        return getClientType(activeItem.policy_holder) || "-";
                      case "start_date":
                        return renderDate(activeItem.start_date);
                      case "end_date":
                        return renderDate(activeItem.end_date);
                      case "valuation_date":
                        return renderDate(activeItem.valuation_date);
                      case "provider_note":
                        return renderRichText(activeItem.provider_note);
                      case "benefit_text":
                        return renderRichText(activeItem.benefit_text);
                      case "in_trust_note":
                        return renderRichText(activeItem.in_trust_note);
                      case "admin_note":
                        return renderRichText(activeItem.admin_note);
                      case "report_note":
                        return renderRichText(activeItem.report_note);
                      default:
                        return activeItem[policyHA.value];
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else if (activeItem.case_type === "General") {
      return (
        <div>
          {Object.keys(activeItem).length !== 0 &&
            General.length > 0 &&
            General.map((policyGeneral: any) => (
              <div key={policyGeneral.General_id} className="details">
                <p className="details_label">{policyGeneral.label} </p>
                <p>
                  {(() => {
                    switch (policyGeneral.value) {
                      case "policy_holder":
                        return getClientType(activeItem.policy_holder) || "-";
                      case "start_date":
                        return renderDate(activeItem.start_date);
                      case "end_date":
                      case "value_date":
                        return renderDate(activeItem.value_date);
                        return renderDate(activeItem.end_date);
                      case "valuation_date":
                        return renderDate(activeItem.valuation_date);
                      case "provider_note":
                        return renderRichText(activeItem.provider_note);
                      case "benefit_text":
                        return renderRichText(activeItem.benefit_text);
                      case "in_trust_note":
                        return renderRichText(activeItem.in_trust_note);
                      case "admin_note":
                        return renderRichText(activeItem.admin_note);
                      case "report_note":
                        return renderRichText(activeItem.report_note);
                      default:
                        return activeItem[policyGeneral.value];
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    }
  } else {
    return <p>No details available</p>;
  }
};

const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "10px",
  },
  divider: {
    flexGrow: 0,
  },
  btnWrapper: {
    columnGap: "15px",
    display: "flex",
    marginTop: "20px",
    marginBottom: "10px",
  },
  selectBoxLabel: {
    fontWeight: "bold",
    marginBottom: "10px",
  },
  tabList: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    ...shorthands.padding("10px", "0px"),
    rowGap: "20px",
  },
  field: {
    display: "grid",
    gridRowGap: tokens.spacingVerticalS,
    marginBottom: "20px",
  },
});

type componentProps = {
  [x: string]: any;
};

const PolicyForm = (props: componentProps) => {
  const selectId = useId();
  const styles = useStyles();

  const [policyType, setPolicyType] = useState("");
  const [policyList, setPolicyList] = useState([]);
  const [defaultAction, setDefaultAction] = useState(false);
  const [policyActiveTabLevel1, setPolicyActiveTabLevel1] = useState("policy");
  const [policyActiveTabLevel2, setPolicyActiveTabLevel2] = useState("");
  const [currentPage, setCurrentPage] = useState("listing");
  const [formContext, setFormContext] = useState("");
  const [detailFormContext, setDetailFormContext] = useState("");
  const [mode, setMode] = useState("");

  const [detailPageMode, setDetailPageMode] = useState("");
  const [initialFormData, setInitialFormData] = useState({});
  const [policyRowSelection, setPolicyRowSelection] = React.useState({});

  const [activeItem, setActiveItem] = useState<any | {}>({});
  const [activePolicyTab, setActivePolicyTab] = useState("policy");
  const dispatch: AppDispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);

  const [policySubitemsList, setPolicySubitemsList] = useState<any>([]);
  const [policySubActiveItem, setPolicySubActiveItem] = useState<any>({});
  const [detailPageSelectedRowIndex, setDetailPageSelectedRowIndex] =
    useState(0);
  const [initialDetailPageFormData, setInitialDetailPageFormData] = useState(
    {}
  );
  const [fundValue, setFundValue] = useState<any>();
  const [paymentValue, setPaymentValue] = useState<any>();
  const [withdrawalValue, setWithdrawalValue] = useState<any>();
  const [currentRecord, setCurrentRecord] = useState(1);
  const [currentSubRecord, setCurrentSubRecord] = useState(1);
  const [isOpen, setIsOpen] = React.useState(false);

  const [PolicyCatPopup, setPolicyCatPopup] = React.useState(false);
  const [selectedPolicyCatPopup, setSelectedPolicyCatPopup] =
    React.useState("");
  const [policyActiveTabLevel2Subtab, setPolicyActiveTabLevel2Subtab] =
    useState("");
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const radioName = useId("radio");
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);

  const labelId = useId("label");
  const [loadStandardActionPopup, setLoadStandardActionPopup] = useState(false);
  const [loadStandardActionDate, setLoadStandardActionDate] = useState(
    new Date()
  );
  const [selectedLoadStandardActions, setSelectedLoadStandardActions] =
    useState<any>([]);
  const [activePaymentItem, setActivePaymentItem] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [subItemLoading, setSubItemLoading] = useState(false);
  const [activeProvider, setActiveProvider] = useState<any>({});
  const [activePolicyDetail, setActivePolicyDetail] = useState<any>({});



  const contextList: any = {
    "Life Assurance": {
      policy: "POLICIES_LIFE_ASSURANCE_POLICIES",
      review: "POLICIES_LIFE_ASSURANCE_REVIEW",
      contractenquiry: "POLICIES_LIFE_ASSURANCE_CONTRACT_ENQUIRY",
      fund: "POLICIES_LIFE_ASSURANCE_FUNDS",
      payment: "POLICIES_LIFE_ASSURANCE_PAYMENT",
      paymentcomplaince: "POLICIES_LIFE_ASSURANCE_PAYMENTS_COMPLAINCE",
      paymentCommission: "POLICIES_LIFE_ASSURANCE_PAYMENTS_COMMISSION",
      withdrawals: "POLICIES_LIFE_ASSURANCE_WITHDRAWALS",
      actions: "POLICIES_LIFE_ASSURANCE_ACTIONS",
      valuations: "POLICIES_LIFE_ASSURANCE_VALUATION",
    },
    Pensions: {
      policy: "POLICIES_PENSIONS_POLICY",
      review: "POLICIES_PENSIONS_REVIEW",
      contractenquiry: "POLICIES_PENSIONS_CONTRACT_ENQUIRY",
      fund: "POLICIES_PENSIONS_FUNDS",
      payment: "POLICIES_PENSIONS_PAYMENT",
      paymentcomplaince: "POLICIES_PENSIONS_PAYMENTS_COMPLAINCE",
      paymentCommission: "POLICIES_PENSIONS_PAYMENTS_COMMISSION",
      withdrawals: "POLICIES_PENSIONS_WITHDRAWALS",
      actions: "POLICIES_PENSIONS_ACTIONS",
      valuations: "POLICIES_PENSIONS_VALUATION",
    },
    Investments: {
      policy: "POLICIES_INVESTMENTS_POLICY",
      review: "POLICIES_INVESTMENTS_REVIEW",
      contractenquiry: "POLICIES_INVESTMENTS_CONTRACT_ENQUIRY",
      fund: "POLICIES_INVESTMENTS_FUNDS",
      payment: "POLICIES_INVESTMENTS_PAYMENT",
      paymentcomplaince: "POLICIES_INVESTMENTS_PAYMENTS_COMPLAINCE",
      paymentCommission: "POLICIES_INVESTMENTS_PAYMENTS_COMMISSION",
      withdrawals: "POLICIES_INVESTMENTS_WITHDRAWALS",
      actions: "POLICIES_INVESTMENTS_ACTIONS",
      valuations: "POLICIES_INVESTMENTS_VALUATION",
    },
    "Savings Plans": {
      policy: "POLICIES_SAVINGS_PLANS_POLICY",
      review: "POLICIES_SAVINGS_PLANS_REVIEW",
      contractenquiry: "POLICIES_SAVINGS_PLANS_CONTRACT_ENQUIRY",
      fund: "POLICIES_SAVINGS_PLANS_FUNDS",
      payment: "POLICIES_SAVINGS_PLANS_PAYMENT",
      paymentcomplaince: "POLICIES_SAVINGS_PLANS_COMPLAINCE",
      paymentCommission: "POLICIES_SAVINGS_PLANS_COMMISSION",
      withdrawals: "POLICIES_SAVINGS_PLANS_WITHDRAWALS",
      actions: "POLICIES_SAVINGS_PLANS_ACTIONS",
      valuations: "POLICIES_SAVINGS_PLANS_VALUATION",
    },
    "Income Protection": {
      policy: "POLICIES_INCOME_PROTECTION_POLICY",
      review: "POLICIES_INCOME_PROTECTION_REVIEW",
      contractenquiry: "POLICIES_INCOME_PROTECTION_CONTRACT_ENQUIRY",
      fund: "POLICIES_INCOME_PROTECTION_FUNDS",
      payment: "POLICIES_INCOME_PROTECTION_PAYMENT",
      paymentcomplaince: "POLICIES_INCOME_PROTECTION_COMPLAINCE",
      paymentCommission: "POLICIES_INCOME_PROTECTION_COMMISSION",
      withdrawals: "POLICIES_INCOME_PROTECTION_WITHDRAWALS",
      actions: "POLICIES_INCOME_PROTECTION_ACTIONS",
      valuations: "POLICIES_INCOME_PROTECTION_VALUATION",
    },
    "Health Assurance": {
      policy: "POLICIES_HEALTH_ASSURANCE_POLICY",
      review: "POLICIES_HEALTH_ASSURANCE_REVIEW",
      contractenquiry: "POLICIES_HEALTH_ASSURANCE_CONTRACT_ENQUIRY",
      fund: "POLICIES_HEALTH_ASSURANCE_FUNDS",
      payment: "POLICIES_HEALTH_ASSURANCE_PAYMENT",
      paymentcomplaince: "POLICIES_HEALTH_ASSURANCE_COMPLAINCE",
      paymentCommission: "POLICIES_HEALTH_ASSURANCE_COMMISSION",
      withdrawals: "POLICIES_HEALTH_ASSURANCE_WITHDRAWALS",
      actions: "POLICIES_HEALTH_ASSURANCE_ACTIONS",
      valuations: "POLICIES_HEALTH_ASSURANCE_VALUATION",
    },
    General: {
      policy: "POLICIES_GENERAL_POLICY",
      review: "POLICIES_GENERAL_REVIEW",
      contractenquiry: "POLICIES_GENERAL_CONTRACT_ENQUIRY",
      fund: "POLICIES_GENERAL_FUNDS",
      payment: "POLICIES_GENERAL_PAYMENT",
      paymentcomplaince: "POLICIES_GENERAL_PAYMENTS_COMPLAINCE",
      paymentCommission: "POLICIES_GENERAL_PAYMENTS_COMMISSION",
      withdrawals: "POLICIES_GENERAL_WITHDRAWALS",
      actions: "POLICIES_GENERAL_ACTIONS",
      valuations: "POLICIES_GENERAL_VALUATION",
    },
  };

  const handleBreadcrumbs = () => {
    let breadcrumbsItems: { key: number; value: string; link?: string }[] = [
      {
        key: 0,
        value: "Home",
        link: "/",
      },
      {
        key: 1,
        value: "Clients",
        link: "/clients",
      },
      {
        key: 2,
        value: "Client",
        link: "/client",
      },
      {
        key: 3,
        value: "Policy",
        link: "/client",
      },
    ];
    if (policyType !== "" && policyList.length > 0) {
      breadcrumbsItems.push({
        key: 4,
        value: camalize(policyType),
      });
    }
    dispatch(addBreadcrumbs(breadcrumbsItems));
    // }
  };
  const getContextDetails = (policyType: string, context: string) => {
    if (contextList[policyType] && contextList[policyType][context]) {
      setFormContext(contextList[policyType][context]);
    } else {
      setFormContext("");
    }
  };

  useEffect(() => {
    setPolicySubitemsList([]);
  }, [policyActiveTabLevel2Subtab]);
  useEffect(() => {
    fetchPolicyLists();
  }, []);

  useEffect(() => {
    if (policyList.length > 0) {
      selectedIndex(0);
    }
  }, [policyList]);

  useEffect(() => {
    if (policySubitemsList.length > 0) {
      selectedSubIndex(0);
    } else {
      setDetailFormContext("");
    }
  }, [policySubitemsList]);

  // useEffect(() => {
  //   getPolicySubItems();
  //   setDetailFormContext("");
  //   handleBreadcrumbs();
  // }, [policyActiveTabLevel2]);

  // useEffect(()=>{
  //   if (policyActiveTabLevel2 === "payment") {
  //       setPaymentData((policySubActiveItem as { [key: string]: any }).payment.payment_id)
  //   }

  // },[policyActiveTabLevel2])

  useEffect(() => {
    if (policyActiveTabLevel2 !== "") {
      if (policyActiveTabLevel2 === "payment") {
        setPolicyActiveTabLevel2Subtab("payment");
      } else {
        setPolicyActiveTabLevel2Subtab("");
      }
      // else {
      //   getPolicySubItems();
      // }
    }
    getPolicySubItems();
    setDetailFormContext("");
    handleBreadcrumbs();
  }, [policyActiveTabLevel2]);
  useEffect(() => {
    if (policyActiveTabLevel2Subtab !== "") {
      getPolicySubItems();
    }
  }, [policyActiveTabLevel2Subtab]);
  useEffect(() => {
    if (policyList.length > 0) {
      let index = 0;
      index = policyList.findIndex(
        (x: any) => x.policy_id === activeItem.policy_id
      );
      if (activePolicyTab === "policy") {
        setInitialFormData(setPolicyFieldValues(policyList[index]));
      } else if (activePolicyTab === "review") {
        setInitialFormData(setPolicyReviewFieldValues(policyList[index]));
      } else {
      }
    }
  }, [mode]);
  useEffect(() => {
    // setMode(detailPageMode);

    if (policySubitemsList.length > 0) {
      let index = 0;

      if (policyActiveTabLevel2 === "payment") {
        if (policyActiveTabLevel2Subtab === "paymentCommission") {
          index = policySubitemsList.findIndex(
            (x: any) => x.commission_id === policySubActiveItem.commission_id
          );
        } else {
          index = policySubitemsList.findIndex(
            (x: any) =>
              x.payment.payment_id === policySubActiveItem.payment.payment_id
          );

          if (policyActiveTabLevel2Subtab === "payment") {
            setInitialDetailPageFormData(
              setPaymentFieldValues(policySubitemsList[index])
            );
          } else if (policyActiveTabLevel2Subtab === "paymentcomplaince") {
            setInitialDetailPageFormData(
              setPaymentComplainceFieldValues(policySubitemsList[index])
            );
          } else {
          }
        }
      } else if (policyActiveTabLevel2 === "withdrawals") {
        index = policySubitemsList.findIndex(
          (x: any) => x.withdrawal_id === policySubActiveItem.withdrawal_id
        );
        setInitialDetailPageFormData(
          setWithdrawalsFieldValues(policySubitemsList[index])
        );
      } else if (policyActiveTabLevel2 === "valuations") {
        index = policySubitemsList.findIndex(
          (x: any) => x.valuation_id === policySubActiveItem.valuation_id
        );
        setInitialDetailPageFormData(
          setValuationsFieldValues(policySubitemsList[index])
        );
      } else if (policyActiveTabLevel2 === "actions") {
        index = policySubitemsList.findIndex(
          (x: any) => x.business_id === policySubActiveItem.business_id
        );
        setInitialDetailPageFormData(
          setActionsFieldValues(policySubitemsList[index])
        );
      } else if (policyActiveTabLevel2 === "fund") {
        index = policySubitemsList.findIndex(
          (x: any) => x.fund_id === policySubActiveItem.fund_id
        );
        setInitialDetailPageFormData(
          setFundFieldValues(policySubitemsList[index])
        );
      } else {
      }
    }
  }, [detailPageMode]);

  useEffect(() => {
    // alert("activePolicyTab==" + currentRecord);
    if (activePolicyTab === "review" && currentRecord) {
      setInitialFormData(
        setPolicyReviewFieldValues(policyList[currentRecord - 1])
      );
    } else if (activePolicyTab === "policy" && currentRecord) {
      setInitialFormData(setPolicyFieldValues(policyList[currentRecord - 1]));
    } else {
    }
    getContextDetails(policyType, activePolicyTab);
  }, [activePolicyTab]);

  const showReviewForm = () => {
    // getContextDetails(policyType, "review");
    // alert(policyType);
  };
  useEffect(() => {
    // if (policyList.length === 0 && policyType !== "") {
    //   setDefaultAction(true);
    //   getContextDetails(policyType, policyActiveTabLevel1);
    // } else {
    //   setDefaultAction(false);
    // }
    handleBreadcrumbs();
  }, [policyType]);

  // useEffect(() => {
  //   // console.log("activeItem--->>>", activeItem);
  //   if (Object.keys(activeItem).length !== 0) {
  //     getPolicyDetails(activeItem.customer_id, activeItem.policy_id);
  //   }
  // }, [activeItem]);

  useEffect(() => {
    // console.log("activePolicyDetail--->>>", activePolicyDetail);
    if (Object.keys(activePolicyDetail).length !== 0) {
      if (activePolicyTab === "policy") {
        setInitialFormData(setPolicyFieldValues(activePolicyDetail));
      } else if (activePolicyTab === "review") {
        setInitialFormData(setPolicyReviewFieldValues(activePolicyDetail));
      } else {
      }
      // setInitialFormData(setPolicyFieldValues(activePolicyDetail));
    }
  }, [activePolicyDetail]);

  const getPolicyDetails = (customerId: string, policyId: string) => {
    setLoading(true);
    apiServices.policy
      .getDetail(policyId, customerId)
      .then((response) => {
        setLoading(false);
        // console.log("policy detail", response);
        if (response.data) {
          if (response.data.data) {
            setActivePolicyDetail(response.data.data);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err");
      });
  };

  const fetchPolicyLists = () => {
    if (isFetching) return;
    setIsFetching(true);
    setLoading(true);
    apiServices.policy
      .getPolicies(props.activeCustomerId)
      .then((res) => {
        setLoading(false);
        if (res.data !== undefined) {
          if (res.data.data) {
            setPolicyList(res.data.data);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const selectedIndex = (index: number) => {
    if (policyList.length > 0) {
      setActiveItem(policyList[index]);
      setActivePolicyTab(activePolicyTab);
      setMode("view");
      if (activePolicyTab === "policy") {
        setInitialFormData(setPolicyFieldValues(policyList[index]));
      } else if (activePolicyTab === "review") {
        setInitialFormData(setPolicyReviewFieldValues(policyList[index]));
      } else {
      }

      if ((policyList as { [key: string]: any })[index].case_type) {
        let case_type_value = (policyList as { [key: string]: any })[index]
          .case_type;
        setFormContext(
          (contextList as { [key: string]: any })[case_type_value]["policy"]
        );

        setPolicyType(case_type_value);
        getContextDetails(case_type_value, policyActiveTabLevel1);
      }
      setLoading(true);
      apiServices.policy
        .getDetail(
          (policyList as { [key: string]: any })[index].policy_id,
          props.activeCustomerId
        )
        .then((response) => {
          setLoading(true);
          if (response.data) {
            if (response.data.data) {
              setActiveProvider(response.data.data);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("err");
        });
    }
  };

  const selectedSubIndex = (index: number) => {
    if (index !== undefined) {
      setPolicySubActiveItem(policySubitemsList[index]);

      if (policyActiveTabLevel2 === "payment") {
        if (policyActiveTabLevel2Subtab === "payment") {
          setInitialDetailPageFormData(
            setPaymentFieldValues(policySubitemsList[index])
          );
          setActivePaymentItem(policySubitemsList[index]);
        } else if (policyActiveTabLevel2Subtab === "paymentcomplaince") {
          setInitialDetailPageFormData(
            setPaymentComplainceFieldValues(policySubitemsList[index])
          );
        } else if (policyActiveTabLevel2Subtab === "paymentCommission") {
          setInitialDetailPageFormData(
            setcommissionFieldValues(policySubitemsList[index])
          );
        } else {
        }
      } else if (policyActiveTabLevel2 === "withdrawals") {
        setActivePaymentItem({});
        setInitialDetailPageFormData(
          setWithdrawalsFieldValues(policySubitemsList[index])
        );
      } else if (policyActiveTabLevel2 === "valuations") {
        setActivePaymentItem({});
        setInitialDetailPageFormData(
          setValuationsFieldValues(policySubitemsList[index])
        );
      } else if (policyActiveTabLevel2 === "actions") {
        setActivePaymentItem({});
        setInitialDetailPageFormData(
          setActionsFieldValues(policySubitemsList[index])
        );
      } else if (policyActiveTabLevel2 === "fund") {
        setActivePaymentItem({});
        setInitialDetailPageFormData(
          setFundFieldValues(policySubitemsList[index])
        );
      } else {
      }

      setDetailPageMode("view");
      if (policyActiveTabLevel2 === "payment") {
        setDetailFormContext(
          contextList[policyType][policyActiveTabLevel2Subtab]
        );
      } else {
        setDetailFormContext(contextList[policyType][policyActiveTabLevel2]);
      }
      handleBreadcrumbs();
    }
  };
  const selectActivePolicyItem = (item: string) => {
    // setDetailFormContext(contextList[policyType][item]);
    setCurrentPage("detail");
    setPolicyActiveTabLevel2(item);
    // getContextDetails(policyType, item);
  };
  const getPolicySubItems = () => {
    // alert("hiii")

    if (policyActiveTabLevel2 != "") {
      // alert(policyActiveTabLevel2);
      // api call
      fetchSubItemList();
      // setPolicySubitemsList(policySubitems);
    }
  };
  const handleRowAction = (mode: string, row: any) => {
    setActiveItem(row.orginal);
    setMode(mode);
  };
  const handleRowSelection = (policyId: any, mode: string) => {
    let policyObj = policyList.find((item: any) => item.policy_id === policyId);
    if (policyObj) {
      setActiveItem(policyObj);
      setMode("view");
      setActivePolicyTab(activePolicyTab);
      if (activePolicyTab === "policy") {
        setInitialFormData(setPolicyFieldValues(policyObj));
      } else if (activePolicyTab === "review") {
        setInitialFormData(setPolicyReviewFieldValues(policyObj));
      } else {
      }
      // setInitialFormData(setPolicyFieldValues(policyObj));
      let case_type_value = (policyObj as { [key: string]: any }).case_type;

      setFormContext(case_type_value[activePolicyTab]);
      setPolicyType(case_type_value);
      getContextDetails(case_type_value, activePolicyTab);
      // getPolicyDetails(
      //   (policyObj as { [key: string]: any }).customer_id,
      //   (policyObj as { [key: string]: any }).policy_id
      // );
    }
  };
  const handleDetailPageRowSelection = (index: number) => {
    // setDetailPageSelectedRowIndex(index);
    selectedSubIndex(index);
    // alert(index);
    //  let policyObj = policyList.find((item: any) => item.PolicyId === policy_id);
    //  if (policyObj) {
    //    setActiveItem(policyObj);
    //    setMode("view");
    //    setInitialFormData(setPolicyFieldValues(policyObj));
    //    let case_type_value = (policyObj as { [key: string]: any }).CaseType;

    //    setFormContext(case_type_value["policy"]);
    //    setPolicyType(case_type_value);
    //    getContextDetails(case_type_value, policyActiveTabLevel1);
    //  }
  };
  const handleDetailFormContext = (module: string) => {
    setDetailFormContext(contextList[policyType][module]);
  };
  const handleReloadList = () => {
    fetchPolicyLists();
  };
  const handleReloadSublist = () => {
    fetchSubItemList();
  };
  const fetchSubItemList = () => {
    setLoading(true);
    if (policyActiveTabLevel2 === "payment") {
      if (policyActiveTabLevel2Subtab === "paymentCommission") {
        if (Object.keys(activePaymentItem).length !== 0) {
          apiServices.commission
            .getCommission(
              (activePaymentItem as { [key: string]: any }).payment?.payment_id,
              props.activeCustomerId,
              (activeItem as { [key: string]: any }).policy_id
            )
            .then((response) => {
              setLoading(false);
              setIsFetching(false);
              if (response.data && response.data.data) {
                setPolicySubitemsList(response.data.data);
              }
            })
            .catch((err) => {
              setLoading(false);
              setIsFetching(false);
              console.log(err);
            });
        }
      } else {
        apiServices.Payments.getPayments(
          (activeItem as { [key: string]: any }).policy_id,
          props.activeCustomerId
        )
          .then((response) => {
            setLoading(false);
            setIsFetching(false);
            if (response.data && response.data.data && response.data.data.result) {
              setPolicySubitemsList(response.data.data?.result || []);
              setPaymentValue(response.data.data?.total);
            }
          })
          .catch((err) => {
            setLoading(false);
            setIsFetching(false);
            console.log(err);
          });
      
    } }else if (policyActiveTabLevel2 === "withdrawals") {
      apiServices.Withdrawals.getList(
        (activeItem as { [key: string]: any }).policy_id,
        props.activeCustomerId
      )
        .then((response) => {
          setLoading(false);
          if (response.data) {
            if (response.data.data && response.data.data.result) {
              setPolicySubitemsList(response.data.data?.result || []);
              setWithdrawalValue(response.data.data?.total);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else if (policyActiveTabLevel2 === "actions") {
      apiServices.Actions.getActions(
        (activeItem as { [key: string]: any }).policy_id,
        props.activeCustomerId
      )
        .then((response) => {
          setLoading(false);
          if (response.data) {
            if (response.data.data) {
              setPolicySubitemsList(response.data.data);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else if (policyActiveTabLevel2 === "fund") {
      apiServices.fund
        .getList(
          props.activeCustomerId,
          (activeItem as { [key: string]: any }).policy_id
        )
        .then((response) => {
          setLoading(false);
          if (response.data) {
            if (response.data.data && response.data.data.result) {
              setPolicySubitemsList(response.data.data?.result || []);
              setFundValue(response.data.data?.total_fund);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else if (policyActiveTabLevel2 === "valuations") {
      // let itemArr=[{
      //   id: "f47ac10b-58cc-4372-a567-0e02b2c3d479",
      //   effective_date:"05/04/2024",
      //   valuation_amount:"3500",
      //   note_text:"<p>string</p>"
      // }]
      // setPolicySubitemsList(itemArr);
      apiServices.valuation
        .getvaluation(
          (activeItem as { [key: string]: any }).policy_id,
          props.activeCustomerId
        )
        .then((response) => {
          setLoading(false);
          if (response.data) {
            if (response.data.data) {
              setPolicySubitemsList(response.data.data);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
    }
  };
  const setPolicyFieldValues = (params: any) => {
    let paramVal = {};

    if (params?.case_type === "Life Assurance") {
      paramVal = {
        ...paramVal,
        policy_holder:
          mode == "view"
            ? getClientType(params.policy_holder)
            : params.policy_holder,
        assured_detail: params.assured_detail ? params.assured_detail : "",
        assured_name1: params.assured_name1 ? params.assured_name1 : "",
        assured_name2: params.assured_name2 ? params.assured_name2 : "",
        subtype: params.subtype ? params.subtype : "",
        provider: params.provider ? params.provider : "",
        provider_correspondence: params.provider_correspondence
          ? activePolicyDetail?.provider_name
          : "",
        purpose: params.purpose ? params.purpose : "",
        link_to_mortgage:
          mode == "view"
            ? getYes_NoConversion(params.link_to_mortgage)
            : params.link_to_mortgage,
        // link_to_mortgage: params.link_to_mortgage
        //   ? params.link_to_mortgage
        //   : "",
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        term: params.term ? params.term : "",
        scheme_name: params.scheme_name ? params.scheme_name : "",
        policy_number: params.policy_number ? params.policy_number : "",
        other_reference: params.other_reference ? params.other_reference : "",
        status: params.status ? params.status : "",
        status_date: params.status_date ? params.status_date : "",
        service_status: params.service_status ? params.service_status : "",
        premium_indexed: params.premium_indexed ? params.premium_indexed : "",
        waiver: params.waiver ? params.waiver : "",
        withdrawal_note: params.withdrawal_note ? params.withdrawal_note : "",
        surrender_value: params.surrender_value ? params.surrender_value : "",
        future_value: params.future_value ? params.future_value : "",
        total_fund_value: params.total_fund_value
          ? params.total_fund_value
          : "",
        valuation_date: params.valuation_date ? params.valuation_date : "",

        sum_assured: params.sum_assured ? parseFloat(params.sum_assured) : "",
        lump_sum_benefit: params.lump_sum_benefit
          ? parseFloat(params.lump_sum_benefit)
          : "",

        periodic_benefit: params.periodic_benefit
          ? params.periodic_benefit
          : "",
        benefit_frequency: params.benefit_frequency
          ? params.benefit_frequency
          : "",
        benefits_indexed: params.benefits_indexed
          ? params.benefits_indexed
          : "",
        benefit_text: params.benefit_text ? params.benefit_text : "",
        rated: params.rated ? params.rated : "",
        in_trust: params.in_trust ? params.in_trust : "",
        in_trust_note: params.in_trust_note ? params.in_trust_note : "",
        age_admitted: params.age_admitted ? params.age_admitted : "",
        assigned_to: params.assigned_to ? params.assigned_to : "",
        admin_note: params.admin_note ? params.admin_note : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_note: params.provider_note ? params.provider_note : "",
      };
    } else if (params?.case_type === "Pensions") {
      paramVal = {
        ...paramVal,
        policy_holder:
          mode == "view"
            ? getClientType(params.policy_holder)
            : params.policy_holder,
        assured_detail: params.assured_detail ? params.assured_detail : "",
        assured_name1: params.assured_name1 ? params.assured_name1 : "",
        assured_name2: params.assured_name2 ? params.assured_name2 : "",
        subtype: params.subtype ? params.subtype : "",
        provider: params.provider ? params.provider : "",
        provider_correspondence: params.provider_correspondence
          ? activePolicyDetail?.provider_name
          : "",
        purpose: params.purpose ? params.purpose : "",
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        term: params.term ? params.term : "",
        scheme_name: params.scheme_name ? params.scheme_name : "",
        policy_number: params.policy_number ? params.policy_number : "",
        other_reference: params.other_reference ? params.other_reference : "",
        status: params.status ? params.status : "",
        status_date: params.status_date ? params.status_date : "",
        service_status: params.service_status ? params.service_status : "",
        contracted_out: params.contracted_out ? params.contracted_out : "",
        premium_indexed: params.premium_indexed ? params.premium_indexed : "",
        waiver: params.waiver ? params.waiver : "",
        transfer_value: params.transfer_value || "",
        future_value: params.future_value ? params.future_value : "",
        total_fund_value: params.total_fund_value
          ? params.total_fund_value
          : "",
        valuation_date: params.valuation_date ? params.valuation_date : "",
        expression_of_wish: params.expression_of_wish
          ? params.expression_of_wish
          : "",
        expression_of_wishnote: params.expression_of_wishnote
          ? params.expression_of_wishnote
          : "",
        lump_sum_benefit: params.lump_sum_benefit
          ? params.lump_sum_benefit
          : "",
        sum_assured: params.sum_assured ? parseFloat(params.sum_assured) : "",
        periodic_benefit: params.periodic_benefit
          ? params.periodic_benefit
          : "",
        benefit_frequency: params.benefit_frequency
          ? params.benefit_frequency
          : "",
        benefits_indexed: params.benefits_indexed
          ? params.benefits_indexed
          : "",
        benefit_text: params.benefit_text ? params.benefit_text : "",
        in_trust: params.in_trust ? params.in_trust : "",
        in_trust_note: params.in_trust_note ? params.in_trust_note : "",
        admin_note: params.admin_note ? params.admin_note : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_note: params.provider_note ? params.provider_note : "",
      };
    } else if (params?.case_type === "Investments") {
      paramVal = {
        ...paramVal,
        policy_holder:
          mode == "view"
            ? getClientType(params.policy_holder)
            : params.policy_holder,
        assured_detail: params.assured_detail ? params.assured_detail : "",
        assured_name1: params.assured_name1 ? params.assured_name1 : "",
        assured_name2: params.assured_name2 ? params.assured_name2 : "",
        subtype: params.subtype ? params.subtype : "",
        provider: params.provider ? params.provider : "",
        provider_correspondence: params.provider_correspondence
          ? activePolicyDetail?.provider_name
          : "",
        surrender_value: params.surrender_value ? params.surrender_value : "",
        purpose: params.purpose ? params.purpose : "",
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        term: params.term ? params.term : "",
        scheme_name: params.scheme_name ? params.scheme_name : "",
        policy_number: params.policy_number ? params.policy_number : "",
        other_reference: params.other_reference ? params.other_reference : "",
        status: params.status ? params.status : "",
        status_date: params.status_date ? params.status_date : "",
        service_status: params.service_status ? params.service_status : "",
        premium_indexed: params.premium_indexed ? params.premium_indexed : "",
        waiver: params.waiver ? params.waiver : "",
        transfer_value: parseInt(params.transfer_value) || 0,
        future_value: params.future_value ? params.future_value : "",
        total_fund_value: params.total_fund_value
          ? params.total_fund_value
          : "",
        valuation_date: params.valuation_date ? params.valuation_date : "",
        lump_sum_benefit: params.lump_sum_benefit
          ? params.lump_sum_benefit
          : "",
        periodic_benefit: params.periodic_benefit
          ? params.periodic_benefit
          : "",
        benefit_frequency: params.benefit_frequency
          ? params.benefit_frequency
          : "",
        benefits_indexed: params.benefits_indexed
          ? params.benefits_indexed
          : "",
        benefit_text: params.benefit_text ? params.benefit_text : "",
        in_trust: params.in_trust ? params.in_trust : "",
        in_trust_note: params.in_trust_note ? params.in_trust_note : "",
        age_admitted: params.age_admitted ? params.age_admitted : "",
        assigned_to: params.assigned_to ? params.assigned_to : "",
        admin_note: params.admin_note ? params.admin_note : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_note: params.provider_note ? params.provider_note : "",
        sum_assured: params.sum_assured ? parseFloat(params.sum_assured) : "",
      };
    } else if (params?.case_type === "Savings Plans") {
      paramVal = {
        ...paramVal,
        policy_holder:
          mode == "view"
            ? getClientType(params.policy_holder)
            : params.policy_holder,
        assured_detail: params.assured_detail ? params.assured_detail : "",
        assured_name1: params.assured_name1 ? params.assured_name1 : "",
        assured_name2: params.assured_name2 ? params.assured_name2 : "",
        subtype: params.subtype ? params.subtype : "",
        provider: params.provider ? params.provider : "",
        provider_correspondence: params.provider_correspondence
          ? activePolicyDetail?.provider_name
          : "",
        purpose: params.purpose ? params.purpose : "",
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        term: params.term ? params.term : "",
        scheme_name: params.scheme_name ? params.scheme_name : "",
        policy_number: params.policy_number ? params.policy_number : "",
        other_reference: params.other_reference ? params.other_reference : "",
        status: params.status ? params.status : "",
        status_date: params.status_date ? params.status_date : "",
        service_status: params.service_status ? params.service_status : "",
        premium_indexed: params.premium_indexed ? params.premium_indexed : "",
        waiver: params.waiver ? params.waiver : "",
        surrender_value: params.surrender_value ? params.surrender_value : "",
        future_value: params.future_value ? params.future_value : "",
        total_fund_value: params.total_fund_value
          ? params.total_fund_value
          : "",
        valuation_date: params.valuation_date ? params.valuation_date : "",
        lump_sum_benefit: params.lump_sum_benefit
          ? params.lump_sum_benefit
          : "",
        periodic_benefit: params.periodic_benefit
          ? params.periodic_benefit
          : "",
        benefit_frequency: params.benefit_frequency
          ? params.benefit_frequency
          : "",
        benefits_indexed: params.benefits_indexed
          ? params.benefits_indexed
          : "",
        benefit_text: params.benefit_text ? params.benefit_text : "",
        rated: params.rated ? params.rated : "",
        in_trust: params.in_trust ? params.in_trust : "",
        in_trust_note: params.in_trust_note ? params.in_trust_note : "",
        age_admitted: params.age_admitted ? params.age_admitted : "",
        assigned_to: params.assigned_to ? params.assigned_to : "",
        admin_note: params.admin_note ? params.admin_note : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_note: params.provider_note ? params.provider_note : "",
        sum_assured: params.sum_assured ? parseFloat(params.sum_assured) : "",
      };
    } else if (params?.case_type === "Income Protection") {
      paramVal = {
        ...paramVal,
        policy_holder:
          mode == "view"
            ? getClientType(params.policy_holder)
            : params.policy_holder,
        assured_detail: params.assured_detail ? params.assured_detail : "",
        assured_name1: params.assured_name1 ? params.assured_name1 : "",
        assured_name2: params.assured_name2 ? params.assured_name2 : "",
        subtype: params.subtype ? params.subtype : "",
        provider: params.provider ? params.provider : "",
        provider_correspondence: params.provider_correspondence
          ? activePolicyDetail?.provider_name
          : "",
        purpose: params.purpose ? params.purpose : "",
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        term: params.term ? params.term : "",
        scheme_name: params.scheme_name ? params.scheme_name : "",
        policy_number: params.policy_number ? params.policy_number : "",
        other_reference: params.other_reference ? params.other_reference : "",
        status: params.status ? params.status : "",
        status_date: params.status_date ? params.status_date : "",
        service_status: params.service_status ? params.service_status : "",
        premium_indexed: params.premium_indexed ? params.premium_indexed : "",
        waiver: params.waiver ? params.waiver : "",
        total_fund_value: params.total_fund_value
          ? params.total_fund_value
          : "",
        valuation_date: params.valuation_date ? params.valuation_date : "",
        lump_sum_benefit: params.lump_sum_benefit
          ? params.lump_sum_benefit
          : "",
        periodic_benefit: params.periodic_benefit
          ? params.periodic_benefit
          : "",
        benefit_frequency: params.benefit_frequency
          ? params.benefit_frequency
          : "",
        benefits_indexed: params.benefits_indexed
          ? params.benefits_indexed
          : "",
        benefit_text: params.benefit_text ? params.benefit_text : "",
        rated: params.rated ? params.rated : "",
        in_trust: params.in_trust ? params.in_trust : "",
        in_trust_note: params.in_trust_note ? params.in_trust_note : "",
        age_admitted: params.age_admitted ? params.age_admitted : "",
        assigned_to: params.assigned_to ? params.assigned_to : "",
        deffered_period: params.deffered_period ? params.deffered_period : "",
        admin_note: params.admin_note ? params.admin_note : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_note: params.provider_note ? params.provider_note : "",
        sum_assured: params.sum_assured ? parseFloat(params.sum_assured) : "",
      };
    } else if (params?.case_type === "Health Assurance") {
      paramVal = {
        ...paramVal,
        policy_holder:
          mode == "view"
            ? getClientType(params.policy_holder)
            : params.policy_holder,
        assured_detail: params.assured_detail ? params.assured_detail : "",
        assured_name1: params.assured_name1 ? params.assured_name1 : "",
        assured_name2: params.assured_name2 ? params.assured_name2 : "",
        subtype: params.subtype ? params.subtype : "",
        provider: params.provider ? params.provider : "",
        provider_correspondence: params.provider_correspondence
          ? activePolicyDetail?.provider_name
          : "",
        purpose: params.purpose ? params.purpose : "",
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        term: params.term ? params.term : "",
        scheme_name: params.scheme_name ? params.scheme_name : "",
        policy_number: params.policy_number ? params.policy_number : "",
        other_reference: params.other_reference ? params.other_reference : "",
        status: params.status ? params.status : "",
        status_date: params.status_date ? params.status_date : "",
        service_status: params.service_status ? params.service_status : "",
        premium_indexed: params.premium_indexed ? params.premium_indexed : "",
        waiver: params.waiver ? params.waiver : "",
        lump_sum_benefit: params.lump_sum_benefit
          ? params.lump_sum_benefit
          : "",
        periodic_benefit: params.periodic_benefit
          ? params.periodic_benefit
          : "",
        benefit_frequency: params.benefit_frequency
          ? params.benefit_frequency
          : "",
        benefits_indexed: params.benefits_indexed
          ? params.benefits_indexed
          : "",
        benefit_text: params.benefit_text ? params.benefit_text : "",
        rated: params.rated ? params.rated : "",
        in_trust: params.in_trust ? params.in_trust : "",
        in_trust_note: params.in_trust_note ? params.in_trust_note : "",
        assigned_to: params.assigned_to ? params.assigned_to : "",
        admin_note: params.admin_note ? params.admin_note : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_note: params.provider_note ? params.provider_note : "",
        sum_assured: params.sum_assured ? parseFloat(params.sum_assured) : "",
      };
    } else if (params?.case_type === "General") {
      paramVal = {
        ...paramVal,
        policy_holder:
          mode == "view"
            ? getClientType(params.policy_holder)
            : params.policy_holder,
        assured_detail: params.assured_detail ? params.assured_detail : "",
        assured_name1: params.assured_name1 ? params.assured_name1 : "",
        assured_name2: params.assured_name2 ? params.assured_name2 : "",
        subtype: params.subtype ? params.subtype : "",
        provider: params.provider ? params.provider : "",
        provider_correspondence: params.provider_correspondence
          ? activePolicyDetail?.provider_name
          : "",
        purpose: params.purpose ? params.purpose : "",
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        term: params.term ? params.term : "",
        scheme_name: params.scheme_name ? params.scheme_name : "",
        policy_number: params.policy_number ? params.policy_number : "",
        other_reference: params.other_reference ? params.other_reference : "",
        status: params.status ? params.status : "",
        status_date: params.status_date ? params.status_date : "",
        service_status: params.service_status ? params.service_status : "",
        premium_indexed: params.premium_indexed ? params.premium_indexed : "",
        waiver: params.waiver ? params.waiver : "",
        lump_sum_benefit: params.lump_sum_benefit
          ? params.lump_sum_benefit
          : "",
        periodic_benefit: params.periodic_benefit
          ? params.periodic_benefit
          : "",
        benefit_frequency: params.benefit_frequency
          ? params.benefit_frequency
          : "",
        benefits_indexed: params.benefits_indexed
          ? params.benefits_indexed
          : "",
        benefit_text: params.benefit_text ? params.benefit_text : "",
        in_trust: params.in_trust ? params.in_trust : "",
        in_trust_note: params.in_trust_note ? params.in_trust_note : "",
        assigned_to: params.assigned_to ? params.assigned_to : "",
        admin_note: params.admin_note ? params.admin_note : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_note: params.provider_note ? params.provider_note : "",
        sum_assured: params.sum_assured ? parseFloat(params.sum_assured) : "",
      };
    }
    return paramVal;
  };
  const setPolicyReviewFieldValues = (params: any) => {
    let paramVal = {};
    paramVal = {
      ...paramVal,

      review_assigned: params.review_assigned ? params.review_assigned : "",
      review_completed: params.review_completed ? params.review_completed : "",
      review_date: params.review_date ? params.review_date : "",
      review_interval: params.review_interval ? params.review_interval : "",
      review_reason: params.review_reason ? params.review_reason : "",
    };
    return paramVal;
  };
  const setPaymentFieldValues = (params: any) => {
    let paymentParamVal = {};
    if (params?.payment !== undefined) {
      paymentParamVal = {
        ...paymentParamVal,
        start_date: params.payment.start_date ? params.payment.start_date : "",
        end_date: params.payment.end_date ? params.payment.end_date : "",
        amount: params.payment.amount ? params.payment.amount : "",
        frequency: params.payment.frequency ? params.payment.frequency : "",
        new_business_register: params.payment.new_business_register
          ? params.payment.new_business_register
          : "",
        reinvestment: params.payment.reinvestment
          ? params.payment.reinvestment
          : "",
        payer: params.payment.payer ? params.payment.payer : "",
        note_text: params.payment.note_text ? params.payment.note_text : "",
        payment_type: params.payment.payment_type
          ? params.payment.payment_type
          : "",
      };
    }
    return paymentParamVal;
  };
  const setPaymentComplainceFieldValues = (params: any) => {
    let paymentComplainceParamVal = {};
    if (params.payment_complience !== undefined) {
      paymentComplainceParamVal = {
        ...params.payment_complience,
        consultant: params.payment_complience.consultant
          ? params.payment_complience.consultant
          : "",
      };
    }
    return paymentComplainceParamVal;
  };
  const setcommissionFieldValues = (params: any) => {
    let ParamVal = {};
    if (params !== undefined) {
      ParamVal = {
        ...ParamVal,
        status: params.status ? params.status : "",
        commission_type: params.commission_type ? params.commission_type : "",
        payment_source: params.payment_source ? params.payment_source : "",
        frequency: params.frequency ? params.frequency : "",
        amount_to_company: params.amount_to_company
          ? params.amount_to_company
          : "",
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        nb_register:
          mode == "view"
            ? getYes_NoConversion(params.nb_register)
            : params.nb_register,
        payment_count: params.payment_count ? params.payment_count : "",
        note_text: params.note_text ? params.note_text : "",
      };
    }
    return ParamVal;
  };
  const setWithdrawalsFieldValues = (params: any) => {
    let ParamVal = {};
    if (params !== undefined) {
      ParamVal = {
        ...ParamVal,
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        amount: params.amount ? params.amount : "",
        frequency: params.frequency ? params.frequency : "",
        high_risk: params.high_risk ? params.high_risk : "",
        high_risk_note: params.high_risk_note ? params.high_risk_note : "",
        reinvestment: params.reinvestment ? params.reinvestment : "",
        note_text: params.note_text ? params.note_text : "",
      };
    }

    return ParamVal;
  };
  const setValuationsFieldValues = (params: any) => {
    let ParamVal = {};
    if (params !== undefined) {
      ParamVal = {
        ...ParamVal,

        effective_date: params.effective_date ? params.effective_date : "",
        valuation_amount: params.valuation_amount
          ? params.valuation_amount
          : "",

        note_text: params.note_text ? params.note_text : "",
      };
    }
    return ParamVal;
  };
  const setActionsFieldValues = (params: any) => {
    let ParamVal = {};
    if (params !== undefined) {
      ParamVal = {
        ...ParamVal,
        business_date: params.business_date ? params.business_date : "",
        business_action: params.business_action ? params.business_action : "",
        business_assigned: params.business_assigned
          ? params.business_assigned
          : "",
        business_status: params.business_status ? params.business_status : "",
      };
    }
    return ParamVal;
  };
  const setFundFieldValues = (params: any) => {
    let ParamVal = {};
    if (params !== undefined) {
      ParamVal = {
        ...ParamVal,
        fund_code: params.fund_code ? params.fund_code : "",
        fund_code_source:
          detailPageMode === "view"
            ? getAssetFundCodeSource(params.fund_code_source)
            : params?.fund_code_source?.toString(),
        fund_name: params.fund_name ? params.fund_name : "",
        fund_units: params.fund_units ? parseFloat(params.fund_units) : "",
        unit_price: params.unit_price ? parseFloat(params.unit_price) : 0.0,
        currency_code: params.currency_code ? params.currency_code : "",
        price_date: params.price_date ? params.price_date : "",
        fund_value: params.fund_value ? parseFloat(params.fund_value) : "",
        sector: params.sector ? params.sector : "",
        total_expense_ratio: params.total_expense_ratio
          ? parseFloat(params.total_expense_ratio)
          : "",
        risk_rating: params.risk_rating ? params.risk_rating : "",
      };
    }
    return ParamVal;
  };
  const addLoadStandardActions = () => {
    if (selectedLoadStandardActions.length > 0) {
      let case_type_value = (activeItem as { [key: string]: any }).case_type;
      let contextVal = contextList[case_type_value]["actions"];

      if (formSettingsObj && formSettingsObj.data) {
        let configObj: any = checkIfKeyExist(
          formSettingsObj.data.formConfig,
          contextVal
        );
        if (
          configObj &&
          configObj.renderForm &&
          configObj.renderForm?.reference_id
        ) {
          let actReq: any = {};
          actReq[configObj.renderForm?.reference_id] = {
            customer_id: props.activeCustomerId,
            case_id: activeItem.policy_id,
            effective_date: formatedDate2(loadStandardActionDate),
            tracking_case_actions: [],
          };
          let selAct: any = [];
          selectedLoadStandardActions.map((v: any) => {
            selAct.push({
              tracking_id: v,
            });
          });
          actReq[configObj.renderForm?.reference_id]["tracking_case_actions"] =
            selAct;
          setLoading(true);
          apiServices.tackingActions
            .addStandardActions(actReq)
            .then((response) => {
              setLoading(false);
              if (response.data && response.data.data) {
                setSelectedLoadStandardActions([]);
                getPolicySubItems();
                setLoadStandardActionPopup(false);
                setLoadStandardActionDate(new Date());
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
            });
        }
      }
    }
  };

  const loadActionSelected = (action: any) => {
    let act: any = [...selectedLoadStandardActions];

    const index = act.indexOf(action);
    if (index !== -1) {
      act.splice(index, 1);
    } else {
      act.push(action);
    }
    // setSelectedActions(act);
    setSelectedLoadStandardActions(act);
  };

  return (
    <div className="form-container">
      {/* <OverlayLoader isLoading={loading} /> */}

      {policyList.length === 0 && (formContext === "" || mode === "") && (
        <>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              marginBottom: "10px",
              justifyContent: "flex-end",
              marginRight: "10px",
            }}
          >
            {checkUserPermission(
              loggedUser,
              UserPermissionsList.ALLOW_UPDATE_CLIENT
            ) && (
              <Button
                appearance="primary"
                className="asc-button-primary"
                icon={<Add16Filled />}
                size="small"
                onClick={() => {
                  setPolicyCatPopup(true);
                }}
              >
                Add Policy
              </Button>
            )}
          </div>
        </>
      )}
      {formContext !== "" && mode !== "" && (
        <>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className={styles.btnWrapper}>
                {activeItem &&
                  Object.keys(activeItem).length !== 0 &&
                  currentPage !== "listing" && (
                    <>
                      <Button
                        shape="square"
                        size="small"
                        icon={<ChevronLeft20Regular />}
                        onClick={() => {
                          setPolicyActiveTabLevel2("");
                          setDetailFormContext("");
                          setPolicySubitemsList([]);
                          setCurrentPage("listing");
                          setDetailPageSelectedRowIndex(0);
                        }}
                      >
                        <span style={{ color: "red" }}>Back to Policy</span>
                      </Button>
                    </>
                  )}
                {!convertStringToArray(
                  process.env.REACT_APP_HIDE_CTA_BUTTON
                ).includes("policy_fund_hide_button") ? (
                  <Button
                    shape="square"
                    size="small"
                    style={{
                      background:
                        policyActiveTabLevel2 === "fund" ? "black" : undefined,
                      color:
                        policyActiveTabLevel2 === "fund" ? "white" : undefined,
                    }}
                    onClick={() => {
                      selectActivePolicyItem("fund");
                    }}
                    disabled={
                      !convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("policy_fund_disable_button") &&
                      activeItem &&
                      Object.keys(activeItem).length !== 0
                        ? false
                        : true
                    }
                  >
                    Fund
                  </Button>
                ) : (
                  ""
                )}

                {!convertStringToArray(
                  process.env.REACT_APP_HIDE_CTA_BUTTON
                ).includes("policy_payment_hide_button") ? (
                  <Button
                    shape="square"
                    size="small"
                    style={{
                      background:
                        policyActiveTabLevel2 === "payment"
                          ? "black"
                          : undefined,
                      color:
                        policyActiveTabLevel2 === "payment"
                          ? "white"
                          : undefined,
                    }}
                    onClick={() => {
                      selectActivePolicyItem("payment");
                    }}
                    disabled={
                      !convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("policy_payment_disable_button") &&
                      activeItem &&
                      Object.keys(activeItem).length !== 0
                        ? false
                        : true
                    }
                  >
                    Payments
                  </Button>
                ) : (
                  ""
                )}
                {!convertStringToArray(
                  process.env.REACT_APP_HIDE_CTA_BUTTON
                ).includes("policy_withdrawal_hide_button") ? (
                  <Button
                    shape="square"
                    size="small"
                    style={{
                      background:
                        policyActiveTabLevel2 === "withdrawals"
                          ? "black"
                          : undefined,
                      color:
                        policyActiveTabLevel2 === "withdrawals"
                          ? "white"
                          : undefined,
                    }}
                    onClick={() => {
                      selectActivePolicyItem("withdrawals");
                    }}
                    disabled={
                      !convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("policy_withdrawal_disable_button") &&
                      activeItem &&
                      Object.keys(activeItem).length !== 0
                        ? false
                        : true
                    }
                  >
                    Withdrawals
                  </Button>
                ) : (
                  ""
                )}

                {!convertStringToArray(
                  process.env.REACT_APP_HIDE_CTA_BUTTON
                ).includes("policy_action_hide_button") ? (
                  <Button
                    shape="square"
                    size="small"
                    style={{
                      background:
                        policyActiveTabLevel2 === "actions"
                          ? "black"
                          : undefined,
                      color:
                        policyActiveTabLevel2 === "actions"
                          ? "white"
                          : undefined,
                    }}
                    onClick={() => {
                      selectActivePolicyItem("actions");
                    }}
                    disabled={
                      !convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("policy_action_disable_button") &&
                      activeItem &&
                      Object.keys(activeItem).length !== 0
                        ? false
                        : true
                    }
                  >
                    Actions
                  </Button>
                ) : (
                  ""
                )}
                {!convertStringToArray(
                  process.env.REACT_APP_HIDE_CTA_BUTTON
                ).includes("policy_valuation_hide_button") ? (
                  <Button
                    shape="square"
                    size="small"
                    style={{
                      background:
                        policyActiveTabLevel2 === "valuations"
                          ? "black"
                          : undefined,
                      color:
                        policyActiveTabLevel2 === "valuations"
                          ? "white"
                          : undefined,
                    }}
                    onClick={() => {
                      selectActivePolicyItem("valuations");
                    }}
                    disabled={
                      !convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("policy_valuation_disable_button") &&
                      activeItem &&
                      Object.keys(activeItem).length !== 0
                        ? false
                        : true
                    }
                  >
                    Valuations
                  </Button>
                ) : (
                  ""
                )}
              </div>
              {currentPage && currentPage === "listing" && mode === "view" && (
                <div className={styles.btnWrapper}>
                  {checkUserPermission(
                    loggedUser,
                    UserPermissionsList.ALLOW_UPDATE_CLIENT
                  ) && (
                    <Button
                      appearance="primary"
                      className="asc-button-primary"
                      icon={<Add16Filled />}
                      size="small"
                      onClick={() => {
                        setPolicyCatPopup(true);
                      }}
                      disabled={
                        activePolicyTab === "review"
                          ? true
                            ? policyType !== "" &&
                              !["edit", "view"].includes(mode)
                              ? false
                              : true
                            : false
                          : false
                      }
                    >
                      Add Policy
                    </Button>
                  )}
                </div>
              )}
              {currentPage && currentPage === "detail" && (
                <div className={styles.btnWrapper}>
                  {/* <Button
                    size="small"
                    appearance="primary"
                    icon={<Add20Regular />}
                    onClick={() => {
                      setDetailFormContext(
                        contextList[policyType][policyActiveTabLevel2]
                      );
                      // getContextDetails(policyType, policyActiveTabLevel2);
                    }}
                  >
                    {camalize(`add ${policyActiveTabLevel2}`)}
                  </Button> */}
                  {
                    //   policyActiveTabLevel2 === "fund" && (
                    //   <Button
                    //     size="small"
                    //     appearance="outline"
                    //     icon={<DocumentFolder20Regular />}
                    //   >
                    //     Locate Fund
                    //   </Button>
                    // )
                  }
                  {policyActiveTabLevel2 === "actions" && (
                    <>
                      {checkUserPermission(
                        loggedUser,
                        UserPermissionsList.ALLOW_UPDATE_CLIENT
                      ) &&
                        !convertStringToArray(
                          process.env.REACT_APP_HIDE_CTA_BUTTON
                        ).includes("policy_load_standard_action_hide") && (
                          <Button
                            size="small"
                            appearance="outline"
                            disabled={convertStringToArray(
                              process.env.REACT_APP_DISABLE_CTA_BUTTON
                            ).includes("policy_load_standard_action_disable")}
                            onClick={() => {
                              setLoadStandardActionPopup(true);
                              // dispatch(
                              //   setDialogModalOptions({
                              //     open: true,
                              //     load_action: true,
                              //     updateSelectedActions: updateSelectedActions,
                              //     customerId: props.activeCustomerId,
                              //     reload: () => {
                              //       setReloadRequired(reloadRequired + 1);
                              //     },
                              //     cancel: {
                              //       onclick: () => {
                              //         setSelectedActions([]);
                              //         dispatch(
                              //           setDialogModalOptions({ open: false })
                              //         );
                              //       },
                              //       label: "Close",
                              //     },
                              //     apply: {
                              //       onclick: () => {
                              //         addLoadStandardActions();
                              //         // dispatch(
                              //         //   setDialogModalOptions({ open: false })
                              //         // );
                              //       },
                              //       label: "Save",
                              //       type: "primary",
                              //     },
                              //   })
                              // );
                            }}
                          >
                            Load Standard Actions
                          </Button>
                        )}
                    </>
                  )}
                  {policyActiveTabLevel2 === "valuations" &&
                    !convertStringToArray(
                      process.env.REACT_APP_HIDE_CTA_BUTTON
                    ).includes("policy_use_case_fund_value") && (
                      <Button
                        size="small"
                        appearance="outline"
                        disabled={convertStringToArray(
                          process.env.REACT_APP_DISABLE_CTA_BUTTON
                        ).includes("policy_use_case_fund_value")}
                      >
                        Use Case Fund Value
                      </Button>
                    )}
                  {!convertStringToArray(
                    process.env.REACT_APP_HIDE_CTA_BUTTON
                  ).includes("policy_case_details_hide") ? (
                    <Button
                      size="small"
                      appearance="outline"
                      disabled={convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("policy_case_details_disable")}
                      icon={<DocumentBulletList20Regular />}
                      onClick={() => {
                        setIsOpen(true);
                      }}
                    >
                      Case Details
                    </Button>
                  ) : (
                    ""
                  )}

                  {/* <Button
                    size="small"
                    appearance="primary"
                    className="asc-button-primary"
                    icon={<Add20Regular />}
                    disabled={
                      policyActiveTabLevel2Subtab === "paymentcomplaince"
                        ? true
                        : false
                    }
                    onClick={() =>{
                      handleDetailFormContext(policyActiveTabLevel2);}
                      setDetailPageMode("add");
                    }}
                  >
                    {camalize(`add ${policyActiveTabLevel2}`)}
                  </Button> */}
                  {checkUserPermission(
                    loggedUser,
                    UserPermissionsList.ALLOW_UPDATE_CLIENT
                  ) && (
                    <Button
                      size="small"
                      appearance="primary"
                      className="asc-button-primary"
                      icon={<Add20Regular />}
                      disabled={
                        policyActiveTabLevel2Subtab === "paymentcomplaince"
                          ? true
                          : false
                      }
                      onClick={() => {
                        policyActiveTabLevel2Subtab === "paymentCommission"
                          ? handleDetailFormContext(policyActiveTabLevel2Subtab)
                          : handleDetailFormContext(policyActiveTabLevel2);
                        setDetailPageMode("add");
                      }}
                    >
                      {policyActiveTabLevel2Subtab === "paymentCommission"
                        ? "Add Commission"
                        : camalize(`Add ${policyActiveTabLevel2}`)}
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className={styles.tabList}>
            {currentPage === "listing" && (
              <TabList selectedValue={activePolicyTab}>
                {!convertStringToArray(
                  process.env.REACT_APP_HIDE_CTA_BUTTON
                ).includes("policy_policy_hide_button") ? (
                  <Tab
                    value="policy"
                    onClick={() => {
                      setActivePolicyTab("policy");
                    }}
                    disabled={
                      !convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("policy_policy_disable_button")
                        ? false
                        : true
                    }
                  >
                    Policy
                  </Tab>
                ) : (
                  ""
                )}

                {!convertStringToArray(
                  process.env.REACT_APP_HIDE_CTA_BUTTON
                ).includes("policy_review_hide_button") ? (
                  <Tab
                    value="review"
                    onClick={() => {
                      setActivePolicyTab("review");
                      // showReviewForm();
                    }}
                    disabled={
                      !convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("policy_review_disable_button") &&
                      activeItem &&
                      Object.keys(activeItem).length !== 0
                        ? false
                        : true
                    }
                  >
                    Review
                  </Tab>
                ) : (
                  ""
                )}

                {!convertStringToArray(
                  process.env.REACT_APP_HIDE_CTA_BUTTON
                ).includes("policy_contract_enquiry_hide_button") ? (
                  <Tab
                    value="contract-enquiry"
                    disabled={
                      !convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("policy_contract_enquiry_disable_button") &&
                      activeItem &&
                      Object.keys(activeItem).length !== 0
                        ? true
                        : true
                    }
                    onClick={() => {
                      setActivePolicyTab("contract-enquiry");
                    }}
                  >
                    Contract Enquiry
                  </Tab>
                ) : (
                  ""
                )}
              </TabList>
            )}
            {currentPage === "detail" && (
              <>
                {policyActiveTabLevel2 === "payment" && (
                  <TabList selectedValue={policyActiveTabLevel2Subtab}>
                    {!convertStringToArray(
                      process.env.REACT_APP_HIDE_CTA_BUTTON
                    ).includes("policy_payment_payment_hide_button") ? (
                      <Tab
                        value="payment"
                        onClick={() => {
                          setPolicyActiveTabLevel2Subtab("payment");
                        }}
                        disabled={
                          !convertStringToArray(
                            process.env.REACT_APP_DISABLE_CTA_BUTTON
                          ).includes("policy_payment_payment_disable_button")
                            ? false
                            : true
                        }
                      >
                        Payment
                      </Tab>
                    ) : (
                      ""
                    )}

                    {!convertStringToArray(
                      process.env.REACT_APP_HIDE_CTA_BUTTON
                    ).includes("policy_payment_compliance_hide_button") ? (
                      <Tab
                        value="paymentcomplaince"
                        onClick={() => {
                          setPolicyActiveTabLevel2Subtab("paymentcomplaince");
                        }}
                        disabled={
                          !convertStringToArray(
                            process.env.REACT_APP_DISABLE_CTA_BUTTON
                          ).includes(
                            "policy_payment_compliance_disable_button"
                          ) &&
                          policySubActiveItem &&
                          Object.keys(policySubActiveItem).length !== 0
                            ? false
                            : true
                        }
                      >
                        Compliance
                      </Tab>
                    ) : (
                      ""
                    )}

                    {!convertStringToArray(
                      process.env.REACT_APP_HIDE_CTA_BUTTON
                    ).includes("policy_payment_commission_hide_button") ? (
                      <Tab
                        value="paymentCommission"
                        onClick={() => {
                          setPolicyActiveTabLevel2Subtab("paymentCommission");
                        }}
                        disabled={
                          !convertStringToArray(
                            process.env.REACT_APP_DISABLE_CTA_BUTTON
                          ).includes(
                            "policy_payment_commission_disable_button"
                          ) &&
                          policySubActiveItem &&
                          Object.keys(policySubActiveItem).length !== 0
                            ? false
                            : true
                        }
                      >
                        Commission
                      </Tab>
                    ) : (
                      ""
                    )}
                  </TabList>
                )}
                {policyActiveTabLevel2 === "withdrawals" && (
                  <TabList defaultSelectedValue="tab1">
                    <Tab value="tab1">Withdrawals</Tab>
                  </TabList>
                )}
                {policyActiveTabLevel2 === "valuations" && (
                  <TabList defaultSelectedValue="tab1">
                    <Tab value="tab1">Valuations</Tab>
                  </TabList>
                )}
              </>
            )}
          </div>
          <div className="form-container">
            {currentPage === "listing" && mode !== "" ? (
              <FormContainer
                {...{
                  context: formContext,
                  defaultAction,
                  setFormContext,
                  setPolicyType,
                  currentPage,
                  setPolicyList,
                  mode,
                  setMode,
                  initialFormData,
                  handleReloadList,
                  activeItem,
                  activeCustomerId: props.activeCustomerId,
                  policyType,
                  notify: props.notify,
                  handleRowSelection,
                  policyList,
                  currentRecord,
                  caseDetails: activePolicyDetail,
                  activePartnerId: props.activePartnerId,
                  setActivePartnerId: props.setActivePartnerId,
                }}
              />
            ) : (
              <>
                {policyActiveTabLevel2Subtab === "paymentCommission" ? (
                  <>
                    {policySubitemsList.length === 0 &&
                      detailFormContext === "" &&
                      detailPageMode === "" && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "200px",
                          }}
                        >
                          <Label size="large" style={{ color: "red" }}>
                            There are no active records - click{" "}
                            {camalize(`add commission`)} to create a new record
                          </Label>
                        </div>
                      )}

                    <FormContainerDetailPage
                      {...{
                        context: detailFormContext,
                        setDetailFormContext,
                        activeItem,
                        mode: detailPageMode,
                        initialFormData: initialDetailPageFormData,
                        activeCustomerId: props.activeCustomerId,
                        policyType,
                        setMode: setDetailPageMode,
                        detailPageMode,
                        setDetailPageMode,
                        policyActiveTabLevel2,
                        handleReloadSublist,
                        notify: props.notify,
                        detailPageSelectedRowIndex,
                        policySubitemsList,
                        policySubActiveItem,
                        currentSubRecord,
                        policyActiveTabLevel2Subtab,
                        activePaymentItem,
                        fundValue,
                        paymentValue,
                        withdrawalValue,
                        activePartnerId: props.activePartnerId,
                        setActivePartnerId: props.setActivePartnerId,
                      }}
                    />
                  </>
                ) : (
                  currentPage === "detail" &&
                  detailPageMode !== "" && (
                    <FormContainerDetailPage
                      {...{
                        context: detailFormContext,
                        setDetailFormContext,
                        activeItem,
                        mode: detailPageMode,
                        initialFormData: initialDetailPageFormData,
                        activeCustomerId: props.activeCustomerId,
                        policyType,
                        detailPageMode,
                        setDetailPageMode,
                        policyActiveTabLevel2,
                        setMode: setDetailPageMode,
                        handleReloadSublist,
                        notify: props.notify,
                        detailPageSelectedRowIndex,
                        policySubitemsList,
                        policySubActiveItem,
                        currentSubRecord,
                        policyActiveTabLevel2Subtab,
                        activePaymentItem,
                        fundValue,
                        paymentValue,
                        withdrawalValue,
                        activePartnerId: props.activePartnerId,
                        setActivePartnerId: props.setActivePartnerId,
                      }}
                    />
                  )
                )}
              </>
            )}
          </div>
        </>
      )}
      {!subItemLoading && currentPage === "listing" && policyList.length > 0 ? (
        <>
          <div style={{ margin: 6 }}>
            <PolicyListItems
              {...{
                context: formContext,
                data: policyList,
                activeItem: activeItem,
                handleRowAction,
                mode,
                policyRowSelection,
                handleRowSelection,
                setCurrentRecord,
              }}
            />
          </div>
        </>
      ) : (
        mode === "" && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 200,
            }}
          >
            <Label size="large" style={{ color: "red" }}>
              There are no active records - click Add Policy to create a new
              record
            </Label>
          </div>
        )
      )}
      {!subItemLoading &&
      currentPage === "detail" &&
      policySubitemsList.length > 0 ? (
        <PolicyDetails
          {...{
            context: detailFormContext,
            data: policySubitemsList,
            policyActiveTabLevel2,
            policyActiveTabLevel2Subtab,
            handleDetailFormContext,
            detailPageMode,
            setDetailPageMode,
            handleRowAction,
            policySubActiveItem,
            handleDetailPageRowSelection,
            detailPageSelectedRowIndex,
            setCurrentSubRecord,
          }}
        />
      ) : (
        currentPage === "detail" &&
        detailPageMode === "" &&
        policyActiveTabLevel2Subtab !== "paymentCommission" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <Label size="large" style={{ color: "red" }}>
              There are no active records - click{" "}
              {camalize(`add ${policyActiveTabLevel2}`)} to create a new record
            </Label>
          </div>
        )
      )}
      <OverlayDrawer
        position={"end"}
        open={isOpen}
        onOpenChange={(_, { open }) => setIsOpen(open)}
        className={"common-drawer"}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => setIsOpen(false)}
              />
            }
          >
            Case Details
          </DrawerHeaderTitle>
        </DrawerHeader>
        <Divider className={styles.divider} />

        <DrawerBody>{CaseDetails(activeItem)}</DrawerBody>
      </OverlayDrawer>
      <Dialog open={PolicyCatPopup} modalType="modal">
        <DialogSurface>
          <DialogBody>
            <DialogContent>
              <div className={styles.field}>
                <Label id={labelId} weight="semibold">
                  Select Policy Category
                </Label>
                <RadioGroup
                  // role="radiogroup"
                  value={selectedPolicyCatPopup}
                  aria-labelledby={labelId}
                  className="radioItems"
                  onChange={(_, data) => {
                    setSelectedPolicyCatPopup(data.value);
                  }}
                >
                  <Radio
                    name={radioName}
                    value="Life Assurance"
                    label="Life Assurance"
                  />
                  <Radio name={radioName} value="Pensions" label="Pensions" />
                  <Radio
                    name={radioName}
                    value="Investments"
                    label="Investments"
                  />
                  <Radio
                    name={radioName}
                    value="Savings Plans"
                    label="Savings Plans"
                  />
                  <Radio
                    name={radioName}
                    value="Income Protection"
                    label="Income Protection"
                  />
                  <Radio
                    name={radioName}
                    value="Health Assurance"
                    label="Health Assurance"
                  />
                  <Radio name={radioName} value="General" label="General" />
                </RadioGroup>
              </div>
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="secondary"
                  size="small"
                  onClick={() => {
                    setSelectedPolicyCatPopup("");
                    setPolicyCatPopup(false);
                  }}
                >
                  Close
                </Button>
              </DialogTrigger>
              <Button
                appearance="primary"
                className="asc-button-primary"
                size="small"
                onClick={() => {
                  if (selectedPolicyCatPopup !== "") {
                    setMode("add");
                    setPolicyType(selectedPolicyCatPopup);
                    setPolicyCatPopup(false);

                    getContextDetails(
                      selectedPolicyCatPopup,
                      policyActiveTabLevel1
                    );
                    setSelectedPolicyCatPopup("");
                  }
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      <Dialog open={loadStandardActionPopup} modalType="modal">
        <DialogSurface>
          <DialogBody>
            <DialogContent>
              <LoadStandardAction
                loadStandardActionDate={loadStandardActionDate}
                setLoadStandardActionDate={setLoadStandardActionDate}
                selectedLoadStandardActions={selectedLoadStandardActions}
                loadActionSelected={loadActionSelected}
                type="case"
              />
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="secondary"
                  size="small"
                  onClick={() => {
                    setSelectedLoadStandardActions([]);
                    setLoadStandardActionPopup(false);
                  }}
                >
                  Close
                </Button>
              </DialogTrigger>
              <Button
                appearance="primary"
                className="asc-button-primary"
                size="small"
                onClick={addLoadStandardActions}
              >
                Submit
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
};

export default PolicyForm;