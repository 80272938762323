import React, { useEffect, useState, ChangeEvent } from "react";
import "../style.css";
import {
  Button,
  Label,
  Field,
  Select,
  useId,
  Input,
  makeStyles,
} from "@fluentui/react-components";
import moment from "moment";
import {
  ArrowUndo16Regular,
  ArrowRedo16Regular,
  Add16Filled,
  Edit24Filled,
  Delete16Regular,
} from "@fluentui/react-icons";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { useStyles } from "./helper";
import TextEditor from "../../../components/TextEditor";
import ShowMoreText from "react-show-more-text";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setDialogModalOptions } from "../../../redux/modelDialogReducer";
import apiServices from "../../../service";
import VulnerabilityTable from "./VulnerabilityTable";
import FormikInputRequest from "../../../service/hook/useFormik";
import { formatedDate, formatedDate2 } from "../../../utils";
import OverlayLoader from "../../../components/OverlayLoader";
import ReactQuillComponent from "../../../components/ReactQuillComponent";
import { setFormData, resetFormData } from "../../../redux/formUpdateReducer";

interface CharacteristicProps {
  [key: string]: any;
}
function VulnerabilityForm(props: any) {
  let { context } = props;
  const classes = useStyles();
  const selectId = useId();
  const [formMode, setFormMode] = useState(props.mode === "add" ? "New" : "");
  // const [vulnerabilityData, setVulnerabilityData] = useState([]);

  const [dataList, setDataList] = useState<{ [key: string]: any }[]>([]);
  const dispatch: AppDispatch = useDispatch();
  const [mode, setMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [nextAction, setNextAction] = useState("");

  const [currentRecord, setCurrentRecord] = useState(1);
  const [selectedItem, setSelectedItem] = useState<any | {}>({});
  const [initialFormData, setInitialFormData] = useState({});

  const [selectedRowItem, setSelectedRowItem] = useState<{
    [key: string]: any;
  }>({});
  const isFormupdated = useSelector(
    (state: RootState) => state.formUpdated.isFormUpdated
  );
  // const [formupdated, setFormupdated] = useState(false);
  const onFormatDate = (date?: Date): string => {
    return !date ? "" : moment(date).format("DD/MM/YYYY");
  };
  const changeMode = (mode: string) => {
    setMode(mode);
    if (mode === "edit") {
      formikData.values = selectedRowItem;
    } else if (mode === "add") {
      formikData.resetForm();
    }
  };

  const formikData = FormikInputRequest({
    initialValues: {
      vulnerabilitydriver_id: "",
      vulnerabilitycharacteristic_id: "",
      note_text: "",
      ratings: "",
      vulnerability_date: null,
    },
    validate: (values) => {
      const errors: any = {};
      if (
        !values.vulnerabilitydriver_id ||
        values.vulnerabilitydriver_id === "0"
      ) {
        errors.vulnerabilitydriver_id = "Driver is required";
      }
      if (
        !values.vulnerabilitycharacteristic_id ||
        values.vulnerabilitydriver_id === "0"
      ) {
        errors.vulnerabilitycharacteristic_id = "Characteristics is required";
      }
      if (!values.note_text) {
        errors.note_text = "Note is required";
      } else if (values.note_text.length > 5000) {
        errors.note_text = "Note cannot be longer than 5000 characters";
      }
      if (isNaN(Number(values.ratings))) {
        errors.ratings = "Ratings must be a number";
      }
      return errors;
    },
    onSubmitFn: async (values: any) => {
      if (mode === "add") {
        try {
          let newData;
          newData = {
            vulnerability: {
              customer_id: props.activeCustomerId,
              vulnerabilitydriver_id: values.vulnerabilitydriver_id,
              ratings: values.ratings === "" ? 0 : Number(values.ratings) || 0,
              vulnerabilitycharacteristic_id:
                values.vulnerabilitycharacteristic_id,
              vulnerability_date:
                formatedDate2(values.vulnerability_date) || "",
              note_text: values.note_text,
            },
          };
          setLoading(true);
          apiServices.client.vulnerability
            .create(newData)
            .then((response: any) => {
              setLoading(false);
              if (!response.data.isError) {
                props.notify(response.data.message, "", "success");
                dispatch(resetFormData());
                getvulnerability(props.activeCustomerId);

                if (nextAction === "next") {
                  props.changeTab?.(props.tabMenuIndex.next);
                }
              } else {
                props.notify("Something went wrong", "", "error");
              }
            })
            .catch((error: Error) => {
              setLoading(false);
              console.log("error");
            });
        } catch (error) {
          console.error("Error adding folder:", error);
        }
      } else if (mode === "edit") {
        try {
          const newData = {
            vulnerability: {
              customer_id: props.activeCustomerId,
              vulnerabilitydriver_id: values.vulnerabilitydriver_id,
              ratings: values.ratings || 0,
              vulnerabilitycharacteristic_id:
                values.vulnerabilitycharacteristic_id,
              vulnerability_date:
                formatedDate2(values.vulnerability_date) || "",
              note_text: values.note_text,
            },
          };
          setLoading(true);
          apiServices.client.vulnerability
            .update(selectedRowItem.vulnerability_id, newData)
            .then((response: any) => {
              setLoading(false);
              if (!response.data.isError) {
                props.notify(response.data.message, "", "success");

                dispatch(resetFormData());
                getvulnerability(props.activeCustomerId);
                if (nextAction === "next") {
                  props.changeTab?.(props.tabMenuIndex.next);
                }
              } else {
                props.notify("Something went wrong", "", "error");
              }
            })
            .catch((error: Error) => {
              setLoading(false);
              console.log("error");
            });
        } catch (error) {
          console.error("Error adding document:", error);
        }
      }
    },
  });

  function onChangeValue(key: string, value: any) {
    formikData.setFieldValue(key, value);
  }

  const [driver, setDriver] = useState([{}]);
  const [characteristic, setCharacteristic] = useState<CharacteristicProps[]>(
    []
  );

  useEffect(() => {
    if (
      mode &&
      selectedRowItem &&
      ["view", "edit"].includes(mode) &&
      Object.keys(selectedRowItem).length !== 0
    ) {
      let rowItem = {};
      if ((context = "CLIENT_VULNERABILITY")) {
        rowItem = {
          ...rowItem,
          ...selectedRowItem,
        };
      }

      setInitialFormData(rowItem);
    }
  }, [mode, selectedRowItem]);
  useEffect(() => {
    if (
      mode === "edit" &&
      Object.keys(selectedRowItem).length === 0 &&
      Object.keys(initialFormData).length !== 0
    ) {
      formikData.setValues(initialFormData);
    } else if (mode === "edit" && Object.keys(selectedRowItem).length !== 0) {
      formikData.setValues(selectedRowItem);
    } else {
      if (mode === "add") {
        setCharacteristic([]);
      }
    }
  }, [mode, selectedRowItem]);

  const fetchDriver = () => {
    setLoading(true);
    apiServices.client.vulnerability
      .getDriver()
      .then((response: any) => {
        setLoading(false);
        setDriver(response.data.data);
      })
      .catch((e: Error) => {
        setLoading(false);
        console.log("error", e);
      });
  };
  const fetchCharacterstics = (id: string) => {
    setLoading(true);
    apiServices.client.vulnerability
      .getCharacteristics(id)
      .then((response: any) => {
        setLoading(false);
        const characteristics = response.data.data.data || [];
        setCharacteristic(characteristics);

        if (formikData.values.vulnerabilitydriver_id !== "" && mode === "add") {
          formikData.setFieldValue(
            "vulnerabilitycharacteristic_id",
            characteristics[0]?.vulnerabilitycharacteristic_id || ""
          );
        } else if (
          formikData.values.vulnerabilitydriver_id &&
          mode === "edit"
        ) {
          formikData.setFieldValue(
            "vulnerabilitycharacteristic_id",
            formikData.values.vulnerabilitycharacteristic_id || ""
          );
        } else {
          formikData.setFieldValue("vulnerabilitycharacteristic_id", "");
        }
      })
      .catch((e: Error) => {
        setLoading(false);
        console.log("error", e);
      });
  };

  useEffect(() => {
    fetchDriver();
  }, []);

  useEffect(() => {
    if (formikData.values.vulnerabilitydriver_id) {
      setCharacteristic([]);
      if (formikData.values.vulnerabilitydriver_id !== "0") {
        fetchCharacterstics(formikData.values.vulnerabilitydriver_id);
      }
    }
  }, [formikData.values.vulnerabilitydriver_id]);
  const handleRowAction = (action: string, row: any) => {
    changeMode(action);
    setSelectedRowItem(row);
  };

  const getvulnerability = (id: string) => {
    setLoading(true);
    apiServices.client.vulnerability
      .get(id)
      .then((response: any) => {
        setLoading(false);
        setDataList(response.data.data);
      })
      .catch((e: Error) => {
        setLoading(false);
        console.log("error", e);
      });
  };

  useEffect(() => {
    if (dataList.length > 0) {
      selectedIndex(0);
    }
  }, [dataList]);

  useEffect(() => {
    if (props.activeCustomerId && props.activeCustomerId !== null) {
      getvulnerability(props.activeCustomerId);
    }
  }, [props.activeCustomerId]);

  const selectedIndex = (index: number) => {
    if (dataList.length > 0) {
      setMode("view");
      setSelectedRowItem(dataList[index]);
    }
  };

  return (
    <>
      <OverlayLoader isLoading={loading} />
      {(mode === "add" || mode === "edit") && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div>
              <Button
                icon={<ArrowUndo16Regular />}
                className={classes.btn_mode}
                // onClick={undo}
                //   disabled={
                //     past !== undefined && past.length === 0 ? true : false
                //   }
              ></Button>
              <Button
                icon={<ArrowRedo16Regular />}
                className={classes.btn_mode}
                //   onClick={redo}
                //   disabled={future.length === 0 ? true : false}
              ></Button>
            </div>
            <Button
              appearance="transparent"
              // onClick={() => {
              //   formik.resetForm();
              // }}
              // disabled={!isFormDataUpdated}
            >
              Reset All
            </Button>
          </div>
          <div className="form">
            <div className="grid-container">
              <Field label="Date">
                <DatePicker
                  // placeholder="Date"
                  formatDate={onFormatDate}
                  name="vulnerability_date"
                  onSelectDate={(date) => {
                    onChangeValue("vulnerability_date", date);
                    dispatch(setFormData());
                  }}
                  value={
                    formikData.values.vulnerability_date
                      ? mode === "add"
                        ? formikData.values.vulnerability_date
                        : mode === "edit"
                        ? new Date(formikData.values.vulnerability_date)
                        : null
                      : null
                  }
                />
              </Field>
              <Field
                label={
                  <span>
                    Driver<span className="required-item">*</span>
                  </span>
                }
                validationState={
                  formikData.errors.vulnerabilitydriver_id ? "error" : "none"
                }
                validationMessage={`${
                  formikData?.errors?.vulnerabilitydriver_id || ""
                }`}
              >
                <Select
                  id={selectId}
                  placeholder="Select a driver"
                  value={formikData.values.vulnerabilitydriver_id}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    onChangeValue("vulnerabilitydriver_id", e.target.value);
                    dispatch(setFormData());
                  }}
                >
                  <option value={0}>{""}</option>
                  {driver &&
                    driver.map((optn: any) => (
                      <option
                        key={optn?.vulnerabilitydriver_id}
                        value={optn.vulnerabilitydriver_id}
                        selected={
                          formikData.values.vulnerabilitydriver_id ===
                          optn?.vulnerabilitydriver_id
                        }
                      >
                        {optn.driver}
                      </option>
                    ))}
                </Select>
              </Field>
              <Field
                label={
                  <span>
                    Characteristics<span className="required-item">*</span>
                  </span>
                }
                validationState={
                  formikData.errors.vulnerabilitycharacteristic_id
                    ? "error"
                    : "none"
                }
                validationMessage={`${
                  formikData?.errors?.vulnerabilitycharacteristic_id || ""
                }`}
              >
                <Select
                  id={selectId}
                  placeholder="Select a Characteristics"
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    onChangeValue(
                      "vulnerabilitycharacteristic_id",
                      e.target.value
                    );
                    dispatch(setFormData());
                  }}
                  value={formikData.values.vulnerabilitycharacteristic_id}
                >
                  {characteristic.length &&
                    characteristic.map((optn: any) => (
                      <option
                        key={optn?.vulnerabilitycharacteristic_id}
                        value={optn?.vulnerabilitycharacteristic_id}
                        selected={
                          formikData.values.vulnerabilitycharacteristic_id ===
                          optn?.vulnerabilitycharacteristic_id
                        }
                      >
                        {optn?.characteristics}
                      </option>
                    ))}
                </Select>
              </Field>
              <Field
                label={
                  <span>
                    Note<span className="required-item">*</span>
                  </span>
                }
                validationState={formikData.errors.note_text ? "error" : "none"}
                validationMessage={`${formikData?.errors?.note_text || ""}`}
              >
                <div style={{ backgroundColor: "#ffff", maxWidth: 350 }}>
                  <TextEditor
                    name="note_text"
                    inputData={formikData.values.note_text}
                    onChange={(text: string) => {
                      onChangeValue("note_text", text);
                      dispatch(setFormData());
                    }}
                  />
                </div>
              </Field>
              <div style={{ alignSelf: "auto" }}>
                <Field label="Score">
                  <Input
                    // placeholder="ratings"
                    type="number"
                    name="ratings"
                    min="0"
                    value={formikData.values.ratings}
                    onChange={(ev: any) => {
                      onChangeValue("ratings", ev.target.value);
                      dispatch(setFormData());
                    }}
                  />
                </Field>
              </div>
            </div>
            <div>
              <Button
                type="submit"
                appearance="primary"
                className="asc-button-primary"
                onClick={() => {
                  if (isFormupdated) {
                    formikData.handleSubmit();
                  }
                }}
                disabled={
                  !isFormupdated || Object.keys(formikData.errors).length === 0
                    ? false
                    : true
                }
              >
                Save
              </Button>

              {props.tabMenuIndex &&
                props.tabMenuIndex.next !== -1 &&
                !props.isContactHistory && (
                  <Button
                    className={classes.btn_mode}
                    onClick={() => {
                      setNextAction("next");
                      isFormupdated
                        ? formikData.handleSubmit()
                        : setNextAction("next");
                    }}
                    disabled={
                      !isFormupdated ||
                      Object.keys(formikData.errors).length === 0
                        ? false
                        : true
                    }
                  >
                    Save & Next
                  </Button>
                )}
              <Button
                onClick={() => {
                  if (isFormupdated === true) {
                    dispatch(
                      setDialogModalOptions({
                        open: true,
                        content_line_1:
                          "We have detected an attempt to move away from the current page.",
                        content_line_2:
                          "This would cause all changes made to be lost",
                        cancel: {
                          onclick: () => {
                            dispatch(
                              setDialogModalOptions({
                                open: false,
                              })
                            );
                            // props.setFormUpdated(false);
                            dispatch(resetFormData());
                            changeMode("view");
                            formikData.resetForm();
                            if (props.isContactHistory) {
                              dispatch(setDialogModalOptions({ open: false }));
                            }
                          },
                          label: "Leave the Page",
                        },
                        no: {
                          onclick: () => {
                            dispatch(setDialogModalOptions({ open: false }));
                            formikData.resetForm();
                          },
                          label: "Stay on Page",
                          type: "primary",
                        },
                      })
                    );
                  } else {
                    dataList.length === 0 ? changeMode("") : changeMode("view");
                    formikData.resetForm();
                  }
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
      {mode === "view" && (
        <div>
          <div className="btn-wraper-container">
            <div>
              Record {currentRecord} of {dataList.length}
            </div>
            <div>
              <Button
                className="asc-button-primary"
                style={{ marginRight: 2, marginBottom: 6 }}
                appearance="primary"
                size="medium"
                icon={<Add16Filled />}
                onClick={() => {
                  changeMode("add");
                  formikData.resetForm();
                }}
              >
                Add New
              </Button>
              <Button
                style={{ marginRight: 2, marginBottom: 6 }}
                icon={<Edit24Filled />}
                size="medium"
                onClick={() => {
                  changeMode("edit");
                }}
              >
                Edit
              </Button>
              <Button
                icon={<Delete16Regular />}
                style={{ marginRight: 2, marginBottom: 6, color: "red" }}
                size="medium"
                onClick={() => {
                  dispatch(
                    setDialogModalOptions({
                      open: true,
                      content_line_1: "Do you want to delete?",
                      no: {
                        onclick: () => {
                          dispatch(setDialogModalOptions({ open: false }));
                        },
                        label: "No",
                        type: "primary",
                      },
                      cancel: {
                        onclick: () => {
                          dispatch(setDialogModalOptions({ open: false }));
                        },
                        label: "Yes",
                      },
                    })
                  );
                }}
              >
                Delete
              </Button>
            </div>
          </div>
          <div className="form">
            <div className="grid-container">
              <div className="fieldWrapper">
                <Label weight="semibold" size="medium">
                  Date
                </Label>
                <Label>
                  {formatedDate(selectedRowItem?.vulnerability_date)}
                </Label>
              </div>
              <div className="fieldWrapper">
                <Label weight="semibold" size="medium">
                  Driver
                </Label>
                <Label>{selectedRowItem?.driver}</Label>
              </div>
              <div className="fieldWrapper">
                <Label weight="semibold" size="medium">
                  Characteristics
                </Label>
                <Label>{selectedRowItem?.characteristics}</Label>
              </div>
              <div className="fieldWrapper">
                <Label weight="semibold" size="medium">
                  Note
                </Label>

                <ReactQuillComponent value={selectedRowItem?.note_text} />
              </div>
              <div className="fieldWrapper">
                <Label weight="semibold" size="medium">
                  Score
                </Label>
                <Label> {selectedRowItem?.ratings}</Label>
              </div>
            </div>
          </div>
        </div>
      )}
      {dataList && dataList.length > 0 ? (
        <VulnerabilityTable
          {...{
            changeMode,
            handleRowAction,
            mode,
            context: props.context,
            data: dataList,
            selectedRowItem,
            setCurrentRecord,
            tableId: props.tableId,
            selectedItem: selectedItem,
            setSelectedItem: setSelectedItem,
          }}
        />
      ) : (
        <div>
          {mode === "" && (
            <>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-end",
                  alignItems: "flex-start",
                }}
              >
                <Button
                  className="asc-button-primary"
                  style={{ marginRight: 2, marginBottom: 6 }}
                  appearance="primary"
                  size="medium"
                  icon={<Add16Filled />}
                  onClick={() => {
                    changeMode("add");
                  }}
                >
                  Add New
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 200,
                }}
              >
                <Label size="large" style={{ color: "red" }}>
                  There are no active records - click Add New to create a new
                  record
                </Label>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default VulnerabilityForm;
