import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const useStyles = makeStyles({
  btn_mode: {
    marginLeft: "3px",
    marginRight: "3px",
  },
  btnwrapper: {
    columnGap: "8px",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "6px",
    marginTop: "6px",
  },
  buttonWrapper: {
    columnGap: "15px",
    display: "flex",
    ...shorthands.padding("10px"),
  },
  containerTopArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  message: {
    marginRight: "14px",
  },
  
});

// only for development purpose
export const characteristicOptions = [
  { id: "1", driverId: "1", text: "Characteristic 1 for John Doe" },
  { id: "2", driverId: "1", text: "Characteristic 2 for John Doe" },
  { id: "3", driverId: "1", text: "Characteristic 3 for John Doe" },
  { id: "4", driverId: "2", text: "Characteristic 1 for Jane Smith" },
  { id: "5", driverId: "2", text: "Characteristic 2 for Jane Smith" },
  { id: "6", driverId: "3", text: "Characteristic 1 for Michael Johnson" },
  { id: "7", driverId: "3", text: "Characteristic 2 for Michael Johnson" },
  { id: "8", driverId: "3", text: "Characteristic 3 for Michael Johnson" },
];

// only for development purpose
export const driverOptions = [
  { id: "1", text: "John Doe" },
  { id: "2", text: "Jane Smith" },
  { id: "3", text: "Michael Johnson" },
];
