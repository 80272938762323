import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  Input,
  Textarea,
} from "@fluentui/react-components";
import { useState } from "react";
import Dropzone from "react-dropzone";
import UploadIcon from "../../../assets/uploadicon.svg";
import FormikInputRequest from "../../../service/hook/useFormik";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import apiServices from "../../../service";
import close from "../../../assets/close.svg";

type ButtonType = {
  label: string;
  action: string;
};

export default function ButtonListComponent({
  setRefresh,
  currentPath,
  GetAllFiles,
  notify,
  activeCustomerId,
  basePath = "",
}: {
  [key: string]: any;
}) {
  const ButtonList: ButtonType[] = [
    {
      label: "Add Folder",
      action: "addFolder",
    },
    {
      label: "Add Document",
      action: "addDoc",
    },
  ];
  const [dialogAction, setDialogAction] = useState({
    isOpen: false,
    action: "",
    title: "",
  });

  const handleClickAction = (action: string) => {
    setDialogAction({
      isOpen: true,
      action,
      title: action === "addFolder" ? "Add Folder" : "Add Document",
    });
  };

  const handleCloseDialog = () => {
    setDialogAction({ isOpen: false, action: "", title: "" });
    setRefresh(true);
  };

  return (
    <>
      <div className="gap">
        {ButtonList.map((item, index: number) => (
          <Button
            key={`Button-${index}`}
            appearance="primary"
            className="asc-button-primary"
            onClick={() => handleClickAction(item.action)}
          >
            {item.label}
          </Button>
        ))}
      </div>
      <DialogComponent
        isOpen={dialogAction.isOpen}
        action={dialogAction.action}
        dialogTitle={dialogAction.title}
        setRefresh={setRefresh}
        handleClose={handleCloseDialog}
        currentPath={currentPath}
        GetAllFiles={GetAllFiles}
        notify={notify}
        basePath={basePath}
        activeCustomerId={activeCustomerId}
      />
    </>
  );
}

const inputDataElements: any = {
  addFolder: [
    {
      label: "Folder Name",
      type: "text",
      name: "folderName",
    },
  ],
  addDoc: [
    {
      label: "Upload File",
      type: "file",
      name: "file",
    },
    {
      label: "File Name",
      type: "text",
      name: "file_name",
    },
    {
      label: "Folder Name",
      type: "text",
      name: "folder_name",
    },
    {
      label: "Date Filed",
      type: "date",
      name: "date",
    },
    {
      label: "Description",
      type: "textarea",
      name: "description",
    },
  ],
};

function DialogComponent({
  isOpen,
  action,
  dialogTitle,
  setRefresh,
  currentPath,
  handleClose,
  GetAllFiles,
  basePath = "",
  notify,
  activeCustomerId,
}: {
  isOpen: boolean;
  action: string;
  dialogTitle: string;
  setRefresh: any;
  currentPath: any;
  handleClose: () => void;
  GetAllFiles: any;
  notify: any;
  activeCustomerId: string;
  basePath: string;
}) {
  const fieldData = inputDataElements[action] || [];
  const path = currentPath.join("/");

  const initialValues =
    action === "addFolder"
      ? {
          folderName: "",
        }
      : {
          file_name: "",
          folder_name: "",
          date: new Date(),
          description: "",
          file: "",
        };

  const formikData = FormikInputRequest({
    initialValues,
    onSubmitFn: async (values: any) => {
      const folderPath = path ? `${basePath}/${path}/` : `${basePath}/`;
      if (action === "addFolder") {
        try {
          const folderNameWithPath = `${folderPath}${values.folderName}/`;
          apiServices.clientDocument
            .addFolder({
              folderName: folderNameWithPath,
              customer_id: activeCustomerId,
              visible: "public",
            })
            .then((res) => {
              setTimeout(() => {
                notify(res.data.message, "", "success");
              }, 1000);
            })
            .catch((err) => {
              throw err;
            });

          setRefresh((prev: any) => !prev);
          handleClose();
          formikData.resetForm();
          GetAllFiles(activeCustomerId);
        } catch (error) {
          console.error("Error adding folder:", error);
        }
      } else if (action === "addDoc") {
        const formData = new FormData();

        const { file_name, date, description, file } = values;

        formData.append("file_name", file_name);
        formData.append("folder_name", `${basePath}/${path}`);
        formData.append("date", date);
        formData.append("description", description);
        formData.append("file", file);
        formData.append("customer_id", activeCustomerId);
        formData.append("visible", "public");

        try {
          await apiServices.clientDocument.addDocument(formData).then((res) => {
            setTimeout(() => {
              notify(res.data.message, "", "success");
            }, 1000);
          });
          setRefresh((prev: any) => !prev);
          handleClose();
          setUploadedFiles([]);
          formikData.resetForm();

          GetAllFiles(activeCustomerId);
        } catch (error) {
          console.error("Error adding document:", error);
        }
      }
    },
    validate: (values: any) => {
      const errors: any = {};
      if (action === "addFolder") {
        if (!values.folderName) {
          errors.folderName = "Folder Name is required";
        } else if (values.folderName.length > 256) {
          errors.folderName =
            "Folder Name cannot be longer than 256 characters";
        }
      } else if (action === "addDoc") {
        if (values.file_name.length > 256) {
          errors.file_name = "File Name cannot be longer than 256 characters";
        }
        if (values.description && values.description.length > 5000) {
          errors.description =
            "Description cannot be longer than 5000 characters";
        }
        if (!values.file) {
          errors.file = "File is required";
        }
      }
      return errors;
    },
  });

  function onChangeValue(key: string, value: any) {
    formikData.setFieldValue(key, value);
  }

  const handleSave = () => {
    formikData.handleSubmit();
  };

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const removeFile = () => {
    setUploadedFiles([]);
    formikData.setFieldValue("file", "");
    formikData.setFieldValue("file_name", "");
  };

  const onFormatDate = (date?: Date): string => {
    return !date ? "" : moment(date).format("DD/MM/YYYY");
  };

  const handleCancel = () => {
    handleClose();
    formikData.resetForm();
    setUploadedFiles([]);
  };

  return (
    <Dialog open={isOpen}>
      <DialogSurface className="dialog-surface">
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          {fieldData.map((item: any, index: number) => (
            <div key={index} className="folder_doc">
              <Field
                label={
                  <span>
                    {item.label}
                    {formikData.errors[item.name] ? (
                      <span className="required-item">*</span>
                    ) : null}
                  </span>
                }
                validationState={
                  formikData.errors[item.name] ? "error" : "none"
                }
                validationMessage={`${
                  formikData.errors[item.name] !== undefined
                    ? formikData.errors[item.name]
                    : ""
                }`}
              >
                {item.type === "file" ? (
                  <div className="flex-block">
                    <Dropzone
                      onDrop={(acceptedFiles: File[]) => {
                        setUploadedFiles([]);
                        if (acceptedFiles.length > 0) {
                          const file = acceptedFiles[0];
                          setUploadedFiles([...acceptedFiles]);
                          formikData.setFieldValue("file", file, false);
                          formikData.setFieldValue(
                            "file_name",
                            file.name,
                            false
                          );
                        }
                      }}
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="container">
                          <div
                            {...getRootProps({ className: "dropzone" })}
                            className="file-drop"
                            style={{ textAlign: "center" }}
                          >
                            <input {...getInputProps()} />
                            <img src={UploadIcon} width="50px" />
                            <p style={{ textAlign: "center" }}>
                              Click or Drag the file here to upload
                            </p>
                          </div>
                        </section>
                      )}
                    </Dropzone>

                    <ul>
                      {uploadedFiles.map((file: any, index: any) => (
                        <div className="list-upload-item" key={index}>
                          <li>
                            {file.name} - {(file.size / 1024).toFixed(2)} KB
                            <img
                              className="file-upload-close"
                              src={close}
                              onClick={() => removeFile()}
                              width={20}
                            />
                          </li>
                        </div>
                      ))}
                    </ul>
                  </div>
                ) : item.type === "textarea" ? (
                  <Textarea
                    appearance="outline"
                    style={{ backgroundColor: "#fff" }}
                    value={formikData.values.description}
                    onChange={(e) =>
                      onChangeValue("description", e.target.value)
                    }
                  />
                ) : item.type === "date" ? (
                  <DatePicker
                    allowTextInput
                    name={item.name}
                    value={formikData.values[item.name]}
                    formatDate={onFormatDate}
                    onSelectDate={(date) => onChangeValue(item.name, date)}
                    disabled // Disable the date picker
                  />
                ) : (
                  <Input
                    appearance="outline"
                    type={item.type}
                    name={item.name}
                    disabled={item.name === "folder_name"}
                    value={
                      item.name === "folder_name"
                        ? path
                        : formikData.values[item.name]
                    }
                    onChange={(e) => {
                      onChangeValue(item.name, e.target.value);
                    }}
                  />
                )}
              </Field>
            </div>
          ))}
        </DialogContent>
        <DialogActions className="flex-end">
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            appearance="primary"
            onClick={handleSave}
            style={{ background: "#0f6cbd" }}
          >
            Save
          </Button>
        </DialogActions>
      </DialogSurface>
    </Dialog>
  );
}
