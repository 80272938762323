import * as React from "react";
import {
  makeStyles,
  Tab,
  TabList,
  shorthands,
} from "@fluentui/react-components";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import { checkUserPermission, convertStringToArray } from "../../utils";
import { useNavigate, useLocation } from "react-router-dom";
import { UserPermissionsList } from "../../config";
const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    color: "#fff",
    ...shorthands.padding("50px 20px"),
    rowGap: "20px",
    margin: "0px 10px",
  },
});

const Navbar = () => {
  const styles = useStyles();
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname.replace(/^\/+/, "");
  const isSystemManagerPath = currentPath.startsWith("system-manager");
  const navItems: any = [
    {
      value: "action-list",
      label: "Action List",
      link: "/action-list",
    },
    {
      value: "clients",
      label: "Clients",
      link: "/",
    },
    {
      value: "utilities",
      label: "Utilities",
      link: "/utilities",
    },
  ];
  if (
    checkUserPermission(loggedUser, UserPermissionsList.ALLOW_CLIENT_REPORTS)
  ) {
    navItems.push({
      value: "report",
      label: "Reports",
      link: "/report",
    });
  }
  if (
    checkUserPermission(loggedUser, UserPermissionsList.ALLOW_ADD_USER) ||
    checkUserPermission(loggedUser, UserPermissionsList.ALLOW_UPDATE_USER) ||
    checkUserPermission(loggedUser, UserPermissionsList.ALLOW_VIEW_USER) ||
    checkUserPermission(loggedUser, UserPermissionsList.ALLOW_DELETE_USER)
  ) {
    navItems.push({
      value: "system-manager",
      label: "System Manager",
      link: "/system-manager/company-details",
    });
  }
  navItems.push({
    value: "commission",
    label: "Commissions",
    link: "/commission",
  });
  //   navItems.push({
  //   value: "reportdemo",
  //   label: "Report Demo",
  //   link: "/reportdemo",
  // });
  const handleNavigationLink = (link: any) => {
    navigate(link);
  };

  return (
    <div className={styles.root}>
      <TabList
        selectedValue={
          isSystemManagerPath
            ? "system-manager"
            : currentPath === ""
            ? "clients"
            : currentPath
        }
      >
        {navItems.map((item: any, i: number) => (
          <Tab
            key={i}
            disabled={
              !convertStringToArray(
                process.env.REACT_APP_DISABLE_NAV_MENU
              ).includes(item.value)
                ? false
                : true
            }
            value={item.value}
            onClick={() => handleNavigationLink(item.link)}
          >
            {!convertStringToArray(
              process.env.REACT_APP_HIDE_NAV_MENU
            ).includes(item.value) ? (
              // <>{item.value === "utilities" ? MenuBar : item.label}</>
              <>{item.label}</>
            ) : (
              ""
            )}
          </Tab>
        ))}
      </TabList>
    </div>
  );
};
export default Navbar;
