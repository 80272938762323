import { useState, useEffect } from "react";
import {
  TabList,
  Tab,
  useToastController,
  useId,
} from "@fluentui/react-components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import FormContainerWithList from "../../../components/FormContainerWithList";
import { addBreadcrumbs } from "../../../redux/appSettingsSlice";
import apiServices from "../../../service";
import { camalize, convertStringToArray } from "../../../utils";
import OverlayLoader from "../../../components/OverlayLoader";

type tabItems = {
  label: string;
  value: string;
}[];

const tabListData: tabItems = [
  {
    label: "Client Action",
    value: "CONTACTS_CLIENT_ACTION",
  },
  {
    label: "Notes",
    value: "CONTACTS_NOTES",
  },
  {
    label: "Time Allocation",
    value: "CONTACTS_TIME_ALLOCATION",
  },
];

type FormContainerProps = {
  style?: React.CSSProperties;
  title?: string;
  mode?: string;
  [x: string]: any;
  changeMode?: (mode: string) => void;
  changeTab?: (index: number) => void;
};

type configFm = {
  context: string;
  actionUrl: {
    create: string;
    edit: string;
    redirect: string;
  };
  renderForm: {
    reference_id: string;
    general_fields: string[];
    custom_fields: string[];
  };
};

const ContactForm = (props: FormContainerProps) => {
  const [tabListItems, setTabListItems] = useState<tabItems>(tabListData);
  const [selectedValue, setSelectedValue] = useState("CONTACTS_CLIENT_ACTION");
  const [tabMenuIndex, setTabMenuIndex] = useState({
    previous: -1,
    current: 0,
    next: -1,
  });
  const [configForm, setConfigForm] = useState<configFm>();
  const [loading, setLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const [initialData, setInitialData] = useState({});

  useEffect(() => {
    if (selectedValue != "" && props.activeCustomerId) {
      setFormContextConfig(selectedValue);
      updateTabMenuIndex(selectedValue);

      getDataList();
      handleBreadcrumbs();
    }
  }, [selectedValue, props.activeCustomerId]);

  useEffect(() => {
    if (props.isContactHistory) {
      setSelectedValue(props.selectedValue);
    }
  }, []);

  const loadListData = () => {
    getDataList();
  };
  const getDataList = () => {
    if (selectedValue === "CONTACTS_CLIENT_ACTION") {
      setLoading(true);
      apiServices.contactClientAction
        .getList(props.activeCustomerId)
        .then((res) => {
          setLoading(false);
          if (res.data !== undefined) {
            if (res.data.data) {
              let singleData = {};
              singleData = {
                ...singleData,
                contactClientAction: res.data.data,
              };
              setInitialData(singleData);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else if (selectedValue === "CONTACTS_NOTES") {
      setLoading(true);
      apiServices.notes
        .getList(props.activeCustomerId)
        .then((res) => {
          setLoading(false);
          if (res.data !== undefined) {
            if (res.data.data) {
              let singleData = {};
              singleData = {
                ...singleData,
                notes: res.data.data,
              };
              setInitialData(singleData);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else if (selectedValue === "CONTACTS_TIME_ALLOCATION") {
      setLoading(true);
      apiServices.timeAllocation
        .getList(props.activeCustomerId)
        .then((res) => {
          setLoading(false);
          if (res.data !== undefined) {
            if (res.data.data) {
              let singleData = {};
              singleData = {
                ...singleData,
                timeAllocation: res.data.data,
              };
              setInitialData(singleData);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
    }
  };

  const onTabSelect = (event: any, data: any) => {
    setSelectedValue(data.value);
  };

  const changeTab = (index: number) => {
    if (index !== -1) setSelectedValue(tabListItems[index]["value"]);
  };
  const handleBreadcrumbs = () => {
    if (props.menu !== undefined && props.menu === "contact") {
      let currentTabLabel = tabListData.find(
        (item: any) => item.value === selectedValue
      );
      dispatch(
        addBreadcrumbs([
          {
            key: 0,
            value: "Home",
            link: "/",
          },
          {
            key: 1,
            value: "Clients",
            link: "/clients",
          },
          {
            key: 2,
            value: "Client",
            link: "/client",
          },
          {
            key: 3,
            value: camalize(props.menu),
          },
          {
            key: 4,
            value: currentTabLabel?.label,
          },
        ])
      );
    }
  };
  const updateTabMenuIndex = (context: string) => {
    if (tabListItems.length > 0) {
      let currentVal = tabListItems.findIndex(
        (obj) => obj["value"] === context
      );
      let prevVal = currentVal === 0 ? -1 : currentVal - 1;
      let nextVal =
        tabListItems.length - 1 !== currentVal ? currentVal + 1 : -1;

      setTabMenuIndex((prevState) => ({
        ...prevState,
        previous: prevVal,
        current: currentVal,
        next: nextVal,
      }));
    }
  };

  const setFormContextConfig = (context: string) => {
    if (formSettingsObj.data && formSettingsObj.data.formConfig !== undefined) {
      let configObj = formSettingsObj.data.formConfig.find(
        (item: any) => item.context === context
      );
      if (configObj) {
        setConfigForm(configObj);
      }
    }
  };

  return (
    <div className="form-container">
      <OverlayLoader isLoading={loading} />
      <div>
        <div>
          <TabList selectedValue={selectedValue} onTabSelect={onTabSelect}>
            {tabListItems &&
              tabListItems.length > 0 &&
              tabListItems.map((item, i) => {
                return (
                  <Tab
                    key={i}
                    value={item.value}
                    disabled={
                      !convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes(item.value)
                        ? false
                        : true
                    }
                  >
                    {!convertStringToArray(
                      process.env.REACT_APP_HIDE_CTA_BUTTON
                    ).includes(item.value) ? (
                      <> {item.label}</>
                    ) : (
                      ""
                    )}
                  </Tab>
                );
              })}
          </TabList>
        </div>
        {selectedValue !== "" && selectedValue === configForm?.context && (
          <FormContainerWithList
            context={selectedValue}
            initialData={initialData}
            tabMenuIndex={tabMenuIndex}
            changeTab={changeTab}
            closePage={props.closePage}
            activeCustomerId={props.activeCustomerId}
            setActiveItem={props.setActiveItem}
            notify={props.notify}
            getList={props.getList}
            getDataList={getDataList}
            config={configForm}
            reloadData={loadListData}
            isContactHistory={props.isContactHistory}
            tableId={props.tableId}
          />
        )}
      </div>
    </div>
  );
};

export default ContactForm;
