export const lifeAssurance = [
    {
        lifeAssurance_id: 0,
        label: "Policy Category",
        value: "case_type",
    },
    {
        lifeAssurance_id: 1,
        label: "Policyholder",
        value: "policy_holder",
    },
    {
        lifeAssurance_id: 2,
        label: "Assured Detail",
        value: "assured_detail",
    },
    {
        lifeAssurance_id: 3,
        label: "Assured Name 1",
        value: "assured_name1",
    },
    {
        lifeAssurance_id: 4,
        label: "Assured Name 2",
        value: "assured_name2",
    },
    {
        lifeAssurance_id: 5,
        label: "Subtype",
        value: "subtype",
    },
    {
        lifeAssurance_id: 6,
        label: "Provider",
        value: "provider",
    },
    {
        lifeAssurance_id: 7,
        label: "Provider Contact",
        value: "provider_contact_name",
    },
    {
        lifeAssurance_id: 8,
        label: "Provider Phone",
        value: "provider_phone",
    },
    {
        lifeAssurance_id: 9,
        label: "Provider Note",
        value: "provider_note",
    },
    {
        lifeAssurance_id: 10,
        label: "Purpose",
        value: "purpose",
    },
    {
        lifeAssurance_id: 11,
        label: "Linked to Mortgage",
        value: "link_to_mortgage",
    },
    {
        lifeAssurance_id: 12,
        label: "Start Date",
        value: "start_date",
    },
    {
        lifeAssurance_id: 13,
        label: "End Date",
        value: "end_date",
    },
    {
        lifeAssurance_id: 14,
        label: "Term",
        value: "term",
    },
    {
        lifeAssurance_id: 15,
        label: "Scheme Name",
        value: "scheme_name",
    },
    {
        lifeAssurance_id: 16,
        label: "Policy Number",
        value: "policy_number",
    },
    {
        lifeAssurance_id: 17,
        label: "Other Reference",
        value: "other_reference",
    },
    {
        lifeAssurance_id: 18,
        label: "Status",
        value: "status",
    },
    {
        lifeAssurance_id: 19,
        label: "Service Status",
        value: "service_status",
    },
    {
        lifeAssurance_id: 20,
        label: "Premium Indexed",
        value: "premium_indexed",
    },
    {
        lifeAssurance_id: 21,
        label: "Waiver",
        value: "waiver",
    },
    {
        lifeAssurance_id: 22,
        label: "Surrender Value",
        value: "surrender_value",
    },
    {
        lifeAssurance_id: 23,
        label: "Future Value",
        value: "future_value",
    },
    {
        lifeAssurance_id: 24,
        label: "Total Fund Value",
        value: "total_fund_value",
    },
    {
        lifeAssurance_id: 25,
        label: "Valuation Date",
        value: "valuation_date",
    },
    {
        lifeAssurance_id: 26,
        label: "Sum Assured",
        value: "sum_assured",
    },
    {
        lifeAssurance_id: 27,
        label: "Periodic Benefit",
        value: "periodic_benefit",
    },
    {
        lifeAssurance_id: 28,
        label: "Benefit Frequency",
        value: "benefit_frequency",
    },
    {
        lifeAssurance_id: 29,
        label: "Benefits Indexed",
        value: "benefits_indexed",
    },
    {
        lifeAssurance_id: 30,
        label: "Benefit Note",
        value: "benefit_text",
    },
    {
        lifeAssurance_id: 31,
        label: "Rated",
        value: "rated",
    },
    {
        lifeAssurance_id: 32,
        label: "In Trust",
        value: "in_trust",
    },
    {
        lifeAssurance_id: 33,
        label: "In Trust Note",
        value: "in_trust_note",
    },
    {
        lifeAssurance_id: 34,
        label: "Age Admitted",
        value: "age_admitted",
    },
    {
        lifeAssurance_id: 35,
        label: "Assigned To",
        value: "assigned_to",
    },
    {
        lifeAssurance_id: 36,
        label: "Admin Note",
        value: "admin_note",
    },
    {
        lifeAssurance_id: 37,
        label: "Report Note",
        value: "report_note",
    },
]
export const pension = [
    {
        pension_id: 0,
        label: "Policyholder",
        value: "policy_holder",
    },
    {
        pension_id: 1,
        label: "Policyholder",
        value: "policy_holder",
    },
    {
        pension_id: 2,
        label: "Assured Detail",
        value: "assured_detail",
    },
    {
        pension_id: 3,
        label: "Assured Name 1",
        value: "assured_name1",
    },
    {
        pension_id: 4,
        label: "Assured Name 2",
        value: "assured_name2",
    },
    {
        pension_id: 5,
        label: "Subtype",
        value: "subtype",
    },
    {
        pension_id: 6,
        label: "Provider",
        value: "provider",
    },
    {
        pension_id: 7,
        label: "Provider Contact",
        value: "provider_contact_name",
    },
    {
        pension_id: 8,
        label: "Provider Phone",
        value: "provider_phone",
    },
    {
        pension_id: 9,
        label: "Provider Note",
        value: "provider_note",
    },
    {
        pension_id: 10,
        label: "Purpose",
        value: "purpose",
    },
    {
        pension_id: 11,
        label: "Start Date",
        value: "start_date",
    },
    {
        pension_id: 12,
        label: "End Date",
        value: "end_date",
    },
    {
        pension_id: 13,
        label: "Term",
        value: "term",
    },
    {
        pension_id: 14,
        label: "Scheme Name",
        value: "scheme_name",
    },
    {
        pension_id: 15,
        label: "Policy Number",
        value: "policy_number",
    },
    {
        pension_id: 16,
        label: "Other Reference",
        value: "other_reference",
    },
    {
        pension_id: 17,
        label: "Status",
        value: "status",
    },
    {
        pension_id: 18,
        label: "Service Status",
        value: "service_status",
    },
    {
        pension_id: 19,
        label: "Contracted Out",
        value: "contracted_out",
    },
    {
        pension_id: 20,
        label: "Premium Indexed",
        value: "premium_indexed",
    },
    {
        pension_id: 21,
        label: "Waiver",
        value: "waiver",
    },
    {
        pension_id: 22,
        label: "Transfer Value",
        value: "transfer_value",
    },
    {
        pension_id: 23,
        label: "Future Value",
        value: "future_value",
    },
    {
        pension_id: 24,
        label: "Total Fund Value",
        value: "total_fund_value",
    },
    {
        pension_id: 25,
        label: "Valuation Date",
        value: "valuation_date",
    },
    {
        pension_id: 26,
        label: "Lump Sum Benefit",
        value: "lump_sum_benefit",
    },
    {
        pension_id: 27,
        label: "Periodic Benefit",
        value: "periodic_benefit",
    },
    {
        pension_id: 28,
        label: "Benefit Frequency",
        value: "benefit_frequency",
    },
    {
        pension_id: 29,
        label: "Benefits Indexed",
        value: "benefits_indexed",
    },
    {
        pension_id: 30,
        label: "Benefit Note",
        value: "benefit_text",
    },
    {
        pension_id: 31,
        label: "In Trust",
        value: "in_trust",
    },
    {
        pension_id: 32,
        label: "In Trust Note",
        value: "in_trust",
    },
    {
        pension_id: 33,
        label: "Admin Note",
        value: "admin_note",
    },
    {
        pension_id: 34,
        label: "Report Note",
        value: "report_note",
    },
]
export const Investments = [
    {
        Investments_id: 0,
        label: "Policyholder",
        value: "policy_holder",
    },
    {
        Investments_id: 1,
        label: " Assured Detail",
        value: "psured_detail",
    },
    {
        Investments_id: 2,
        label: "Assured Name 1",
        value: "aassured_name1",
    },
    {
        Investments_id: 3,
        label: " Assured Name 2",
        value: "aassured_name2",
    },
    {
        Investments_id: 4,
        label: "Subtype",
        value: "subtype",
    },
    {
        Investments_id: 5,
        label: " Provider",
        value: "provider",
    },
    {
        Investments_id: 6,
        label: " Provider Contact",
        value: "provider_contact_name",
    },

    {
        Investments_id: 7,
        label: "Provider Phone",
        value: "pprovider_phone",
    },
    {
        Investments_id: 8,
        label: "Provider Note",
        value: "provider_note",
    },
    {
        Investments_id: 9,
        label: "Purpose ",
        value: "purpose",
    },
    {
        Investments_id: 10,
        label: "Start Date",
        value: "start_date",
    },
    {
        Investments_id: 11,
        label: "End Date",
        value: "end_date",
    },
    {
        Investments_id: 12,
        label: "Term",
        value: "term",
    },
    {
        Investments_id: 13,
        label: " Scheme Name ",
        value: "scheme_name",
    },
    {
        Investments_id: 14,
        label: "Policy Number",
        value: "policy_number",
    },
    {
        Investments_id: 15,
        label: "Other Reference",
        value: "other_reference",
    },
    {
        Investments_id: 16,
        label: " Status",
        value: "status",
    },
    {
        Investments_id: 17,
        label: "Service Status",
        value: "service_status",
    },
    {
        Investments_id: 18,
        label: "Premium Indexed",
        value: "premium_indexed",
    },
    {
        Investments_id: 19,
        label: " Waiver",
        value: "waiver",
    },
    {
        Investments_id: 20,
        label: "Surrender Value",
        value: "surrender_value",
    },
    {
        Investments_id: 21,
        label: "Future Value ",
        value: "future_value",
    },
    {
        Investments_id: 22,
        label: "Total Fund Value",
        value: "total_fund_value",
    },
    {
        Investments_id: 23,
        label: " Valuation Date",
        value: "valuation_date",
    },
    {
        Investments_id: 24,
        label: "  Lump Sum Benefit",
        value: "sum_assured",
    },
    {
        Investments_id: 25,
        label: " Periodic Benefit",
        value: "periodic_benefit",
    },
    {
        Investments_id: 26,
        label: "Benefit Frequency",
        value: "benefit_frequency",
    },
    {
        Investments_id: 27,
        label: "Benefits Indexed",
        value: "benefits_indexed",
    },
    {
        Investments_id: 28,
        label: " Benefit Note",
        value: "benefit_text",
    },
    {
        Investments_id: 29,
        label: "Benefit Frequency",
        value: "benefit_frequency",
    },
    {
        Investments_id: 30,
        label: "Benefits Indexed",
        value: "benefits_indexed",
    },
    {
        Investments_id: 31,
        label: "Benefit Note",
        value: "benefit_text",
    },
    {
        Investments_id: 32,
        label: " In Trust",
        value: "in_trust",
    },
    {
        Investments_id: 33,
        label: "In Trust Note",
        value: "in_trust",
    },
    {
        Investments_id: 34,
        label: "Age Admitted",
        value: "age_admitted",
    },
    {
        Investments_id: 35,
        label:  "Assigned To",
        value: "assigned_to",
    },
    {
        Investments_id: 36,
        label: "Admin Note ",
        value: "aadmin_note",
    },
    {
        Investments_id: 37,
        label: "Report Note",
        value: "areport_note",
    },
   
]
export const SavingsPlan = [
    {
       Savings_Plans_id: 0,
        label: "Policyholder",
        value: "policy_holder",
    },
    {
      Savings_Plans_id: 1,
        label: " Assured Detail",
        value: "assured_detail",
    },
    {
      Savings_Plans_id: 2,
        label: "Assured Name 1",
        value: "aassured_name1",
    },
    {
      Savings_Plans_id: 3,
        label: " Assured Name 2",
        value: "aassured_name2",
    },
    {
      Savings_Plans_id: 4,
        label: "Subtype",
        value: "subtype",
    },
    {
      Savings_Plans_id: 5,
        label: " Provider",
        value: "provider",
    },
    {
      Savings_Plans_id: 6,
        label: " Provider Contact",
        value: "provider_contact_name",
    },

    {
      Savings_Plans_id: 7,
        label: "Provider Phone",
        value: "provider_phone",
    },
    {
      Savings_Plans_id: 8,
        label: "Provider Note",
        value: "provider_note",
    },
    {
      Savings_Plans_id: 9,
        label: "Purpose ",
        value: "purpose",
    },
    {
      Savings_Plans_id: 10,
        label: "Start Date",
        value: "start_date",
    },
    {
      Savings_Plans_id: 11,
        label: "End Date",
        value: "end_date",
    },
    {
      Savings_Plans_id: 12,
        label: "Term",
        value: "term",
    },
    {
      Savings_Plans_id: 13,
        label: " Scheme Name ",
        value: "scheme_name",
    },
    {
      Savings_Plans_id: 14,
        label: "Policy Number",
        value: "policy_number",
    },
    {
      Savings_Plans_id: 15,
        label: "Other Reference",
        value: "other_reference",
    },
    {
      Savings_Plans_id: 16,
        label: " Status",
        value: "status",
    },
    {
        Savings_Plans_id: 16,
          label: " Service Status",
          value: "service_status",
      },
    {
      Savings_Plans_id: 17,
        label: "Premium Indexed",
        value: "premium_indexed",
    },
    {
        Savings_Plans_id: 18,
          label: " Waiver",
          value: "waiver",
      },
    {
      Savings_Plans_id: 19,
        label: " Waiver",
        value: "waiver",
    },
    {
      Savings_Plans_id: 20,
        label: " Surrender Value",
        value: "surrender_value",
    },
    {
      Savings_Plans_id: 21,
        label: " Future Value",
        value: "future_value",
    },
    {
      Savings_Plans_id: 21,
        label: "Total Fund Value ",
        value: "total_fund_value",
    },
    
    {
      Savings_Plans_id: 23,
        label: " Valuation Date",
        value: "valuation_date",
    },
    {
      Savings_Plans_id: 24,
        label: "  Lump Sum Benefit",
        value: "lump_sum_benefit",
    },
    {
      Savings_Plans_id: 25,
        label: " Periodic Benefit",
        value: "periodic_benefit",
    },
    {
      Savings_Plans_id: 26,
        label: "Benefit Frequency",
        value: "benefit_frequency",
    },
    {
      Savings_Plans_id: 27,
        label: "Benefits Indexed",
        value: "benefits_indexed",
    },
    {
      Savings_Plans_id: 28,
        label: " Benefit Note",
        value: "benefit_text",
    },
    {
      Savings_Plans_id: 29,
        label: " Rated",
        value: "rated",
    },

    {
      Savings_Plans_id: 30,
        label: " In Trust",
        value: "in_trust",
    },
    {
      Savings_Plans_id: 31,
        label: "In Trust Note",
        value: "in_trust",
    },

    {
      Savings_Plans_id: 32,
        label: "Age Admitted",
        value: "age_admitted",
    },
    {
      Savings_Plans_id: 33,
        label:  "Assigned To",
        value: "assigned_to",
    },
    {
      Savings_Plans_id: 34,
        label: "Admin Note ",
        value: "aadmin_note",
    },
    {
      Savings_Plans_id: 35,
        label: "Report Note",
        value: "areport_note",
    },
   
]
export const IncomeProtection = [
    {
        Income_Protection_id: 0,
        label: "Policyholder",
        value: "policy_holder",
    },
    {
      Income_Protection_id: 1,
        label: " Assured Detail",
        value: "assured_detail",
    },
    {
      Income_Protection_id: 2,
        label: "Assured Name 1",
        value: "aassured_name1",
    },
    {
      Income_Protection_id: 3,
        label: " Assured Name 2",
        value: "aassured_name2",
    },
    {
      Income_Protection_id: 4,
        label: "Subtype",
        value: "subtype",
    },
    {
      Income_Protection_id: 5,
        label: " Provider",
        value: "provider",
    },
    {
      Income_Protection_id: 6,
        label: " Provider Contact",
        value: "provider_contact_name",
    },

    {
      Income_Protection_id: 7,
        label: "Provider Phone",
        value: "provider_phone",
    },
    {
      Income_Protection_id: 8,
        label: "Provider Note",
        value: "provider_note",
    },
    {
      Income_Protection_id: 9,
        label: "Purpose ",
        value: "purpose",
    },
    {
      Income_Protection_id: 10,
        label: "Start Date",
        value: "start_date",
    },
    {
      Income_Protection_id: 11,
        label: "End Date",
        value: "end_date",
    },
    {
      Income_Protection_id: 12,
        label: "Term",
        value: "term",
    },
    {
      Income_Protection_id: 13,
        label: " Scheme Name ",
        value: "scheme_name",
    },
    {
      Income_Protection_id: 14,
        label: "Policy Number",
        value: "policy_number",
    },
    {
      Income_Protection_id: 15,
        label: "Other Reference",
        value: "other_reference",
    },
    {
      Income_Protection_id: 16,
        label: " Status",
        value: "status",
    },
    {
        Income_Protection_id: 16,
          label: " Service Status",
          value: "service_status",
      },
    {
      Income_Protection_id: 17,
        label: "Premium Indexed",
        value: "premium_indexed",
    },
    {
        Income_Protection_id: 18,
          label: " Waiver",
          value: "waiver",
      },
    {
      Income_Protection_id: 19,
        label: "  Total Fund Value",
        value: "total_fund_value",
    },
    {
      Income_Protection_id: 20,
        label: " Valuation Date",
        value: "valuation_date",
    },
    
    {
      Income_Protection_id: 21,
        label: "  Lump Sum Benefit",
        value: "lump_sum_benefit",
    },
    {
      Income_Protection_id: 25,
        label: " Periodic Benefit",
        value: "periodic_benefit",
    },
    {
      Income_Protection_id: 26,
        label: "Benefit Frequency",
        value: "benefit_frequency",
    },
    {
      Income_Protection_id: 27,
        label: "Benefits Indexed",
        value: "benefits_indexed",
    },
    {
      Income_Protection_id: 28,
        label: " Benefit Note",
        value: "benefit_text",
    },
    {
      Income_Protection_id: 29,
        label: " Rated",
        value: "rated",
    },

    {
      Income_Protection_id: 30,
        label: " In Trust",
        value: "in_trust",
    },
    {
      Income_Protection_id: 31,
        label: "In Trust Note",
        value: "in_trust",
    },

    {
      Income_Protection_id: 32,
        label: "Age Admitted",
        value: "age_admitted",
    },
    {
      Income_Protection_id: 33,
        label:  "Assigned To",
        value: "assigned_to",
    },
    {
      Income_Protection_id: 34,
        label: "Admin Note ",
        value: "aadmin_note",
    },
    {
      Income_Protection_id: 35,
        label: "Report Note",
        value: "areport_note",
    },
   
]
export const HealthAssurance = [
    {
        Health_Assurance_id: 0,
        label: "Policyholder",
        value: "policy_holder",
    },
    {
      Health_Assurance_id: 1,
        label: " Assured Detail",
        value: "assured_detail",
    },
    {
      Health_Assurance_id: 2,
        label: "Assured Name 1",
        value: "aassured_name1",
    },
    {
      Health_Assurance_id: 3,
        label: " Assured Name 2",
        value: "aassured_name2",
    },
    {
      Health_Assurance_id: 4,
        label: "Subtype",
        value: "subtype",
    },
    {
      Health_Assurance_id: 5,
        label: " Provider",
        value: "provider",
    },
    {
      Health_Assurance_id: 6,
        label: " Provider Contact",
        value: "provider_contact_name",
    },

    {
      Health_Assurance_id: 7,
        label: "Provider Phone",
        value: "provider_phone",
    },
    {
      Health_Assurance_id: 8,
        label: "Provider Note",
        value: "provider_note",
    },
    {
      Health_Assurance_id: 9,
        label: "Purpose ",
        value: "purpose",
    },
    {
      Health_Assurance_id: 10,
        label: "Start Date",
        value: "start_date",
    },
    {
      Health_Assurance_id: 11,
        label: "End Date",
        value: "end_date",
    },
    {
      Health_Assurance_id: 12,
        label: "Term",
        value: "term",
    },
    {
      Health_Assurance_id: 13,
        label: " Scheme Name ",
        value: "scheme_name",
    },
    {
      Health_Assurance_id: 14,
        label: "Policy Number",
        value: "policy_number",
    },
    {
      Health_Assurance_id: 15,
        label: "Other Reference",
        value: "other_reference",
    },
    {
      Health_Assurance_id: 16,
        label: " Status",
        value: "status",
    },
    {
        Health_Assurance_id: 16,
          label: " Service Status",
          value: "service_status",
      },
    {
      Health_Assurance_id: 17,
        label: "Premium Indexed",
        value: "premium_indexed",
    },
    {
        Health_Assurance_id: 18,
          label: " Waiver",
          value: "waiver",
      },
    {
      Health_Assurance_id: 19,
        label: "  Total Fund Value",
        value: "total_fund_value",
    },
    {
      Health_Assurance_id: 20,
        label: " Valuation Date",
        value: "valuation_date",
    },
    
    {
      Health_Assurance_id: 21,
        label: "  Lump Sum Benefit",
        value: "lump_sum_benefit",
    },
    {
      Health_Assurance_id: 25,
        label: " Periodic Benefit",
        value: "periodic_benefit",
    },
    {
      Health_Assurance_id: 26,
        label: "Benefit Frequency",
        value: "benefit_frequency",
    },
    {
      Health_Assurance_id: 27,
        label: "Benefits Indexed",
        value: "benefits_indexed",
    },
    {
      Health_Assurance_id: 28,
        label: " Benefit Note",
        value: "benefit_text",
    },
    {
      Health_Assurance_id: 29,
        label: " Rated",
        value: "rated",
    },

    {
      Health_Assurance_id: 30,
        label: " In Trust",
        value: "in_trust",
    },
    {
      Health_Assurance_id: 31,
        label: "In Trust Note",
        value: "in_trust",
    },

    {
      Health_Assurance_id: 32,
        label: "Age Admitted",
        value: "age_admitted",
    },
    {
      Health_Assurance_id: 33,
        label:  "Assigned To",
        value: "assigned_to",
    },
    {
      Health_Assurance_id: 34,
        label: "Admin Note ",
        value: "aadmin_note",
    },
    {
      Health_Assurance_id: 35,
        label: "Report Note",
        value: "areport_note",
    },
   
]
export const General = [
    {
        General_id: 0,
        label: "Policyholder",
        value: "policy_holder",
    },
    {
      General_id: 1,
        label: " Assured Detail",
        value: "assured_detail",
    },
    {
      General_id: 2,
        label: "Assured Name 1",
        value: "aassured_name1",
    },
    {
      General_id: 3,
        label: " Assured Name 2",
        value: "aassured_name2",
    },
    {
      General_id: 4,
        label: "Subtype",
        value: "subtype",
    },
    {
      General_id: 5,
        label: " Provider",
        value: "provider",
    },
    {
      General_id: 6,
        label: " Provider Contact",
        value: "provider_contact_name",
    },

    {
      General_id: 7,
        label: "Provider Phone",
        value: "provider_phone",
    },
    {
      General_id: 8,
        label: "Provider Note",
        value: "provider_note",
    },
    {
      General_id: 9,
        label: "Purpose ",
        value: "purpose",
    },
    {
      General_id: 10,
        label: "Start Date",
        value: "start_date",
    },
    {
      General_id: 11,
        label: "End Date",
        value: "end_date",
    },
    {
      General_id: 12,
        label: "Term",
        value: "term",
    },
    {
      General_id: 13,
        label: " Scheme Name ",
        value: "scheme_name",
    },
    {
      General_id: 14,
        label: "Policy Number",
        value: "policy_number",
    },
    {
      General_id: 15,
        label: "Other Reference",
        value: "other_reference",
    },
    {
      General_id: 16,
        label: " Status",
        value: "status",
    },
    {
        General_id: 17,
          label: " Service Status",
          value: "service_status",
      },
    {
      General_id: 18,
        label: "Premium Indexed",
        value: "premium_indexed",
    },
    {
      General_id: 19,
        label: "  Lump Sum Benefit",
        value: "lump_sum_benefit",
    },
    {
      General_id: 20,
        label: " Periodic Benefit",
        value: "periodic_benefit",
    },
    {
      General_id: 21,
        label: "Benefit Frequency",
        value: "benefit_frequency",
    },
    {
      General_id: 22,
        label: "Benefits Indexed",
        value: "benefits_indexed",
    },
    {
      General_id: 23,
        label: " Benefit Note",
        value: "benefit_text",
    },

    {
      General_id: 24,
        label: " In Trust",
        value: "in_trust",
    },
    {
      General_id: 25,
        label: "In Trust Note",
        value: "in_trust",
    },
    {
      General_id: 26,
        label: "Admin Note ",
        value: "aadmin_note",
    },
    {
      General_id: 27,
        label: "Report Note",
        value: "areport_note",
    },
   
]


