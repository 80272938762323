import React from "react";

interface PropsType {
  [x: string]: any;
}
export default function PaymentCalculation(props: PropsType) {
  return (
    <div className="calculation_box">
      <div className="calculation_main">
        <p>
          Current Premium: <span>{props.calculatedData?.currentPremium}</span>
        </p>
        <p>
          Frequency: <span>{props.calculatedData?.currentFrequency}</span>
        </p>
        <p>
          Single Premium:{" "}
          <span>{props.calculatedData?.totalSinglePremiums}</span>
        </p>
        <p>
          Total Paid to Date:{" "}
          <span>{props.calculatedData?.totalPaidToDate}</span>
        </p>
        <p>
          Current Monthly Payment:{" "}
          <span>{props.calculatedData?.currentRegular}</span>
        </p>
      </div>
    </div>
  );
}
