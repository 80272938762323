import React, { useEffect, useState } from "react";
import "../style.css";
import {
  Subtitle2,
  Caption1,
  Caption1Strong,
} from "@fluentui/react-components";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux/store";
import FormContainerWithList from "../../../components/FormContainerWithList";
import { configFm } from "../../../types";
import apiServices from "../../../service";
import OverlayLoader from "../../../components/OverlayLoader";
type ComponentProps = {
  [x: string]: any;
  changeMode?: (mode: string) => void;
  setActiveItem?: (item: any) => void;
  closePage?: () => void;
};
const DependantForm = (props: ComponentProps) => {
  const [formContext, setFormContext] = useState("CLIENT_DEPENDANT");
  const [loading, setLoading] = useState(false);
  const [initialData, setInitialData] = useState({});
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const [configForm, setConfigForm] = useState<configFm>();

  useEffect(() => {
    if (
      formContext &&
      formSettingsObj?.data &&
      formSettingsObj?.data &&
      formSettingsObj.data.formConfig !== undefined
    ) {
      let configObj = formSettingsObj.data.formConfig.find(
        (item: any) => item.context === formContext
      );
      if (configObj) {
        setConfigForm(configObj);
      }
    }
  }, [formSettingsObj]);
  useEffect(() => {
    getDependantsList();
  }, [props.activeCustomerId]);

  const loadListData = () => {
    getDependantsList();
  };
  const getDependantsList = async () => {
    setLoading(true);
    try {
      const res = await apiServices.dependant.getList(props.activeCustomerId);

      if (res.data !== undefined) {
        if (res.data.data) {
          const singleData = {
            dependants: res.data.data,
          };
          setInitialData(singleData);
        }
        setLoading(false);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <>
    <OverlayLoader isLoading={loading} />
      <header className="cl-page-header">
        <Subtitle2>Dependants</Subtitle2>

        <nav className="cl-breadcrumbs">
          <ul>
            <li
              onClick={() => {
                props.setVerticalTab("Client");
              }}
            >
              <a>
                <Caption1>Client Detail</Caption1>
              </a>

              <span className="cl-separator"> / </span>
            </li>
            <li>
              <Caption1Strong>Dependants</Caption1Strong>
            </li>
          </ul>
        </nav>
      </header>
      <div className="form-container">
        <FormContainerWithList
          context={formContext}
          config={configForm}
          activeCustomerId={props.activeCustomerId}
          notify={props.notify}
          initialData={initialData}
          reloadData={loadListData}
        />
      </div>
    </>
  );
};

export default DependantForm;
