import React from "react";

interface PropsType {
  [x: string]: any;
}
export default function WithdrawalCalculation(props: PropsType) {
  return (
    <div className="calculation_box">
      <div className="calculation_main">
        <p>
          Current Withdrawal:{" "}
          <span>{props.calculatedData?.currentRegularWithdrawal}</span>
        </p>
        <p>
          Frequency: <span>{props.calculatedData?.currentFrequency}</span>
        </p>
        <p>
          Single Withdrawal:{" "}
          <span>{props.calculatedData?.totalSinglePremiums}</span>
        </p>
        <p>
          Total Withdrawn to Date:{" "}
          <span>{props.calculatedData?.totalPaidToDate}</span>
        </p>
        <p>
          Current Monthly Withdrawal:{" "}
          <span>{props.calculatedData?.currentRegular}</span>
        </p>
      </div>
    </div>
  );
}
