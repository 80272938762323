import * as React from "react";
import { Tree, TreeItem, TreeItemLayout } from "@fluentui/react-components";
import apiServices from "../../service";
import OverlayLoader from "../../components/OverlayLoader";

interface TreeNode {
  items?: TreeNode[];
  subModule?: string;
  fields?: string;
  module?: string;
}

interface TreeComProps {
  onSelect: (module: string) => void;
  excludedItems: string[];
  selectedField: string | null;
}

const RenderTreeItems = (
  items: TreeNode[],
  onSelect: (
    fields: string,
    fullPath: string,
    customPath: string,
    ModuleFields?: string,
    Fields?: string,
    trimmedPath?: string
  ) => void,
  excludedItems: string[],
  selectedField: string | null,
  parentPath: string = ""
) => {
  if (!Array.isArray(items)) {
    console.warn("Expected items to be an array, but got:", items);
    return null;
  }

  return items.map((item, index) => {
    const isExcluded = excludedItems.includes(item.fields || "");
    if (isExcluded) return null;

    const isBranch = item.items && item.items.length > 0;
    const fullPath = parentPath
      ? `${parentPath} - ${item.module || item.subModule || item.fields || ""}`
      : item.module || item.subModule || item.fields || "";
    const isSelected = selectedField === item.fields;

    const ModuleFields = item.module;
    const Fields = item.fields;

    // Use 'let' for trimmedPath to allow reassignment
    let trimmedPath = parentPath;
    if (trimmedPath) {
      trimmedPath = trimmedPath.split(" - ")[0].trim(); // Correctly reassign the trimmedPath
    }

    const customPath = trimmedPath
      ? `${trimmedPath} - ${item.fields}`
      : `${item.fields}`;

    return (
      <TreeItem key={index} itemType={isBranch ? "branch" : "leaf"}>
        <TreeItemLayout
          onClick={() => {
            const moduleName = item.module || "N/A";
            const fieldName = item.fields || "";

            // Pass the customPath to the onSelect function
            onSelect(
              fieldName,
              fullPath,
              customPath,
              ModuleFields,
              Fields,
              trimmedPath
            );
          }}
          style={{ background: isSelected ? "#f5f5f5" : "transparent" }}
        >
          {isBranch ? item.module || item.subModule : item.fields}
        </TreeItemLayout>
        {isBranch && item.items && (
          <Tree>
            {RenderTreeItems(
              item.items,
              onSelect,
              excludedItems,
              selectedField,
              fullPath
            )}
          </Tree>
        )}
      </TreeItem>
    );
  });
};

export const Default = ({
  onSelect,
  excludedItems,
  selectedField,
}: {
  onSelect: (
    fields: string,
    fullPath: string,
    customPath: string,
    ModuleFields?: string,
    Fields?: string,
    trimmedPath?: string
  ) => void;
  excludedItems: string[];
  selectedField: string | null;
}) => {
  const [treeData, setTreeData] = React.useState<TreeNode[]>([]);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const getExportList = () => {
    setLoading(true);
    apiServices.exportExcel
      .getFields()
      .then((res) => {
        const response = res.data && res.data.data;
        setTreeData(response);
        setLoading(false);
      })

      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  React.useEffect(() => {
    getExportList();
  }, []); // Added dependency array to avoid infinite loop

  return (
    <>
      <OverlayLoader isLoading={isLoading} />
      <Tree aria-label="Dynamic Tree">
        {RenderTreeItems(treeData, onSelect, excludedItems, selectedField)}
      </Tree>
    </>
  );
};
