import React, { useEffect, useState } from "react";
import { configFm } from "../../../types";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import FormContainer from "../FormContainer";
import {
  Tab,
  TabList,
  useId,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
  Link,
  ToastBody,
  ToastTrigger,
} from "@fluentui/react-components";
import { convertStringToArray } from "../../../utils";
import apiServices from "../../../service";

type ComponentProps = {
  [x: string]: any;
};

function CompanyDetails(props: ComponentProps) {
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const [formContext, setFormContext] = useState(
    "SYSTEM_MANAGER_COMPANY_DETAILS"
  );
  const [initialData, setInitialData] = useState({});
  const [configForm, setConfigForm] = useState<configFm>();
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const [mode, setMode] = useState("");
  const [activeTab, setActiveTab] = useState("tab2");
  const [getCredentials, setGetCrendentials] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [companyDetails, setCompanyDetails] = useState([
    {
      value: "",
      label: "",
    },
  ]);

  const handleTabChange = (event: any, data: any) => {
    setActiveTab(data.value);
  };
  const notify = (title?: string, message?: string, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {title ?? title}
        </ToastTitle>
        <ToastBody>{message ?? message}</ToastBody>
      </Toast>,
      { intent: type ? type : "success" }
    );
  const getDataList = async () => {
    try {
      const res = await apiServices.credentials.getList();
      if (res.data && res.data.data) {
        setGetCrendentials(res.data.data);
      }
      // setIsLoading(false);
    } catch (err) {
      // setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getDataList();
  }, []);

  useEffect(() => {
    if (
      formContext &&
      formSettingsObj.systemAdmin &&
      formSettingsObj.systemAdmin.formConfig !== undefined
    ) {
      let configObj = formSettingsObj.systemAdmin.formConfig.find(
        (item: any) => item.context === formContext
      );
      if (configObj) {
        setConfigForm(configObj);
      }
    }
  }, [formSettingsObj, formContext]);

  return (
    <div>
      <Toaster toasterId={toasterId} position="top-end" timeout={5000} />
      <TabList selectedValue={activeTab} onTabSelect={handleTabChange}>
        {!convertStringToArray(process.env.REACT_APP_HIDE_CTA_BUTTON).includes(
          "sm_basicDetails"
        ) ? (
          <Tab value="tab1">Basic Details</Tab>
        ) : (
          ""
        )}
        {!convertStringToArray(process.env.REACT_APP_HIDE_CTA_BUTTON).includes(
          "sm_communication_credentials"
        ) ? (
          <Tab value="tab2">Communication Credentials</Tab>
        ) : (
          ""
        )}
      </TabList>

      {activeTab === "tab1" && (
        <FormContainer
          // context={formContext}
          mode={mode}
          initialData={initialData}
          config={configForm}
          notify={notify}
          dataList={companyDetails}
          isFormAdmin={true}
        />
      )}
      {activeTab === "tab2" && (
        <FormContainer
          context={formContext}
          mode={mode}
          initialData={initialData}
          config={configForm}
          notify={notify}
          dataList={getCredentials}
          isFormAdmin={true}
          getDataList={getDataList}
          />
      )}
    </div>
  );
}

export default CompanyDetails;
