export const Mortgages = [
    {
        Mortgages_id: 0,
        label: "Owner",
        value: "joint_indicator",
    },
    {
      Mortgages_id: 1,
        label: "Borrower",
        value: "borrower",
    },
    {
      Mortgages_id: 2,
        label: "  Borrower Name 1",
        value: "borrower_name1",
    },
    {
      Mortgages_id: 3,
        label: " Borrower Name 2",
        value: "borrower_name2",
    },
    {
      Mortgages_id: 4,
        label: "  Type",
        value: "liability_type",
    },
    {
      Mortgages_id: 5,
        label: " Subtype",
        value: "subtype",
    },
    {
      Mortgages_id: 6,
        label: "  Repayment Method",
        value: "repayment_method",
    },

    {
      Mortgages_id: 7,
        label: "  Purpose",
        value: "purpose",
    },
    {
      Mortgages_id: 8,
        label: "Provider",
        value: "provider",
    },
    {
      Mortgages_id: 9,
        label: "Contact ",
        value: "provider_contact_name",
    },
    {
      Mortgages_id: 10,
        label: "Telephone",
        value: "provider_phone",
    },
    {
      Mortgages_id: 11,
        label: "Address 1",
        value: "provider_address1",
    },
    {
      Mortgages_id: 12,
        label: "Address 2",
        value: "provider_address2",
    },
    {
      Mortgages_id: 13,
        label: " Address 3 ",
        value: "provider_address3",
    },
    {
      Mortgages_id: 14,
        label: "Provider Town",
        value: "provider_town",
    },
    {
      Mortgages_id: 15,
        label: "Provider County",
        value: "provider_county",
    },
    {
      Mortgages_id: 16,
        label: " Provider Country",
        value: "provider_country",
    },
    {
        Mortgages_id: 17,
          label: "Provider Postcode",
          value: "provider_postcode",
      },
    {
      Mortgages_id: 18,
        label: "Provider Note",
        value: "provider_note",
    },
    {
        Mortgages_id: 19,
          label: "Account Number",
          value: "account_number",
      },
    {
      Mortgages_id: 20,
        label: "  Start Date",
        value: "start_date",
    },
    {
      Mortgages_id: 21,
        label: "   End Date",
        value: "end_date",
    },
    {
      Mortgages_id: 22,
        label: " Term",
        value: "term",
    },
    {
      Mortgages_id: 23,
        label: "  Amount Borrowed",
        value: "amount_borrowed",
    },
    {
      Mortgages_id: 24,
        label: " Amount Outstanding",
        value: "amount_outstanding",
    },
    {
        Mortgages_id: 25,
          label: "  Outstanding Date",
          value: "amount_outstanding_date",
      },
   
    {
      Mortgages_id: 26,
        label: "  Interest Rate ",
        value: "interest_rate",
    },
    {
      Mortgages_id: 27,
        label: "Status",
        value: "status",
    },
    {
        Mortgages_id: 28,
          label: "Covered by Insurance",
          value: "covered",
      },
      {
        Mortgages_id: 29,
          label: "Property Value",
          value: "property_value",
      },

      {
        Mortgages_id: 30,
          label: "Loan to Valuation %",
          value: "loan_valuation_percentage",
      },
     
      {
        Mortgages_id: 31,
          label: " Property Address 1",
          value: "property_address1",
      },
      {
        Mortgages_id: 32,
          label: "Property Address 2",
          value: "property_address2",
      },
      {
        Mortgages_id: 33,
          label: " Property Address 3 ",
          value: "property_address3",
      },
      {
        Mortgages_id: 34,
          label: " Property Town",
          value: "property_town",
      },
      {
        Mortgages_id: 35,
          label: " Property County",
          value: "property_county",
      },
      {
        Mortgages_id: 36,
          label: " Property Country",
          value: "property_country",
      },
      {
        Mortgages_id: 37,
          label: " Property Postcode",
          value: "property_postcode",
      },
      {
        Mortgages_id: 38,
          label: " Admin Note",
          value: "note_text",
      },
      
   
   
   
]
export const LoansLeaseHP = [
    {
        LoansLeaseHP_id: 0,
        label: "Owner",
        value: "joint_indicator",
    },
    {
      LoansLeaseHP_id: 1,
        label: "Borrower",
        value: "borrower",
    },
    {
      LoansLeaseHP_id: 2,
        label: "Borrower Name 1",
        value: "borrower_name1",
    },
    {
      LoansLeaseHP_id: 3,
        label: "Borrower Name 2",
        value: "borrower_name2",
    },
    {
      LoansLeaseHP_id: 4,
        label: "Type",
        value: "liability_type",
    },
    {
      LoansLeaseHP_id: 5,
        label: "Subtype",
        value: "subtype",
    },
    {
      LoansLeaseHP_id: 6,
        label: "  Repayment Method",
        value: "repayment_method",
    },
    {
      LoansLeaseHP_id: 7,
        label: "  Purpose",
        value: "purpose",
    },
    {
      LoansLeaseHP_id: 8,
        label: "Provider",
        value: "provider",
    },
    {
      LoansLeaseHP_id: 9,
        label: "Contact ",
        value: "provider_contact_name",
    },
    {
      LoansLeaseHP_id: 10,
        label: "Telephone",
        value: "provider_phone",
    },
    {
      LoansLeaseHP_id: 11,
        label: "Address 1",
        value: "provider_address1",
    },
    {
      LoansLeaseHP_id: 12,
        label: "Address 2",
        value: "provider_address2",
    },
    {
      LoansLeaseHP_id: 13,
        label: "Address 3 ",
        value: "provider_address3",
    },
    {
      LoansLeaseHP_id: 14,
        label: " Town",
        value: "provider_town",
    },
    {
      LoansLeaseHP_id: 15,
        label: " County",
        value: "provider_county",
    },
    {
      LoansLeaseHP_id: 16,
        label: "Country",
        value: "provider_country",
    },
    {
        LoansLeaseHP_id: 17,
          label: "Postcode",
          value: "provider_postcode",
      },
    {
      LoansLeaseHP_id: 18,
        label: "Provider Note",
        value: "provider_note",
    },
    {
      LoansLeaseHP_id: 19,
        label: "Start Date",
        value: "start_date",
    },
    {
      LoansLeaseHP_id: 20,
        label: "End Date",
        value: "end_date",
    },
    {
      LoansLeaseHP_id: 21,
        label: "Term",
        value: "term",
    },
    {
      LoansLeaseHP_id: 22,
        label: "Amount Borrowed",
        value: "amount_borrowed",
    },
    {
      LoansLeaseHP_id: 23,
        label: "Amount Outstanding",
        value: "amount_outstanding",
    },
    {
        LoansLeaseHP_id: 24,
          label: "Outstanding Date",
          value: "amount_outstanding_date",
      },
    {
      LoansLeaseHP_id: 25,
        label: "Interest Rate ",
        value: "interest_rate",
    },
    {
        LoansLeaseHP_id: 26,
          label: "Interest Basis ",
          value: "interest_basis",
      },
    {
      LoansLeaseHP_id: 27,
        label: "Status",
        value: "status",
    },
    {
        LoansLeaseHP_id: 28,
          label: "Premium Covered",
          value: "covered",
      },
      {
        LoansLeaseHP_id: 29,
          label: " Admin Note",
          value: "note_text",
      },
      {
        LoansLeaseHP_id: 30,
          label: " Report Note",
          value: "report_note",
      },  
]
export const CreditCards = [
    {
        Credit_Cards_id: 0,
        label: "Owner",
        value: "joint_indicator",
    },
    {
      Credit_Cards_id: 1,
        label: "Borrower",
        value: "borrower",
    },
    {
      Credit_Cards_id: 2,
        label: "Borrower Name 1",
        value: "borrower_name1",
    },
    {
      Credit_Cards_id: 3,
        label: "Borrower Name 2",
        value: "borrower_name2",
    },
    {
      Credit_Cards_id: 4,
        label: "Type",
        value: "liability_type",
    },
    {
      Credit_Cards_id: 5,
        label: "Subtype",
        value: "subtype",
    },
    {
      Credit_Cards_id: 6,
        label: "  Repayment Method",
        value: "repayment_method",
    },
    {
      Credit_Cards_id: 7,
        label: "  Purpose",
        value: "purpose",
    },
    {
      Credit_Cards_id: 8,
        label: "Provider",
        value: "provider",
    },
    {
      Credit_Cards_id: 9,
        label: "Contact ",
        value: "provider_contact_name",
    },
    {
      Credit_Cards_id: 10,
        label: "Telephone",
        value: "provider_phone",
    },
    {
      Credit_Cards_id: 11,
        label: "Address 1",
        value: "provider_address1",
    },
    {
      Credit_Cards_id: 12,
        label: "Address 2",
        value: "provider_address2",
    },
    {
      Credit_Cards_id: 13,
        label: "Address 3 ",
        value: "provider_address3",
    },
    {
      Credit_Cards_id: 14,
        label: " Town",
        value: "provider_town",
    },
    {
      Credit_Cards_id: 15,
        label: " County",
        value: "provider_county",
    },
    {
      Credit_Cards_id: 16,
        label: "Country",
        value: "provider_country",
    },
    {
        Credit_Cards_id: 17,
          label: "Postcode",
          value: "provider_postcode",
      },
    {
      Credit_Cards_id: 18,
        label: "Provider Note",
        value: "provider_note",
    },
    {
      Credit_Cards_id: 19,
        label: "Start Date",
        value: "start_date",
    },
    {
      Credit_Cards_id: 20,
        label: "End Date",
        value: "end_date",
    },
    {
      Credit_Cards_id: 21,
        label: "Term",
        value: "term",
    },
    {
      Credit_Cards_id: 22,
        label: "Amount Borrowed",
        value: "amount_borrowed",
    },
    {
      Credit_Cards_id: 23,
        label: "Amount Outstanding",
        value: "amount_outstanding",
    },
    {
        Credit_Cards_id: 24,
          label: "Outstanding Date",
          value: "amount_outstanding_date",
      },
    {
      Credit_Cards_id: 25,
        label: "Interest Rate ",
        value: "interest_rate",
    },
    {
        Credit_Cards_id: 26,
          label: "Interest Basis ",
          value: "interest_basis",
      },
    {
      Credit_Cards_id: 27,
        label: "Status",
        value: "status",
    },
    {
        Credit_Cards_id: 28,
          label: "Premium Covered",
          value: "covered",
      },
      {
        Credit_Cards_id: 29,
          label: " Admin Note",
          value: "note_text",
      },
      {
        Credit_Cards_id: 30,
          label: " Report Note",
          value: "report_note",
      },  
]
