import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTrigger,
  Field,
  Input,
} from "@fluentui/react-components";
import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import FormikInputRequest from "../../service/hook/useFormik";
import apiServices from "../../service";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { resetFormData } from "../../redux/reducer";
import { FormErrorMessage } from "../FormErrorMessage/FormErrorMessage";

interface Option {
  readonly label: string;
  readonly value: string;
}
const PartnerDetailPopup = (props: any) => {
  const [loading, setLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const [value, setValue] = useState<Option | null>();
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [newlyAddedSelectOptions, setNewlyAddedSelectOptions] = useState<any>(
    []
  );
  const createOption = (label: string) => ({
    label,
    value: label,
  });
  const defaultOptions = [createOption("")];
  const [options, setOptions] = useState(defaultOptions);

  const AddSelectOptions = () => {
    if (newlyAddedSelectOptions.length > 0) {
      apiServices.selectionList
        .addOptions(newlyAddedSelectOptions)
        .then((response: any) => {
          // console.log("response--add options", response);
        })
        .catch((err) => console.log(err));
    }
  };
  const [selectFieldOptions, setSelectFieldOptions] = useState<any>([]);

  const getSelectionListOptions = async () => {
    try {
      let fieldLbl = "";
      fieldLbl = "title:ModifiableList";
      if (fieldLbl !== "") {
        const res = await apiServices.selectionList.getOptions(fieldLbl);
        if (res.data && res.data.data) {
          setSelectFieldOptions(res.data.data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const FindOption = () => {
    const foundObject = selectFieldOptions.find(
      (obj: any) => obj.field === "title"
    );
    // console.log(foundObject, "Objects found");
    if (foundObject !== undefined) setOptions(foundObject.options);
  };
  useEffect(() => {
    FindOption();
  }, [selectFieldOptions, "title"]);

  const formikData = FormikInputRequest({
    initialValues: {
      title: "",
      first_names: "",
      last_name: "",
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.last_name) {
        errors.last_name = "Last Name is required";
      }
      return errors;
    },
    onSubmitFn: async (values: any) => {
      if (props.mode === "add") {
        try {
          const newData = {
            name_and_address: {
              customer_id: props.activeCustomerId,
              title: values.title,
              first_names: values.first_names,
              last_name: values.last_name,
              client_value: "partner",
            },
          };
          setLoading(true);
          apiServices.client
            .createNew(newData)
            .then((response: any) => {
              setLoading(false);
              if (!response.data.isError) {
                AddSelectOptions();
                setTimeout(() => {
                  setIsFormLoading(false);
                  props.notify(response.data.message, "", "success");
                }, 1000);
                dispatch(resetFormData());
                console.log(response.data.data, "jiugyft");
                props.setActivePartnerId(response.data?.data.customer_id);
                props.setPartnerDialogPopup(false);
                props.reloadDetail();
              } else {
                props.notify("Something went wrong", "", "error");
              }
            })
            .catch((error: Error) => {
              setLoading(false);
              console.log("error");
            });
        } catch (error) {
          console.error("Error adding folder:", error);
        }
      } else if (props.mode === "edit") {
        try {
          const newData = {
            name_and_address: {
              customer_id: props.activeCustomerId,
              title: values.title,
              first_names: values.first_names,
              last_name: values.last_name,
            },
          };
          setLoading(true);
          apiServices.client
            .updateClient(props.initialData.customer_id, newData)
            .then((response: any) => {
              if (!response.data.isError) {
                AddSelectOptions();
                setTimeout(() => {
                  setIsFormLoading(false);
                  props.notify(response.data.message, "", "success");
                }, 1000);
                dispatch(resetFormData());
                // props.getList();
                // if (props.resetClientFormSubmited) {
                //   props.resetClientFormSubmited();
                // }
                props.setPartnerDialogPopup(false);
                if (props.changeMode) {
                  // props.setActiveItem(response.data.data);
                  props.changeMode("edit");
                }
              } else if (response.data.isError) {
                if (
                  response.data.error &&
                  Object.keys(response.data.error).length !== 0
                ) {
                  if (Array.isArray(response.data.error.message)) {
                    props.setFormError(response.data.error.message);
                  } else {
                    props.setFormError((prev: any) => [
                      response.data.error.message,
                    ]);
                  }
                }
              } else {
                props.notify("Something went wrong", "", "error");
              }
            })
            .catch((e: Error) => {
              props.notify(e.message, "", "error");
            });
        } catch (error) {
          console.error("Error adding document:", error);
        }
      }
    },
  });
  const handleCreate = (inputValue: string, name: string) => {
    setLoading(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setLoading(false);
      // setOptions((prev) => [...prev, newOption]);
      setValue(newOption);
      formikData.setFieldValue(name, inputValue);
      if (props.newSelectOptionAdded !== undefined) {
        props.newSelectOptionAdded(name, newOption, "add");
      }
      /* We can add function to add this new value to our option json */
    }, 1000);
  };
  const handleCreateWithParam = (inputValue: string) => {
    handleCreate(inputValue, "title");
  };
  function onChangeValue(key: string, value: any) {
    formikData.setFieldValue(key, value);
  }
  useEffect(() => {
    getSelectionListOptions();
  }, []);
  return (
    <Dialog open={props.partnerDialogPopup} modalType="modal">
      <DialogSurface>
        <DialogBody>
          <DialogContent>
            <div>
              <h3>Add Partner Details</h3>
              <p>
                Please add partner information before adding the details for the
                partner{" "}
              </p>
              {props.formError && props.formError.length > 0 && (
                <FormErrorMessage
                  errorMessages={props.formError}
                  onDismiss={() => props.setFormError([])}
                />
              )}
              <div
                style={{
                  backgroundColor: "#f9f9f9",
                  padding: 10,
                }}
              >
                <Field label="Title">
                  <CreatableSelect
                    name="title"
                    styles={{
                      control: (baseStyles: any, state) => ({
                        ...baseStyles,
                        border: "none",
                        borderColor: state.isFocused ? "grey" : "grey",
                        width: 500,
                      }),
                      menuList: (styles: any) => ({
                        ...styles,
                        maxHeight: 100, // Adjust the maximum height here
                        overflowY: "auto", // Add scrollbar if needed
                      }),
                    }}
                    isClearable
                    isLoading={loading}
                    options={options}
                    onChange={(newValue) => {
                      if (newValue === null) {
                        console.log(newValue);
                        formikData.setFieldValue("title", "");
                        setValue(null);
                        if (props.newSelectOptionAdded !== undefined) {
                          props.newSelectOptionAdded("title", "", "remove");
                        }
                      }
                      newValue &&
                        formikData.setFieldValue("title", newValue.value);
                      setValue(newValue);
                      if (props.customHandleChange !== undefined) {
                        props.customHandleChange({
                          target: { name: "title", value: newValue?.value },
                        });
                      }
                    }}
                    onCreateOption={handleCreateWithParam}
                    value={value}
                    formatCreateLabel={(inputValue: string) => `${inputValue}`}
                  />
                </Field>
                <Field label="First Name(s)">
                  <Input
                    appearance="outline"
                    type="text"
                    name="first_names"
                    value={formikData.values.first_names}
                    onChange={(e) => {
                      onChangeValue("first_names", e.target.value);
                    }}
                  />
                </Field>
                <Field
                  label={
                    <span>
                      Last Name<span className="required-item">*</span>
                    </span>
                  }
                  validationState={
                    formikData.errors.last_name ? "error" : "none"
                  }
                  validationMessage={`${formikData?.errors?.last_name || ""}`}
                >
                  <Input
                    appearance="outline"
                    type="text"
                    name="last_name"
                    value={formikData.values.last_name}
                    onChange={(e) => {
                      onChangeValue("last_name", e.target.value);
                    }}
                  />
                </Field>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button
                appearance="secondary"
                onClick={() => {
                  props.setPartnerDialogPopup(false);
                }}
              >
                Close
              </Button>
            </DialogTrigger>
            <Button
              type="submit"
              appearance="primary"
              className="asc-button-primary"
              onClick={() => {
                if (props.isFormDataUpdated) {
                  formikData.handleSubmit();
                }
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default PartnerDetailPopup;
