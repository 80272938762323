import React, { useEffect, useState } from "react";
import "../style.css";
import {
  Subtitle2,
  Caption1,
  Caption1Strong,
} from "@fluentui/react-components";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { configFm } from "../../../types";
import apiServices from "../../../service";
import FormContainerWithList from "../../../components/FormContainerWithList";
import OverlayLoader from "../../../components/OverlayLoader";

type ComponentProps = {
  [x: string]: any;
  changeMode?: (mode: string) => void;
  setActiveItem?: (item: any) => void;
  closePage?: () => void;
};
const OutgoingsForm = (props: ComponentProps) => {
  const [formContext, setFormContext] = useState("CLIENT_OUTGOINGS");

  const [initialData, setInitialData] = useState({});
  const dispatch: AppDispatch = useDispatch();
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const [configForm, setConfigForm] = useState<configFm>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      formContext &&
      formSettingsObj?.data &&
      formSettingsObj.data.formConfig !== undefined
    ) {
      let configObj = formSettingsObj.data.formConfig.find(
        (item: any) => item.context === formContext
      );
      if (configObj) {
        setConfigForm(configObj);
      }
    }
  }, [formSettingsObj]);
  useEffect(() => {
    if (props.activeCustomerId && props.activeCustomerId !== null) {
      getDataList();
    }
  }, [props.activeCustomerId]);

  const loadListData = () => {
    getDataList();
  };
  const getDataList = async () => {
    setLoading(true);
    try {
      const res = await apiServices.outgoings.getList(props.activeCustomerId);

      if (res.data !== undefined) {
        if (
          res.data.data &&
          res.data.data.result &&
          res.data.data.result.length > 0
        ) {
          const singleData = {
            outgoings: res.data.data.result,
            outgoingsTotals: res.data.data.total,
          };
          setInitialData(singleData);
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <div className="form-container">
      <OverlayLoader isLoading={loading} />
      <header className="cl-page-header">
        <Subtitle2>Outgoings</Subtitle2>

        <nav className="cl-breadcrumbs">
          <ul>
            <li
              onClick={() => {
                props.setVerticalTab("Client");
              }}
            >
              <a>
                <Caption1>Client Detail</Caption1>
              </a>

              <span className="cl-separator"> / </span>
            </li>
            <li>
              <Caption1Strong>Outgoings</Caption1Strong>
            </li>
          </ul>
        </nav>
      </header>
      {configForm && configForm !== undefined && (
        <div className="form-container">
          <FormContainerWithList
            context={formContext}
            config={configForm}
            activeCustomerId={props.activeCustomerId}
            notify={props.notify}
            initialData={initialData}
            reloadData={loadListData}
          />
        </div>
      )}
    </div>
  );
};

export default OutgoingsForm;
