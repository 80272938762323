import React from "react";
import { useParams } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  useId,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
  Link,
  ToastBody,
  ToastTrigger,
} from "@fluentui/react-components";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import ModalDialog from "../../../components/ModalDialog";
import CommissionRule from "./CommissionRule";
import CommissionType from "./CommissionType";
import SideBar from "./SideBar";
const Commission = () => {
  const { section } = useParams();
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);
  const sideMenuList = ["Commission Rule", "Commission Type"];
  const sidebarMenuMap: any = {
    "commision-type": "Commission Type",
    "commission-rule": "Commission Rule",
  };
  const [activeSideMenu, setActiveSideMenu] = useState("");
  useEffect(() => {
    if (section) {
      setActiveSideMenu(sidebarMenuMap[section]);
    }
  }, [section]);
  const notify = (title?: string, message?: string, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {title ?? title}
        </ToastTitle>
        <ToastBody>{message ?? message}</ToastBody>
      </Toast>,
      { intent: type ? type : "success" }
    );
  return (
    <div>
      <Toaster toasterId={toasterId} position="top-end" timeout={5000} />

      {section === "commission-rule" && <CommissionRule notify={notify} />}
      {section === "commission-type" && <CommissionType notify={notify} />}
    </div>
  );
};

export default Commission;
