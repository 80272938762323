import { createSlice } from "@reduxjs/toolkit";
import { Dispatch } from "redux";

const initialState = {
  breadcrumbItems: [],
  loginSessionExpiry: false,
  userPermissionDenied: false,
};
export const appSettingsSlice = createSlice({
  name: "appSettings",
  initialState,
  reducers: {
    addBreadcrumbs: (state, action) => {
      state.breadcrumbItems = action.payload;
    },
    loginSessionExpired: (state, action) => {
      state.loginSessionExpiry = action.payload;
    },
    accessDenied: (state, action) => {
      state.userPermissionDenied = action.payload;
    },
  },
});

export const { addBreadcrumbs, loginSessionExpired, accessDenied } =
  appSettingsSlice.actions;

export default appSettingsSlice.reducer;

export const seesionExpired = (status: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(loginSessionExpired(status));
  };
};
