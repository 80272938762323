import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Search from "../../components/Search";
import ActionDataList from "./ActionDataList";
import Multiselectsearch from "../../components/MultiSearchDropdown";
import { DropdownDatepicker } from "../../components/DropdownwithDatepicker";
import ModalDialog from "../../components/ModalDialog";
import { useSelector } from "react-redux";
import { ChevronDownRegular } from "@fluentui/react-icons";
import { SkeletonLoader } from "../../components/SkeletonLoader";

import { RootState } from "../../redux/store";
import {
  Button,
  Checkbox,
  Link,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Toast,
  ToastTitle,
  ToastTrigger,
  Toaster,
  useId,
  useToastController,
} from "@fluentui/react-components";
import apiServices from "../../service";
import { MRT_PaginationState } from "material-react-table";
import OverlayLoader from "../../components/OverlayLoader";
import { Paginate } from "./Paginate";
interface PropsType {
  setTabs?: Dispatch<SetStateAction<string>>;
}

const List = ({ setTabs }: PropsType) => {
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [actionListItems, setActionListItems] = useState<any>([]);
  const [option, setOption] = useState<string>(
    "category=asset_bank_review&category=asset_shares_review&category=asset_investment_review&category=asset_investment&category=asset_home_review&category=credit_review_date&category=loan_review_date&category=mortgage_review_date&category=policy_review_date&category=client_action&category=case_tracking_action&category=client_review"
  );
  const [rowCount, setRowCount] = useState(0);
  const [optionDate, setOptionDate] = useState("pastDate");
  const [optionDateValue, setOptionDateValue] = useState("Past Dates");
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState(false);
  const [selectedDropDownValues, setSelectedDropDownValues] = useState<
    string[]
  >([]);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [nextPage, setnextPage] = useState(false);
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const [selectedConsultantValues, setSelectedConsultantValues] = useState<
    string[]
  >([]);
  const [consultantOptions, setConsultantOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectedMenuItemConsultant, setSelectedMenuItem] =
    useState("Consultants");
  const [selectedMenuItemAssigne, setSelectedMenuItemAssigne] =
    useState("Assigned");

  const [actionCategories, setActionCategories] = useState<any>([
    { label: "Client Action", value: "client_action" },
    { label: "Asset (Banks) Review", value: "asset_bank_review" },
    { label: "Asset (Home/Person)", value: "asset_home_review" },
    {
      label: "Asset (Investment) Review",
      value: "asset_investment_review",
    },
    { label: "Asset (Shares) Review", value: "asset_shares_review" },
    { label: "Case Tracking Action", value: "case_tracking_action" },
    { label: "Client Review", value: "client_review" },
    { label: "Credit Card Review Date", value: "credit_review_date" },
    { label: "Loan Review Date", value: "loan_review_date" },
    { label: "Mortgage Review Date", value: "mortgage_review_date" },
    { label: "Policy Review Date", value: "policy_review_date" },
  ]);
  const [selectedActionCategories, setSelectedActionCategories] = useState<any>(
    [
      "asset_home_review",
      "credit_review_date",
      "mortgage_review_date",
      "loan_review_date",
      "policy_review_date",
      "client_review",
      "client_action",
      "asset_bank_review",
      "case_tracking_action",
      "asset_investment_review",
      "asset_shares_review",
      "asset_bank_review",
    ]
  );
  const [assigneeOptions, setAssigneeOptions] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    const newOption = selectedConsultantValues.join("&");
    setOption(newOption);
  }, [selectedConsultantValues]);

  const handleMenuItemClick = (value: string) => {
    setSelectedConsultantValues((prev) => {
      if (prev.includes(value)) {
        return prev.filter((val) => val !== value); // Remove if already selected
      }
      return [...prev, value]; // Add if not selected
    });
  };

  const handleCheckboxChangeConsultant = (value: any) => {
    setSelectedConsultantValues((prevValues) => {
      if (prevValues.includes(value)) {
        return prevValues.filter((val) => val !== value);
      } else {
        return [...prevValues, value];
      }
    });
  };

  const handleCheckboxChangeAssigne = (value: any) => {
    setSelectedAssigneeValues((prevValues) => {
      if (prevValues.includes(value)) {
        return prevValues.filter((val) => val !== value);
      } else {
        return [...prevValues, value];
      }
    });
  };

  const renderedMenuItems = consultantOptions.map((option) => (
    <MenuItem
      key={option.value}
      onClick={() => handleMenuItemClick(option.value)}
    >
      {option.label}
    </MenuItem>
  ));

  const [selectedAssigneeValues, setSelectedAssigneeValues] = useState<
    string[]
  >([]);
  // consultant list
  const getConsultantOptions = async () => {
    setIsLoadingSkeleton(true);
    try {
      const response = await apiServices.actionList.getConsultant(
        loggedUser.userId
      );
      if (Array.isArray(response.data.data)) {
        const consultantData = response.data.data.map((consultant: string) => ({
          label: consultant,
          value: consultant,
        }));
        setConsultantOptions(consultantData);
      } else {
        notify("Something went wrong", "error");
      }
    } catch (error) {
      console.log(error);
      notify("Something went wrong", "error");
    } finally {
      setIsLoadingSkeleton(false); // Set loading to false after fetching is complete
    }
  };
  // Assignee list
  const getAssigneeOptions = async () => {
    setIsLoadingSkeleton(true);
    try {
      const response = await apiServices.actionList.getAssignee(
        loggedUser.userId
      );
      if (Array.isArray(response.data.data)) {
        const assigneeData = response.data.data.map((assignee: string) => ({
          label: assignee,
          value: assignee,
        }));
        setAssigneeOptions(assigneeData);
      } else {
        notify("Something went wrong", "error");
      }
    } catch (error) {
      console.log(error);
      notify("Something went wrong", "error");
    } finally {
      setIsLoadingSkeleton(false); // Set loading to false after fetching is complete
    }
  };
  useEffect(() => {
    if (loggedUser?.userId !== null) {
      getActionListData();
    }
  }, [loggedUser?.userId, pagination.pageIndex, pagination.pageSize, page]);
  // useEffect(() => {
  //   if (loggedUser?.userId !== null) {
  //     getConsultantOptions();
  //     getAssigneeOptions();
  //   }
  // }, []);
  useEffect(() => {
    let newOption = selectedDropDownValues.join("&");
    setOption(newOption);
  }, [selectedDropDownValues]);
  function dateSelection(item: any) {
    setOptionDate(item);
  }
  function dateLabel(item: any) {
    setOptionDateValue(item);
  }

  const notify = (message: any, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {message}
        </ToastTitle>
      </Toast>,
      { intent: type ? type : "success" }
    );
  const getActionListData = async () => {
    if (selectedActionCategories.length > 0) {
      setLoading(true);
      try {
        const response = await apiServices.actionList.getList(
          loggedUser.userId,
          optionDate,
          selectedActionCategories,
          selectedConsultantValues,
          selectedAssigneeValues,
          page,
          search
        );
        if (response.data && response.data.data && response.data.data) {
          await setRowCount(
            response.data && response.data.data ? response.data.data.count : 0
          );

          if (response.data.data.length > 0) {
            setnextPage(response.data.nextPage);
            const resultData = response.data.data.map((object: any) => {
              let newItem;
              if (object.category) {
                if (
                  [
                    "asset_bank",
                    "asset_home",
                    "asset_investment",
                    "asset_share",
                  ].includes(object.category)
                ) {
                  newItem = {
                    ...object,
                    date: object.review_date,
                    time: "",
                    type: object.case_type,
                    case_category: "asset",
                    category: object.category,
                    assigned: object.review_assigned,
                    description: object.review_reason,
                    itemId: object.asset_id,
                  };
                } else if (object.category === "case_tracking_action") {
                  newItem = {
                    ...object,
                    date: object.action_date,
                    time: "",
                    type: "Case Action",
                    case_category: "case_tracking_action",
                    assigned: object.action_assigned,
                    description: object.action_note,
                    itemId: object.case_id,
                    category: "Case Tracking Action",
                  };
                } else if (object.category === "policy_review") {
                  newItem = {
                    ...object,
                    date: object.review_date,
                    time: "",
                    type: "Case Action",
                    case_category: "policy_review",
                    category: "Policy Review",
                    assigned: object.review_assigned,
                    description: object.review_reason,
                    itemId: object.policy_id,
                  };
                } else if (object.category === "client_action") {
                  newItem = {
                    ...object,
                    date: object.appointment_date,
                    time: object.appointment_time,
                    type: object.appointment_type,
                    assigned: object.appointment_assigned,
                    description: object.appointment_note,
                    consultant: object.consultant,
                    itemId: object.appointment_id,
                    category: "Client Action",
                  };
                } else if (object.category === "client_review") {
                  newItem = {
                    ...object,
                    date: object.review_date,
                    time: "",
                    type: "",
                    assigned: object.review_assigned,
                    description: object.review_reason,
                    category: "Client Review",
                    consultant: object.consultant,
                    itemId: object.customer_id,
                  };
                } else if (
                  object.category === "loan_review_date" ||
                  object.category === "mortgage_review_date"
                ) {
                  newItem = {
                    ...object,
                    date: object.review_date,
                    time: object.review_completed,
                    type: object.case_type,
                    assigned: object.review_assigned,
                    description: object.review_reason,
                    consultant: object.consultant,
                    itemId: object.liability_id,
                    case_category: "liability",
                  };
                }
              } else {
                if (object.case_type) {
                  if (
                    [
                      "Unit-linked",
                      "Shares",
                      "Home/Personal",
                      "Banks/B Societies",
                    ].includes(object.case_type)
                  ) {
                    newItem = {
                      ...object,
                      date: object.review_date,
                      time: object.review_completed,
                      type: object.case_type,
                      assigned: object.review_assigned,
                      description: object.review_reason,
                      itemId: object.asset_id,
                      category:
                        object.case_type === "Unit-linked"
                          ? "Asset (Investment) Review"
                          : object.case_type === "Shares"
                          ? "Asset (Shares)Review"
                          : object.case_type === "Home/Personal"
                          ? "Asset (Home/Personal)Review"
                          : object.case_type === "Banks/B Societies"
                          ? "Asset (Bank) Review"
                          : "",
                    };
                  }
                  if (
                    ["Mortgages", "Loans/Lease/HP", "Credit Cards"].includes(
                      object.case_type
                    )
                  ) {
                    newItem = {
                      ...object,
                      date: object.review_date,
                      time: object.review_completed,
                      type: object.case_type,
                      assigned: object.review_assigned,
                      description: object.review_reason,
                      itemId: object.liability_id,
                      category:
                        object.case_type === "Mortgages"
                          ? "Mortgages Review Date"
                          : object.case_type === "Loans/Lease/HP"
                          ? "Loan Review Date"
                          : object.case_type === "Credit Cards"
                          ? "Credit Cards Review Date"
                          : "",
                    };
                  }
                }
              }

              return newItem;
            });
            setLoading(false);
            setActionListItems(resultData);
          } else {
            setActionListItems("");
          }
          setLoading(false);
        } else {
          notify(response.data.error, "error");
          setLoading(false);
          return;
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  };
  const handleNext = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePrevious = () => {
    setPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };
  useEffect(() => {
    if (setTabs) {
      setTabs("actionList");
    }
  }, []);

  return (
    <>
      <OverlayLoader isLoading={loading} />
      <div className="page-content-area">
        <Toaster
          toasterId={toasterId}
          position="top-end"
          pauseOnHover
          pauseOnWindowBlur
          timeout={1000}
        />

        <div className="acionlist_filter">
          <Search setSearch={setSearch} search={search} isLoading={loading} />
          <Multiselectsearch
            setSelectedDropDownValues={setSelectedActionCategories}
            selectedDropDownValues={selectedActionCategories}
            placeholder="Choose by Action Category"
            options={actionCategories}
          />
          <DropdownDatepicker
            dateSelection={dateSelection}
            dateLabel={dateLabel}
            optionDateValue={optionDateValue}
            optionDate={optionDate}
          />
          {/* <Multiselectsearch
            setSelectedDropDownValues={setSelectedConsultantValues}
            selectedDropDownValues={selectedConsultantValues}
            placeholder="Consultants"
            options={consultantOptions}
          /> */}
          <Menu
            onOpenChange={() => {
              getConsultantOptions();
            }}
          >
            <MenuTrigger disableButtonEnhancement>
              <Button
                style={{
                  fontWeight: "normal",
                  color: "#606060",
                  border: "1px solid grey",
                }}
                icon={<ChevronDownRegular />}
                iconPosition="after"
              >
                {selectedConsultantValues.length > 0
                  ? selectedConsultantValues.join(", ")
                  : selectedMenuItemConsultant}
              </Button>
            </MenuTrigger>
            <MenuPopover>
              <MenuList>
                {isLoadingSkeleton ? ( // Add a loading state variable
                  <SkeletonLoader isLoadingSkeleton={isLoadingSkeleton} /> // Show the loader if loading
                ) : (
                  <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                    {consultantOptions.map((option) => (
                      <div key={option.value}>
                        <Checkbox
                          label={option.label}
                          checked={selectedConsultantValues.includes(
                            option.value
                          )}
                          onChange={() =>
                            handleCheckboxChangeConsultant(option.value)
                          }
                        />
                      </div>
                    ))}
                  </div>
                )}
              </MenuList>
            </MenuPopover>
          </Menu>

          {/* <Multiselectsearch
            setSelectedDropDownValues={setSelectedAssigneeValues}
            selectedDropDownValues={selectedAssigneeValues}
            placeholder="Assigned"
            options={assigneeOptions}
          /> */}

          <Menu
            onOpenChange={() => {
              getAssigneeOptions();
            }}
          >
            <MenuTrigger disableButtonEnhancement>
              <Button
                style={{
                  fontWeight: "normal",
                  color: "#606060",
                  border: "1px solid grey",
                }}
                icon={<ChevronDownRegular />}
                iconPosition="after"
              >
                {selectedAssigneeValues.length > 0
                  ? selectedAssigneeValues.join(", ")
                  : selectedMenuItemAssigne}
              </Button>
            </MenuTrigger>
            <MenuPopover>
              <MenuList>
                {isLoadingSkeleton ? ( // Add a loading state variable
                  <SkeletonLoader isLoadingSkeleton={isLoadingSkeleton} /> // Show the loader if loading
                ) : (
                  <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                    {assigneeOptions.map((option) => (
                      <div key={option.value}>
                        <Checkbox
                          label={option.label}
                          checked={selectedAssigneeValues.includes(
                            option.value
                          )}
                          onChange={() =>
                            handleCheckboxChangeAssigne(option.value)
                          }
                        />
                      </div>
                    ))}
                  </div>
                )}
              </MenuList>
            </MenuPopover>
          </Menu>
          <Button
            className="asc-button-primary"
            appearance="primary"
            onClick={() => {
              getActionListData();
            }}
            disabled={selectedActionCategories.length === 0}
          >
            Apply filter
          </Button>
        </div>
        <ActionDataList
          actionListItems={actionListItems}
          getActionListData={getActionListData}
          setPagination={setPagination}
          rowCount={rowCount}
          pagination={pagination}
          search={search}
        />
        {actionListItems.length > 0 && (
          <Paginate
            nextPage={nextPage}
            page={page}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
          />
        )}

        <ModalDialog
          options={dialogModalOptions}
          getActionListData={getActionListData}
        />
      </div>
    </>
  );
};

export default List;
