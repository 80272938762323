import React, { useEffect, useState } from "react";
import { makeStyles, tokens, Card } from "@fluentui/react-components";
import ReactQuillComponent from "../../../components/ReactQuillComponent";
import {
  clientDetail,
  partnerDetail,
  policyDetail,
  liabilityDetail,
  assetDetail,
  // review,
} from "../../../shared/commissionSummary";
import apiServices from "../../../service";
import {
  formatedDate,
  formatedDate2,
  formatedTime,
  getYes_NoConversion,
  removeEmptyValues,
} from "../../../utils";
import OverlayLoader from "../../../components/OverlayLoader";

interface PropsType {
  [x: string]: any;
}

const useStyles = makeStyles({
  boxHeading: {
    fontWeight: "bolder",
    marginBottom: "20px",
    paddingTop: "20px",
    paddingBottom: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    fontSize: "18px",
  },
  card: {
    width: "100%",
    height: "fit-content",
    marginBottom: "10px",
  },
});
interface ClientData {
  [key: string]: string | number | boolean | any;
}
interface PartnerData {
  [key: string]: string | number | boolean | any;
}

const Summary = (props: PropsType) => {
  let { context } = props;
  // const [initialData, setInitialData] = useState({});
  const [clientData, setClientData] = useState<ClientData>({});
  const [partnerData, setPartnerData] = useState<PartnerData>({});
  const [activePartnerId, setActivePartnerId] = useState("");
  const [loading, setLoading] = useState(false);

  const styles = useStyles();

  const getClientDetails = (id: string) => {
    setLoading(true);
    apiServices.commissionModule.commissionClient
      .getList(id)
      .then((response: any) => {
        if (!response.data.isError) {
          if (response.data.data) {
            setClientData(response.data.data);
          }
        }
        setLoading(false);
      })
      .catch((e: Error) => {
        setLoading(false);
        console.log(e);
      });
  };
  const getPartnerDetails = (id: string) => {
    apiServices.commissionModule.commissionClient
      .getList(id)
      .then((response: any) => {
        if (!response.data.isError) {
          if (
            response.data.data &&
            Object.keys(response.data.data).length !== 0
          ) {
            if (response.data.data?.customer_id) {
              setPartnerData(response.data.data);
            }
          }
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  // useEffect(() => {
  //   if (
  //     Object.keys(clientData).length !== 0 &&
  //     clientData.associated_type === 0 &&
  //     clientData.associated_with !== "" &&
  //     clientData.associated_with !== null
  //   ) {
  //     getPartnerDetails(clientData.associated_with);
  //     setActivePartnerId(clientData.associated_with);
  //   }
  // }, [clientData]);
  useEffect(() => {
    if (props.activeUser.customer_id)
      getClientDetails(props.activeUser.customer_id);
  }, [props.activeUser]);
  return (
    <div>
      <OverlayLoader isLoading={loading} />
      <div>
        <div className="summary_grid">
          {/* client */}
          <div className="detail-component">
            <p className="case_titile">Client Details</p>
            <div className="detail-grid">
              {Object.keys(clientData).length !== 0 &&
                clientDetail.length > 0 &&
                clientDetail.map((client) => (
                  <div key={client.clientDetail_id}>
                    <p className="detail-label">{client.label}</p>
                    <p className="detail-value">
                      {(() => {
                        switch (client.value) {
                          case "date_of_birth":
                            return (
                              formatedDate(clientData.date_of_birth) || "-"
                            );
                          case "fact_find_completed":
                            return (
                              formatedDate(clientData.fact_find_completed) ||
                              "-"
                            );
                          case "menu":
                            return formatedDate(clientData.menu) || "-";
                          case "idd":
                            return formatedDate(clientData.idd) || "-";
                          default:
                            return clientData[client.value] || "-";
                        }
                      })()}
                    </p>
                  </div>
                ))}
            </div>
          </div>
          <div className="detail-component">
            <p className="case_titile">Partner Details</p>
            <div className="detail-grid">
              {Object.keys(partnerData).length !== 0 &&
                partnerDetail.length > 0 &&
                partnerDetail.map((partner) => (
                  <div key={partner.partnerDetail_id}>
                    <p className="detail-label">{partner.label}</p>
                    <p className="detail-value">-</p>
                    {/* {(() => {
                        switch (partner.value) {
                          case "date_of_birth":
                            return (
                              formatedDate(partnerData.date_of_birth) || "-"
                            );
                          case "fact_find_completed":
                            return (
                              formatedDate(partnerData.fact_find_completed) ||
                              "-"
                            );
                          case "menu":
                            return formatedDate(partnerData.menu) || "-";
                          case "idd":
                            return formatedDate(partnerData.idd) || "-";
                          default:
                            return partnerData[partner.value] || "-";
                        }
                      })()} */}
                  </div>
                ))}
            </div>
          </div>
          {/* Case */}
          <div className="detail-component">
            <p className="case_titile">Case Details</p>
            <h3>Asset</h3>
            <div className="detail-grid">
              {Object.keys(clientData).length !== 0 &&
                assetDetail.length > 0 &&
                assetDetail.map((asset) => (
                  <div key={asset.assetDetail_id}>
                    <p className="detail-label">{asset.label}</p>
                    <p className="detail-value">-</p>
                  </div>
                ))}
            </div>
            <h3>Liability</h3>
            <div className="detail-grid">
              {Object.keys(clientData).length !== 0 &&
                liabilityDetail.length > 0 &&
                liabilityDetail.map((liability) => (
                  <div key={liability.liability_id}>
                    <p className="detail-label">{liability.label}</p>
                    <p className="detail-value">-</p>
                  </div>
                ))}
            </div>
            <h3>Policy</h3>
            <div className="detail-grid">
              {Object.keys(clientData).length !== 0 &&
                policyDetail.length > 0 &&
                policyDetail.map((policy) => (
                  <div key={policy.policy_id}>
                    <p className="detail-label">{policy.label}</p>
                    <p className="detail-value">-</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
