import "./style.css";
import { WaffleIcon } from "@fluentui/react-icons-mdl2";
import axios from "axios";
import {
  Avatar,
  Label,
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuPopover,
  Toaster,
  useToastController,
  useId,
} from "@fluentui/react-components";
import { useSelector, useDispatch } from "react-redux";
import ASCLogo from "../../assets/logo/header_logo.png";
import { AppDispatch, RootState } from "../../redux/store";
import { doLogout } from "../../redux/authSlice";
import { useNavigate } from "react-router-dom";
import { ApiConstants } from "../../config";

const Header = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const { userToken, userData, userId, refreshToken, userProfileInfo } =
    useSelector((state: any) => state.authUser);
  const authUser = useSelector((state: RootState) => state.authUser);

  // useEffect(() => {
  //   if (userToken === undefined || userToken === null) {
  //     navigate("/login");
  //   }
  // }, [userToken]);
  const doUserLogout = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
      Refreshtoken: refreshToken,
    };
    let link = "";
    if (userId) {
      link = `${ApiConstants.auth.logout}`.replace(":user_id", userId);
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}` + link,
        {},
        {
          headers: headers,
        }
      )
      .then((response: any) => {
        if (
          response.data !== undefined &&
          response.data.statusCode !== undefined &&
          [204, 205].includes(response.data.statusCode)
        ) {
          dispatch(doLogout());
        } else {
          dispatch(doLogout());
        }
      })
      .catch((error) => {
        dispatch(doLogout());
        console.log("error", error);
      });
  };

  return (
    <div className="header-body">
      <Toaster toasterId={toasterId} />
      <div className="logo">
        <img src={ASCLogo} alt="assyst_logo" />
      </div>
      {userToken && (
        <div className="header-right-area">
          <Label size="medium" style={{ color: "white" }}>
            Welcome&nbsp;
            {userProfileInfo?.short_name ? `${userProfileInfo.short_name}` : ""}
          </Label>

          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <Avatar aria-label="Guest" />
            </MenuTrigger>

            <MenuPopover>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    navigate("/profile");
                  }}
                >
                  Profile{" "}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    // dispatch(doLogout());
                    doUserLogout();
                  }}
                >
                  {" "}
                  Logout
                </MenuItem>
              </MenuList>
            </MenuPopover>
          </Menu>
        </div>
      )}
    </div>
  );
};

export default Header;
