import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "@fluentui/react-components";
import FileFolderItem from "./FileFolderComponent";
import "./style.css";

interface FolderItem {
  type: "folder";
  name: string;
  lastModified: string;
  path: string;
  children: (FolderItem | FileItem)[];
}

interface FileItem {
  type: "file";
  name: string;
  path: string;
  lastModified: string;
  metadata: {
    customerid: string;
    userid: string;
    description: string;
    visible: string;
    documentid: string;
  };
}

type Item = FolderItem | FileItem;

interface FolderViewProps {
  structure: Item[];
  navigateTo: (path: string[]) => void;
  currentPath: string[];
  searchResults: Item[];
  activeCustomerId: string;
  search: string;
}

const FolderView: React.FC<FolderViewProps> = ({
  structure,
  navigateTo,
  currentPath,
  searchResults,
  search,
  activeCustomerId,
}) => {

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHeaderCell style={{ width: "15vw" }}>Name</TableHeaderCell>
          <TableHeaderCell style={{ width: "5vw" }}>Type</TableHeaderCell>
          <TableHeaderCell style={{ width: "8vw" }}>
            Last Modified
          </TableHeaderCell>
          <TableHeaderCell>Description</TableHeaderCell>
          {search && <TableHeaderCell>Path</TableHeaderCell>}
          <TableHeaderCell>Actions</TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {(search ? searchResults : structure).map((item, index) => (
          <FileFolderItem
            activeCustomerId={activeCustomerId}
            key={index}
            item={item}
            search={search}
            navigateTo={navigateTo}
            currentPath={currentPath}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default FolderView;
